import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { imageUrlFor } from "../../../lib/image-url";
import { buildImageObj } from "../../../lib/helpers";

import LazyLoad from 'react-lazyload';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "120px",
    height: "100px",
    display: "block",
    float: "left",
    paddingRight: "16px",
  },
  imageWrapper: {
    width: "100%",
  },
  logoImage: {
    border: "1px solid #d8dadc",
    borderRadius: "8px",
    maxWidth: "100%",
    width: "100px",
    height: "100px",
    boxShadow: "0px 2px 4px 0px #4040401A",
  },
  imageWrap: {
    cursor: 'pointer',
    maxWidth: "100%",
    width: "100px",
    height: "100px",
  }
}));

export default function BestBuysImage(props) {
  const classes = useStyles();
  const { bestBuyLogo, companyDisplayName, linkHrefReview } = props;
  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'best_buy_card_clicked_value': JSON.stringify(googleAnalyticsObject) });

          window.dataLayer.push({ event: 'best_buy_card_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  var logoClicked = function () {
    setGoogleAnalyticsObject({
      ProviderName: companyDisplayName,
      Source: 'provider logo'
    });
  }

  return (

    <div className={classes.imageContainer}>
      <Grid container alignItems="center" justify="center">
        <Grid item className={classes.imageWrapper}>
          <a href={linkHrefReview} className={classes.imageWrap} onClick={() => { logoClicked() }}>
            {bestBuyLogo && (
              <LazyLoad height={100}>
              <img
                src={imageUrlFor(buildImageObj(bestBuyLogo))
                  .width(100)
                  .height(100)
                  .fit("crop")
                  .auto("format")
                  .url()}
                alt={companyDisplayName}
                className={classes.logoImage}
                />
              </LazyLoad>
            )}
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
