import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ratingContainer: {
    width: "140px",
    lineHeight: "14px",
  },
  ratingSectionTitle: {
    color: "#3C465F",
    fontWeight: "700",
    textTransform: "uppercase",
    fontSize: "14px",
    paddingBottom: "6px",
    lineHeight: "16px"
  },
  weSayResizer: {
    [theme.breakpoints.down("sm")]: {
      // paddingLeft: "8px",
      // paddingRight: "8px",
    },
    [theme.breakpoints.down(360)]: {
      // paddingLeft: "1px",
      // paddingRight: "1px",
    },
  },
  scale: {
    paddingLeft: "2px",
    paddingRight: "2px",
    // transform: "scale(0.75,0.75)",
    // width:"16px",
    // height: "16px"
  },
  ratingSectionTitlePaddedTop: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "16px",
    },
    paddingTop: "1.5em",
  },
  greyBubble: {
    background: "#ECF1F4",
    borderRadius: "8px",
    padding: "5px",
    textAlign: "center",
    color: "#3C465F",
    marginBlockStart: 0,
    display: 'inline-block'
  },
  costLabel: {
    color: '#3C465F',
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: '600',
    marginBlockStart: '0',
    marginBlockEnd: 0,
    textAlign: 'center'
  },
  textCenter: {
    textAlign: 'center'
  }
}));


export default function BestBuysCost(props) {
  const classes = useStyles();
  const apiURL = process.env.GATSBY_CALC_COST_API;
  const [costs, setCosts] = useState("n/a");
  const { dashboardId, calculatorAssumptionsModel, type } = props;
  const [qsAmt, setQsAmt] = React.useState(1000);


  var showRoboBubble = false;
  var showDIYBubble = false;
  if (props.platformType  == "Robo-advisor") {
    showRoboBubble = true;
  }
  if (props.platformType == "Platform") {
    showDIYBubble = true;
  }



  try {


      return (
        <Grid container alignItems="center" className={classes.ratingContainer}>
          <Grid item xs={12}>
          {props.cost ? (<p className={classes.costLabel}>£{props.cost}</p>) : (<p></p>)}
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>

            {showRoboBubble &&
              <p className={classes.greyBubble}>All Inclusive</p>
            }
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>

            {showDIYBubble &&
              <p className={classes.greyBubble}>+ Investment Fees</p>
            }
          </Grid>

        </Grid>
      );


  } catch (e) {
    console.log("ERROR-BestBuysCosts", e)
    return (<p></p>)
  }

}
