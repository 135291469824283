import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import DangerCircle from "../../icon/Danger Circle.svg";
import ContainerForSVG from "../../containerForSVG";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#8C8CA1",
    paddingBottom: "8px",
  },
  percentage: {
    color: "#2F2F4E",
  },
  companyName: {
    color: "#3C455C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "24px",
    },
  },
}));

export default function FundName(props) {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Typography variant="body2" className={classes.title}>
        Fund Name
      </Typography>
      <Typography variant="h4" className={classes.companyName}>
        {props.CompanyName}
      </Typography>
    </Grid>
  );
}
