// @ts-ignore
import React from "react";
import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import BasePortableText from "@sanity/block-content-to-react";
// @ts-ignore
import clientConfig from "../../../../client-config";
import serializers from "../../serializers";
// import Transparent from "../../icon/Transparent.svg";
import Container from "../../container";
// @ts-ignore
import { buildImageObj, getUserObject, createClient } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";
// @ts-ignore
import CampusStrokeGrey from "../../icon/CampusStroke.svg";
// @ts-ignore
import CampusStrokeWhite from "../../icon/WhiteStroke.svg"
// @ts-ignore
import TimerWhite from "../../icon/Timer.svg";
// @ts-ignore
import TimerGreen from "../../icon/TimerGreen.svg";
// @ts-ignore
import TimerOrange from "../../icon/TimerOrange.svg";
// @ts-ignore
import TimerGrey from "../../icon/TimerGrey.svg"
// @ts-ignore
import LessonWhite from "../../icon/Lesson.svg";
// @ts-ignore
import LessonGreen from "../../icon/LessonGreen.svg";
// @ts-ignore
import LessonOrange from "../../icon/LessonOrange.svg";
// @ts-ignore
import LessonGrey from "../../icon/LessonGrey.svg";
// @ts-ignore
import ArrowRight from "../../icon/ArrowRight.svg";
import useAvailableCourses from "../../../hooks/useAvailableCourses";
import CampusCTA from "./reuseableCampusComponents/campusCTA";
import useMediaQuery from "@material-ui/core/useMediaQuery";


type CourseTitleProps = {
  courseId: string;
  mobileImage: any;
  tabletImage: any;
  desktopImage: any;
  rawIntroPara: object;
  rawIntroTitle: object;
  showButton: any;
  buttonText: string;
  buttonLink: any;
  fontColour?: string;
};

type CourseDetailProps = {
  CourseId: string;
  LessonCount: number;
  Status?: string;
  TimeToComplete: string;
}

declare const window: any

const CourseTitle = ({
  courseId,
  mobileImage,
  tabletImage,
  desktopImage,
  rawIntroTitle,
  rawIntroPara,
  showButton,
  buttonText,
  buttonLink,
  // Default dark grey
  fontColour = "dark grey",
}: CourseTitleProps): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    // Overlay 1: img
    // Overlay 2: white gradient bg
    // Overlay 3: blue gradient bg
    backgroundImg: {
      // minWidth: "100vw",
      minHeight: "640px",
      [theme.breakpoints.down(4000)]: {
        backgroundImage: `${desktopImageUrl}`,
        backgroundSize: "contain",
        backgroundPositionX: "center",
      },
      [theme.breakpoints.down(2500)]: {
        // backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.476492) 34.89%, rgba(255, 255, 255, 0.95) 69.6%), ${desktopImageUrl}`,
        backgroundSize: "cover",
        backgroundPositionX: "center",
      },
      [theme.breakpoints.down(1280)]: {
        backgroundImage: `${tabletImageUrl}`,

        backgroundSize: "cover",
      },
      [theme.breakpoints.down(600)]: {
        backgroundImage: `${mobileImageUrl}`,
        backgroundSize: "100%",
        minHeight: "auto",
      },
      backgroundRepeat: "no-repeat",
      // backgroundSize: "cover",
    },
    whiteOverlay: {
      minWidth: "100%",
      minHeight: "640px",
      // background:
      // "linear-gradient(180deg, rgba(255, 255, 255, 4e-05) -62.07%, rgba(255, 255, 255, 0.00627451) -47.13%, rgba(255, 255, 255, 0.0219608) -31.66%, rgba(255, 255, 255, 0.0470588) -15.92%, rgba(255, 255, 255, 0.08) -0.17%, rgba(255, 255, 255, 0.116078) 15.32%, rgba(255, 255, 255, 0.156863) 30.3%, rgba(255, 255, 255, 0.200784) 44.49%, rgba(255, 255, 255, 0.243137) 57.64%, rgba(255, 255, 255, 0.283922) 69.49%, rgba(255, 255, 255, 0.32) 79.78%, rgba(255, 255, 255, 0.352941) 88.23%, rgba(255, 255, 255, 0.378039) 94.59%, rgba(255, 255, 255, 0.393725) 98.6%, rgba(255, 255, 255, 0.4) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.0001) -62.07%, rgba(255, 255, 255, 0.0156863) -47.13%, rgba(255, 255, 255, 0.054902) -31.66%, rgba(255, 255, 255, 0.117647) -15.92%, rgba(255, 255, 255, 0.2) -0.17%, rgba(255, 255, 255, 0.290196) 15.32%, rgba(255, 255, 255, 0.392157) 30.3%, rgba(255, 255, 255, 0.501961) 44.49%, rgba(255, 255, 255, 0.607843) 57.64%, rgba(255, 255, 255, 0.709804) 69.49%, rgba(255, 255, 255, 0.8) 79.78%, rgba(255, 255, 255, 0.882353) 88.23%, rgba(255, 255, 255, 0.945098) 94.59%, rgba(255, 255, 255, 0.984314) 98.6%, #FFFFFF 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, rgba(255, 255, 255, 0.0156863) 9.22%, rgba(255, 255, 255, 0.054902) 18.76%, rgba(255, 255, 255, 0.117647) 28.48%, rgba(255, 255, 255, 0.2) 38.19%, rgba(255, 255, 255, 0.290196) 47.75%, rgba(255, 255, 255, 0.392157) 56.99%, rgba(255, 255, 255, 0.501961) 65.75%, rgba(255, 255, 255, 0.607843) 73.87%, rgba(255, 255, 255, 0.709804) 81.18%, rgba(255, 255, 255, 0.8) 87.52%, rgba(255, 255, 255, 0.882353) 92.74%, rgba(255, 255, 255, 0.945098) 96.66%, rgba(255, 255, 255, 0.984314) 99.14%, #FFFFFF 100%)",
      [theme.breakpoints.between(960, 1253)]: {
        height: "753px",
      },
      [theme.breakpoints.down(600)]: {
        minHeight: "auto",
        background: "inherit",
      },
      [theme.breakpoints.down(460)]: {
        minHeight: "auto",
      },
    },
    blueOverlay: {
      minWidth: "100%",
      height: "640px",
      // background: `linear-gradient(180deg, #5B5AC7 0%, rgba(91, 90, 199, 0) 100%)`,
      opacity: 0.15,
      // transform: "rotateX(180deg)",
      position: "absolute",
      [theme.breakpoints.between(960, 1253)]: {
        height: "753px",
      },
      [theme.breakpoints.down(460)]: {
        height: "753px",
      },
    },
    titleBlockContainer: {
      zIndex: 10,
      position: "relative",
    },
    text: {
      fontSize: "50px",
      color: "black",
    },
    titleContainer: {
      paddingTop: "148px",
      [theme.breakpoints.down("md")]: {
        wordWrap: "anywhere",
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: "112px",
      },
      "& h2, p, h3, h4": {
        color: textFontColour,
        [theme.breakpoints.down(600)]: {
          color: '#3C465F'
        }
      },
      "& h1": {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "35px",
        color: textFontColour,
        padding: "0 !important" as any,
        textShadow: "1px 2px 4px rgba(0, 0, 0, 0.1)",
        [theme.breakpoints.up("md")]: {
          fontSize: "48px",
          lineHeight: "60px",
          fontWeight: "700 !important" as any,
        },

        [theme.breakpoints.down(1211)]: {
          lineHeight: "50px",
          fontSize: "40px",
          fontWeight: 700,
        },
        [theme.breakpoints.down(600)]: {
          color: '#3C465F'
        }
      },
    },
    stroke: {
      maxHeight: "18px",
      maxWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        maxHeight: "fit-content",
      },
    },
    timerLessonSpan: {
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      "& > img": {
        paddingRight: "4px",
      },
    },
    lessonPadding: {
      paddingRight: "20px",
      [theme.breakpoints.up("md")]: {
        paddingRight: "48px",
      },
    },
    startButton: {
      padding: "11.85px 15.8px",
      position: "absolute",
      width: "100%",
      height: "49px",
      background: "#FFD574",
      color: "#3C465F",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16.2025px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        height: "62px",
        marginTop: "24px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
    textParaContainer: {
      paddingTop: "24px",
      [theme.breakpoints.up("md")]: {
        paddingTop: "16px",
      },
      '& h1, h2, h3, ul, li': {
        color: textFontColour,
        [theme.breakpoints.down(600)]: {
          color: '#3C465F'
        }
      },
      "& p": {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: textFontColour,
        paddingBottom: "0 !important" as any,
        [theme.breakpoints.down(600)]: {
          color: '#3C465F'
        }
      },
    },
    arrow: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
        width: "27px",
        height: "26px",
        marginLeft: "auto",
      },
    },
    rootHolder: {
      background: "#FFFFFF",
    },
    titleText: {},
    introParaText: {},
    buttonHolder: {
      marginTop: "24px",
      [theme.breakpoints.between(960, 1253)]: {
        // height: "49px",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "32px",
      },
      [theme.breakpoints.down(460)]: {
        // height: "49px",
        marginTop: "32px",
        marginBottom: "32px",
      },
    },
    buttonHolderSponsor: {
      alignSelf: "center",
      [theme.breakpoints.between(960, 1253)]: {
        // height: "49px",
      },
      [theme.breakpoints.down("md")]: {
        // marginTop: "32px",
      },
      [theme.breakpoints.down(460)]: {
        // height: "49px",
        marginTop: "32px",
        marginBottom: "16px",
      },
    },
    sponsorContainer: {
      padding: "8px",
      justifyContent: "center",
    },
    sponsorImg: {
      height: "100%",
      width: "100%",
    },
    customBodyClass: {
      [theme.breakpoints.up("md")]: {
        maxWidth: "1170px",
      },
    },
  }));

  const [mobileImageUrl, setMobileImageUrl] = useState(
    "url(" +
    (mobileImage && mobileImage.asset && imageUrlFor(buildImageObj(mobileImage).asset).url()) +
    ")"
  );
  const [tabletImageUrl, setTabletImageUrl] = useState(
    "url(" +
    (tabletImage && tabletImage.asset && imageUrlFor(buildImageObj(tabletImage).asset).url()) +
    ")"
  );
  const [desktopImageUrl, setDesktopImageUrl] = useState(
    "url(" +
    (desktopImage && desktopImage.asset && imageUrlFor(buildImageObj(desktopImage).asset).url()) +
    ")"
  );

  var apiURL = process.env.GATSBY_QUESTIONAPI;

  const [activeLessonKey, setActiveLessonKey] = useState<string>();
  const [lessonsMarkedAsCompleted, setLessonsMarkedAsCompleted] = useState([]);
  const [shouldRedirectToLoginPage, setShouldRedirectToLoginPage] =
    useState(false);
  const [triggerMarkCourseAsStarted, setTriggerMarkCourseAsStarted] = useState(false);
  const [courseDetails, setCourseDetails] = useState<CourseDetailProps>();
  const [courseSlug, setCourseSlug] = useState('');
  const [startButtonText, setStartButtonText] = useState('start course');

  const [getLinkToGo, setLinkToGo] = useState('/');


  const matchesMobile = useMediaQuery('(max-width: 600px)')

  const [courseSponsor, setCourseSponsor] = useState({
    _rawCourseSponsorImg: "",
    courseSponsorLink: "",
    courseSponsorName: "",
  })

  const client = createClient();
  var linkToGo = "/";

  useEffect(() => {
    (async () => {
      if ((courseId != "") && (typeof courseId != "undefined") && (courseId != null)) {
        if (triggerMarkCourseAsStarted) {
          window.location.href = courseSlug;
        }
      }
      else {
        if (buttonLink != null) {

          var type = buttonLink._type;

          switch (type) {

            case "post":
              linkToGo = "/learn/articles/" + buttonLink.slug.current;
              break;
            case "linkCategory":
              linkToGo = buttonLink.url
              break;
            case "information":
              linkToGo = buttonLink.subfolder.folder + buttonLink.slug.current;
              break;
            case "landingPage":
              linkToGo = buttonLink.subfolder.folder + buttonLink.slug.current;
              break;
            default:
              linkToGo = "#"
              break;
          }

          setLinkToGo(linkToGo)
        }
      }
    })();
  }, [triggerMarkCourseAsStarted]);

  useEffect(() => {
    (async () => {
      if ((courseId != "") && (typeof courseId != "undefined") && (courseId != null)) {
        var token = window.localStorage.getItem("token");

        var userObject = await getUserObject(token);

        if (userObject.IsAuthenticated) {
          var courseStatus = await getCourseStatus(userObject.Id, token);



          if (courseStatus && courseStatus === 'Completed') {
            setStartButtonText('REVISIT COURSE');
          } else if (courseStatus && courseStatus === 'Started') {
            setStartButtonText('CONTINUE');
          }

        }
      }
      else {
        if (buttonText != null) {
          setStartButtonText(buttonText)
        }
      }

    })();
  }, []);

  const getCourseStatus = async (userId, token) => {


    var model = {
      CourseId: courseId,
      UserId: userId,
    }

    return await fetch(process.env.GATSBY_QUESTIONAPI + '/CourseStatus/CourseStatus', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson.Result;

      });

  }

  const handleCourseStart = async () => {
    if ((courseId != "") && (typeof courseId != "undefined") && (courseId != null)) {
      setTriggerMarkCourseAsStarted(true);

      //Braze tracking
      if (startButtonText == "start course") {
        try {
          window.visibleCourseStarted(courseId);
        } catch (e) {

        }

      }
    }
    else {
      window.location.href = getLinkToGo;
    }


  };

  const getCourseDetails = async () => {

    await fetch(
      process.env.GATSBY_QUESTIONAPI +
      `/CourseDetails/CourseDetails?courseId=${courseId}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // return responseJson;
        setCourseDetails(responseJson.Result)
      })
  }

  useEffect(() => {
    (async () => {
      if ((courseId != "") && (typeof courseId != "undefined") && (courseId != null)) {
        getCourseDetails();

        var courseSlug = await getCourseSlug();

        setCourseSlug(courseSlug);
      }
      // setCourseDetails(getCourseDetails())

    })();
  }, []);

  const bgRef = useRef();

  const getCourseSlug = async () => {
    var pageType = 'course';

    const params = {
      pageType: pageType,
      courseId: courseId
    }


    var query = '*[_type == $pageType && _id ==$courseId]{"slug": slug.current,  "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } }';

    var courses = await client.fetch(query, params)
      .then((response) => response)
      .then((responseJson) => { return responseJson });

    if (!courses || courses.length === 0) {
      return '';
    }

    var subfolder = courses[0].subfolder[0].folder;
    var slug = courses[0].slug;

    if (subfolder === '/') {
      return subfolder + slug;
    } else {
      return subfolder + '/' + slug;
    }
  }

  const [courseColour, setCourseColour] = useState<string>()
  const sanityCourseData = useAvailableCourses()

  useEffect(() => {

    if ((courseId != "") && (typeof courseId != "undefined") && (courseId != null)) {
      const getColourData = () => {
        const { edges } = sanityCourseData.allSanityCourse;

        edges.map((item) => {


          if (courseSlug.includes(item.node.courseLandingPage.slug.current)) {
            setCourseColour(item.node.courseColour)
          }


          if (item.node._id === courseDetails.CourseId) {
            setCourseColour(item.node.courseColour)
            item.node.sponsor &&
              setCourseSponsor({
                _rawCourseSponsorImg: item.node.sponsor._rawCourseSponsorImg,
                courseSponsorLink: item.node.sponsor.courseSponsorLink,
                courseSponsorName: item.node.sponsor.courseSponsorName
              })
          }
        })
      }
      if (courseDetails) {
        getColourData();
      }
    }


  }, [courseDetails])

  const textFontColour = fontColour === "white" ? '#FFFFFF' : '#3C465F';

  const classes = useStyles(textFontColour);
  const yellowSpecificStyles = makeStyles((theme) => ({
    lessonTimeContainer: {
      height: "36px",
      borderRadius: "4px",
      padding: "8px 62px 8px 62px",
      fontStyle: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "1rem",
      background: "#FAE27D",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3C465F",
      [theme.breakpoints.up("md")]: {
        paddingTop: "18px",
        paddingBottom: "18px",
        height: "56px",
      },
    },
  }));

  const greenSpecificStyles = makeStyles((theme) => ({
    lessonTimeContainer: {
      height: "36px",
      borderRadius: "4px",
      padding: "8px 62px 8px 62px",
      fontStyle: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "1rem",
      background: "#6AE579",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3C465F",
      [theme.breakpoints.up("md")]: {
        paddingTop: "18px",
        paddingBottom: "18px",
        height: "56px",
      },
    },
  }));

  const greySpecificStyles = makeStyles((theme) => ({
    lessonTimeContainer: {
      height: "36px",
      borderRadius: "4px",
      padding: "8px 62px 8px 62px",
      fontStyle: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "1rem",
      background: "#D4D4D4;",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3C465F",
      [theme.breakpoints.up("md")]: {
        paddingTop: "18px",
        paddingBottom: "18px",
        height: "56px",
      },
    },
  }));

  const yellowStyles = yellowSpecificStyles()
  const greenStyles = greenSpecificStyles()
  const greyStyles = greySpecificStyles();


  const getCourseColour = (courseColour) => {

    if (courseColour === "yellow") {
      return yellowStyles
    } else if (courseColour === "green") {
      return greenStyles
    } else if (courseColour === "grey") {
      return greyStyles
    }
  }

  const colourSpecificClasses = getCourseColour(courseColour)


  var yellowBtn = true;

  if ((showButton) || (typeof showButton == "undefined") || (showButton == null)) {
    yellowBtn = true;
  }
  else {
    yellowBtn = false;
  }

  return (
    <div className={classes.rootHolder}>
      <div className={classes.backgroundImg} ref={bgRef} id="backgroundImg">
        <div className={classes.whiteOverlay}>
          <Grid container>
            <Container customClassName={undefined} customBodyClass={classes.customBodyClass}>
              <Grid container direction={"row"} md={6} className={classes.titleBlockContainer}>
                <Grid item className={classes.titleContainer}>
                  <BasePortableText
                    className={classes.titleText}
                    blocks={rawIntroTitle}
                    serializers={serializers}
                    {...clientConfig.sanity}
                  />
                </Grid>
                {courseId &&
                  <Grid item xs={12}>
                    <Grid className={courseColour ? colourSpecificClasses.lessonTimeContainer : null}>
                      <span
                        className={`${classes.timerLessonSpan} ${classes.lessonPadding}`}
                      >
                        {courseDetails && courseDetails.LessonCount > 0 && <><img
                          src={LessonGrey} />{courseDetails && courseDetails.LessonCount} lessons</>}

                      </span>
                      <span className={classes.timerLessonSpan}>
                        <img src={TimerGrey} />{courseDetails && courseDetails.TimeToComplete}
                      </span>
                    </Grid>
                  </Grid>
                }
                <Grid className={classes.textParaContainer} item>
                  <BasePortableText
                    className={classes.introParaText}
                    blocks={rawIntroPara}
                    serializers={serializers}
                    {...clientConfig.sanity}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={courseSponsor.courseSponsorName ? classes.buttonHolderSponsor : yellowBtn ? classes.buttonHolder : null}>
                  {/*<Button*/}
                  {/*  onClick={() => handleCourseStart()}*/}
                  {/*  className={classes.startButton}*/}
                  {/*>*/}
                  {/*  start course*/}
                  {/*  <img className={classes.arrow} src={ArrowRight} />*/}
                  {/*</Button>*/}

                  {yellowBtn && <CampusCTA linkText={startButtonText} buttonType={"rectangular"}
                                           pageType={matchesMobile ? "available courses" : "regular"}
                                           customClickEvent={handleCourseStart} arrowIcon={true} newTab={false} />}
                </Grid>
                {courseSponsor && courseSponsor.courseSponsorName &&
                  <Grid container item xs={12} sm={4} md={4} className={classes.sponsorContainer}>
                    <a href={courseSponsor.courseSponsorLink} target={"_blank"} >
                      <img className={classes.sponsorImg} src={imageUrlFor(buildImageObj(courseSponsor._rawCourseSponsorImg as any))
                        .fit("scale")
                        .auto("format")
                        .url()}
                        alt={courseSponsor.courseSponsorName}
                      />
                    </a>
                  </Grid>
                }
              </Grid>
              <Grid xs={6}></Grid>
            </Container>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default CourseTitle;



