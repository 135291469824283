import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Container from "../../container";
import ReusableButton from "../../reusableButton";
import ReusableCheckbox from "../../landingPageComponents/blocks/reusableCheckbox";
import BasePortableText from "@sanity/block-content-to-react";
// @ts-ignore
import clientConfig from "../../../../client-config";
import titlesSerializersForBlueBackground from "./inheritableColorSerializersForBlueBackground";
import axios from "axios";
import fileDownload from "js-file-download";
// @ts-ignore
import errorIcon from "../../widgets/emailSignupWidget/errorIcon.svg";
import titlesSerializers from "./inheritableColorSerializers";
import {Typography} from "@material-ui/core";
import {Link} from "gatsby";
import { emailCaptureSubmissionLogging } from "../../../lib/helpers"

function EmailInputForm(props) {
  const {
    alignment,
    _rawIntroPara,
    _rawThankYouText,
    brazeCustomAttributes,
    downloadUrl,
    linkText,
    signupRedirectLink,
    isAuthed,
  } = props;


  const useStyles = makeStyles((theme) => ({
    background: {
      backgroundColor: "#3C465F",
    },
    textHolder: {
      [theme.breakpoints.up("md")]:{
        width: "100%",
        textAlign: alignment === "Center" ? "center" : "start",
      },
      "& > div":{
        "& h1, h2, h3, h4" :{
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
        },
      },
    },
    contentHolder: {
      alignItems: alignment === "Center" ? "start" : "center",
      color: "#ffffff",
      display: "flex",
      flexDirection: alignment === "Center" ? "column" : "row",
      justifyContent: alignment === "Center" ? "start" : "space-between",
      paddingTop: "48px",
      paddingBottom: "48px",
      [theme.breakpoints.down("md")]:{
        paddingTop: "24px",
        paddingBottom: "24px",
      },
    },
    contentHolderSubmitted:{
      alignItems: alignment === "Center" ? "start" : "center",
      color: "#ffffff",
      display: "flex",
      flexDirection: alignment === "Center" ? "column" : "row",
      justifyContent: alignment === "Center" ? "start" : "space-between",
      paddingTop: "48px",
      paddingBottom: "48px",
      justifyContent: "center",
    },
    textLeft: {
      flexDirection: "row-reverse",
    },
    textRight: {
      flexDirection: "row",
    },
    whiteHeroBlock: {
      background: "#ffffff",
      minHeight: "500px",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    blueHeroBlock: {
      background: "#3C465F",
      color: "#fff",
      minHeight: "500px",
      "& h2, h3, h4, h1, h5, h6": {
        color: "#fff !important",
      },
    },

    beigeHeroBlockCourse: {
      background: "#F7F5E8",
      minHeight: "500px",
      color: "#3C465F",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    blueCoursePageBlock: {
      backgroundColor: "#6464FF",

      color: "#fff",
      minHeight: "500px",
      "& h2, h3, h4, h1, h5, h6": {
        color: "#fff !important",
      },
    },
    buttonMargin: {
      paddingTop: "25px",
      paddingBottom: "25px",
    },
    button: {
      padding: "16px 32px",
      borderRadius: "8px",
      backgroundColor: "#FF0F5F",
      boxShadow: "0px 2px 4px 0px #4040401A",
      transition: "background 0.3s",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&:hover": {
        background: "#ffffff",
      },
      "&:hover $buttonText": {
        color: "#FF0F5F",
      },
    },
    buttonSubscribe: {
      padding: "14px 24px 14px 24px",
      borderRadius: "8px",
      backgroundColor: "#FF0F5F",
      boxShadow: "0px 2px 4px 0px #4040401A",
      transition: "background 0.3s",
      height: "52px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&:hover": {
        background: "#ffffff",
        "& img": {
          filter:
            "invert(58%) sepia(16%) saturate(325%) hue-rotate(201deg) brightness(93%) contrast(86%)",
        },
      },
      "&:hover $buttonText": {
        color: "#FF0F5F",
      },
    },
    buttonText: {
      color: "#ffffff",
      transition: "color 0.3",
    },
    text: {
      flexDirection: "row",
      [theme.breakpoints.down("md")]: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
    imageHolderPaddingTextLeft: {
      padding: "15% 0% 15% 0",
      [theme.breakpoints.down("md")]: {
        padding: "15% 0 15% 0",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "4.16666666666667% 0 24px 0",
        // paddingBottom: "24px",
      },
    },

    imageHolderPaddingTextRight: {
      padding: "15% 0 15% 0%",
      [theme.breakpoints.down("md")]: {
        padding: "15% 0 15% 0",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "4.16666666666667% 0 24px 0",
        // paddingBottom: "24px",
      },
    },
    imageHolderCourse: {
      "& figure": {
        "& img": {
          borderRadius: "0 !important",
        },
      },
    },
    textContainer: {
      paddingRight: "16px",
      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      "& div": {
        "& h1": {
          margin: 0,
        },
        "& h2": {
          paddingTop: "0 !important",
        },
      },
      [theme.breakpoints.down("md")]: {
        paddingRight: "0",
        maxWidth: "100%",
        flex: 1,
      },
    },

    coursePageTextContainerWhite: {
      "& p, h1, h2, h3, h4, h5": {
        color: "#3C465F !important",
      },
      "& ul": {
        listStyle: "none",
      },
      "& li": {
        color: "#3C465F !important",
        "&:before": {
          content: "b",
          color: "#3C465F !important",
          display: "inline-block",
          width: "1em",
          marginLeft: "-1em",
        },
      },
    },
    coursePageTextContainerBlue: {
      "& p, h1, h2, h3, h4, h5": {
        color: "#ffffff !important",
      },
      "& ul": {
        listStyle: "none",
      },
      "& li": {
        color: "#ffffff !important",

        "&::before": {
          content: "b",
          color: "#C94277",
          display: "inline-block",
          width: "1em",
          marginLeft: "-1em",
        },
      },
      "& .textbox-widget-text h5, .textbox-widget-text h4, .textbox-widget-text h3, .textbox-widget-text h2, .textbox-widget-text h1, .textbox-widget-text p, .textbox-widget-text ul, .textbox-widget-text li":
        {
          color: "#3C465F !important",
        },
    },
    textContainerLeftPadding: {
      paddingRight: "10.94017094017094%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0",
      },
    },
    textContainerRightPadding: {
      paddingLeft: "10.94017094017094%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0%",
      },
    },
    heroBodyContainerPadding: {
      maxWidth: "1170px",
    },
    videoSection: {
      // height: "100%",
      // width: "100%",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    inputFieldWhite: {
      background: "#F5F7FA",
      border: "0.5px solid #DBE3EF",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      borderRadius: "8px",
      height: "56px",
      width: "100%",
      "& > div": {
        fontSize: "14px",
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
    },
    inputFieldWhiteError: {
      background: "#F5F7FA",
      border: "1px solid #E82667",
      boxShadow: "0px 0px 0px 4px rgba(237, 75, 158, 0.2)",
      borderRadius: "8px",
      height: "56px",
      width: "100%",
      "& > div": {
        fontSize: "14px",
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },
    inputFieldBlue: {
      background: "#F5F7FA",
      border: "0.5px solid #DBE3EF",
      boxShadow:
        "0px 2px 4px rgba(64, 64, 64, 0.1), inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      borderRadius: "8px",
      height: "56px",
      width: "100%",
      "& > div": {
        fontSize: "14px",
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
    },
    inputFieldBlueError: {
      background: "#F5F7FA",
      border: "1px solid #E82667",
      boxShadow: "0px 0px 0px 4px rgba(237, 75, 158, 0.2)",
      borderRadius: "8px",
      height: "56px",
      width: "100%",
      "& > div": {
        fontSize: "14px",
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },
    submitIcon: {
      paddingLeft: "9px",
    },
    subscriptionContainer: {
      marginTop: "9px",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    inputHolder: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
        paddingBottom: "12px !important",
      },
    },
    buttonHolder: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px !important",
      },
    },
    errorIcon: {
      height: "16px",
      paddingRight: "8px",
    },
    errorTextWhite: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      color: "#50586C",
      paddingTop: "8px",
    },
    errorTextBlue: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      color: "#FFFFFF",
      paddingTop: "8px",
    },
    submitBodyText: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px",
      },
    },
    thankYouContainer: {
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      "& > *":{
        color: "#ffffff !important",
      },
    },
    checkboxSpan: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      display: "inline-block",
      width: "calc(100% - 47px)",
      cursor: "pointer",
    },
    marketingConsentContainer: {
      marginTop: "12px",
      [theme.breakpoints.down(959)]: {
        marginTop: "initial",
      },
    },
    checkboxHolder: {
      display: "flex",
    },
    blockSizing: {
      [theme.breakpoints.up("md")]: {
        maxWidth: "548px",
      },
    },
    linkText:{
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      textDecoration: "none",
      color: "#57C7FF",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    helpContainer:{
      marginTop: "20px",
      [theme.breakpoints.down("md")]:{
        textAlign: "start",
      }
    },
    helpText:{
      fontWeight: 400,
      color: "#ffffff"
    },
  }));

  const classes = useStyles();

  const holderEl = useRef(null);

  // const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});
  const [logGA, setLogGA] = useState(false);


  useEffect(() => {
    (async () => {
      if (logGA) emailCaptureSubmissionLogging()
    })();
  }, [logGA]);

  const [state, setState] = useState({
    username: "",
    errors: {},
    formIsValid: false,
    isSubmitted: false,
    isToggleOn: true,
    mrketingConsent: false,
  });

  let getInputClass = () => {
    if (state.errors && Object.keys(state.errors).length > 0) {
      return classes.inputFieldBlueError;
    } else {
      return classes.inputFieldBlue;
    }
  };
  const handleUsername = (event) => {
    let errors = {};
    let formIsValid = true;
    let field = event.target.value;

    setState((state) => ({
      ...state,
      username: event.target.value,
      errors: errors,
      formIsValid: formIsValid,
    }));
  };
  const submitted = (event) => {
    let formIsValid = state.formIsValid;
    let userName = state.username;
    let errors = {};

    if (typeof state.formIsValid == "undefined") {
      formIsValid = false;
      errors["empty"] = "Field is empty";
      setState((state) => ({ ...state, errors: errors }));
    }

    if (typeof state.formIsValid !== "undefined") {
      let lastAtPos = userName.lastIndexOf("@");
      let lastDotPos = userName.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          userName.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          userName.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is invalid";
        setState((state) => ({ ...state, errors: errors }));
      }
    }

    event.preventDefault();

    if (formIsValid) {
      //signupToBlog(state.username);
      setLogGA(true);

      if (brazeCustomAttributes && brazeCustomAttributes[0]?.customAttributeKey) {
        localStorage.setItem('custom_attr', btoa(brazeCustomAttributes[0].customAttributeKey + "|" + brazeCustomAttributes[0].customAttributeValue));
      }
      localStorage.setItem("genMkt", state.marketingConsent)
      localStorage.setItem('returnUrl', encodeURIComponent(signupRedirectLink ? signupRedirectLink : "/members"));
      window.location.href = "/login?returnUrl=" + encodeURIComponent(signupRedirectLink ? signupRedirectLink : "/members") + "&usr=" + btoa(state.username);

      setState((state) => ({
        ...state,
        isToggleOn: !state.isToggleOn,
        isSubmitted: true,
      }));
    } else {
      holderEl.current.className =
        holderEl.current.className + ` ${classes.inputError}`;
      //console.log("formIsValid", formIsValid);
    }
  };

  const handleChange = (event) => {
    setState({ ...state, marketingConsent: event.target.checked });
  };

  return (
    <Grid
      className={classes.background}
      container
      item
      alignItems="center"
      justify="center"
    >
      <Container
        customBodyClass={alignment === "Center" ? classes.blockSizing : null}
      >
        <Grid container className={!state.isToggleOn ? classes.contentHolderSubmitted : classes.contentHolder}>
            <>
              <Grid
                item
                md={alignment === "Center" ? 12 : 6}
                className={classes.textHolder}
              >
                <BasePortableText
                  blocks={_rawIntroPara}
                  serializers={titlesSerializersForBlueBackground}
                  {...clientConfig.sanity}
                />
              </Grid>
              <Grid
                item
                md={alignment === "Center" ? 12 : 5}
                className={classes.inputHolderOuter}
              >
                <Grid
                  container
                  className={classes.subscriptionContainer}
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                    md={8}
                    lg={8}
                    className={classes.inputHolder}
                  >
                    <TextField
                      placeholder="Enter your email address..."
                      ref={holderEl}
                      id="emailField"
                      className={getInputClass()}
                      value={state.username}
                      onChange={handleUsername}
                    />
                    {state.errors && Object.keys(state.errors).length > 0 ? (
                      <span className={classes.errorTextBlue}>
                        <img
                          className={classes.errorIcon}
                          src={errorIcon}
                          alt="error"
                        />
                        Please enter a valid email address{" "}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    className={classes.buttonHolder}
                  >
                    <ReusableButton
                      noLRPadding={true}
                      linkText={linkText}
                      arrowIcon={false}
                      subscribeIcon={true}
                      customClickEvent={submitted}
                    />
                    {/*<Button className={classes.buttonSubscribe} onClick={submitted}>*/}
                    {/*  <Typography variant="button" className={classes.buttonText}>*/}
                    {/*    {linkText}*/}
                    {/*  </Typography>*/}
                    {/*  <Hidden only={"md"}>*/}
                    {/*    <img src={emailIcon} className={classes.submitIcon} />*/}
                    {/*  </Hidden>*/}
                    {/*</Button>*/}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.marketingConsentContainer}
                  >
                    <label className={classes.checkboxHolder}>
                      <ReusableCheckbox
                        handleChange={handleChange}
                        checked={state.marketingConsent}
                        isSidebar={true}
                      />
                      <span className={classes.checkboxSpan}>
                        I would like to hear from you about products, events,
                        general marketing and other things from Boring Money –
                        including personalised content and ads - but please try
                        not to make them as boring as everything else in
                        finance!
                      </span>
                    </label>
                  </Grid>
                  <Grid item xs={12} className={classes.helpContainer}>
                    <Typography className={classes.helpText} variant={"body1"}>
                        Already have an account?{" "}
                          <a className={classes.linkText} href={"/login"}>Login</a>
                      </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
        </Grid>
      </Container>
    </Grid>
  );
}

export default EmailInputForm;
