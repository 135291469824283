import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { imageUrlFor } from "../../../../../lib/image-url";
import { buildImageObj } from "../../../../../lib/helpers";
import { AddTrailingSlashToUrl } from "../../../../../lib/helpers";

import BestBuysImage from "../../bestBuysImage";
import BestBuysRatings3x3WouldRecommend from "../..//layout/3x3WouldRecommend/bestBuysRatings";
const useStyles = makeStyles((theme) => ({
  ratingsContainer: {
    height: '100%',
    paddingLeft: '16px'
  },
  awardProviderPageLogo: {
    maxHeight: '67px',
    [theme.breakpoints.down(1270)]: {
      maxHeight: '50px',

    },
    [theme.breakpoints.down(1000)]: {
      width: '40px'
    },
  },
  logoContainer: {
    width: '100px',
    [theme.breakpoints.down(1270)]: {
      width: '80px'
    },
    [theme.breakpoints.down(1220)]: {
      width: '60px'
    },

  },
  ratingAndProviderLogoContainer: {
    width: 'calc(100% - 100px)',
    [theme.breakpoints.down(1270)]: {
      width: 'calc(100% - 80px)'
    },
    [theme.breakpoints.down(1220)]: {
      width: 'calc(100% - 60px)'
    },
  },
}));

export default function BestBuysFirstRow(props) {
  const {

    companyLogo,
    companyDisplayName,
    linkHrefReview,
    materialUiScoreOur,
    smallDistanceStyle,
    bestBuyAward,
    awardURL
  } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.logoContainer}>
        <BestBuysImage
          bestBuyLogo={companyLogo}
          companyDisplayName={companyDisplayName}
          linkHrefReview={AddTrailingSlashToUrl(linkHrefReview)}
        />
      </div>
      <Grid container alignItems="center" justify="flex-start" className={classes.ratingAndProviderLogoContainer}>
        <Grid item xs={8}>
          <Grid container className={classes.ratingsContainer} alignItems="center" justify="flex-start">
            <BestBuysRatings3x3WouldRecommend
              materialUiScoreOur={materialUiScoreOur}
              smallDistanceStyle={smallDistanceStyle}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" justify="flex-end" className={classes.fullHeight}>
            <Grid item>
              {bestBuyAward && bestBuyAward.length > 0 && bestBuyAward[0].providerPageLogo &&
                <a href={AddTrailingSlashToUrl(awardURL)} target="_blank">
                <img
                  src={imageUrlFor(buildImageObj(bestBuyAward[0].providerPageLogo))
                    .width(100)
                    .height(100)
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt={companyDisplayName}
                  className={classes.awardProviderPageLogo}
                />
              </a>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
