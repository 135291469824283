import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContainerForLinks from "../../../containerForLinks";
import { Grid, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonMargin: {
    paddingTop: "25px",
    paddingBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonNoMargin: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    background: "#FFD574",
    borderRadius: "61px",
    height: "54px",
    padding: "18px 24px 18px 24px",
    [theme.breakpoints.down("sm")]:{
      width: "100%",
      height: "62px",
    },
  },
  buttonText: {
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "0.04em",
    color: "#3C465F",
  },
}));

const RoundCampusCTA = ({reference, styles, linkText, alignment}) => {
  const classes = useStyles();
  return (
    <Grid className={alignment === "Text Left" ? classes.buttonNoMargin : classes.buttonMargin}>
      {/*<button onClick={() => console.log(alignment)}>align</button>*/}
      {reference?._type === "widgetCategory" ? (
        <ContainerForLinks reference={reference} _type="widgetCategory">
          <Button
            className={classes.button}
          >
            <Typography
              variant="button"
              className={
                (styles == "White" && classes.buttonTextWhite) ||
                classes.buttonText
              }
            >
              {linkText}
            </Typography>
          </Button>
        </ContainerForLinks>
      ) : (
        <ContainerForLinks reference={reference} _type={reference?._type}>
          <Button
            className={
              (styles == "White" && classes.redButton) || classes.button
            }
          >
            <Typography
              variant="button"
              className={
                classes.buttonText
              }
            >
              {linkText}
            </Typography>
          </Button>
        </ContainerForLinks>
      )}
    </Grid>
  );
};

export default RoundCampusCTA;
