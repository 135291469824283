import * as React from "react";
import {Grid} from "@material-ui/core";
import Container from "./../../../../container"
import {RenderBigBlockCards} from "./renderBigBlockCards";

export default function LeftOneBigByRightTwoSmallCards(props) {

  const {
    classes,
    styles,
    cardListDecider,
    isCoursePage,
    bigBlockItems,
    layout,
    equaliseHeight,
    paragraphLineClamp,
    clampClasses,
    brazeData,
    firstname,
    type,
    contentCardKeyValuePair
  } = props;

 

  return (
    <Grid
      className={isCoursePage ? cardListDecider(styles) : (styles === "White" ? classes.cardList : styles === "Blue" ? classes.cardListBlueNoCampus : null)}>
      <Container>
        <Grid container className={classes.bigBySmallContainer} spacing={3}>
          <RenderBigBlockCards contentCardKeyValuePair={contentCardKeyValuePair} type={type} bigBlockItems={bigBlockItems} brazeData={brazeData} layout={layout} isCoursePage={isCoursePage} styles={styles}
            bigCardRender={true} clampClasses={clampClasses} equaliseHeight={equaliseHeight}
            paragraphLineClamp={paragraphLineClamp} firstname={firstname} />
          <Grid
            container
            md={6}
            direction="row"
            // spacing={3}
            // justifyContent="space-between"
            className={classes.rightTwoCardsContainer}
          >
            <RenderBigBlockCards contentCardKeyValuePair={contentCardKeyValuePair} type={type} bigBlockItems={bigBlockItems} brazeData={brazeData} layout={layout} isCoursePage={isCoursePage}
                                 styles={styles}
              equaliseHeight={equaliseHeight} clampClasses={clampClasses} firstname={firstname}/>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}
