import React from "react";
import { Typography, Button, Menu, MenuItem, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PageProps } from "gatsby";
import TwitterIcon from "./icons/Twitter.svg";
import bmlogo2 from "./icons/bmlogo2.jpg";

interface TweetProps {
  tweetContent: string;
  URLofTweet: string;
}

const TwitterCard = ({ tweetContent, URLofTweet }: TweetProps) => {
  const useStyles = makeStyles((theme) => {
    return {
      card: {
        [theme.breakpoints.down("md")]: {
          padding: "8px",
        },
        [theme.breakpoints.up("md")]: {
          padding: "12px",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "15px",
        },
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      },
      tweetContainer: {
        backgroundColor: "#ffffff",
        fontFamily: `"Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif`,
        fontSize: "12px",
        height: "100%",
        width: "100%",
        lineHeight: "16px",
        borderColor: "#eee #ddd #bbb",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
        padding: "12px 16px 12px 16px",
        "&:hover": {
          boxShadow: "0px 10px 30px 1.6px #4646462b",
          filter: "none",
        },
        "& a": {
          color: "inherit",
          fontWeight: "normal",
          textDecoration: "none",
          outline: "0 none",
        },
        "& a:hover, a:focus": {
          textDecoration: "underline",
        },
      },
      twitterIconContainer: {
        alignSelf: "flex-start",
        display: "flex",
        [theme.breakpoints.between("sm", "md")]: {
          justifyContent: "flex-end",
        },
      },
      twitterIcon: {
        // height: "24px",
        // width: "24px",
      },
      bmLogo: {
        height: "48px",
        width: "48px",
        borderRadius: "20px",
      },
      accountName: {
        display: "contents",
        "& a": {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 700,
        },
      },
      accountHandle: {
        "& a": {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 400,
        },
      },
      twitterLink: {
        color: "rgb(29, 155, 240) !important",
        wordBreak: "break-all",
      },
      logoTitleContainer: {},
      tweetContent: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        wordBreak: "break-word",
      },
      accountDetailsHolder: {
        flexGrow: 1,
        maxWidth: "88.33%",
        width: "100%",
        "@media (max-width: 360px)":{
          paddingLeft: "10px",
        },
        [theme.breakpoints.between("sm", "md")]:{
          maxWidth: "inherit",
        },
      },
      detailsContainer: {
        flexWrap: "nowrap",
      },
      bmLogoHolder: {
        "@media (min-width: 0px) and (max-width: 360px)": {
          maxWidth: "inherit",
        },
      },
    };
  });

  const classes = useStyles();

  const tweetLink = `https://twitter.com/boringmoney/status/${URLofTweet}`;

  return (
    <Grid className={classes.card} xs={12} md={4}>
      <a href={tweetLink}>
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          className={classes.tweetContainer}
        >
          <Grid item xs={12}>
            <a href={tweetLink}>
              <Grid container alignItems="center">
                <Grid
                  item
                  className={classes.bmLogoHolder}
                  xs={2}
                  sm={1}
                  md={3}
                  lg={2}
                >
                  <img className={classes.bmLogo} src={bmlogo2} />
                </Grid>
                <Grid
                  item
                  container
                  className={classes.accountDetailsHolder}
                  direction="row"
                  xs={9}
                  sm={11}
                  md={9}
                  lg={10}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    xs={10}
                    className={classes.detailsContainer}
                  >
                    <Grid xs={6} item className={classes.accountName}>
                      <a href="https://twitter.com/boringmoney">Boring Money</a>
                    </Grid>
                    <Grid xs={6} item className={classes.accountHandle}>
                      <a href="https://twitter.com/boringmoney">@boringmoney</a>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.twitterIconContainer} xs={2}>
                    <img
                      className={classes.twitterIcon}
                      src={TwitterIcon}
                      alt="twitter logo"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </a>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.tweetContent}>{tweetContent}</p>
          </Grid>
        </Grid>
      </a>
    </Grid>
  );
};

export default TwitterCard;
