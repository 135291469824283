import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
// import BestBuysImage from "./components/bestBuysImage";
// import BestBuysInANutshell from "./components/bestBuysInANuthshell";
// import BestBuysRatings from "./components/bestBuysRatings";
// import BestBuysButtons from "./components/bestBuysButtons";
// import MobileBestBuysCost from "./components/mobileBestBuyCost";
// import BestBuysAwardImage from "./components/bestBuysAwardImage";

const useStyles = makeStyles((theme) => ({
  providerHeaderContainer: {
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    padding: "8px 16px",
    position: "relative",
    borderBottom: "1px solid #E6E8F0",
    height: "41px"
  },
  companyDisplayName: {
    color: "#3C465F",
    fontWeight: "600",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "white",
    width: "auto",
    // height: "24px",
  },
  providerContainer: {
    background: "white",
    borderRadius: "0px 0px 8px 8px",
    maxWidth: "100%",
    margin: "0",
    // overflow: "hidden",
    position: "relative",
    paddingTop: "16px",
    minHeight: "fit-content",
  },
  logoAndScoreContainer: {
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cardContainer: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    marginBottom: "24px",
  },
  companyHighlight: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    color: "#2F2F4E",
    overflow: "hidden",
  },
  reviewAnchor: {
    textDecoration: "none",
    cursor: "pointer",
  },
  ratingsPullRight: {
    marginRight: "-1em",
  },
  cardContainerNoMarginBottom: {
    background: "white",
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    minHeight: "444px",
  },
  imageContainer: {
    width: "120px",
    height: "100px",
    display: "block",
    float: "left",
  },
  imageWrapper: {
    width: "100%",
  },
  logoImage: {
    border: "1px solid #d8dadc",
    borderRadius: "8px",
    maxWidth: "100%",
    width: "100px",
    height: "100px",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  imageWrap: {
    cursor: "pointer",
    maxWidth: "100%",
    width: "100px",
    height: "100px",
  },
  nutshellSkeleton: {
    height: "110px",
    // padding: "1rem",
    padding: "0 1rem 1rem 1rem"
  },
  costSkeleton: {
    height: "54px",
  },
  skeletonButtons: {
    height: "70px",
  },
  skeletonEffect: {
    [theme.breakpoints.down("sm")]: {
      height: "50px"
    }},
  buttonSkeletonContainer: {
    padding: "1rem",
    marginTop: "0",
    marginBottom: "0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  ratingsSkeleton: {
    height: "100px",
    paddingRight: "1rem"
  }
}));

function BestBuysImageSkeleton() {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.imageContainer}>
      <Grid container alignItems="center" justify="center">
        <Skeleton variant="rect" width="100%">
          <Grid item className={classes.imageWrapper}>
            <div className={classes.logoImage}></div>
          </Grid>
        </Skeleton>
      </Grid>
    </Grid>
  );
}

export default function BestBuysTableRowMobileSkeleton(props) {
  const classes = useStyles();

  return (
    <Grid
      className={`${
        props.defaultLayout == null || props.defaultLayout == "1x1"
          ? classes.cardContainer
          : classes.cardContainerNoMarginBottom
      }`}
    >
      <div className={classes.providerHeaderContainer}>
        <Grid className={classes.companyDisplayName}>
          <Skeleton variant="rect" height={24}/>
        </Grid>
      </div>
      <div className={classes.providerContainer}>
        <Grid container direction="row" className={classes.logoAndScoreContainer}>
          <Grid container xs={12}>
            <BestBuysImageSkeleton />
          </Grid>
          {/* <Grid item xs={7}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid container item className={classes.ratingsPullRight} justify="flex-end">
                <Grid className={classes.ratingsSkeleton} item>
                  <Skeleton variant="rect" height={"105px"} width={"120px"}></Skeleton>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>

        <Grid className={classes.nutshellSkeleton}>
          {" "}
          <Skeleton variant="rect" height="100%"></Skeleton>
        </Grid>

        <Grid className={classes.costSkeleton}>
          <Skeleton variant="rect" height="100%"></Skeleton>
        </Grid>

        <Grid container className={classes.buttonSkeletonContainer} spacing={2}>
          <Grid item md={6} sm={12} className={classes.skeletonButtons}>
            {" "}
            <Skeleton variant="rect" height="100%" width="100%"></Skeleton>
          </Grid>
          <Grid item md={6} sm={12} className={classes.skeletonButtons}>
            {" "}
            <Skeleton variant="rect" height="100%" width="100%"></Skeleton>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
