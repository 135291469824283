import React, { useState, useEffect, useRef } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../../../container";
import DesktopTab from "./desktopTab";
import DesktopMainText from "./desktopMainText";
import MobileSlide from "./mobileSlide";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const useStyles = makeStyles((theme) => ({
  tabsTextboxBlockWrapper: {
    background: '#3C465F'
  },
  tabsTextboxBlockContainer: {
    height: '428px',
    paddingTop: '48px',
    paddingBottom: '48px',
    [theme.breakpoints.down(600)]: {
      display: 'none'
    }
  },
  tabsTextboxBlockContainerMobile: {
    width: '100vw',
    paddingTop: '32px',
    paddingBottom: '32px',
    [theme.breakpoints.up(600)]: {
      display: 'none'
    }
  },
  tabsContainer: {
    height: '100%',
    width: '400px',
    overflowY: 'auto',
    paddingRight: '12px',
    display: 'inline-block',
    [theme.breakpoints.down(1250)]: {
      width: '300px'
    },
    [theme.breakpoints.down(950)]: {
      width: '250px'
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '30px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(148, 164, 214, .4)',
      opacity: '0.4',
      width: '4px',
      borderRadius: '30px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(148, 164, 214, 1)',
      boxShadow: '0px 3px 4px rgba(111, 116, 126, 0.16)',
      borderRadius: '30px',
      width: '6px'
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(148, 164, 214, 1) rgba(148, 164, 214, .4)'
  },
  tabContainer: {
    cursor: 'pointer'
  },
  mainTextContainer: {
    display: 'inline-block',
    width: 'calc(100% - 420px)',
    height: '100%',
    verticalAlign: 'top',
    borderRadius: '12px',
    marginLeft: '20px',
    [theme.breakpoints.down(1250)]: {
      width: 'calc(100% - 320px)',
    },
    [theme.breakpoints.down(950)]: {
      width: 'calc(100% - 270px)',
    },
  },
  tabTitle: {

  },
  tabParagraph: {

  },
  paginationContainer: {
    marginTop: '1.5em',
    display: 'flex',
    justifyContent: 'center'
  },
  swiperSlide: {
    height: 'auto'
  },
  paginationElement: {

  },
  bulletActiveClass: {
    background: '#FFFFFF !important',
    border: '1.5px solid #FFFFFF',
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    marginRight: '6px'
  },
  bulletClass: {
    background: 'rgba(0,0,255,0.0)',
    border: '1.5px solid #FFFFFF',
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    marginRight: '6px'
  }
}));

export default function TabsTextboxBlock(props) {
  const { tabs } = props;

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const paginationElement = useRef(null);

  //console.log("tabs:", tabs);

  const classes = useStyles();

  var tabClick = function (index) {
    setActiveTabIndex(index);
    setActiveTab(tabs[index]);
  }

  return (
    <div className={classes.tabsTextboxBlockWrapper}>
      {/*<button onClick={() => console.log(props)}>props</button>*/}
      <Container>
        <div className={classes.tabsTextboxBlockContainer}>
          <div className={classes.tabsContainer}>
            {tabs && tabs.map((tab, index) =>
              <DesktopTab tab={tab} isActive={index === 0 ? true : false} tabClicked={tabClick} index={index} activeTabIndex={activeTabIndex} />
            )}
          </div>
          <div className={classes.mainTextContainer}>
            <DesktopMainText text={activeTab._rawTextParagraph} fullSizeImage={activeTab.fullSizeImage} image={activeTab.image} imagePosition={activeTab.imagePosition} />
          </div>

        </div>
        <div className={classes.tabsTextboxBlockContainerMobile}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{
              el: '.swiper-pagination-container',
              bulletClass: classes.bulletClass,
              bulletActiveClass:
                classes.bulletActiveClass,
              clickable: true,
            }}
            slidesPerView={1.15}
            spaceBetween={16}
            centeredSlides={true}
          >
            {tabs && tabs.map((tab, index) =>
              <SwiperSlide className={classes.swiperSlide}>
                <MobileSlide tab={tab} />
              </SwiperSlide>
            )}

            <div ref={paginationElement} className={`${'swiper-pagination-container'} ${classes.paginationContainer}`}></div>
          </Swiper>
        </div>
      </Container>
    </div>
  );
}
