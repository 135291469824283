import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, useMediaQuery, Typography } from "@material-ui/core";
import BasePortableText from "@sanity/block-content-to-react";
// @ts-ignore
import clientConfig from "../../../../client-config";
import serializers from "../../serializers";
import useAvailableCourses from "../../../hooks/useAvailableCourses";
import Container from "../../container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
// @ts-ignore
import ArrowRight from "../../icon/ArrowRight.svg";
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";
import ContainerForLinks from "../../containerForLinks";
// @ts-ignore
import TimerRed from "../../icon/TimerRed.svg";
// @ts-ignore
import TimerGreen from "../../icon/TimerGreen.svg";
// @ts-ignore
import LessonRed from "../../icon/LessonRed.svg";
// @ts-ignore
import LessonGreen from "../../icon/LessonGreen.svg";
import CourseStatusBar from "./reuseableCampusComponents/courseStatusBar";
import CampusCTA from "./reuseableCampusComponents/campusCTA";
import { getUserObject, getPageUrlOutOfSanityReference } from "../../../lib/helpers";
import PortableText from "../../portableText";
import VideoLinkHandler from "../../linkHandler/videoHandler";
import { Link } from "gatsby";

interface customWindow extends Window {
  courseTitleClick: any
}

declare const window: customWindow;

type CourseProps = {
  courseColour: string;
  courseDescription?: string;
  _rawAvailableCourseDescription?: any;
  _id: string;
  title: string;
  mainImage: {
    alt: string;
    asset: object;
  };
  courseLandingPage: {
    _type: string;
    slug: {
      _key?: string;
      _type: string;
      current: string;
    };
    subfolder: {
      folder: string;
    };
  };
  slug: {
    current: string;
  };
  videoUrl?: string;
  courseName: string;
};


type AvailableCourseProps = {
  backgroundColour: "blue" | "white" | "beige";
  isAuth: boolean;
}

function CourseCard(props: { colourSpecificClasses: ClassNameMap<string>, classes: ClassNameMap<string>, videoUrl: string, mainImage: { alt: string; asset: object }, colour: string, id: string, userId: unknown, token: unknown, userAuthenticated: boolean, cardCourses: (value: (((prevState: any[]) => any[]) | any[])) => void, onClick: () => void, auth: boolean, subfolder: any, slug: { current: string }, title: string, blocks: any, buttonText: string, isSwiperCard: boolean, courseName: string}) {
  return <Grid
    container
    direction="column"
    className={props.isSwiperCard ? props.colourSpecificClasses.courseCardSwiper : props.colourSpecificClasses.courseCard}
    lg={props.isSwiperCard ? 12 : 4}
  >
    <Grid container className={props.colourSpecificClasses.innerCard}>
      <Grid item className={props.classes.cardImage}>
        {props.videoUrl ? (
          <VideoLinkHandler videoUrl={props.videoUrl} type={"availableCourses"} />
        ) : (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .height(253)
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </Grid>
      <CourseStatusBar
        colour={props.colour}
        completed={true}
        status={"default"}
        id={props.id}
        userId={props.userId}
        token={props.token}
        userAuthenticated={props.userAuthenticated}
        setCardCourses={props.cardCourses}
      />

      <Grid item onClick={props.onClick} className={props.colourSpecificClasses.titleHolder}>
        {props.auth ?
          <Link className={props.classes.titleAnchor}
                to={props.subfolder ? `${props.subfolder.folder}${props.slug.current}/` : `slug.current`}>
            <Typography variant="h4" className={props.classes.courseTitle}>
              {props.courseName}
            </Typography>
          </Link>
          :
          <a className={props.classes.titleAnchorNoAuth}>
            <Typography variant="h4" className={props.classes.courseTitle}>
              {props.courseName}
            </Typography>
          </a>
        }
      </Grid>
      <Grid item className={props.classes.courseBodyText}>
        <PortableText blocks={props.blocks} />
      </Grid>
      <Grid item className={props.classes.buttonContainer}>
        {props.auth && <CampusCTA
          reference={undefined}
          subfolder={props.subfolder}
          slug={props.slug}
          linkText={props.buttonText || "Find out more"}
          buttonType={"rectangular"}
          pageType={"available courses"}
          arrowIcon={true}
          isAuth={props.auth}

        />}

        {!props.auth &&
          <CampusCTA
            reference={undefined}
            linkText={props.buttonText || "Find out more"}
            buttonType={"rectangular"}
            pageType={"available courses"}
            arrowIcon={true}
            isAuth={props.auth}
            slugNo={props.slug}
          />
        }

      </Grid>
    </Grid>
  </Grid>;
}

const AvailableCourses = ({
  backgroundColour, isAuth
}: AvailableCourseProps ): JSX.Element => {

  const selectedBackground = backgroundColour === "blue" ? "#6464FF" : backgroundColour === "beige" ? "#F7F5E8" : "#FFFFFF";


  const useStyles = makeStyles((theme) => ({
    containerPadding: {
      paddingTop: "24px",
      paddingBottom: "24px",
      "& .swiper-slide":{
        height: "auto",
        display: "flex",
      },
    },
    cardContainer: {
      [theme.breakpoints.up(768)]: {
        gap: "16px",
      },
    },
    cardImage: {
      padding: "1rem 1rem 0 1rem",
      width: "100%",
      [theme.breakpoints.up(1280)]: {
        padding: "24px 24px 0 24px",
      },
      "& img": {
        width: "100%",
        borderRadius: 0,
        maxHeight: "233px",
      },
    },
    buttonContainer: {
      width: "100%",
      padding: "24px 16px 16px 16px",
      // alignSelf: "flex-end",
      marginTop: "auto",
      [theme.breakpoints.up(1280)]: {
        padding: "24px 24px 24px 24px",
      },
    },
    yellowCTA: {
      background: "#FFD574",
      height: "56px",
      width: "100%",
      borderRadius: 0,
      padding: 0,
      "& > span": {
        padding: "18px 24px 18px 24px",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "20px",
        color: "#3C465F",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    arrowIcon: {
      height: "16px",
      width: "16px",
      marginLeft: "1rem",
    },
    cardsContainer: {
      // columnGap: "16px",
      marginTop: "24px",
      marginBottom: "24px",
      [theme.breakpoints.up(1280)]: {
        marginTop: "24px",
        marginBottom: "24px",
      },
      [theme.breakpoints.up(768)]: {
        gap: "16px",
        marginTop: "24px",
        marginBottom: "24px",
      },
    },
    timerLessonSpanPadding: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "1rem",
      },
    },
    firstCourseCard:{
      paddingLeft: "12px",
    },
    lastCourseCard:{
      paddingRight: "12px"
    },
    swiperInnerCardWidth: {
      width: "inherit"
    },
    courseBodyText: {
      width: "100%",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#3C465F",
      padding: "0 16px 0px 16px",
      zIndex: 2,
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 0 24px",
      },
      '& ul': {
        paddingInlineStart: '24px !important'
      },
      '& ul, li, p, h1, h2, h3, h4, h5': {
        color: '#3C465F'
      }
    },
    courseTitle: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "30px",
      color: "#3C465F",
      padding: "0px 16px 8px 16px",
      textTransform: "uppercase",
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 8px 24px",
      },
    },
    containerBackground:{
      backgroundColor: selectedBackground,
    },
    titleAnchor: {
      textDecoration: "none",
    },
    titleAnchorNoAuth: {
      textDecoration: "none",
      cursor: "pointer",
    },
    swiperSlide: {
      height: "auto",
    },
    // desktopSwiperPadding
  }));

  const yellowSpecificStyles = makeStyles((theme) => ({
    innerCard: {
      background: "#F6E38B",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    courseCard: {
      minHeight: "525px",
      width: "100%",
      // flex: "1 1 0px",
      flexGrow: 1,
      // flexBasis: "100%",
      [theme.breakpoints.up(768)]: {
        // maxWidth: "344px",
        // marginTop: "24px",
        // paddingRight: "16px",
        padding: "0 16px",
        marginLeft: "-6px",
        marginRight: "-6px",
      },
      "& > div": {
        color: "#3C465F",
      },
    },
    courseCardSwiper: {
      minHeight: "525px",
      width: "100%",
      height: "100%",
      // flex: "1 1 0px",
      [theme.breakpoints.up(768)]: {
        // maxWidth: "344px",
        // marginTop: "24px",
        // paddingRight: "16px",
        // padding: "0 16px",
        // marginLeft: "-6px",
        // marginRight: "-6px",
      },
      flexGrow: 1,
      // flexBasis: "100%",
      "& > div": {
        color: "#3C465F",
      },
    },
    courseBodyText: {
      width: "100%",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3C465F",
      padding: "0 16px 0px 16px",
      zIndex: 2,
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 0 24px",
      },
      '& ul': {
        paddingInlineStart: '24px !important'
      },
      '& ul, li, p, h1, h2, h3, h4, h5': {
        color: '#3C465F'
      }
    },
    titleHolder: {
      zIndex: 2,
      width: "100%",
      position: "relative",
    },
    courseTitle: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      color: "#3C465F",
      padding: "0px 16px 8px 16px",
      textTransform: "uppercase",
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 8px 24px",
      },
    },
  }));

  const greenSpecificStyles = makeStyles((theme) => ({
    innerCard: {
      background: "#8BE285",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    courseCard: {
      minHeight: "525px",
      width: "100%",
      // flex: "1 1 0px",
      flexGrow: 1,
      // flexBasis: "100%",
      [theme.breakpoints.up(768)]: {
        // maxWidth: "344px",
        // marginTop: "24px",
        // paddingRight: "16px",
        padding: "0 16px",
        marginLeft: "-6px",
        marginRight: "-6px",
      },
      "& > div": {
        color: "#3C465F",
      },
    },
    courseCardSwiper: {
      minHeight: "525px",
      width: "100%",
      // flex: "1 1 0px",
      height: "100%",
      flexGrow: 1,
      [theme.breakpoints.up(768)]: {
        // maxWidth: "344px",
        // marginTop: "24px",
        // paddingRight: "16px",
        // padding: "0 16px",
        // marginLeft: "-6px",
        // marginRight: "-6px",
      },
      // flexBasis: "100%",
      "& > div": {
        color: "#3C465F",
      },
    },
    courseBodyText: {
      width: "100%",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3C465F",
      padding: "0 16px 0px 16px",
      zIndex: 2,
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 0 24px",
      },
      '& ul': {
        paddingInlineStart: '24px !important'
      },
      '& ul, li, p, h1, h2, h3, h4, h5': {
        color: '#3C465F'
      }
    },
    titleHolder: {
      zIndex: 2,
      width: "100%",
      position: "relative",
    },
    courseTitle: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      color: "#3C465F",
      padding: "0px 16px 8px 16px",
      textTransform: "uppercase",
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 8px 24px",
      },
    },
    swiperSlide:{
      height: "auto",
    },
  }));

  const greySpecificStyles = makeStyles((theme) => ({
    innerCard: {
      background: "#D4D4D4",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    courseCard: {
      minHeight: "525px",
      width: "100%",
      // flex: "1 1 0px",
      flexGrow: 1,
      // flexBasis: "100%",
      [theme.breakpoints.up(768)]: {
        // maxWidth: "344px",
        // marginTop: "24px",
        // paddingRight: "16px",
        padding: "0 16px",
        marginLeft: "-6px",
        marginRight: "-6px",
      },
      "& > div": {
        color: "#3C465F",
      },
    },

    courseCardSwiper: {

      minHeight: "525px",
      width: "100%",
      // flex: "1 1 0px",
      height: "100%",
      flexGrow: 1,
      [theme.breakpoints.up(768)]: {
        // maxWidth: "344px",
        // marginTop: "24px",
        // paddingRight: "16px",
        // padding: "0 16px",
        // marginLeft: "-6px",
        // marginRight: "-6px",
      },
      "& > div": {
        color: "#3C465F",
      },
    },
    courseBodyText: {
      width: "100%",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3C465F",
      padding: "0 16px 0px 16px",
      zIndex: 2,
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 0 24px",
      },
      '& ul': {
        paddingInlineStart: '24px !important'
      },
      '& ul, li, p, h1, h2, h3, h4, h5': {
        color: '#3C465F'
      }
    },
    titleHolder: {
      zIndex: 2,
      width: "100%",
      position: "relative",
    },
    courseTitle: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      color: "#3C465F",
      padding: "0px 16px 8px 16px",
      textTransform: "uppercase",
      [theme.breakpoints.up(1280)]: {
        padding: "0 24px 8px 24px",
      },
    },
    swiperSlide:{
      height: "auto !important",
    },
    swiperContainer: {
      height: "auto",
    }
  }));




  const classes = useStyles(selectedBackground);

  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userAuthenticated, setUserIsAuthenticated] = useState<boolean>();
  const [cardButtonText, setButtonText] = useState([{}]);
  const [cardCourses, setCardCourses] = useState([]);

  const yellowStyles = yellowSpecificStyles();
  const greenStyles = greenSpecificStyles();
  const greyStyles = greySpecificStyles();

  const getCourseColour = (courseColour) => {
    if (courseColour === "yellow") {
      return yellowStyles;
    } else if (courseColour === "green") {
      return greenStyles;
    }
    else if (courseColour === "grey"){
      return greyStyles;
    }
  };

  const data = useAvailableCourses();
  const { edges } = data.allSanityCourse;

  const matchesTabletUpwards = useMediaQuery("(min-width:768px)");
  const matchesMobile = useMediaQuery("(max-width: 767px)");

  function brazeTitleClickLogged(slug){
    if (!isAuth){
      window.courseTitleClick(slug.current, isAuth)
    }

  }

  var onStorageUpdate = (e) => {
    const { key, newValue } = e;

    if (key === "isUserAuthenticated") {
      var isAuthenticated = newValue === 'true' ? true : false;
      if (isAuthenticated){
        setUserIsAuthenticated(true);
      }
      if (!isAuthenticated) {
        setUserIsAuthenticated(false);
      }
    }
  };

  useEffect(() => {



    (async () => {

      //console.log("availableCourses");
      window.addEventListener("storage", onStorageUpdate);
      var token = window.localStorage.getItem("token");

      setToken(token);

      var userObject = await getUserObject(token);


      if (!userObject.IsAuthenticated) {
        return null
      } else {
        setUserId(userObject.Id);
      }
    })();
  }, []);

  const getButtonText = (status) => {
    if (status === "Completed") {
      return "Revisit"
    }
    else if (status === "Started"){
      return "Continue"
    }
    else {
      return "Find out more"
    }
  }

  if (matchesMobile) {
    return (
      <div className={`${classes.containerPadding} ${classes.containerBackground}`}>
        <Swiper
          modules={[Navigation, Pagination, Autoplay, A11y]}
          slidesPerView={1.3}
          centeredSlides={true}
          centeredSlidesBounds={true}
          // slidesOffsetBefore={12}
          // slidesOffsetAfter={12}
          spaceBetween={8}
        >
          {edges.map((course, index) => {
            const {
              courseColour,
              courseDescription,
              subfolder,
              _id,
              mainImage,
              title,
              courseLandingPage,
              slug,
              _rawAvailableCourseDescription,
              videoUrl,
              courseName
            }: CourseProps = course.node;

            const colourSpecificClasses = getCourseColour(courseColour);


            const findCardCourse = cardCourses.find(({ id}) => id === _id)
            const buttonText = findCardCourse && getButtonText(findCardCourse.courseStatus)

            const getOffset = () => {

              if (index === 0){
                return classes.firstCourseCard
              } else if (index === edges.length -1){
                return classes.lastCourseCard
              } else {
                return null;
              }
            }
            return (
              <SwiperSlide>
                <Grid
                  container
                  direction="column"
                  className={`${colourSpecificClasses.courseCard} ${getOffset()}`}
                >
                  <Grid className={`${colourSpecificClasses.innerCard} ${classes.swiperInnerCardWidth}`}>
                    <Grid item className={classes.cardImage}>
                      {videoUrl ? (
                        <VideoLinkHandler videoUrl={videoUrl} type={'availableCourses'} />
                      ) : (
                          <img
                            src={imageUrlFor(buildImageObj(mainImage))
                              .height(253)
                              .url()}
                            alt={mainImage.alt}
                          />
                        )}
                    </Grid>
                    <CourseStatusBar
                      colour={courseColour}
                      completed={false}
                      status={"default"}
                      id={_id}
                      userId={userId}
                      token={token}
                      userAuthenticated={userAuthenticated}
                      setCardCourses={setCardCourses}
                    />
                    <Grid onClick={() => brazeTitleClickLogged(slug)} item className={colourSpecificClasses.titleHolder}>
                      {isAuth ?
                        <Link className={classes.titleAnchor}
                              to={subfolder ? `${subfolder.folder}${slug.current}/` : `slug.current`}>
                          <Typography

                            variant="h4"
                            className={classes.courseTitle}
                          >
                            {courseName}
                          </Typography>
                        </Link>
                        :
                        <a className={classes.titleAnchorNoAuth}>
                          <Typography variant="h4" className={classes.courseTitle}>
                            {courseName}
                          </Typography>
                        </a>
                      }
                    </Grid>
                    <Grid item className={classes.courseBodyText}>
                      <PortableText blocks={_rawAvailableCourseDescription} />
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <CampusCTA
                        reference={undefined}
                        subfolder={subfolder}
                        slug={slug}
                        linkText={buttonText || "Find out more"}
                        buttonType={"rectangular"}
                        pageType={"available courses"}
                        arrowIcon={true}
                        isAuth={isAuth}
                      />

                    </Grid>
                  </Grid>
                </Grid>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }

  if (matchesTabletUpwards) {
    return (
      edges.length >= 22 ?


        <Container customClassName={classes.containerBackground}>
          <Grid className={classes.cardsContainer}>
          <Swiper
            modules={[Navigation, Pagination, Autoplay, A11y]}
            slidesPerView={3}
            // centeredSlides={true}
            // centeredSlidesBounds={true}
            // slidesOffsetBefore={12}
            // slidesOffsetAfter={12}
            loop={true}
           className={classes.swiperContainer}
            spaceBetween={32}
            autoplay={
              {
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
                delay: 2000,
              }}
          >
            {edges.map((course, index) => {
              const {
                courseColour,
                courseDescription,
                subfolder,
                _id,
                mainImage,
                title,
                courseLandingPage,
                slug,
                _rawAvailableCourseDescription,
                videoUrl,
                courseName,
              }: CourseProps = course.node;

              const colourSpecificClasses = getCourseColour(courseColour);

              const findCardCourse = cardCourses.find(({ id }) => id === _id);
              const buttonText =
                findCardCourse && getButtonText(findCardCourse.courseStatus);

              return (
                <SwiperSlide className={classes.swiperSlide}>
                  <CourseCard
                    colourSpecificClasses={colourSpecificClasses}
                    classes={classes}
                    videoUrl={videoUrl}
                    mainImage={mainImage}
                    colour={courseColour}
                    id={_id}
                    userId={userId}
                    token={token}
                    userAuthenticated={userAuthenticated}
                    cardCourses={setCardCourses}
                    onClick={() => brazeTitleClickLogged(slug)}
                    auth={isAuth}
                    subfolder={subfolder}
                    slug={slug}
                    title={title}
                    blocks={_rawAvailableCourseDescription}
                    buttonText={buttonText}
                    isSwiperCard={true}
                    courseName={courseName}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          </Grid>
        </Container>
        :
          <Container
            customClassName={classes.containerBackground}
            customBodyClass={undefined}
          >
              <Grid
                container
                className={classes.cardsContainer}
                // justifyContent={"space-between"}
                direction={"row"}
                spacing={2}
              >
                {edges.map((course, index) => {
                  const {
                    courseColour,
                    courseDescription,
                    subfolder,
                    _id,
                    mainImage,
                    title,
                    courseLandingPage,
                    slug,
                    _rawAvailableCourseDescription,
                    videoUrl,
                    courseName,
                  }: CourseProps = course.node;

                  const colourSpecificClasses = getCourseColour(courseColour);

                  const findCardCourse = cardCourses.find(({ id }) => id === _id);
                  const buttonText =
                    findCardCourse && getButtonText(findCardCourse.courseStatus);

                  return (
                    <CourseCard
                      colourSpecificClasses={colourSpecificClasses}
                      classes={classes}
                      videoUrl={videoUrl}
                      mainImage={mainImage}
                      colour={courseColour}
                      id={_id}
                      userId={userId}
                      token={token}
                      userAuthenticated={userAuthenticated}
                      cardCourses={setCardCourses}
                      onClick={() => brazeTitleClickLogged(slug)}
                      auth={isAuth}
                      subfolder={subfolder}
                      slug={slug}
                      title={title}
                      blocks={_rawAvailableCourseDescription}
                      buttonText={buttonText}
                      courseName={courseName}
                    />
                  );
                })}
              </Grid>

          </Container>
    );
  } else {
    return null;
  }
};

export default AvailableCourses;
