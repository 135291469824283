import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Hidden } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    width: "100px",
    [theme.breakpoints.down(1270)]: {
      width: "80px",
    },
    [theme.breakpoints.down(1220)]: {
      width: "60px",
    },
  },
  imageContainer: {
    width: "120px",
    height: "100px",
    display: "block",
    float: "left",
    paddingRight: "16px",
  },
  imageWrapper: {
    width: "100%",
  },
  logoImage: {
    border: "1px solid #d8dadc",
    borderRadius: "8px",
    maxWidth: "100%",
    width: "100px",
    height: "100px",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  imageWrap: {
    maxWidth: "100%",
    width: "100px",
    height: "100px",
  },
  providerHeaderContainer: {
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    padding: "8px 16px",
    position: "relative",
    borderBottom: "1px solid #E6E8F0",
    height: "41px",
  },
  companyDisplayName: {
    color: "#3C465F",
    fontWeight: "600",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",

    width: "auto",
  },
  providerContainer: {
    background: "white",
    borderRadius: "0px 0px 8px 8px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    position: "relative",
    paddingTop: "16px",
  },
  logoAndScoreContainer: {
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cardContainer: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    marginBottom: "24px",
  },
  companyHighlight: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    color: "#2F2F4E",
    overflow: "hidden",
  },
  reviewAnchor: {
    textDecoration: "none",
    cursor: "pointer",
  },
  ratingsPullRight: {
    marginRight: "-1em",
  },
  cardContainerNoMarginBottom: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
  },
  wouldRecommendContainer: {
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingBottom: "8px",
    background:
      "linear-gradient(180deg, #F3F8FC 34.24%, #FFFFFF 75.38%), linear-gradient(180deg, #F3F8FC 34.24%, #FFFFFF 75.38%)",
    boxShadow: "inset 0px 1px 0px #E6E8F0",
    paddingTop: "8px",
  },
  wouldRecommendPercentageContainer: {
    [theme.breakpoints.down(1380)]: {
      maxWidth: "70% !important",
      flexBasis: "70% !important",
    },
    //[theme.breakpoints.down(959)]: {
    //    maxWidth: '75% !important',
    //    flexBasis: '75% !important',
    //},
    [theme.breakpoints.down(1185)]: {
      maxWidth: "100% !important",
      flexBasis: "100% !important",
    },
    [theme.breakpoints.down(959)]: {
      maxWidth: "70% !important",
      flexBasis: "70% !important",
    },
  },
  numberOfReviewsLabel: {
    float: "right",
    color: "#8C8CA2",
    fontSize: "14px",
    lineHeight: "12px",
    //[theme.breakpoints.down(1380)]: {
    //    float: 'left'
    //},

    //[theme.breakpoints.down(400)]: {
    //    float: 'left'
    //},
    [theme.breakpoints.down(1185)]: {
      float: "left",
    },
    [theme.breakpoints.down(959)]: {
      float: "right",
    },
    [theme.breakpoints.down(375)]: {
      fontWeight: "500",
      fontSize: "13px",
    },
    [theme.breakpoints.down(336)]: {
      fontWeight: "500",

      fontSize: "12px",
    },
  },
  inANutshellText: {
    fontSize: "14px",
    color: "#2F2F4E",
    fontWeight: "500",
    lineHeight: "18px",
    display: "block",
    verticalAlign: "middle",
  },
  wouldRecommendText: {
    fontSize: "14px",
    color: "#2F2F4E",
    fontWeight: "500",
    lineHeight: "18px",
    display: "block",
    verticalAlign: "middle",
    [theme.breakpoints.down(1240)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(1024)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(334)]: {
      fontSize: "11px",
    },
  },
  bbHeartImage: {
    display: "block",
    verticalAlign: "middle",
    paddingRight: "calc(.3em + 1.7px)",
    height: "18px",
  },
  wouldRecommendPercentageLabel: {
    color: "#3C465F",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "bold",
    marginBlockStart: "0",
    marginBlockEnd: 0,
    textAlign: "center",
    paddingRight: ".3em",
  },
  fullHeight: {
    height: "100%",
  },
  awardProviderPageLogo: {
    maxHeight: "67px",
  },
  reviewsLabelContainer: {
    [theme.breakpoints.down(1380)]: {
      maxWidth: "30% !important",
      flexBasis: "30% !important",
    },
    //[theme.breakpoints.down(959)]: {
    //    maxWidth: '25% !important',
    //    flexBasis: '25% !important',
    //    paddingTop: 'initial'
    //},
    [theme.breakpoints.down(1185)]: {
      maxWidth: "100% !important",
      flexBasis: "100% !important",
      paddingTop: "8px",
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: "30% !important",
      flexBasis: "30% !important",
      paddingTop: "initial",
    },
  },
}));

export default function BestBuysTableRowSkeleton() {
  const classes = useStyles();

  return (
    <Grid className={classes.cardContainer}>
      <div className={classes.providerHeaderContainer}>
        <Skeleton variant="text" width={"80px"}></Skeleton>
      </div>
      <div className={classes.providerContainer}>
        <Grid container className={classes.logoAndScoreContainer}>
          <Hidden mdDown implementation="js">
            <div className={classes.logoContainer}>
              <div className={classes.imageContainer}>
                <Grid container alignItems="center" justify="center">
                  <Skeleton variant="rect">
                    <Grid item className={classes.imageWrapper}>
                      <div className={classes.imageWrap}>
                        <div className={classes.logoImage}></div>
                      </div>
                    </Grid>
                  </Skeleton>
                </Grid>
              </div>
            </div>
          </Hidden>
          <Hidden lgUp implementation="js">
            <Grid></Grid>
          </Hidden>
        </Grid>
      </div>
    </Grid>
  );
}
