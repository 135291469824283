import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

import Container from "../../components/container";
import BestBuysTableRow from "./bestBuyTableRow";
import BestBuysTableRowSkeleton from "./bestBuyTableRowSkeleton";
import BestBuysTableRowMobile from "./bestBuysTableRowMobile";
import BestBuysTableRowMobileSkeleton from "./bestBuysTableRowMobileSkeleton";
import BestBuysTableRow3x3WouldRecommend from "./layout/3x3WouldRecommend/bestBuysTableRow";
import BestBuysFilters from "./components/bestBuysFilters";
import BestBuySortAndProviders from "./components/bestBuySortAndProviders";
import BestBuySortAndProvidersSkeleton from "./components/bestBuySortAndProvidersSkeleton";
import ComparisonBottomBar from "./comparisonBottombar";
import queryString from "query-string";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bbCard: {
    marginTop: "20px",
  },
  bbCardBlock: {
    marginTop: "0",
  },
  totalRows: {
    color: "#3C455C",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    letterSpacing: "-0.02em",
  },
  whiteBg: {
    backgroundColor: "white",
    padding: "16px 0",
    boxShadow: "0px 2px 4px 0px #4040401A",
    marginBottom: "20px",
  },
  bestBuysTableRowMobileDesktopContainer: {
    padding: "15px",
  },
  comparison: {
   background: "#3C465F",
   width: "100vw",
  }
}));

const InitialCalc = (props, sponsoredProvider) => {
  const data = useStaticQuery(graphql`
    {
      allCalcApiIsa {
        nodes {
          platformType
          dashboardId
          cost
          companyName
        }
      }
      allCalcApiJisa {
        nodes {
          platformType
          dashboardId
          cost
          companyName
        }
      }
      allCalcApiLisa {
        nodes {
          platformType
          dashboardId
          cost
          companyName
        }
      }
      allCalcApiPension {
        nodes {
          platformType
          dashboardId
          cost
          companyName
        }
      }

      allCalcTable {
        nodes {
          id
          companyDisplayName

          companyLogo {
            asset {
              _ref
              _type
            }

            alt
          }
          bestBuyType
          dashboardId
          mostPopularRank
          ourRating
          reviewLink {
            slug
            subfolder {
              folder
            }
          }
          yourRating
          type
          companyBBCats
          bestBuyAward {
            providerPageLogo {
              _type
              asset {
                _ref
                _type
              }
            }
            bestBuyCompareTableLogo {
              _type
              asset {
                _ref
                _type
              }
            }
          }
          bestBuyLink {
            url
          }

          inANutshell
          isAffiliate
          noOfReviews
          wouldRecommendRating
          awardURL
        }
      }
    }
  `);
  let result = data.allCalcTable.nodes;
  let arr = [];
  //console.log('result: ', result);
  //console.log('sponsoredProvider: ', sponsoredProvider);
  switch (props) {
    case "ISA":
      return data.allCalcApiIsa.nodes;
      break;
    case "JISA":
      return data.allCalcApiJisa.nodes;
      break;
    case "LISA":
      return data.allCalcApiLisa.nodes;
      break;
    case "PENSION":
      return data.allCalcApiPension.nodes;
      break;

    case "ISATABLE":
      for (var i = 0; i < result.length; i++) {
        var obj = result[i];
        if (obj.bestBuyType == "ISA") {

          if (sponsoredProvider && obj.dashboardId == sponsoredProvider.dashboardId) {
            obj.isSponsoredProvider = true;
          }
          else {
            obj.isSponsoredProvider = false;
          }

          arr.push(obj);
        }


      }
      return arr;
      break;

    case "JISATABLE":
      for (var i = 0; i < result.length; i++) {
        var obj = result[i];
        if (obj.bestBuyType == "JISA") {
          if (sponsoredProvider && obj.dashboardId == sponsoredProvider.dashboardId) {
            obj.isSponsoredProvider = true;
          }
          else {
            obj.isSponsoredProvider = false;
          }

          arr.push(obj);
        }
      }
      return arr;
      break;

    case "LISATABLE":
      for (var i = 0; i < result.length; i++) {
        var obj = result[i];
        if (obj.bestBuyType == "LISA") {
          if (sponsoredProvider && obj.dashboardId == sponsoredProvider.dashboardId) {
            obj.isSponsoredProvider = true;
          }
          else {
            obj.isSponsoredProvider = false;
          }

          arr.push(obj);
        }
      }
      return arr;
      break;

    case "PENSIONTABLE":
      for (var i = 0; i < result.length; i++) {
        var obj = result[i];
        if (obj.bestBuyType == "PENSION") {
          if (sponsoredProvider && obj.dashboardId == sponsoredProvider.dashboardId) {
            obj.isSponsoredProvider = true;
          }
          else {
            obj.isSponsoredProvider = false;
          }

          arr.push(obj);
        }
      }
      return arr;
      break;

    case "DIGITAL ADVICETABLE":
      for (var i = 0; i < result.length; i++) {
        var obj = result[i];
        if (obj.bestBuyType == "DIGITAL ADVICE") {
          if (sponsoredProvider && obj.dashboardId == sponsoredProvider.dashboardId) {
            obj.isSponsoredProvider = true;
          }
          else {
            obj.isSponsoredProvider = false;
          }

          arr.push(obj);
        }
      }
      return arr;
      break;

    default:
      return null;
      break;
  }
};

function BestBuysTable(props) {
  const {
    type,
    calculatorAssumptionsModel,
    costInBBTable,
    showAdvancedFilters,
    calcLumpSum,
    defaultLayout,
    defaultSortOption,
    sortOptions,
    comparisonPageReference,
    enableComparison,
    comparators,
    dispatch,
    sponsoredProvider,
    loggedOutRowLimit,
    loggedOutRowLimitMobile,
    emailCaptured
  } = props;
  //const client = createClient();
  //const pageType = "companyCategory";

  const bbTableType = type + "TABLE";

  const [totalRows, setTotalRows] = useState(0);
  const [help, setHelp] = useState(false);
  const [companiesNoCost, setCompaniesNoCost] = useState([]);

  const [companiesScroll, setCompaniesScroll] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const classes = useStyles();
  const location = useLocation();
  const [initialCosts, setIntitialCosts] = useState(InitialCalc(type));
  const [initialFiltersSet, setInitialFiltersSet] = useState(false);
  const [amount, setAmount] = React.useState(getPageLoadAmountValue());
  const [sort, setSort] = useState(getPageLoadSortByValue());
  const [filters, setFilters] = useState(getPageLoadAdvancedFiltersValue());
  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});
  const [originalRowAmount, setOriginalRowAmount] = useState()

  const [bbTable, setbbTable] = useState(InitialCalc(bbTableType, props.sponsoredProvider));

  const apiURL = process.env.GATSBY_CALC_COST_API;
  const [costs, setCosts] = useState();

  const [loading, setLoading] = useState(true);
  const placeholderAmount = [0, 1, 2];

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"))

  function handleChange(newAmount) {
    setGoogleAnalyticsObject({
      Source: "AmountSearchButtonClicked",
      LumpSum: newAmount.amount,
      Monthly:
        newAmount.amountMonthly != undefined ? newAmount.amountMonthly : 0,
      Sort: sort,
      Robo: filters.ROBO,
      Diy: filters.DIY,
      OnlineExperience: filters.DIGITAL,
      Green: filters.GREEN,
      BigBrand: filters.BIGBRAND,
    });
    setIntitialCosts([]);
    setAmount(newAmount);
  }
  function handleSetSort(newSortBy) {
    setGoogleAnalyticsObject({
      Source: "SortChange",
      LumpSum: amount.amount,
      Monthly: amount.amountMonthly != undefined ? amount.amountMonthly : 0,
      Sort: newSortBy,
      Robo: filters.ROBO,
      Diy: filters.DIY,
      OnlineExperience: filters.DIGITAL,
      Green: filters.GREEN,
      BigBrand: filters.BIGBRAND,
    });

    setSort(newSortBy);
  }
  function handleSetFilters(newFilters) {
    setGoogleAnalyticsObject({
      Source: "AdvancedFiltersSearchButtonClicked",
      LumpSum: amount.amount,
      Monthly: amount.amountMonthly != undefined ? amount.amountMonthly : 0,
      Sort: sort,
      Robo: newFilters.ROBO,
      Diy: newFilters.DIY,
      OnlineExperience: newFilters.DIGITAL,
      Green: newFilters.GREEN,
      BigBrand: newFilters.BIGBRAND,
    });

    newFilters.initialFilters = false;

    setFilters(newFilters);
  }
  function getPageLoadSortByValue() {
    var sortByQueryStringValue = queryString.parse(location.search).sortBy;
    var returnValue = "OurRatingHL";

    if (
      defaultSortOption != null &&
      defaultSortOption != undefined &&
      defaultSortOption != ""
    ) {
      returnValue = defaultSortOption;
    }

    if (
      sortByQueryStringValue != null &&
      sortByQueryStringValue != undefined &&
      sortByQueryStringValue != ""
    ) {
      returnValue = sortByQueryStringValue;
    }

    return returnValue;
  }
  function getPageLoadAmountValue() {
    var lumpSumQueryStringValue = queryString.parse(location.search).lumpSum;

    var lumpSumObject = {
      amount: calcLumpSum,
      amountMonthly: undefined,
      refreshInitialCosts: false,
    };

    if (
      lumpSumQueryStringValue != null &&
      lumpSumQueryStringValue != undefined &&
      lumpSumQueryStringValue != ""
    ) {
      lumpSumObject = {
        amount: lumpSumQueryStringValue,
        amountMonthly: undefined,
        refreshInitialCosts: true,
      };
    }

    return lumpSumObject;
  }
  function getPageLoadAdvancedFiltersValue() {
    var robo = false;
    var diy = false;
    var digital = false;
    var green = false;
    var bigBrand = false;

    var roboQueryStringValue = queryString.parse(location.search).robo;

    if (
      roboQueryStringValue != null &&
      roboQueryStringValue != undefined &&
      roboQueryStringValue != ""
    ) {
      robo = roboQueryStringValue.toLowerCase() === "true";
    }

    var diyQueryStringValue = queryString.parse(location.search).diy;

    if (
      diyQueryStringValue != null &&
      diyQueryStringValue != undefined &&
      diyQueryStringValue != ""
    ) {
      diy = diyQueryStringValue.toLowerCase() === "true";
    }

    var digitalQueryStringValue = queryString.parse(location.search).digital;

    if (
      digitalQueryStringValue != null &&
      digitalQueryStringValue != undefined &&
      digitalQueryStringValue != ""
    ) {
      digital = digitalQueryStringValue.toLowerCase() === "true";
    }

    var greenQueryStringValue = queryString.parse(location.search).green;

    if (
      greenQueryStringValue != null &&
      greenQueryStringValue != undefined &&
      greenQueryStringValue != ""
    ) {
      green = greenQueryStringValue.toLowerCase() === "true";
    }

    var bigBrandQueryStringValue = queryString.parse(location.search).bigBrand;

    if (
      bigBrandQueryStringValue != null &&
      bigBrandQueryStringValue != undefined &&
      bigBrandQueryStringValue != ""
    ) {
      bigBrand = bigBrandQueryStringValue.toLowerCase() === "true";
    }

    var filtersObject = {
      ROBO: robo,
      DIY: diy,
      DIGITAL: digital,
      GREEN: green,
      BIGBRAND: bigBrand,
      initialFilters: true,
    };

    return filtersObject;
  }
  function ourRatingHL(a, b) {
    if (a.ourRating < b.ourRating) {
      return 1;
    }
    if (a.ourRating > b.ourRating) {
      return -1;
    }
    return 0;
  }
  function mostPopularRank(a, b) {
    if (!a.mostPopularRank && b.mostPopularRank) {
      return 0;
    }

    if (a.mostPopularRank === undefined && b.mostPopularRank > 0) {
      return 1;
    }

    if (b.mostPopularRank === undefined && a.mostPopularRank > 0) {
      return -1;
    }

    if (a.mostPopularRank > 0 && b.mostPopularRank > 0) {
      if (a.mostPopularRank < b.mostPopularRank) {
        return -1;
      }
      if (a.mostPopularRank > b.mostPopularRank) {
        return 1;
      }
    }

    return 0;
  }
  function WouldRecommendRating(a, b) {
    if (!a.wouldRecommendRating && b.wouldRecommendRating) {
      return 0;
    }

    if (a.wouldRecommendRating === undefined && b.wouldRecommendRating > 0) {
      return 1;
    }

    if (b.wouldRecommendRating === undefined && a.wouldRecommendRating > 0) {
      return -1;
    }

    if (a.wouldRecommendRating > 0 && b.wouldRecommendRating > 0) {
      if (a.wouldRecommendRating < b.wouldRecommendRating) {
        return -1;
      }
      if (a.wouldRecommendRating > b.wouldRecommendRating) {
        return 1;
      }
    }

    return 0;
  }

  function SponsoredSort(a, b) {
    return (a.isSponsoredProvider === b.isSponsoredProvider) ? 0 : a.isSponsoredProvider ? -1 : 1;
  }

  function yourRatingLH(a, b) {
    if (!a.yourRating) {
      return -1;
    }

    if (!b.yourRating) {
      return 1;
    }
    const aHas = typeof a.yourRating !== "undefined";
    const bHas = typeof b.yourRating !== "undefined";
    return bHas - aHas || (aHas === true && a.yourRating - b.yourRating) || 0;
  }
  function costLH(a, b) {
    if (!a.cost) {
      return -1;
    }

    if (!b.cost) {
      return 1;
    }
    const aHas = typeof a.cost !== "undefined";
    const bHas = typeof b.cost !== "undefined";
    return bHas - aHas || (aHas === true && a.cost - b.cost) || 0;
  }
  function filtering(filters, comps) {
    let helperArray = [];
    let filteredCompanies = [];
    if (filters.ROBO == true) {
      helperArray.push("ROBO");
    }
    if (filters.DIY == true) {
      helperArray.push("DIY");
    }
    if (filters.DIGITAL == true) {
      helperArray.push("DIGITAL");
    }
    if (filters.GREEN == true) {
      helperArray.push("GREEN");
    }
    if (filters.BIGBRAND == true) {
      helperArray.push("BIGBRAND");
    }
    let checker = (arr, target, isSponsoredProvider) =>
      target.every(function (v) {
        if (arr != undefined) {
          return arr.includes(v) || isSponsoredProvider;
        } else {
          return false;
        }
      });

    if (allCompanies !== []) {
      filteredCompanies = allCompanies.filter((company) =>
        checker(company.companyBBCats, helperArray, company.isSponsoredProvider)
      );
    }

    setCompaniesNoCost(filteredCompanies);
    sorter(sort, filteredCompanies);
  }
  function sorter(sort, comps) {
    //console.log('comps: ', comps);

    if (sort == "OurRatingHL" || sort.toLowerCase() === "ourratinghl") {
      comps = comps.sort(ourRatingHL);
    }
    if (sort == "OurRatingLH" || sort.toLowerCase() === "ourratinglh") {
      comps = comps.sort(ourRatingHL).reverse();
    }
    if (sort == "YourRatingHL" || sort.toLowerCase() === "yourratinghl") {
      comps = comps.sort(yourRatingLH).reverse();
    }
    if (sort == "YourRatingLH" || sort.toLowerCase() === "yourratinglh") {
      comps = comps.sort(yourRatingLH);
    }
    if (sort == "CostLH" || sort.toLowerCase() === "costlh") {
      comps = comps.sort(costLH);
    }
    if (sort == "CostHL" || sort.toLowerCase() === "costhl") {
      comps = comps.sort(costLH).reverse();
    }
    if (sort == "MostPopular" || sort.toLowerCase() === "mostpopular") {
      comps = comps.sort(mostPopularRank);
    }
    if (sort == "MostRecommended" || sort.toLowerCase() === "mostrecommended") {
      comps = comps.sort(WouldRecommendRating).reverse();
    }

    //console.log('comps: ', comps);
    comps = comps.sort(SponsoredSort);

    setCompaniesNoCost(comps);
    setTotalRows(
      (loggedOutRowLimitMobile && !emailCaptured) ||
        (loggedOutRowLimit && !emailCaptured)
        ? originalRowAmount
        : comps.length
    );
    //console.log('sorted: ', comps);
  }

  var getComps = async function () {
    var comps = await getCompsList();
    setTotalRows(comps?.length);
    setOriginalRowAmount(comps?.length)

    var compsScroll = comps.slice(3, comps?.length);
    setCompaniesScroll(compsScroll);

    var initcomps = comps.slice(0, 1);

    // Filter number of provider rows according to loggedOutRowLimit
    const limitedRowValue = matchesMobile ? loggedOutRowLimitMobile : (loggedOutRowLimit !== 0 ? loggedOutRowLimit * 3 : loggedOutRowLimit)
    const filteredComps = comps
      .sort(
        (a, b) =>
          (b.isSponsoredProvider ? 1 : 0) - (a.isSponsoredProvider ? 1 : 0)
      )
      .filter(
        function (item) {
        if (this.count < limitedRowValue && limitedRowValue > 0) {
            this.count++;
            return true;
          }
          return false;
        }, {count: 0});
    const rowFilteredCards = (typeof loggedOutRowLimit === "number" && !emailCaptured) || (typeof loggedOutRowLimitMobile === "number" && !emailCaptured) ? filteredComps : comps;

    setCompaniesNoCost(rowFilteredCards);
    setAllCompanies(rowFilteredCards);
  };

  var getCompsList = async function () {
    return bbTable;
  };

  //-------------------------------------------------------------------------------------

  var getCosts = async function () {
    var compsGraph = initialCosts;

    if (compsGraph?.length == 0 || amount.refreshInitialCosts) {
      var costsReturn = await getCostAPI();
      compsGraph = JSON.parse(costsReturn);
      getCostAPI().then((value) => setHelp(!help));
    }

    const obj = compsGraph;
    setCosts(obj);
  };
  var getCostAPI = async function () {
    var FundTrades = 1;
    try {
      for (var i = 0; i < calculatorAssumptionsModel.length; i++) {
        var arr = calculatorAssumptionsModel[i].split(",");
        var amt = parseFloat(amount.amount.replace(",", ""));

        var b1 = parseFloat(arr[0]);
        var b2 = parseFloat(arr[1]);

        if (amt > b1 && amt <= b2) {
          FundTrades = arr[2];
          //console.log("FundTrades", FundTrades)
        }
      }
    } catch (e) {
      console.log("ERR COST - ", e);
    }

    try {
      var model = {
        Amount: amount.amount,
        Provider: "ALL",
        Split: type,
        FundTradesFreqYear: FundTrades,
        isaPerc: 0.0,
        giaPerc: 0.0,
        sippPerc: 0.0,
        jisaPerc: 0.0,
      };

      switch (type) {
        case "ISA":
          model.isaPerc = "100.00";
          break;
        case "LISA":
          model.isaPerc = "100.00";
          break;
        case "PENSION":
          model.sippPerc = "100.00";
          break;
        case "JISA":
          model.jisaPerc = "100.00";
          break;
      }

      return fetch(apiURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(model),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          return responseJson;
        });
    } catch (e) {
      console.log("ERROR-BestBuysCosts", e);
    }
  };

  injectCostsIntoCompanies(costs, companiesNoCost);

  function injectCostsIntoCompanies(costs, companiesNoCost) {
    if (
      costs == undefined ||
      companiesNoCost[0] == undefined ||
      companiesNoCost == undefined ||
      companiesNoCost == []
    ) {
      return null;
      //      console.log("waiting on all api calls to return...")
    } else {
      costs.forEach(function (cost) {
        if (cost.dashboardId != undefined) {
          let obj = companiesNoCost.find((o, i) => {
            if (
              o.dashboardId.toUpperCase() === cost.dashboardId.toUpperCase()
            ) {
              companiesNoCost[i] = {
                ...companiesNoCost[i],
                cost: cost.cost,
                platformType: cost.platformType,
              };
              return true; // stop searching
            }
          });
        }
      });
      return null;
    }
  }
  //-------------------------------------------------------------------------------------

  useEffect(() => {
    (async () => {
      await getComps();
    })();
  }, [matchesMobile]);

  useEffect(() => {
    (async () => {
      if (costInBBTable) {
        await getCosts();
      }
    })();
  }, [amount.amount, filters, sort]);

  //Analytics
  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === "{}") {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({
            best_buy_filters_combined: JSON.stringify(googleAnalyticsObject),
          });

          window.dataLayer.push({ event: "best_buy_filters_change" });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  useEffect(() => {
    (async () => {
      if (
        filters.initialFilters &&
        !initialFiltersSet &&
        companiesNoCost?.length > 0 &&
        allCompanies?.length > 0
      ) {
        filtering(filters, companiesNoCost);
        setInitialFiltersSet(true);
      }
    })();
  }, [allCompanies]);

  useEffect(() => {
    injectCostsIntoCompanies(costs, companiesNoCost);
    setCompaniesNoCost(companiesNoCost);
    sorter(sort, companiesNoCost);
  }, [costs, amount.amount, help]);

  useEffect(() => {
    filtering(filters, companiesNoCost);
  }, [sort, filters]);

  const onRefChange = useCallback((node) => {
    if (node === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, []);

  try {
    return (
      <>
        {costInBBTable == true && (
          <Grid container className={classes.whiteBg}>
            <Container>
              <BestBuysFilters amount={amount} onChange={handleChange} />
            </Container>
          </Grid>
        )}
        <Grid className={classes.bbCardBlock} id="bbCardBlock">
          <Container>
            <BestBuySortAndProviders
              costInBBTable={costInBBTable}
              totalRows={totalRows}
              onSetSort={handleSetSort}
              onSetFilters={handleSetFilters}
              showAdvancedFilters={showAdvancedFilters}
              initialSortValue={sort}
              defaultSortOption={defaultSortOption}
              sortOptions={sortOptions}
              loading={loading}
            />
            {/* Placeholder skeleton screen cards until BB cards rendered */}
            {loading ? (
              <>
                {defaultLayout == null || defaultLayout == "1x1" ? (
                  <Grid className={classes.bbCard}>
                    <Hidden smDown implementation="css">
                      {placeholderAmount &&
                        placeholderAmount.map(() => (
                          <>
                            <BestBuysTableRowSkeleton />
                          </>
                        ))}
                    </Hidden>
                    <Hidden mdUp implementation="css">
                      {placeholderAmount &&
                        placeholderAmount.map(() => (
                          <>
                            <BestBuysTableRowMobileSkeleton />
                          </>
                        ))}
                    </Hidden>
                  </Grid>
                ) : (
                  <Grid className={classes.bbCard}>
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      {placeholderAmount &&
                        placeholderAmount.map(() => (
                          <Grid item xs={12} md={4}>
                            {defaultLayout === "3x3" ? (
                              <BestBuysTableRowMobileSkeleton
                                defaultLayout={defaultLayout}
                              />
                            ) : (
                              <BestBuysTableRowMobileSkeleton
                                defaultLayout={defaultLayout}
                              />
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                )}
              </>
            ) : null}

            {/* cards fetched from API Data */}
            {defaultLayout == null || defaultLayout == "1x1" ? (
              <Grid ref={onRefChange} className={classes.bbCard}>
                <Hidden smDown implementation="css">
                  {companiesNoCost &&
                    companiesNoCost.map((company) => (
                      <>
                        <BestBuysTableRow
                          company={company}
                          calculatorAssumptionsModel={
                            calculatorAssumptionsModel
                          }
                          type={type}
                          enableComparison={enableComparison}
                          costInBBTable={costInBBTable}
                          amount={amount}
                          key={company.companyDisplayName}
                          companies={companiesNoCost}
                          bestBuyAward={company.bestBuyAward}
                          awardURL={company.awardURL}
                        />
                      </>
                    ))}
                </Hidden>
                <Hidden mdUp implementation="css">
                  {companiesNoCost &&
                    companiesNoCost.map((company) => (
                      <>
                        <BestBuysTableRowMobile
                          company={company}
                          calculatorAssumptionsModel={
                            calculatorAssumptionsModel
                          }
                          costInBBTable={costInBBTable}
                          type={type}
                          amount={amount}
                          key={company.companyDisplayName}
                          companies={companiesNoCost}
                          bestBuyAward={company.bestBuyAward}
                          awardURL={company.awardURL}
                        />
                      </>
                    ))}
                </Hidden>
              </Grid>
            ) : (
              <Grid ref={onRefChange} className={classes.bbCard}>
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={3}
                >
                  {companiesNoCost &&
                    companiesNoCost.map((company) => (
                      <Grid item xs={12} md={4}>
                        {defaultLayout === "3x3" ? (
                          <BestBuysTableRowMobile
                            company={company}
                            calculatorAssumptionsModel={
                              calculatorAssumptionsModel
                            }
                            costInBBTable={costInBBTable}
                            type={type}
                            amount={amount}
                            key={company.companyDisplayName}
                            companies={companiesNoCost}
                            bestBuyAward={company.bestBuyAward}
                            awardURL={company.awardURL}
                            defaultLayout={defaultLayout}
                          />
                        ) : (
                          <BestBuysTableRow3x3WouldRecommend
                              company={company}
                              calculatorAssumptionsModel={
                                calculatorAssumptionsModel
                              }
                              enableComparison={enableComparison}
                              costInBBTable={costInBBTable}
                              type={type}
                              amount={amount}
                              key={company.companyDisplayName}
                              companies={companiesNoCost}
                              bestBuyAward={company.bestBuyAward}
                              awardURL={company.awardURL}
                              defaultLayout={defaultLayout}
                              isSponsoredProvider={company.isSponsoredProvider}
                          />
                        )}
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
          </Container>

        </Grid>
        <Grid className={classes.bbCard}>
          <Container>
            <Grid className={classes.bbCard}>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                {/*<Scroll*/}
                {/*  companiesScroll={companiesScroll} */}
                {/*  calculatorAssumptionsModel={calculatorAssumptionsModel}*/}
                {/*  costInBBTable={costInBBTable}*/}
                {/*  type={type}*/}
                {/*  amount={amount}*/}

                {/*  companies={companiesNoCost}*/}

                {/*  defaultLayout={defaultLayout}*/}
                {/*/>*/}
              </Grid>
            </Grid>
          </Container>

        </Grid>
        {comparators.length > 0 && <ComparisonBottomBar  comparisonPageReference={comparisonPageReference} type={type}/>
        }
        </>

    );
  } catch (e) {
    console.log("ERR - BBT", e);
    return <>null</>;
  }
}

export default connect((state) => ({
  comparators: state.comparatorReducer.comparators
}), null)(BestBuysTable)
