import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: "24px",
    width: "100%"
  },
  cardList: {
    backgroundColor: "#ffffff",
    width: "100%",
    color: "#3C465F",
  },
  cardListGrey: {
    backgroundColor: "#F5F7FA",
    width: "100%",
  },
  cardListBlue: {
    backgroundColor: "#3C465F",
    width: "100%",
  },
}))

export default function SmallSpacerBlock(props) {
  const { styles } = props

  const classes = useStyles();

  const cardListDecider = (styles) => {

    if (styles === "White") {
      return classes.cardList
    }
    if (styles === "Blue") {
      return classes.cardListBlue
    }
    if (styles === "Grey-Transparent") {
      return null
    }
  }

  return (
    <>
      <Grid className={cardListDecider(styles)}>
        <Grid className={classes.spacer}></Grid>
      </Grid>
    </>
  )

}

