import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import HeroBlock from "./blocks/heroBlockSplitter";
import HeaderBlock from "./blocks/headerBlock";
import BigBlockGroup from "./blocks/bigBlockGroup/bigBlockGroup";
import PopularConversations from "./blocks/popularConversationsBlock/popularConversationsBlock";
import TitleBlock from "./blocks/titleBlock";
import LatestArticlesBlock from "./blocks/latestArticlesBlock";
import CalculatorBlock from "./blocks/calculatorBlock";
import SmallSpacerBlock from "./blocks/smallSpacerBlock";
import FilteredQuestions from "../filteredQuestions/filtered-questions";
import Breadcrumb from "./blocks/breadcrumb";
import WidgetComponent from "../sidebarComponents/widgetComponent";
import FundList from "../funds/fundList";
import Tips from "./blocks/tips";
import Container from "../container";
import RecProviders from "./blocks/recProviders";
import BestBuysTable from "../bestBuys/bestBuysTable";
import TwitterBlock from "./blocks/twitterBlock";
import SearchBlock from "./blocks/landingSearchBlock";
import SocialMediaPosts from "./blocks/socialMediaPosts";
import DropdownWidget from "../dropdownWidget/dropdownWidget";
import TabsTextboxBlock from "./blocks/tabsTextboxBlock/tabsTextboxBlock";
import CourseTitle from "./blocks/courseTitle";
import AvailableCourses from "./blocks/availableCourses";
import OriginalGatedLanding from "../gatedContent/landingPageGates/originalGatedLanding";
import DataCaptureForm from "./blocks/dataCaptureForm/dataCaptureForm";
//import DataCaptureForm from "./blocks/DataCaptureFormNew";
import qString from "query-string";
import { useLocation } from "@reach/router";
import EmailCaptureForm from "./blocks/emailInputForm";
import loadable from "@loadable/component";
import ContentCardBigBlockGroup from "./blocks/bigBlockGroup/contentCardBBGroup";

import { useApiData } from '../auth0/ApiContext';
import SurveyWidget from "../survey/surveyWidget";
import WealthWizard from "./blocks/wealthWizard/wealthWizard"

const AdviserList = loadable(() => import("./adviserList"))

export function LandingPageContent(props) {


  const location = useLocation();

  var questionCat = qString.parse(location.search).cat;
  const headerBlockVideoUrl = props?.content[0]?.headerVideo?.asset?.url
  const desktopVideo = props?.content[0]?.desktopVideo?.asset?.url
  const tabletVideo = props?.content[0]?.tabletVideo?.asset?.url
  const mobileVideo = props?.content[0]?.mobileVideo?.asset?.url
  console.log('props', props)

  const apiData = useApiData();

  var firstname = apiData != null ? apiData : "";

  const tintPage =
    props.gateStyle === "Blue background  - Text center - Faded" || props.gateStyle ===
    "White background  - Text center - Faded" ? true : false

  return (
    <>
      {((props.privatePage &&
        props.userAuthenticated &&
        props.emailConfirmed) ||
        !props.privatePage) &&
        props.content.map(
          (
            {
              _type,
              title,
              _key,
              linkText,
              mainImage,
              reference,
              bigBlockItems,
              _rawIntroPara,
              listAdvisers,
              alignment,
              articleCount,
              href,
              breadcrumbs,
              styles,
              tipElements,
              tipChoose,
              calculatorAssumptionsModel,
              costInBBTable,
              showAdvancedFilters,
              position,
              defaultLayout,
              defaultSortOption,
              sortOptions,
              layout,
              arrows,
              autoscroll,
              smoothAutoscroll,
              smoothAutoscrollSpeed,
              desktopImage,
              tabletImage,
              mobileImage,
              comparisonPageReference,
              enableComparison,
              companies,
              categories,
              CategoryMultiSelect,
              videoUrl,
              socialPost,
              items,
              tabs,
              sponsoredProvider,
              courseId,
              _rawIntroTitle,
              queryString,
              showButton,
              buttonText,
              buttonLink,
              backgroundColor,
              fontColour,
              backgroundColour: availableCourseBackground,
              signupRedirectLink,
              _rawThankYouText,
              downloadUrl,
              brazeCustomAttributes,
              brazeSubscriptionRef,
              dataCaptureInputs,
              equaliseHeight,
              titleLineClamp,
              paragraphLineClamp,
              inputFieldPlaceholderText,
              showEmailCapture,
              contentCardKeyValuePair,
              surveyID,
              loggedOutRowLimit,
              loggedOutRowLimitMobile,
              wizardTitle
            },
            index
          ) => (
            <Grid className={props.classes.fullWidth} key={_key}>
              {_type == "wealthWizard" && (
                <WealthWizard
                  title={wizardTitle} slug={props?.slug?.current} folder={props?.subfolder?.folder}
                />
              )}
              {_type == "heroSection" && (
                <HeroBlock
                  signupRedirectLink={signupRedirectLink}
                  styles={styles}
                  title={title}
                  linkText={linkText}
                  mainImage={mainImage}
                  reference={reference}
                  _rawIntroPara={_rawIntroPara}
                  alignment={alignment}
                  videoUrl={videoUrl}
                  isCoursePage={
                    props.theme == "Boring Money Campus theme" ? true : false
                  }
                  _rawThankYouText={_rawThankYouText}
                  downloadUrl={downloadUrl}
                  brazeCustomAttributes={brazeCustomAttributes}
                  showEmailCapture={showEmailCapture}
                  isAuthed={props.emailCaptured}
                  firstname={firstname}
                />
              )}
              {_type == "headerBlock" && (
                <HeaderBlock
                  title={title}
                  linkText={linkText}
                  desktopImage={desktopImage}
                  tabletImage={tabletImage}
                  mobileImage={mobileImage}
                  reference={reference}
                  _rawIntroPara={_rawIntroPara}
                  alignment={alignment}
                  headerBlockVideoUrl={headerBlockVideoUrl}
                  desktopVideo={desktopVideo}
                  tabletVideo={tabletVideo}
                  mobileVideo={mobileVideo}
                />
              )}
              {_type == "bigBlockGroup" && (
                <BigBlockGroup
                  styles={styles}
                  title={title}
                  bigBlockItems={bigBlockItems}
                  layout={layout}
                  arrows={arrows}
                  autoscroll={autoscroll}
                  smoothAutoscroll={smoothAutoscroll}
                  smoothAutoscrollSpeed={smoothAutoscrollSpeed}
                  isCoursePage={
                    props.theme == "Boring Money Campus theme" ? true : false
                  }
                  equaliseHeight={equaliseHeight}
                  titleLineClamp={titleLineClamp}
                  paragraphLineClamp={paragraphLineClamp}
                  firstname={firstname}
                />
              )}
              {_type == "contentCardBigBlockGroup" && (
                <ContentCardBigBlockGroup
                  styles={styles}
                  title={title}
                  bigBlockItems={bigBlockItems}
                  layout={layout}
                  arrows={arrows}
                  autoscroll={autoscroll}
                  smoothAutoscroll={smoothAutoscroll}
                  smoothAutoscrollSpeed={smoothAutoscrollSpeed}
                  isCoursePage={
                    props.theme == "Boring Money Campus theme" ? true : false
                  }
                  equaliseHeight={equaliseHeight}
                  titleLineClamp={titleLineClamp}
                  paragraphLineClamp={paragraphLineClamp}
                  type={_type}
                  contentCardKeyValuePair={contentCardKeyValuePair}
                />
              )}
              {_type == "popularConversationsBlock" && (
                <PopularConversations
                  title={title}
                  layout={layout}
                  arrows={arrows}
                  autoscroll={autoscroll}
                  queryString={queryString}
                  backgroundColor={backgroundColor}
                />
              )}
              {_type == "titleSection" && (
                <TitleBlock
                  styles={styles}
                  title={title}
                  _rawIntroPara={_rawIntroPara}
                  alignment={alignment}
                  linkText={linkText}
                  reference={reference}
                  isCoursePage={
                    props.theme == "Boring Money Campus theme" ? true : false
                  }
                  firstname={firstname}
                />
              )}
              {_type == "advisersList" && (
                <AdviserList
                  {..._type}
                  title={title}
                  listAdvisers={props.advisers}
                  isCoursePage={
                    props.theme == "Boring Money Campus theme" ? true : false
                  }
                />
              )}
              {_type == "latestArticlesBlock" && (
                <LatestArticlesBlock
                  allArticles={props.allArticles}
                  articleCount={articleCount}
                  categories={categories}
                  CategoryMultiSelect={CategoryMultiSelect}
                />
              )}
              {_type == "calcReference" && (
                <CalculatorBlock
                  href={href}
                  isCoursePage={
                    props.theme == "Boring Money Campus theme" ? true : false
                  }
                />
              )}
              {_type == "smallSpacerBlock" && <SmallSpacerBlock styles={styles} />}
              {_type == "filteredQuestionsBlock" && <FilteredQuestions questionCat={questionCat} />}
              {_type == "breadcrumb" && (
                <Breadcrumb
                  title={title}
                  slug={props.slug}
                  subfolder={props.subfolder}
                  bc={breadcrumbs}
                  isCoursePage={
                    props.theme == "Boring Money Campus theme" ? true : false
                  }
                />
              )}
              {_type == "widgetSelection" && (
                <WidgetComponent
                  widgets={props.content[index]}
                  target="body"
                  dictionary={props.dictionary}
                />
              )}
              {_type == "fundList" && <FundList />}
              {_type == "tipChooser" && (
                <Tips
                  tipElements={tipChoose.tipElements}
                  title={tipChoose.title}
                  description={tipChoose.description}
                />
              )}
              {_type == "recProviders" && (
                <Container>
                  <RecProviders
                    title={title}
                    position={position}
                    overrideCompanies={companies}
                  />
                </Container>
              )}
              {_type == "bestBuysTable" && (
                <BestBuysTable
                  emailCaptured={props.emailCaptured}
                  loggedOutRowLimit={loggedOutRowLimit}
                  loggedOutRowLimitMobile={loggedOutRowLimitMobile}
                  type={title}
                  calculatorAssumptionsModel={
                    calculatorAssumptionsModel?.assumptionRow
                  }
                  calcLumpSum={calculatorAssumptionsModel?.lumpSum}
                  costInBBTable={costInBBTable}
                  showAdvancedFilters={showAdvancedFilters}
                  defaultLayout={defaultLayout}
                  defaultSortOption={defaultSortOption}
                  sortOptions={sortOptions}
                  comparisonPageReference={comparisonPageReference}
                  enableComparison={enableComparison}
                  sponsoredProvider={sponsoredProvider}
                />
              )}
              {_type == "twitterBlock" && <TwitterBlock />}
              {_type == "landingSearchBlock" && <SearchBlock />}
              {_type == "socialMediaPosts" && (
                <SocialMediaPosts socialPost={socialPost} />
              )}
              {_type == "dropdownWidget" && (
                <Container>
                  <Grid container alignItems="center" justify="center">
                    <Grid
                      item
                      md={10}
                      className={props.classes.dropdownContainer}
                    >
                      <DropdownWidget items={items} isForLandingPage={true} />
                    </Grid>
                  </Grid>
                </Container>
              )}
              {_type == "tabsTextboxBlock" && <TabsTextboxBlock tabs={tabs} />}

              {_type == "courseTitle" && (
                <CourseTitle
                  fontColour={fontColour}
                  rawIntroPara={_rawIntroPara}
                  rawIntroTitle={_rawIntroTitle}
                  desktopImage={desktopImage}
                  tabletImage={tabletImage}
                  mobileImage={mobileImage}
                  courseId={props.updatedCourseID}
                  showButton={showButton}
                  buttonText={buttonText}
                  buttonLink={buttonLink}
                />
              )}
              {_type == "surveyWidget" && (
                <SurveyWidget surveyID={surveyID} />
              )}
              {_type == "availableCourses" && (
                <AvailableCourses
                  backgroundColour={availableCourseBackground}
                  isAuth={props.isUserAuthenticated}
                />
              )}
              {_type == "dataCaptureForm" && (
                <DataCaptureForm _rawThankYouText={_rawThankYouText}
                                 brazeCustomAttributes={brazeCustomAttributes}
                                 dataCaptureInputs={dataCaptureInputs} downloadUrl={downloadUrl} linkText={linkText}
                                 signupRedirectLink={signupRedirectLink} />)}
              {_type == "emailCaptureForm" && !props.emailCaptured && (
                <EmailCaptureForm alignment={alignment} _rawIntroPara={_rawIntroPara} _rawThankYouText={_rawThankYouText}
                brazeCustomAttributes={brazeCustomAttributes}  inputFieldPlaceholderText={inputFieldPlaceholderText}
                 downloadUrl={downloadUrl} linkText={linkText}
                signupRedirectLink={signupRedirectLink} />)}
            </Grid>
          )
        )}

      {props.enableGatedContent &&
        !props.emailCaptured &&
        (props.gateStyle &&
          <OriginalGatedLanding
            tintPage={tintPage}
            gateStyle={props.gateStyle}
            paragraphText={props.emailSignupWidgetParagraphText}
            title={props.emailSignupWidgetTitle}
            buttonText={props.emailSignupWidgetButtonText}
            subscribedCallback={props.subscribedCallback}
            brazeCustomAttributes={props.brazeCustomAttributes}
            placeholder={props.emailSignupWidgetTextboxPlaceholder}
          />
        )}
  </>
  );
}

LandingPageContent.propTypes = {
  privatePage: PropTypes.any,
  userAuthenticated: PropTypes.bool,
  emailConfirmed: PropTypes.bool,
  map: PropTypes.any,
};
