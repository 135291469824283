import React, { useEffect, useState } from "react";
import { LandingPageContent } from "../landingPageComponents/landingPageContent";

const LandingpageGatedContentDecider = ({
  content,
  dictionary,
  slug,
  isPrivatePage,
  showConfetti,
  theme,
  allArticles,
  enableGatedContent,
  gateStyle,
  emailSignupWidgetTitle,
  emailSignupWidgetParagraphText,
  emailSignupWidgetButtonText,
  emailSignupWidgetTextboxPlaceholder,
  gatedContentBody,
  gatedContentReadMoreText,
  textNextToKey,
  classes,
  isUserAuthenticated,
  emailConfirmed,
  advisers,
  updatedCourseID,
  brazeCustomAttributes,
  emailCaptured, setEmailCaptured,
  title,
  subfolder,
  showGate,
}) => {
  // const [emailCaptured, setEmailCaptured] = useState(false);

  useEffect(() => {
    (async () => {
      var res = showGate;

      //console.log("res-land", res);

      if (res) {
        setEmailCaptured(showGate);
      }
    })();
  }, [showGate]);

  const subscribedCallback = () => {
    window.scrollTo(0, 0);

    setEmailCaptured(true);
  };
  if ((enableGatedContent && !emailCaptured )) {
    return (
      <>
        <LandingPageContent
          enableGatedContent={enableGatedContent}
          emailCaptured={emailCaptured}
          title={title}
          subfolder={subfolder}
          gateStyle={gateStyle}
          privatePage={isPrivatePage}
          userAuthenticated={isUserAuthenticated}
          emailConfirmed={emailConfirmed}
          content={gatedContentBody}
          classes={classes}
          theme={theme}
          advisers={advisers}
          allArticles={allArticles}
          slug={slug}
          dictionary={dictionary}
          updatedCourseID={updatedCourseID}
          isUserAuthenticated={isUserAuthenticated}
          emailSignupWidgetTitle={emailSignupWidgetTitle}
          emailSignupWidgetButtonText={emailSignupWidgetButtonText}
          emailSignupWidgetParagraphText={emailSignupWidgetParagraphText}
          subscribedCallback={subscribedCallback}
          brazeCustomAttributes={brazeCustomAttributes}
          emailSignupWidgetTextboxPlaceholder={
            emailSignupWidgetTextboxPlaceholder
          }
        />
      </>
    );
  } else {
    return (
      <>
        <LandingPageContent
          privatePage={isPrivatePage}
          userAuthenticated={isUserAuthenticated}
          title={title}
          subfolder={subfolder}
          emailConfirmed={emailConfirmed}
          content={content}
          classes={classes}
          theme={theme}
          advisers={advisers}
          allArticles={allArticles}
          slug={slug}
          dictionary={dictionary}
          updatedCourseID={updatedCourseID}
          isUserAuthenticated={isUserAuthenticated}
          emailCaptured={emailCaptured}
        />
      </>
    );
  }
};

export default LandingpageGatedContentDecider;
