import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../../container";
import SearchIcon from "../img/search-icon.svg";
import SearchIconBlue from "../img/search-icon-blue.svg";
import * as styles from "./landingSearchBlock.module.css";

const useStyles = makeStyles((theme) => ({
  searchBlockContainer: {
    background: '#3C465F',
    paddingTop: '24px',
    paddingBottom: '24px',
    [theme.breakpoints.down(959)]: {
      padding: '24px'
    }
  },
  headerBlockTitleContainer: {
    paddingBottom: '1.5em'
  },
  headerBlockItemContainer: {
    maxWidth: '1170px !important'
  },
  headerBlockTitle: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '-0.02em',
    color: '#ffffff',
    marginTop: 0,
    marginBottom: '8px'
  },
  headerBlockSubTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    marginBottom: '0',
    marginTop: 0
  },
  headerBlockButton: {
    backgroundColor: '#FF0F5F !important',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    borderRadius: '8px',
    height: '56px',
    width: '100%',
    textTransform: 'uppercase',
    '& span': {
      color: '#ffffff'
    }
  },
  searchIcon: {
    paddingRight: '0.5em'
  },
  searchBarContainer: {
    [theme.breakpoints.down(800)]: {
      flexBasis: '75% !important',
      maxWidth: '75% !important'
    },
    [theme.breakpoints.down(600)]: {
      flexBasis: '100% !important',
      maxWidth: '100% !important'
    }
  },
  buttonContainer: {
    [theme.breakpoints.down(800)]: {
      flexBasis: '25% !important',
      maxWidth: '25% !important'
    },
    [theme.breakpoints.down(600)]: {
      flexBasis: '100% !important',
      maxWidth: '100% !important'
    }
  }
}));

export default function LandingSearchBlock(props) {
  const { } = props;

  const [searchPhrase, setSearchPhrase] = useState('');
  const [shouldRedirectToSearch, setShouldRedirectToSearch] = useState(false);

  const classes = useStyles();

  useEffect(
    () => {
      if (shouldRedirectToSearch) {
        window.location.href = "/articles/all?search=" + encodeURIComponent(searchPhrase);
      }
    }
    , [shouldRedirectToSearch]);

  const handleSearch = function () {
    setShouldRedirectToSearch(true);
  }


  var handleKeyDown = function (e) {
    if (e.key === 'Enter') {
      setShouldRedirectToSearch(true);
    }
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      justify="space-between"
      className={classes.searchBlockContainer}
    >
      <Grid item md={1}></Grid>
      <Grid
        item
        xs={12}
        md={10}
        className={classes.headerBlockItemContainer}
      >
        <Grid item xs={12} className={classes.headerBlockTitleContainer}>
          <p className={classes.headerBlockTitle}>
            Search our articles
            </p>
          <p className={classes.headerBlockSubTitle}>
            If you are looking for something specific, use our search bar to browse our library of articles and content.
              </p>
        </Grid>
        <Grid container alignItems="center" justify="center" className={classes.inputHolder}>
          <Grid
            item
            xs={12}
            sm={10}
            className={`${styles.searchDropdownContainer} ${classes.searchBarContainer}`}
          >
            <input type="text" className={styles.searchDropdown} onChange={event => setSearchPhrase(event.target.value)} onKeyDown={handleKeyDown} />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.buttonContainer}>
            <Button
              className={classes.headerBlockButton}
              onClick={handleSearch}
            >
              <img className={classes.searchIcon} src={SearchIcon} />
                Search
              </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
