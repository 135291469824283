import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Swiper } from "swiper/react";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import WhiteIconLeft from "./icons/WhiteIconLeft.svg";
import WhiteIconRight from "./icons/WhiteIconRight.svg";
import VisibleWhiteIconLeft from "./icons/VisibleWhiteIconLeft.svg";
import VisibleWhiteIconRight from "./icons/VisibleWhiteIconRight.svg";
import { RenderBigBlockCards } from "./layout/renderBigBlockCards";
import LeftOneBigByRightTwoSmallCards from "./layout/LeftOneBigByRightTwoSmallCards";

const useStyles = makeStyles((theme) => ({
    cardList: {
        backgroundColor: "#ffffff",
        width: "100%",
        color: "#3C465F",
    },
    cardListGrey: {
        backgroundColor: "#F7F5E7",
        width: "100%",
    },
    cardListBlue: {
        backgroundColor: "#6464FF",
        width: "100%",
    },
    cardListBlueNoCampus: {
        backgroundColor: "#3C465F",
        width: "100%",
    },
    cardsContainer: {
        height: "100%",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    cardsContainerBlueCampus: {
        height: "100%",
        marginTop: "0 !important",
        marginBottom: "0 !important",
        "& a": {
            textDecorationLine: "underline",
            color: "#FFFFFF !important",
        },
        "& a:hover": {},
    },
    cardsContainerWhiteCampus: {
        height: "100%",
        marginTop: "0 !important",
        marginBottom: "0 !important",
        "& p, h1, h2, h3, h4, h5, h6, span": {
            color: "#3C465F",
        },
        "& a": {
            textDecorationLine: "none",
        },
        "& a:hover": {
            textDecorationLine: "underline",
        },
    },
    cardsContainer5x5: {
        height: "100%",
        marginTop: "0 !important",
        marginBottom: "0 !important",

        // Even spacing between items for 5x5
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-start",
        },
    },
    cardsContainer5x5WhiteCampus: {
        height: "100%",
        marginTop: "0 !important",
        marginBottom: "0 !important",

        // Even spacing between items for 5x5
        [theme.breakpoints.up("md")]: {
            // justifyContent: "space-between",
        },
        "& a": {
            textDecorationLine: "underline",
            color: "#FFFFFF !important",
        },
        "& a:hover": {
            textDecorationLine: "underline",
        },
    },
    cardsContainer5x5BlueCampus: {
        height: "100%",
        marginTop: "0 !important",
        marginBottom: "0 !important",

        // Even spacing between items for 5x5
        [theme.breakpoints.up("md")]: {
            // justifyContent: "space-between",
        },
        "& a": {
            textDecorationLine: "none",
        },
        "& a:hover": {
            textDecorationLine: "underline",
        },
    },
    swiperSlide: {
        overflow: "auto",
    },
    bodyContainer: {
        "@media (min-width: 1423px)": {
            maxWidth: "1170px",
        },
    },
    bodyContainerCourse: {
        "& .swiper-wrapper": {
            "-webkit-transition-timing-function": "linear!important",
            "-o-transition-timing-function": "linear!important",
            "transition-timing-function": "linear!important",
        },
        "@media (min-width: 1423px)": {
            maxWidth: "1170px",
        },
    },
    navIconLeft: {
        height: "48px",
        width: "48px",
    },
    navIconRight: {
        height: "48px",
        width: "48px",
    },
    navIconLeftContainer: {
        display: "flex",
        justifyContent: "end",
        marginLeft: "auto",
        marginRight: "auto",
        cursor: "pointer",
        [theme.breakpoints.up("md")]: {
            flex: 1,
        },
        "@media (min-width: 1625px)": {
            marginRight: "40px",
        },
    },
    navIconRightContainer: {
        display: "flex",
        justifyContent: "start",
        marginRight: "auto",
        marginLeft: "auto",
        cursor: "pointer",
        [theme.breakpoints.up("md")]: {
            flex: 1,
        },
        "@media (min-width: 1625px)": {
            marginLeft: "40px",
        },
    },
    mobileNavContainer: {
        cursor: "pointer",
        display: "flex",
        width: "100%",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        justifyContent: "center",
    },
    mobileLeftIcon: {
        "@media (min-width: 695px)": {
            paddingRight: "6px",
        },
    },
    mobileRightIcon: {
        "@media (min-width: 695px)": {
            paddingLeft: "6px",
        },
    },
    title: {
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "50px",
        textAlign: "center",
        color: "#FFFFFF",
        paddingTop: "70px",
        paddingBottom: "48px",
        [theme.breakpoints.down("md")]: {
            paddingTop: "24px",
            paddingBottom: "32px",
            fontSize: "32px",
            lineHeight: "40px",
        },
    },
    titleWhite: {
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "50px",
        textAlign: "center",
        color: "#282A5E",
        paddingTop: "70px",
        paddingBottom: "48px",
        [theme.breakpoints.down("md")]: {
            paddingTop: "24px",
            paddingBottom: "32px",
            fontSize: "32px",
            lineHeight: "40px",
        },
    },
    titleWhiteCourse: {
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "50px",
        textAlign: "center",
        color: "#3C465F",
        paddingTop: "70px",
        paddingBottom: "48px",
        [theme.breakpoints.down("md")]: {
            paddingTop: "24px",
            paddingBottom: "32px",
            fontSize: "32px",
            lineHeight: "40px",
        },
    },
    rightTwoCardsContainer: {
        boxSizing: "border-box",
        minHeight: "fit-content",
        padding: "12px",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            paddingTop: 0,
        },
        "& > div:nth-child(2)": {
            marginTop: "auto",
            "@media (min-width: 960px) and (max-width: 1290px)": {
                alignSelf: "flex-end",
            },
            [theme.breakpoints.down("sm")]: {
                marginTop: "0",
            },
        },
    },
    innerCardEqualised: {
        height: "100%",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        position: "relative",
        filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
        "&:hover": {
            boxShadow: "0px 10px 30px 1.6px #4646462b",
            filter: "none",
        },
    },

    buttonHolderEqualised: {
        marginTop: "auto",
        alignItems: "center",
    },
    bigBySmallContainer: {
        marginTop: "16px",
        marginBottom: "16px",
    },
  swiperContainer: {
      height: "100%",
  }
}));

// Required in order to trick Swiper into thinking cards are direct children
RenderBigBlockCards.displayName = "SwiperSlide";

export default function ContentCardBigBlockGroup(props) {
    const {
        title,
        bigBlockItems,
        styles,
        layout,
        arrows,
        autoscroll,
        smoothAutoscroll,
        smoothAutoscrollSpeed,
        isCoursePage,
        equaliseHeight,
        titleLineClamp,
        paragraphLineClamp,
        type,
        contentCardKeyValuePair,
    } = props;
    //console.log("big block items: ", bigBlockItems);
    const classes = useStyles();
    const [brazeData, setBrazeData] = useState({})
    const [rightArrow, useRightArrow] = useState(null);
    const [leftArrow, useLeftArrow] = useState(null);

    const [mobileRightArrow, useMobileRightArrow] = useState(null);
    const [mobileLeftArrow, useMobileLeftArrow] = useState(null);

    const matchesDesktop = useMediaQuery("(min-width:960px)");
    const matchesMobile = useMediaQuery("(max-width: 959px)");


    const useStylesClamp = makeStyles((theme) => ({
        clampedPara: {
            "& .MuiTypography-body1": {
                display: "-webkit-box",
                "-webkit-line-clamp": paragraphLineClamp
                    ? `${paragraphLineClamp}`
                    : "none",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                lineClamp: paragraphLineClamp ? `${paragraphLineClamp}` : "none",
                paddingBottom: paragraphLineClamp ? "0 !important" : "inherit",
            },
        },
        clampedTitle: {
            display: "-webkit-box",
            "-webkit-line-clamp": titleLineClamp ? `${titleLineClamp}` : "none",
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            lineClamp: titleLineClamp ? `${titleLineClamp}` : "none",
        },
    }));

    const clampClasses = useStylesClamp();

    function getSlideAmount() {
        switch (layout) {
            case "2x2":
                return 2
            case "3x3":
                return 3
            case "4x4":
                return 4
            case "5x5":
                return 5
            case "6x6":
                return 6
        }
    }

    function getMobileSlideAmount() {
        switch (layout) {
            case "3x3":
                return 1
            case "4x4":
                return 2
            case "5x5":
                return 2
            case "6x6":
                return 2
        }
    }

    function getTabletAmount() {
        switch (layout) {
            case "2x2":
                return 2
            case "3x3":
                return 3
            case "4x4":
                return 4
            case "5x5":
                return 5
            case "6x6":
                return 6
        }
    }

    let slideAmount = getSlideAmount();
    let mobileSlideAmount = getMobileSlideAmount();
    let tableSlideAmount = getTabletAmount();

    function cardListDecider(styles) {
        const cardLists = {
            "White": classes.cardList,
            "Blue": classes.cardListBlueNoCampus,
            "Grey-Transparent": null,
        }

        return cardLists[styles]
    }


    function cardsContainerDecider(styles, layout) {
        const cardsContainers = {
            "Blue": classes.cardsContainerBlueCampus,
            "White": classes.cardsContainerWhiteCampus,
            "Grey-Transparent": classes.cardsContainerWhiteCampus,
        }

        const cardsContainers5x5 = {
            "White": classes.cardsContainer5x5BlueCampus,
            "Grey-Transparent": classes.cardsContainer5x5WhiteCampus,
            "Blue": classes.cardsContainer5x5WhiteCampus,
        }

        return layout === "5x5" ? cardsContainers5x5[styles] : cardsContainers[styles]
    }

    // if (
    //   styles === "Blue" &&
    //   !isCoursePage &&
    //   layout !== "Left 1xbig, Right 2xsmall"
    // )
    //   return null;

    useEffect(() => {
        const contentCards = window.brazeCardsTest()
       // console.log('brazeData', contentCards?.cards)
        if (contentCardKeyValuePair && contentCards?.cards?.length > 0){
          const {contentCardKey, contentCardValue} = contentCardKeyValuePair
          const filteredData = filterArrayByKeyAndValue(contentCards?.cards, contentCardKey, contentCardValue)
          setBrazeData((prevState) => ({
            ...prevState,
            cards: {...filteredData}
          }))
        } else {
          setBrazeData(contentCards)
        }
    }, [])

  // Filter content cards by CMS supplied Key Value pair
  function filterArrayByKeyAndValue(arr, key, value) {
    const filteredArray = arr.filter((item) => {
      if (item.extras.hasOwnProperty(key)) {
        return item.extras[key] === value;
      }
      return false;
    });
    return filteredArray;
  }

   // console.log('bigBlockItems', bigBlockItems)

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
        condition ? wrapper(children) : children;

    const enableSwiper = arrows || autoscroll || smoothAutoscroll;

    if (layout === "Left 1xbig, Right 2xsmall") {
        return <LeftOneBigByRightTwoSmallCards classes={classes} styles={styles} cardListDecider={cardListDecider}
            isCoursePage={isCoursePage}
            bigBlockItems={bigBlockItems}
            layout={layout}
            equaliseHeight={equaliseHeight} paragraphLineClamp={paragraphLineClamp}
            clampClasses={clampClasses} type={type} contentCardKeyValuePair={contentCardKeyValuePair}
        />
    }
    return (
        <Grid
            className={
                isCoursePage
                  ? styles === "White" && classes.cardList : cardListDecider(styles)
              }
        >
            {isCoursePage == true && title && (
                <Typography
                    variant={"h3"}
                    className={
                        styles == "White"
                            ? `${isCoursePage ? classes.titleWhiteCourse : classes.titleWhite
                            }`
                            : styles === "Grey-Transparent"
                                ? `${isCoursePage ? classes.titleWhiteCourse : classes.title}`
                                : classes.title
                    }
                >
                    {title}
                </Typography>
            )}
            {/* Desktop Swiper */}
            {matchesDesktop && (
                <Grid container item alignItems="center" justify="space-between">
                    <Grid item xs={0} md={1} className={classes.navIconLeftContainer}>
                        {arrows && (
                            <img
                                ref={useLeftArrow}
                                className={classes.navIconLeft}
                                src={isCoursePage ? VisibleWhiteIconLeft : WhiteIconLeft}
                            ></img>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={11}
                        md={10}
                        className={
                            smoothAutoscroll
                                ? classes.bodyContainerCourse
                                : classes.bodyContainer
                        }
                    >
                        <Grid
                            container
                            spacing={3}
                            className={
                                isCoursePage
                                    ? cardsContainerDecider(styles, layout)
                                    : (layout === "5x5" ? classes.cardsContainer5x5 : classes.cardsContainer)
                            }
                        >
                            <ConditionalWrapper
                                condition={enableSwiper}
                                wrapper={children =>
                                    <Swiper
                                        modules={[Navigation, Pagination, Autoplay, A11y]}
                                        slidesPerView={slideAmount}
                                        // observer={true}
                                        // resizeObserver={true}
                                        // autoplay={autoscroll}
                                        updateOnWindowResize={true}
                                        navigation={{
                                            nextEl: rightArrow,
                                            prevEl: leftArrow,
                                        }}
                                        autoplay={
                                            autoscroll || smoothAutoscroll
                                                ? {
                                                    pauseOnMouseEnter: true,
                                                    disableOnInteraction: false,
                                                    delay: smoothAutoscroll ? 1 : 2000,
                                                }
                                                : false
                                        }
                                        speed={
                                            smoothAutoscroll && smoothAutoscrollSpeed
                                                ? smoothAutoscrollSpeed
                                                : 300
                                        }
                                        loopedSlidesLimit={false}
                                        className={classes.swiperContainer}
                                        loop={true}
                                    >{children}
                                    </Swiper>
                                }
                            >
                                <RenderBigBlockCards
                                    bigBlockItems={bigBlockItems}
                                    layout={layout}
                                    isCoursePage={isCoursePage}
                                    styles={styles}
                                    clampClasses={clampClasses}
                                    equaliseHeight={equaliseHeight}
                                    paragraphLineClamp={paragraphLineClamp}
                                    enableSwiper={enableSwiper}
                                    type={type}
                                    contentCardKeyValuePair={contentCardKeyValuePair}
                                />
                            </ConditionalWrapper>
                            {matchesMobile && (
                                <div className={classes.mobileNavContainer}>
                                    <img
                                        ref={useMobileLeftArrow}
                                        src={isCoursePage ? VisibleWhiteIconLeft : WhiteIconLeft}
                                    ></img>
                                    <img
                                        ref={useMobileRightArrow}
                                        src={
                                            isCoursePage ? VisibleWhiteIconRight : WhiteIconRight
                                        }
                                    ></img>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={0} md={1} className={classes.navIconRightContainer}>
                        {arrows && (
                            <img
                                ref={useRightArrow}
                                className={classes.navIconRight}
                                src={isCoursePage ? VisibleWhiteIconRight : WhiteIconRight}
                            ></img>
                        )}
                    </Grid>
                </Grid>
            )}
            {/* Mobile swiper  */}
            {matchesMobile && (
                <Grid container item alignItems="center" justify="space-between">
                    <Grid
                        item
                        xs={0}
                        md={1}
                        className={classes.navIconLeftContainer}
                    ></Grid>
                    <Grid
                        item
                        xs={11}
                        md={10}
                        className={
                            smoothAutoscroll
                                ? classes.bodyContainerCourse
                                : classes.bodyContainer
                        }
                    >
                        <Grid
                            container
                            spacing={3}
                            className={
                                isCoursePage
                                    ? cardsContainerDecider(styles, layout)
                                    : classes.cardsContainer
                            }
                        >

                            <ConditionalWrapper
                                condition={enableSwiper}
                                wrapper={children =>
                                    <Swiper
                                        modules={[Navigation, Pagination, Autoplay, A11y]}
                                        slidesPerView={4}
                                        observer={true}
                                        resizeObserver={true}
                                        updateOnWindowResize={true}
                                        navigation={{
                                            nextEl: mobileRightArrow,
                                            prevEl: mobileLeftArrow,
                                        }}
                                        autoplay={
                                            autoscroll || smoothAutoscroll
                                                ? {
                                                    pauseOnMouseEnter: true,
                                                    disableOnInteraction: false,
                                                    delay: smoothAutoscroll ? 1 : 2000,
                                                }
                                                : false
                                        }
                                        loopedSlidesLimit={false}
                                        speed={
                                            smoothAutoscroll && smoothAutoscrollSpeed
                                                ? smoothAutoscrollSpeed
                                                : 300
                                        }
                                        loop={true}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: mobileSlideAmount,
                                            },
                                            659: {
                                                slidesPerView: tableSlideAmount,
                                            },
                                            959: {
                                                slidesPerView: slideAmount,
                                            },
                                        }}
                                    >
                                        {children}
                                    </Swiper>
                                }
                            >
                                <RenderBigBlockCards
                                    bigBlockItems={bigBlockItems}
                                    layout={layout}
                                    isCoursePage={isCoursePage}
                                    styles={styles}
                                    clampClasses={clampClasses}
                                    equaliseHeight={equaliseHeight}
                                    paragraphLineClamp={paragraphLineClamp}
                                    enableSwiper={enableSwiper}
                                    type={type}
                                    contentCardKeyValuePair={contentCardKeyValuePair}
                                />
                            </ConditionalWrapper>
                            {arrows && (
                                <div className={classes.mobileNavContainer}>
                                    <img
                                        ref={useMobileLeftArrow}
                                        src={isCoursePage ? VisibleWhiteIconLeft : WhiteIconLeft}
                                        className={classes.mobileLeftIcon}
                                    ></img>
                                    <img
                                        ref={useMobileRightArrow}
                                        src={
                                            isCoursePage ? VisibleWhiteIconRight : WhiteIconRight
                                        }
                                        className={classes.mobileRightIcon}
                                    ></img>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={0}
                        md={1}
                        className={classes.navIconRightContainer}
                    ></Grid>
                </Grid>
            )}
        </Grid>
    );

}
