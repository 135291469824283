// import { differenceInDays, formatDistance, format } from "date-fns";
import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ContainerForPages from "./containerForPages";
import FooterDisclaimer from "../components/footer-disclaimer";
import LoginForm from "./account/loginForm";
import VerifyEmail from "./account/verifyEmail";
import ResetPassword from "./account/resetPassword";
import ForgotPassword from "./account/forgotPassword";
import Confetti from "react-confetti";
import { getUserObject } from "../lib/helpers";
import * as PropTypes from "prop-types";
import { LandingPageContent } from "./landingPageComponents/landingPageContent";
import LandingpageGatedContentDecider from "./gatedContent/landingpageGatedContentDecider";
import AuthenticatorNoRedirect from "../components/auth0/AuthenticatorNoRedirect";
import {AuthContext} from "../context/AuthContext";
import landingPageTint from "./gatedContent/img/landingPageTint.svg"

const useStyles = makeStyles((theme) => ({
  grey: {
    // marginTop: "15px",
    paddingBottom: "30px",
  },
  greyForAdviser: {
    paddingTop: "15px",
    paddingBottom: "30px",
    backgroundColor: "#F5F7FA",
  },
  fullWidth: {
    width: "100%",
  },
  dropdownContainer: {
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
  },
  disclaimerFlexContainer: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  // tint:{
  //   height: "100%",
  //   bottom: 0,
  //   backgroundImage: `url(${landingPageTint})`,
  //   width: "100%",
  //   backgroundRepeat: "no-repeat",
  //   backgroundSize: "cover",
  //   position: "absolute",
  // },
}));

const LandingPage = ({
  title,
  content,
  dictionary,
  slug,
  subfolder,
  advList,
  isPrivatePage,
  showConfetti,
  theme,
  allArticles,
  enableGatedContent,
  gateStyle,
  brazeCustomAttributes,
  emailSignupWidgetTitle,
  emailSignupWidgetParagraphText,
  emailSignupWidgetButtonText,
  emailSignupWidgetTextboxPlaceholder,
  gatedContentBody,
  gatedContentReadMoreText,
  textNextToKey,
  emailCaptured,
  setEmailCaptured,
  hideFooter,
}) => {
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [isRegisterPage, setIsRegisterPage] = useState(false);
  const [isResetPasswordPage, setIsResetPasswordPage] = useState(false);
  const [isForgotPasswordPage, setIsForgotPasswordPage] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [shouldRedirectToLoginPage, setShouldRedirectToLoginPage] =
    useState(false);
  const [isVerifyEmailPage, setIsVerifyEmailPage] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [emailConfirmed, setEmailConfirmed] = useState(false);




  var exists = false;
  var authedState = useContext(AuthContext);
  var showGate = authedState.isAuthed;


  useEffect(() => {
      setTimeout(() => {
        // Get the hash from the URL (e.g., "#myAnchorTag")
        const hash = window.location.hash;

        // Check if the hash exists and points to a valid anchor tag
        if (hash) {
          // Remove the leading '#' character from the hash
          const cleanedHash = hash.slice(1);

          // Use the cleanedHash as a valid selector
          const element = document.getElementById(cleanedHash);

          if (element) {
            // Scroll to the element's position
            element.scrollIntoView({ behavior: 'instant' });
          }
        }
      })
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });

    //window.cardAdd();

    //window.getUserForAffiliate();

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const classes = useStyles();
  var advisers = null;

  if (advList == undefined) {
    var advisers = null;
  } else {
    var advisers = advList.nodes;
  }

  useEffect(() => {
    (async () => {
      var isLoginPage = false;
      var isRegisterPage = false;

      if (
        window.location.pathname === "/visible/login/" ||
        window.location.pathname === "/visible/login"
      ) {
        isLoginPage = true;
        setIsLoginPage(true);
      }

      //if (
      //  window.location.pathname === "/logout/" ||
      //  window.location.pathname === "/logout"
      //) {
      //  window.localStorage.clear();

      //  window.location.href = "/visible/welcome/";
      //}

      if (
        window.location.pathname === "/visible/register/" ||
        window.location.pathname === "/visible/register"
      ) {
        isRegisterPage = true;
        setIsRegisterPage(true);
      }

      if (
        window.location.pathname === "/visible/verify-email/" ||
        window.location.pathname === "/visible/verify-email"
      ) {
        setIsVerifyEmailPage(true);
      }

      if (
        window.location.pathname === "/visible/forgot-password/" ||
        window.location.pathname === "/visible/forgot-password"
      ) {
        setIsForgotPasswordPage(true);
      }

      if (
        window.location.pathname === "/visible/reset-password/" ||
        window.location.pathname === "/visible/reset-password"
      ) {
        setIsResetPasswordPage(true);
      }

      window.addEventListener("storage", onStorageUpdate);

      var token = window.localStorage.getItem("token");

      var userObject = await getUserObject(token);

      //if (!userObject.IsAuthenticated) {
      //  //if (isPrivatePage) {
      //  //  window.location.href =
      //  //    "/visible/login?returnUrl=" +
      //  //    encodeURIComponent(
      //  //      window.location.pathname + window.location.search
      //  //    );
      //  //}
      //} else {
        //if (
        //  isPrivatePage &&
        //  !userObject.EmailConfirmed &&
        //  window.location.pathname !== "/visible/verify-email/" &&
        //  window.location.pathname !== "/visible/verify-email" &&
        //  !isLoginPage &&
        //  !isRegisterPage
        //) {
        //  window.location.href = "/visible/verify-email";
        //} else {
          setEmailConfirmed(true);
        //}

        //// hide register page from authenticated users
        //if (isRegisterPage) {
        //  window.location.href = "/visible/members/";
        //}

        setIsUserAuthenticated(true);
      //}
    })();
  }, []);

  useEffect(() => {
    if (isPrivatePage && shouldRedirectToLoginPage) {
      window.location.href =
        "/visible/login?returnUrl=" +
        encodeURIComponent(window.location.pathname + window.location.search);
    }
  }, [shouldRedirectToLoginPage]);

  var onStorageUpdate = (e) => {
    const { key, newValue } = e;

    if (key === "isUserAuthenticated") {
      var isAuthenticated = newValue === "true" ? true : false;

      setIsUserAuthenticated(isAuthenticated);

      if (!isAuthenticated) {
        setShouldRedirectToLoginPage(true);
      }
    }
  };

  var updatedCourseID = content.courseId;
  if (typeof updatedCourseID !== "undefined") {
    if (updatedCourseID.includes("draft.")) {
      updatedCourseID = updatedCourseID.replace("draft.", "");
    }
  }

  //var exists = false;
  //if (typeof window !== "undefined") {
  //  exists = window.getACookie("BrazeGroups");

  //  var showGate = false;

  //  if (exists == "false") {
  //    showGate = false;
  //  }
  //  else if (exists.includes(brazeSubscriptionGroupId)) {
  //    showGate = true;
  //  }
  //  else {
  //    showGate = false;
  //  }
  //}


  return (
    <article
    // className={content[0]._type == "advisersList" ? classes.greyForAdviser : classes.grey}
    >

      <ContainerForPages

        isCoursePage={theme == "Boring Money Campus theme" ? true : false}
      >
        {isLoginPage && <LoginForm />}

        {isVerifyEmailPage && <VerifyEmail />}

        {isResetPasswordPage && <ResetPassword />}

        {isForgotPasswordPage && <ForgotPassword />}
        <Grid id="confettiholder">
          {showConfetti && (
            <Confetti
              recycle={false}
              tweenDuration={7000}
              numberOfPieces={248}
              gravity={0.17}
              width={width - 20}
              height={height}
            />
          )}
        </Grid>
        {/* <Container> */}
        {/*<LandingPageContent privatePage={isPrivatePage} userAuthenticated={isUserAuthenticated}*/}
        {/*                    emailConfirmed={emailConfirmed} content={content} classes={classes} theme={theme} advisers={advisers} allArticles={allArticles} slug={slug} dictionary={dictionary} updatedCourseID={updatedCourseID} isUserAuthenticated={isUserAuthenticated}/>*/}
        <LandingpageGatedContentDecider
          title={title}
          subfolder={subfolder}
          emailCaptured={emailCaptured}
          setEmailCaptured={setEmailCaptured}
          content={content}
          dictionary={dictionary}
          slug={slug}
          isPrivatePage={isPrivatePage}
          theme={theme}
          allArticles={allArticles}
          enableGatedContent={enableGatedContent}
          gateStyle={gateStyle}
          emailSignupWidgetTitle={emailSignupWidgetTitle}
          emailSignupWidgetParagraphText={emailSignupWidgetParagraphText}
          emailSignupWidgetButtonText={emailSignupWidgetButtonText}
          emailSignupWidgetTextboxPlaceholder={
            emailSignupWidgetTextboxPlaceholder
          }
          brazeCustomAttributes={brazeCustomAttributes}
          gatedContentBody={gatedContentBody}
          gatedContentReadMoreText={gatedContentReadMoreText}
          textNextToKey={textNextToKey}
          classes={classes}
          isUserAuthenticated={isUserAuthenticated}
          emailConfirmed={emailConfirmed}
          advisers={advisers}
          updatedCourseID={updatedCourseID}
          showGate={showGate}
        />
        <Grid
          className={classes.disclaimerFlexContainer}
          md={10}
          xs={11}
          item
          justify="center"
        >
          {!hideFooter && <FooterDisclaimer
            isCoursePage={theme == "Boring Money Campus theme" ? true : false}
          />}
        </Grid>
      </ContainerForPages>
      {/* </Container> */}
    </article>
  );
};

export default LandingPage;
