// @flow
import * as React from "react";
import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
// @ts-ignore
import clientConfig from "../../../../../../client-config";
import serializers from "../../../../serializers";
import inheritableColorSerializersForBlueBackground from "../../inheritableColorSerializersForBlueBackground";
import { Grid, Typography } from "@material-ui/core";
import ContainerForLinks from "../../../../containerForLinks";
// @ts-ignore
import WhiteArrowRight from "../icons/WhiteArowRight.svg";
// @ts-ignore
import BlueArrowRight from "../icons/BlueArrowRight.svg";
import CampusCTA from "../../reuseableCampusComponents/campusCTA";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { SwiperSlide } from "swiper/react"
// @ts-ignore
import * as fixStyles from "./bigBlockCard.module.css"
import { Skeleton } from "@material-ui/lab";

type Props = {
  title: string;
  linkText: string;
  rawIntroPara: {
    _key: string;
    _type: string;
    children: object[];
    markDefs: string[];
    style: string;
  }[];
  reference?: {
    _type: string;
    slug: object;
    subfolder: object;
    title: string;
  };
  mainImageWithType?: {
    _type: string;
    alt: string;
    asset: {
      _id: string;
    };
    crop?: boolean | string;
    hotspot?: boolean | string;
    isBigBlockGroupImage: boolean;
  };
  isSwiperCard?: boolean;
  isCoursePage?: boolean;
  styles: string;
  equaliseHeight: boolean;
  paragraphLineClamp?: number;
  clampClasses: ClassNameMap<string>;
  layout: string;
  brazeTitle?: string;
  brazeImgUrl?: string;
  brazeDescription?: string;
  brazeRedirectUrl?: string;
  ctaLink?: string;
  type: string;
  hasError: any;
  brazeCards: any;
  contentLoaded?: boolean;
};

export default function BigBlockCard(props: Props): JSX.Element {
  const {
    title,
    linkText,
    rawIntroPara,
    reference,
    mainImageWithType,
    isSwiperCard,
    isCoursePage,
    styles,
    equaliseHeight,
    clampClasses,
    paragraphLineClamp,
    layout,
    brazeTitle,
    brazeImgUrl,
    brazeDescription,
    brazeRedirectUrl,
    ctaLink,
    type,
    hasError,
    brazeCards,
    contentLoaded
  } = props;

  // console.log('brazeTitle', brazeTitle)
  // console.log('brazeCards', brazeCards)


  function getPadding(layout) {

    const defaultPadding = "16px";

    const paddings = {
      "2x2": "24px 24px 24px 24px",
      "3x3": "24px 24px 24px 24px",
      "4x4": "16px 16px 16px 16px",
      "5x5": "16px 16px 16px 16px",
      "6x6": "16px 16px 16px 16px",
    }

    return paddings[layout] ?? paddings[defaultPadding];
  }

  function getSpacingDesktop(layout) {

    const defaultSpacing = 3;

    const spacings = {
      "2x2": 6,
      "3x3": 4,
      "4x4": 3,
      "5x5": 2,
      "6x6": 2,
    }

    return spacings[layout] ?? spacings[defaultSpacing];
  }

  function getSpacingMobile(layout) {

    const defaultSpacing = 6;

    const spacings = {
      "2x2": 12,
      "3x3": 12,
      "4x4": 6,
      "5x5": 6,
      "6x6": 6,
    }

    return spacings[layout] ?? spacings[defaultSpacing];
  }

  const padding = getPadding(layout)
  const desktopSpacing = getSpacingDesktop(layout)
  const mobileSpacing = getSpacingMobile(layout)

  const buttonSizingVisible = layout === "6x6" || layout === "5x5" ? "small" : "large";

  const useStyles = makeStyles((theme) => ({
    cardList: {
      backgroundColor: "#ffffff",
      width: "100%",
    },
    card: {
      position: "relative",
      marginTop: 0,
      marginBottom: 0,
      borderRadius: "8px",
      maxWidth: layout === "5x5" ? "fit-content" : "inherit",
      flexGrow: layout === "5x5" ? 1 : 0,
      "&:hover $title": {
        color: "#8C8CA1",
      },
      "&:hover $svg": {
        stroke: "#FF0F5F",
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px !important",
      },
      [theme.breakpoints.up("md")]: {
        padding: "12px !important",
        // flexBasis: layout === "5x5" ? "20%" : "inherit",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "15px !important",
      },
    },
    card5x5: {
      position: "relative",
      marginTop: 0,
      marginBottom: 0,
      borderRadius: "8px",
      maxWidth: layout === "5x5" ? "fit-content" : "inherit",
      flexGrow: layout === "5x5" ? 1 : 0,
      "&:hover $title": {
        color: "#8C8CA1",
      },
      "&:hover $svg": {
        stroke: "#FF0F5F",
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px !important",
      },
      [theme.breakpoints.up("md")]: {
        padding: "12px !important",
        flexBasis: layout === "5x5" ? "20%" : "inherit",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "15px !important",
      },
    },
    swiperCard: {
      position: "relative",
      marginTop: 0,
      marginBottom: 0,
      borderRadius: "8px",
      maxWidth: "100%",
      minWidth: "100%",
      "&:hover $title": {
        color: "#8C8CA1",
      },
      "&:hover $svg": {
        stroke: "#FF0F5F",
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px !important",
      },
      [theme.breakpoints.up("md")]: {
        padding: "12px !important",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "15px !important",
      },
      "@media (min-width: 1404px)": {
        // maxWidth: "auto",
        // maxWidth: "fit-content",
        // maxHeight: "max-content",
        height:`100%`,
      },
    },
    swiperCardEqualised: {
      height: "100%",
      position: "relative",
      marginTop: 0,
      marginBottom: 0,
      borderRadius: "8px",
      // maxWidth: "fit-content",
      // isSwiperCard ? minWidth: "-webkit-fill-available" : maxWidth: "fit-content",
      "&:hover $title": {
        color: "#8C8CA1",
      },
      "&:hover $svg": {
        stroke: "#FF0F5F",
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px !important",
      },
      [theme.breakpoints.up("md")]: {
        padding: "12px !important",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "15px !important",
      },
      "@media (min-width: 1404px)": {
        // maxWidth: "auto",
        maxWidth: "fit-content",
      },
    },
    cardFillFix:{
      minWidth: "-moz-fill-available",
    },
    innerCard: {
      // height: "100%",
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      position: "relative",
      filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
      "&:hover": {
        boxShadow: "0px 10px 30px 1.6px #4646462b",
        filter: "none",
      },
    },
    innerCardSwiper: {
      height: "100%",
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      position: "relative",
      filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
      "&:hover": {
        // boxShadow: "0px 10px 30px 1.6px #4646462b",
        boxShadow: "0px 6px 12px 1.6px #4646462b",
        filter: "none",
      },
    },
    innerCardSwiperCourse: {
      height: "100%",
      borderradius: "8px",
      position: "relative",
      filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
      color: "#ffffff !important",
    },
    innerCardCoursePage: {
      height: "100%",
      borderRadius: "8px",
      color: "#FFFFFF !important",
      position: "relative",
    },
    innerCardCoursePageEqualised: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      borderRadius: "8px",
      color: "#FFFFFF !important",
      position: "relative",
    },
    normalHeight: {
      // height: "100%",
    },
    singleImageHeight: {
      height: "initial",
    },
    title: {
      color: "#262641",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "-0.02em",
      marginBottom: "8px",
    },
    buttonText: {
      color: "#3C455C",
      marginLeft: "8px",
      marginTop: "auto",
    },
    buttonHolder: {
      alignItems: "center",
      paddingTop: "24px",
    },
    buttonHolderCourse: {
      alignItems: "center",
      justifyContent: "center",
    },
    buttonTextCourse: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "18px",
      display: "flex",
      color: "#FFFFFF",
      textTransform: "uppercase",
      marginTop: "auto",
    },
    buttonTextCourseWhite: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "18px",
      display: "flex",
      color: "#100F41",
      textTransform: "uppercase",
      marginTop: "auto",
    },
    imageHolder: {
      display: "flex",
    },
    imageHolderCourse: {
      "&:hover": {
        // boxShadow: "0px 10px 30px 1.6px #4646462b",
        filter: "none",
      },
      "& figure": {
        "& img": {
          borderRadius: "0px !important",
          backgroundColor: "inherit",
        },
      },
    },
    textHolder: {
      padding: padding,
      display: "flex",
      flexDirection: "column",
      // position: "relative",
      // height: "100%",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        padding: "16px"
      },
      "& p:last-child": {
        paddingBottom: "0 !important",
      },
    },
    textHolderCourse: {
      // padding: "24px 24px 8px 24px",
      marginBottom: "1em",
      display: "flex",
      flexDirection: "column",
      paddingTop: "2em",
      [theme.breakpoints.down("md")]: {
        paddingTop: "1em",
      },
      "& p, h1, h2, h3, h4, h5, h6, span": {
        color: "#FFFFFF",
      },
    },
    textHolderCourseWhite: {
      // padding: "24px 24px 8px 24px",
      marginBottom: "1em",
      display: "flex",
      flexDirection: "column",
      paddingTop: "2em",
      "& a": {
        color: "#6464ff !important",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "1em",
      },
      "& p, h1, h2, h3, h4, h5, h6, span": {
        color: "#100F41",
      },
    },
    svg: {
      "&:hover": {
        stroke: "#FF0F5F",
      },
    },
    cardsContainer: {
      paddingTop: "33px",
      paddingBottom: "48px",
    },
    portableText: {},
    courseTitle: {
      color: "#FFFFFF !important",
      fontWeight: "700 !important" as any,
      fontSize: layout === "6x6" || layout === "5x5" ? "14px !important" : "18px !important",
      lineHeight: layout === "6x6" || layout === "5x5" ? "20px !important" : "27px !important",
      marginBottom: "8px",
    },
    courseTitleWhite: {
      fontWeight: "700 !important" as any,
      fontSize: layout === "6x6" || layout === "5x5" ? "14px !important" : "18px !important",
      lineHeight: layout === "6x6" || layout === "5x5" ? "20px !important" : "27px !important",
      marginBottom: "8px",
    },
    courseRightArrow: {
      marginLeft: "0.5rem",
      width: "12px",
      height: "12px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    courseRightArrowBlack: {
      paddingLeft: "1rem",
      width: "12px",
      height: "12px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    svgPadding: {
      marginLeft: "8px",
    },
    campusLink: {
      textDecoration: "none !important",
    },
    innerCardEqualised: {
      height: "100%",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffffff",
      position: "relative",
      filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
      "&:hover": {
        boxShadow: "0px 10px 30px 1.6px #4646462b",
        filter: "none",
      },
    },

    buttonHolderEqualised: {
      marginTop: "auto",
      alignItems: "center",
    },

    textHolderEqualised: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: padding,
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        padding: "16px",
      },
      "& p:last-child": {
        paddingBottom: "0 !important",
      },
    },
    textHolderEqualisedCourse: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: 0,
      paddingTop: "1em",
      marginBottom: "1em",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
      "& p:last-child": {
        paddingBottom: "0 !important",
      },
    },
    cardEqualised: {
      position: "relative",
      borderRadius: "8px",
      // display: "flex",
      maxWidth: layout === "5x5" ? "fit-content" : "inherit",
      flexGrow: layout === "5x5" ? 1 : 0,
      "&:hover $title": {
        color: "#8C8CA1",
      },
      "&:hover $svg": {
        stroke: "#FF0F5F",
      },

      [theme.breakpoints.down("md")]: {
        padding: "8px !important"
      },
      [theme.breakpoints.up("md")]: {
        padding: "12px !important"

      },
      [theme.breakpoints.up("lg")]: {
        padding: "15px !important"
      },
    },
    equaliseSwiper: {
      display: "flex",
      flexDirection: "column",
    },
    anchorContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      textDecoration: "none",
      color: "inherit",
    },
    swiperSlide: {
      overflow: "auto",
      width: "100%",
      display: "block",
      height: equaliseHeight ? "auto" : "fit-content",
      // minWidth: "-webkit-fill-available"
      // minInlineSize: "-webkit-fill-available"
    },
    brazeImageContainer: {
      width: "100%",
      borderRadius: "8px 8px 0px 0px",
      // height: "200px",
      // objectFit: "cover",
      aspectRatio: "16/9",
      // [theme.breakpoints.up("sm")]: {
      //   height: "400px"
      // },
      // [theme.breakpoints.up("md")]: {
      //   height: "200px"
      // },
    },
    MuiTypographybody1: {
      color: "#2F2F4E",
      margin: 0,
      fontSize: "16px",
      fontStyle: "regural",
      wordBreak: "break-word",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: 0
  },
    loadingCard:{
      width: "100%",
      height: "325px",
      overflow: "hidden",
    },
    brazeDescText:{
      paddingBottom: "0 !important",
    },
  }));
  const classes = useStyles();


  const innerCardClass = isCoursePage
    ? (equaliseHeight ? classes.innerCardCoursePageEqualised : classes.innerCardCoursePage)
    : (equaliseHeight ? classes.innerCardEqualised : classes.innerCard);
  const innerCardClassSwiper = `${isCoursePage ? classes.innerCardSwiperCourse : classes.innerCardSwiper} ${equaliseHeight && classes.equaliseSwiper}`

  // Variables to decide course specific styling options
  const textHolderCourseClass = styles == "White" || styles === "Grey-Transparent" ? classes.textHolderCourseWhite : classes.textHolderCourse;
  const buttonTextCourseClass = styles == "White" || styles === "Grey-Transparent" ? classes.buttonTextCourseWhite : classes.buttonTextCourse;
  const courseTitleClass = styles == "White" || styles == "Grey-Transparent" ? classes.courseTitleWhite : classes.courseTitle;

  const Container = isSwiperCard ? SwiperSlide : Fragment

  //console.log('linktext', linkText)
  //console.log('brazeImageUrl', brazeImgUrl)
  //console.log('brazeCards', brazeCards)

  // return (
  //   <Container className={classes.swiperSlide}>
  //     <Grid
  //       item
  //       className={isSwiperCard ? (equaliseHeight ? classes.swiperCardEqualised : classes.swiperCard) : (equaliseHeight ? classes.cardEqualised : classes.card)}
  //       md={desktopSpacing}
  //       sm={mobileSpacing}
  //       xs={mobileSpacing}
  //       key={title}
  //     >
  //       <Grid
  //         className={`${isSwiperCard ? innerCardClassSwiper : innerCardClass} ${
  //           title || linkText || rawIntroPara
  //             ? classes.normalHeight
  //             : classes.singleImageHeight
  //         }`}
  //       >
  //         <ContainerForLinks
  //           reference={reference}
  //           _type={reference && reference._type}
  //           brazeRedirectUrl={brazeRedirectUrl}
  //           customClassName={isCoursePage ? classes.campusLink : classes.anchorContainer}
  //           newTab={undefined}
  //           onClickHandler={undefined}
  //           queryParams={undefined}
  //           isMenuItem={undefined} logCtaWidgetGoogleAnalyticsEvent={undefined}
  //           logSidebarLinkGoogleAnalyticsEvent={undefined}>
  //           <Grid className={isCoursePage ? classes.imageHolderCourse : classes.imageHolder}>
  //             { brazeImgUrl ? <img className={classes.brazeImageContainer} src={brazeImgUrl} alt="" /> :
  //             mainImageWithType ?
  //                <BasePortableText
  //                 blocks={mainImageWithType}
  //                 serializers={serializers}
  //                 {...clientConfig.sanity}
  //               /> : null
  //             }
  //           </Grid>
  //           {(title || linkText || rawIntroPara) && (
  //             <Grid className={
  //               `  ${isCoursePage ? textHolderCourseClass : classes.textHolder}
  //               ${equaliseHeight ? (isCoursePage ? classes.textHolderEqualisedCourse : classes.textHolderEqualised) : null}
  //             `
  //             }
  //                   style={{
  //                     marginBottom: !linkText && paragraphLineClamp ? 0 : "1em",
  //                   }}
  //             >
  //               {brazeTitle || title && (
  //                 <Typography variant="h4"
  //                             className={`${isCoursePage ? courseTitleClass : classes.title} ${clampClasses.clampedTitle}`}>
  //                   {brazeTitle || title}
  //                 </Typography>
  //               )}
  //             { brazeDescription ? <p>{brazeDescription}</p> :
  //               <BasePortableText
  //               blocks={rawIntroPara}
  //               serializers={serializers}
  //               {...clientConfig.sanity}
  //               />
  //             }
  //              {/* Need link text from sanity to display CTAs for content cards */}
  //               {linkText && (
  //                 isCoursePage ? <Grid className={equaliseHeight && classes.buttonHolderEqualised}><CampusCTA
  //                     linkText={linkText} buttonType={"rectangular"} arrowIcon={false} variant={buttonSizingVisible}
  //                     newTab={false}
  //                     autoHeight={false} loading={false} customBorderRadius={""}/></Grid> :
  //                   <Grid container
  //                         className={
  //                           `${isCoursePage
  //                             ? classes.buttonHolderCourse
  //                             : classes.buttonHolder}
  //                   ${equaliseHeight ? classes.buttonHolderEqualised : null}`
  //                         }
  //                   >
  //                     {!isCoursePage &&
  //                       <svg
  //                         width="24"
  //                         height="24"
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         xmlns="http://www.w3.org/2000/svg"
  //                       >
  //                         <path
  //                           className={classes.svg}
  //                           d="M19.75 11.7257L4.75 11.7257"
  //                           stroke="#200E32"
  //                           stroke-width="1.5"
  //                           stroke-linecap="round"
  //                           stroke-linejoin="round"
  //                         />
  //                         <path
  //                           className={classes.svg}
  //                           d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503"
  //                           stroke="#200E32"
  //                           stroke-width="1.5"
  //                           stroke-linecap="round"
  //                           stroke-linejoin="round"
  //                         />
  //                       </svg>
  //                     }
  //                     <Typography variant="button"
  //                                 className={isCoursePage ? buttonTextCourseClass : classes.buttonText}>
  //                       {ctaLink || linkText}
  //                     </Typography>
  //                     {isCoursePage &&
  //                     styles == "White" ?
  //                       (<img src={BlueArrowRight} className={classes.svgPadding}/>) : (
  //                         <img src={WhiteArrowRight} className={classes.courseRightArrow}/>)
  //                     }
  //                   </Grid>
  //               )}
  //             </Grid>
  //           )}
  //         </ContainerForLinks>
  //       </Grid>
  //     </Grid>
  //   </Container>
  // );

  return (
    // type === "contentCardBigBlockGroup" && !contentLoaded ?
    //   <Container className={classes.swiperSlide}>
    //     <Grid
    //       item
    //       container
    //       className={isSwiperCard ? (equaliseHeight ? `${fixStyles.widthFix} ${classes.swiperCardEqualised}` : `${fixStyles.widthFix} ${classes.swiperCard}`) : (equaliseHeight ? classes.cardEqualised : (layout === "5x5"
    //         ? classes.card5x5 : classes.card))}
    //       md={desktopSpacing}
    //       sm={mobileSpacing}
    //       xs={mobileSpacing}
    //       key={title}
    //     >
    //       <Grid
    //         className={`${classes.loadingCard} ${isSwiperCard ? innerCardClassSwiper : innerCardClass} ${brazeTitle || ctaLink
    //           ? classes.normalHeight
    //           : classes.singleImageHeight
    //         }`}
    //       >
    //         <Skeleton variant={"rect"} height={"100%"} width={"100%"}/>
    //       </Grid>
    //     </Grid>
    //   </Container> :
    type === "contentCardBigBlockGroup" && !hasError && brazeCards.length !== undefined ?
      (brazeTitle || brazeTitle || brazeImgUrl || brazeDescription ?
      <Container className={classes.swiperSlide}>
        <Grid
          item
          className={isSwiperCard ? (equaliseHeight ? `${fixStyles.widthFix} ${classes.swiperCardEqualised}` : `${fixStyles.widthFix} ${classes.swiperCard}`) : (equaliseHeight ? classes.cardEqualised : (layout === "5x5"
            ? classes.card5x5 : classes.card))}
          md={desktopSpacing}
          sm={mobileSpacing}
          xs={mobileSpacing}
          key={title}
        >
          <Grid
            className={`${isSwiperCard ? innerCardClassSwiper : innerCardClass} ${brazeTitle || ctaLink
              ? classes.normalHeight
              : classes.singleImageHeight
            }`}
          >
            <ContainerForLinks
              reference={undefined}
              _type={undefined}
              brazeRedirectUrl={brazeRedirectUrl}
              customClassName={isCoursePage ? classes.campusLink : classes.anchorContainer}
              newTab={undefined}
              onClickHandler={undefined}
              queryParams={undefined}
              isMenuItem={undefined} logCtaWidgetGoogleAnalyticsEvent={undefined}
              logSidebarLinkGoogleAnalyticsEvent={undefined}>
              {/* <Grid className={isCoursePage ? classes.imageHolderCourse : classes.imageHolder}>
                {<img className={classes.brazeImageContainer} src={brazeImgUrl} alt="" />
                }
              </Grid> */}
              <Grid className={isCoursePage ? classes.imageHolderCourse : classes.imageHolder}>
                {brazeImgUrl ? <img className={classes.brazeImageContainer} src={brazeImgUrl} alt=""/> : null}
              </Grid>
              {(brazeTitle || ctaLink) && (
                <Grid className={
                  `  ${isCoursePage ? textHolderCourseClass : classes.textHolder}
                ${equaliseHeight ? (isCoursePage ? classes.textHolderEqualisedCourse : classes.textHolderEqualised) : null}
                ${clampClasses.clampedPara}
              `
                }
                      // style={{
                      //   marginBottom: !linkText && paragraphLineClamp ? 0 : "1em",
                      // }}
                >
                  {brazeTitle && (
                    <Typography variant="h4"
                                className={`${isCoursePage ? courseTitleClass : classes.title} ${clampClasses.clampedTitle}`}>
                      {brazeTitle}
                    </Typography>
                  )}
                  {<p className={`MuiTypography-body1 ${classes.brazeDescText}`}>{brazeDescription}</p>
                  }
                  {/* Need link text from sanity to display CTAs for content cards */}
                  {ctaLink && (
                    isCoursePage ? <Grid className={equaliseHeight && classes.buttonHolderEqualised}><CampusCTA
                        linkText={linkText} buttonType={"rectangular"} arrowIcon={false} variant={buttonSizingVisible}
                        newTab={false}
                        autoHeight={false} loading={false} customBorderRadius={""}/></Grid> :
                      <Grid container
                            className={
                              `${isCoursePage
                                ? classes.buttonHolderCourse
                                : classes.buttonHolder}
                    ${equaliseHeight ? classes.buttonHolderEqualised : null}`
                            }
                      >
                        {!isCoursePage && ctaLink &&
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className={classes.svg}
                              d="M19.75 11.7257L4.75 11.7257"
                              stroke="#200E32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              className={classes.svg}
                              d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503"
                              stroke="#200E32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        <Typography variant="button"
                                    className={isCoursePage ? buttonTextCourseClass : classes.buttonText}>
                          {ctaLink !== "" ? ctaLink : null}
                        </Typography>
                        {isCoursePage &&
                        styles == "White" ?
                          (<img src={BlueArrowRight} className={classes.svgPadding}/>) : (
                            <img src={WhiteArrowRight} className={classes.courseRightArrow}/>)
                        }
                      </Grid>
                  )}
                </Grid>
              )}
            </ContainerForLinks>
          </Grid>
        </Grid>
      </Container> : null)
      :
      <Container className={classes.swiperSlide}>
        <Grid
          item
          className={isSwiperCard ? (equaliseHeight ? `${fixStyles.widthFix} ${classes.swiperCardEqualised}` : `${fixStyles.widthFix} ${classes.swiperCard}`) : (equaliseHeight ? classes.cardEqualised :
            (layout === "5x5" ? classes.card5x5 : classes.card))}
          md={desktopSpacing}
          sm={mobileSpacing}
          xs={mobileSpacing}
          key={title}
        >
          <Grid
            className={`${isSwiperCard ? innerCardClassSwiper : innerCardClass} ${title || linkText || rawIntroPara
              ? classes.normalHeight
              : classes.singleImageHeight
              }`}
          >
            <ContainerForLinks
              reference={reference}
              _type={reference && reference._type}
              brazeRedirectUrl={brazeRedirectUrl}
              customClassName={isCoursePage ? classes.campusLink : classes.anchorContainer}
              newTab={undefined}
              onClickHandler={undefined}
              queryParams={undefined}
              isMenuItem={undefined} logCtaWidgetGoogleAnalyticsEvent={undefined}
              logSidebarLinkGoogleAnalyticsEvent={undefined}>
              <Grid className={isCoursePage ? classes.imageHolderCourse : classes.imageHolder}>
                {
                  <BasePortableText
                    blocks={mainImageWithType}
                    serializers={serializers}
                    {...clientConfig.sanity}
                  />
                }
              </Grid>
              {(title || linkText || rawIntroPara) && (
                <Grid className={
                  `  ${isCoursePage ? textHolderCourseClass : classes.textHolder}
                    ${equaliseHeight ? (isCoursePage ? classes.textHolderEqualisedCourse : classes.textHolderEqualised) : null}
                    ${clampClasses.clampedPara}
                  `
                }
                  // style={{
                  //   marginBottom: !linkText && paragraphLineClamp ? 0 : "1em",
                  // }}
                >
                  {title && (
                    <Typography variant="h4"
                      className={`${isCoursePage ? courseTitleClass : classes.title} ${clampClasses.clampedTitle}`}>
                      {title}
                    </Typography>
                  )}
                  {
                    <BasePortableText
                      blocks={rawIntroPara}
                      serializers={serializers}
                      {...clientConfig.sanity}
                    />
                  }
                  {/* Need link text from sanity to display CTAs for content cards */}
                  {linkText && (
                    isCoursePage ? <Grid className={equaliseHeight && classes.buttonHolderEqualised}><CampusCTA
                      linkText={linkText} buttonType={"rectangular"} arrowIcon={false} variant={buttonSizingVisible}
                      newTab={false}
                      autoHeight={false} loading={false} customBorderRadius={""} /></Grid> :
                      <Grid container
                        className={
                          `${isCoursePage
                            ? classes.buttonHolderCourse
                            : classes.buttonHolder}
                        ${equaliseHeight ? classes.buttonHolderEqualised : null}`
                        }
                      >
                        {!isCoursePage &&
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className={classes.svg}
                              d="M19.75 11.7257L4.75 11.7257"
                              stroke="#200E32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              className={classes.svg}
                              d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503"
                              stroke="#200E32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        <Typography variant="button"
                          className={isCoursePage ? buttonTextCourseClass : classes.buttonText}>
                          {linkText}
                        </Typography>
                        {isCoursePage &&
                          styles == "White" ?
                          (<img src={BlueArrowRight} className={classes.svgPadding} />) : (
                            <img src={WhiteArrowRight} className={classes.courseRightArrow} />)
                        }
                      </Grid>
                  )}
                </Grid>
              )}
            </ContainerForLinks>
          </Grid>
        </Grid>
      </Container>
  )
}
