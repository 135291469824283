import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Hidden } from "@material-ui/core";
import TickSquare from "../../../../icon/quiz-results-check.svg";

const useStyles = makeStyles((theme) => ({
  tickSquare: {
    paddingRight: ".3em",
  },
  inANutshellContainer: {
    paddingBottom: "5px",
    paddingTop: "5px",
    minHeight: "18px",
  },
  nutshellContainer: {
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8px",
      paddingLeft: '16px',
      paddingRight: '16px'
    },
  },
  nutshellContainer3x3: {
    height: "auto",
    paddingBottom: "8px",
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  sectionTitle: {
    color: "#3C465F",
    fontWeight: "700",
    paddingBottom: "7px",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "20px",

  },
  inANutshellText: {
    fontSize: "14px",
    color: "#2F2F4E",
    fontWeight: "500",
    lineHeight: "18px",
    paddingLeft: "4px",
  },
  textContainer: {
    //width: "calc(100% - 24px)"
    maxWidth: '90%',
    [theme.breakpoints.down("1050")]: {
      maxWidth: '86%',
    },
  }
}));

export default function BestBuysInANutshell(props) {
  const classes = useStyles();
  const { inANutshell, defaultLayout } = props;

  if (inANutshell === undefined) {
    return null
  }

  return (
    <Grid item container className={defaultLayout == null || defaultLayout == "1x1" ? classes.nutshellContainer : classes.nutshellContainer3x3}>
      {inANutshell &&
        inANutshell.map((text) => {
          return (
            <>
              <Grid
                container
                alignItems="flex-start"
                direction="row"
                className={classes.inANutshellContainer}
                key={text}
              >

                <img
                  src={TickSquare}
                  alt="TickSquare"
                  className={classes.tickSquare}
                />
                <Grid className={classes.textContainer}>
                  <Typography variant="body1" className={classes.inANutshellText}>
                    {text}
                  </Typography></Grid>
              </Grid>
            </>
          );
        })}
    </Grid>
  );
}
