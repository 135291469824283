import React from "react";
import HeroBlockNoPadding from "./heroBlockNoPadding";
import HeroBlockPadding from "./heroBlockPadding";

export default function HeroBlock(props) {
  const { styles } = props;

 

  if (styles === "Half-No padding-White" || styles === "Half-No padding-Blue" || styles === "Half-No padding-Beige") {
    return <HeroBlockNoPadding {...props} />;
  }
  if (styles === "Half-Padding-White" || styles === "Half-Padding-Blue" || styles === "Half-Padding-Beige") {
    return <HeroBlockPadding {...props} />;
  }
}
