import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { AddTrailingSlashToUrl } from "../../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    minWidth: "25%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  paper: {
    textAlign: "center",
    borderRadius: "8px",
    height: "100%",
  },
  buttonContainerHelper2: {
    // [theme.breakpoints.down("sm")]: {
    //   order: "1",
    // },
    [theme.breakpoints.down(1340)]: {
      width: "100%",
      order: "1",
    },
    textDecoration: "none",
    width: "100%",
    cursor: 'pointer'
  },
  reviewButton: {
    backgroundColor: "#fff",
    width: "100%",
    padding: "12px",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    color: "#3C455C",
    fontSize: "14px",
    marginBottom: "8px",
    lineHeight: "21px",
    border: '1px solid #94A4D6',
    borderRadius: '6px',
    "&:hover": {
      background: "#fff",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#3C455C",
    },
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      marginBottom: "16px",
      borderRadius: '8px'
    },

  },
  buttonTextMoreInfo: {
    color: "#3C455C",
    backgroundColor: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "24px"
  },
  visitSiteButtonAffiliate: {
    backgroundColor: "#FB755C",
    width: "100%",
    padding: "12px",
    "&:hover": {
      background: "#ffffff",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#FB755C",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: '8px',
      marginBottom: "16px",
    },
  },
  visitSiteButton: {
    backgroundColor: "#FF0F5F",
    color: "black",
    width: "100%",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    padding: "12px",
    "&:hover": {
      background: "#FFF",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#FF0F5F",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: '8px',
      marginBottom: "16px",
    },
  },
  buttonMarginTop: {
  },
  buttonTextVisitSite: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "24px",
  },
  mobileOrderSwitcher: {
    [theme.breakpoints.down("sm")]: {

      flexDirection: " column-reverse",
    },
  },
  buttonContainer3x3first: {
    padding: "0 8px 8px 16px",
    [theme.breakpoints.down("sm")]: {

      padding: "0"
    },

  },
  buttonContainer3x3second: {
    padding: "0 16px 8px 8px",
    [theme.breakpoints.down("sm")]: {

      padding: "0"
    },

  }
}));

export default function BestBuysButtons(props) {
  const classes = useStyles();
  const { linkHrefReview, linkHref, isAffiliate, companyDisplayName } = props;
  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  useEffect(() => {
    (async () => {

    //  window.getUserForAffiliate()


    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({
            'best_buy_card_clicked_value': JSON.stringify(googleAnalyticsObject)
          });

          window.dataLayer.push({ event: 'best_buy_card_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  var readReviewClicked = function () {
    setGoogleAnalyticsObject({
      ProviderName: props.companyDisplayName,
      Source: 'read review button'
    });
  }

  var visitSiteClicked = function () {
    setGoogleAnalyticsObject({
      ProviderName: props.companyDisplayName,
      Source: 'visit site button'
    });
  }

  return (
    <Grid item container className={classes.buttonsContainer}>
      <Grid
        container
        className={classes.paper}
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        {props.isForThreeXThree ?
          <Grid container justify="space-between" className={classes.mobileOrderSwitcher}>
            <Grid container xs={12} md={6} className={classes.buttonContainer3x3first}>
              {linkHrefReview && (
                <a href={AddTrailingSlashToUrl(linkHrefReview)} className={classes.buttonContainerHelper2} onClick={() => { readReviewClicked() }} justify="center" alignItems="center">
                  <Button className={classes.reviewButton}>
                    <Typography
                      variant="button"
                      className={classes.buttonTextMoreInfo}
                    >
                      Learn More
              </Typography>
                  </Button>
                </a>
              )}</Grid>
            <Grid container xs={12} md={6} className={classes.buttonContainer3x3second} justify="flex-start" alignItems="flex-start">
              {linkHref && (
                <a href={AddTrailingSlashToUrl(linkHref)} className={classes.buttonContainerHelper2} onClick={() => { visitSiteClicked() }} target="_blank">
                  <Button
                    className={`${isAffiliate == true
                      ? classes.visitSiteButtonAffiliate
                      : classes.visitSiteButton
                      } ${linkHref ? classes.buttonMarginTop : ""}`}
                  >
                    <Typography
                      variant="button"
                      className={classes.buttonTextVisitSite}
                    >
                      Visit Site
              </Typography>
                  </Button>
                </a>
              )}</Grid>
          </Grid>

          :
          <Grid container justify="space-between" className={classes.mobileOrderSwitcher}>
            {linkHrefReview && (
              <a href={AddTrailingSlashToUrl(linkHrefReview)} className={classes.buttonContainerHelper2} onClick={() => { readReviewClicked() }}>
                <Button className={classes.reviewButton}>
                  <Typography
                    variant="button"
                    className={classes.buttonTextMoreInfo}
                  >
                    Learn More
                </Typography>
                </Button>
              </a>
            )}
            {linkHref && (
              <a href={AddTrailingSlashToUrl(linkHref)} className={classes.buttonContainerHelper2} onClick={() => { visitSiteClicked() }} target="_blank">
                <Button
                  className={`${isAffiliate == true
                    ? classes.visitSiteButtonAffiliate
                    : classes.visitSiteButton
                    } ${linkHref ? classes.buttonMarginTop : ""}`}
                >
                  <Typography
                    variant="button"
                    className={classes.buttonTextVisitSite}
                  >
                    Visit Site
                </Typography>
                </Button>
              </a>
            )}
          </Grid>}
      </Grid>
    </Grid>
  );
}
