import { useStaticQuery, graphql } from "gatsby";

function useAvailableCourses() {
  const data = useStaticQuery(graphql`
    query GetAvailableCoursesQuery {
      allSanityCourse (sort: {fields: [publishedAt], order: DESC}, filter: { publishedAt: {gt: "2021-12-31T23:59:35.321Z"},theme: {ne: "Boring Money theme"}}){
        edges {
          node {
            title
            courseName
            courseDescription
            _rawAvailableCourseDescription
            courseColour
            videoUrl
            _id
              slug {
                  _key
                  _type
                  current
                }
            mainImage {
              ...SanityImage
              alt
              _type
            }
            courseLandingPage {
              ... on SanityLandingPage {
                _type
                slug {
                  _key
                  _type
                  current
                }
                subfolder {
                  folder
                }
              }
            }
            sponsor {
              _key
              _rawCourseSponsorImg
              _type
              courseSponsorImg {
                _key
                _rawAsset
                _rawCrop
                _rawHotspot
                _type
              }
              courseSponsorImgDark {
                _key
                _type
                _rawAsset
                _rawHotspot
                _rawCrop
              }
              courseSponsorLink
              courseSponsorName
            }
            subfolder {
              folder
            }
            publishedAt
            enableOpenCourse
          }
        }
      }
    }
  `);
  return data;
}

export default useAvailableCourses;
