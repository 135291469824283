import React from "react";
import { Typography, Button, Menu, MenuItem, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PageProps } from "gatsby";
import TwitterIcon from "./icons/twitterIcon.svg";
import bmlogo2 from "./icons/bmlogo2.jpg";
import Container from "../container";
import TwitterCard from "./twitter";
import LinkedInCard from "./linkedin";

interface RenderSocialCardProps {
  postContent: string;
  postId: string;
  socialPlatform: {
    title: string;
  };
}

interface SocialFeedProps {
  socialPost: Array<RenderSocialCardProps>;
}

const RenderSocialCard = ({
  socialPlatform,
  postContent,
  postId,
}: RenderSocialCardProps) => {
  switch (socialPlatform.title) {
    case "Twitter":
      return <TwitterCard tweetContent={postContent} URLofTweet={postId} />;
    case "LinkedIn":
      return <LinkedInCard postContent={postContent} URLofPost={postId} />;
    case "facebook":
      return null;
    case "instagram":
      return null;
    case "youtube":
      return null;
    default:
      return null;
  }
};

const SocialFeed = ({ socialPost }: SocialFeedProps) => {
  const useStyles = makeStyles((theme) => {
    return {
      socialCardList: {
        // width: "100%",
      },
    };
  });

  const classes = useStyles();

  return (
    <Container>
      <Grid
        container
        direction="row"
        alignContent="space-between"
        className={classes.socialCardList}
        spacing={3}
      >
        {socialPost.map(
          ({ socialPlatform, postContent, postId }: RenderSocialCardProps) => {
            return (
              <RenderSocialCard socialPlatform={socialPlatform} postContent={postContent} postId={postId} />
            );
          }
        )}
      </Grid>
    </Container>
  );
};

export default SocialFeed;
