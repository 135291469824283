import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    cursor: 'pointer',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #989EBE',
    marginBottom: '16px',
    position: 'relative'
  },
  tabContainerActive: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, #F7FBFF 100%)',
    boxShadow: '10px 10px 50px 3px rgba(49, 49, 49, 0.1)',
    border: 'none'
  },
  tabTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'block',
    paddingBottom: '12px',
    cursor: 'pointer'
  },
  tabTitleActive: {
    color: '#262641'
  },
  tabParagraph: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer'
  },
  tabParagraphActive: {
    color: '#2F2F4E'
  },
  bottomStripe: {
    display: 'none'
  },
  bottomStripeActive: {
    position: 'absolute',
    bottom: '0',
    left: 0,
    height: '4px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    background: '#94A4D6',
    width: 'calc(100% - 5px)',
    display: 'block',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}));

export default function DesktopTab(props) {
  const { tab, isActive, clearActive, tabClicked, index, activeTabIndex } = props;

  const [active, setActive] = useState(isActive);

  const classes = useStyles();

  useEffect(() => {
    if (index !== activeTabIndex) {
      setActive(false);
    }
  }, [activeTabIndex]);

  var tabClick = function () {
    props.tabClicked(index);

    setActive(true);
  }

  return (

    <div className={`${active ? classes.tabContainerActive : ''} ${classes.tabContainer}`} onClick={() => tabClick()}>
      <label className={`${active ? classes.tabTitleActive : ''} ${classes.tabTitle}`}>{tab.title}</label>
      <label className={`${active ? classes.tabParagraphActive : ''} ${classes.tabParagraph}`}>{tab.tabParagraph}</label>
      <div className={`${active ? classes.bottomStripeActive : ''} ${classes.bottomStripe}`}></div>
    </div>
  );
}
