import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";
import * as uuid from 'uuid'

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import DeserializationPlugin from "../../../../forumComponents/plugins/DeserializationPlugin";
import Calendar from "../../../../forumComponents/icons/Calendar.svg";
import Replies from "../../../../forumComponents/icons/Replies.svg";

function Placeholder() {
  return <div>What's on your mind...</div>;
}

const useStyles = makeStyles((theme) => ({
  swiperSlide: {
    overflow: "auto",
  },
  username: {
    color: '#6464FF',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    marginLeft: '6px',
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
  },
  usernameNoMarginLeft: {
    color: '#6464FF',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    fontWeight: '700',
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
  },
  bodyText: {
    fontFamily: 'Poppins',
    color: '#3C465F',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    whiteSpace: 'no-wrap',
    display: '-webkit-box',
    wordWrap: 'break-word',
    overflow: 'hidden',
    height: '120px',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    width: '100%'
  },
  secondRow: {
    marginTop: '16px'
  },
  cardList: {
    backgroundColor: "#ffffff",
    width: "100%",
  },
  card: {
    position: "relative",
    borderRadius: "8px",
    "&:hover $title": {
      color: "#8C8CA1",
    },
    "&:hover $svg": {
      stroke: "#FF0F5F",
    },
    [theme.breakpoints.down("md")]: {
      padding: "8px !important"
    },
    [theme.breakpoints.up("md")]: {
      padding: "12px !important"

    },
    [theme.breakpoints.up("lg")]: {
      padding: "15px !important"
    },
  },
  innerCardCoursePage: {
    height: "100%",
    borderRadius: "8px",
    color: "#FFFFFF !important",
    position: "relative",
  },
  swiperCard: {
    position: "relative",
    borderRadius: "24px",
    maxWidth: "fit-content",
    overflow: "hidden",
    border: '1px solid #D4D4D4',
    "&:hover $title": {
      color: "#8C8CA1",
    },
    "&:hover $svg": {
      stroke: "#FF0F5F",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px !important",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "24px !important",
      flexGrow: "1 !important",
      maxWidth: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "24px !important"
    },
  },
  swiperCard4x4: {
    height: "100%",
    position: "relative",
    borderRadius: "24px",
    maxWidth: "fit-content",
    overflow: "hidden",
    border: '1px solid #D4D4D4',
    "&:hover $title": {
      color: "#8C8CA1",
    },
    "&:hover $svg": {
      stroke: "#FF0F5F",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px !important",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "16px !important",
      flexGrow: "1 !important",
      maxWidth: "100%",
    },
  },
  innerCard: {
    height: "100%",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    position: "relative",
    filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
    "&:hover": {
      boxShadow: "0px 10px 30px 1.6px #4646462b",
      filter: "none",
    },
  },
  innerCardSwiper: {
    height: "100%",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    position: "relative",
    filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
    "&:hover": {
      boxShadow: "0px 6px 12px 1.6px #4646462b",
      filter: "none",
    },
  },
  innerCardSwiperCourse: {
    height: "100%",
    borderRadius: "8px",
    position: "relative",
    filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
    color: "#ffffff !important",
  },
  avatar: {
    height: "32px",
    width: "32px",
    borderRadius: "50%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "18px",
    textAlign: "center",
  },
  daysAgo: {
    fontFamily: 'Poppins',
    color: '#6464FF',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    marginLeft: '.3em',
    display: 'block',
    verticalAlign: 'middle',
    [theme.breakpoints.between(400, 820)]:{
      fontSize: "12px",
    },
},
calendar: {
    display: 'block',
    verticalAlign: 'middle'
  },
  bottomRow: {
    marginTop: '16px',
    alignSelf: "flex-end",
  },
  calendarHolder3x3: {
    marginRight: "16px",
  },
  calendarHolder4x4: {
    marginRight: "12px",
    [theme.breakpoints.down("sm")]:{
      marginRight: "16px",
    },
  },
  usernameContainer: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
  }
}));

function ThreeByThreeCard(props) {
  const { username, bodyText, id, avatarText, avatarBackgroundColor, timestampISO, replies, layout } = props;

  const classes = useStyles();

  const theme = useTheme();

  const usernameCutOff = useMediaQuery(theme.breakpoints.between("660", "1166"))
  //useEffect(() => {
  //  //console.log('body text: ', bodyText);
  //}, []);

  const [loaded, setLoaded] = useState(false);
  const outputRef = useRef(null)

  useEffect(() => {
    setLoaded(true)
    return () => setLoaded(false)
  }, [])


  const getNumberOfDays = (num) => {
    let today = new Date();
    let todayISO = today.toISOString();

    const DAY_UNIT_IN_MILLISECONDS = 24 * 3600 * 1000;
    const diffInMilliseconds =
      new Date(todayISO).getTime() - new Date(num).getTime();
    const diffInDays = diffInMilliseconds / DAY_UNIT_IN_MILLISECONDS;

    return Math.round(diffInDays);
  };

  const daysAgo = getNumberOfDays(timestampISO);

  return (
    <Grid item className={layout === "3x3" ? classes.swiperCard : classes.swiperCard4x4} md={4} sm={12} xs={12} key={uuid.v4()}>
      <Grid container className={classes.innerCardSwiperCourse}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" alignItems="center">
            {usernameCutOff === true && layout === "3x3" ? null :
              <Grid item>
                <div
                  className={classes.avatar}
                  style={{ backgroundColor: avatarBackgroundColor }}
                >
                  <span>{avatarText}</span>
                </div>
              </Grid>
            }
            <Grid className={classes.usernameContainer} item>
              <label className={usernameCutOff && layout === "3x3" ? classes.usernameNoMarginLeft : classes.username}>@{username}</label>
            </Grid>
          </Grid>


        </Grid>
        <Grid item xs={12} className={classes.secondRow}>
          <LexicalComposer
            key={uuid.v4()}
            initialConfig={{
              readOnly: true,
              onError(error) {
                throw error;
              },
            }}
          >
            <DeserializationPlugin key={uuid.v4()} content={bodyText} element={id} outputRef={outputRef} isTeaser={false} isPopularConversation={true} />
            <div id={id} ref={outputRef} key={uuid.v4()} className={classes.bodyText}>
              <RichTextPlugin
                key={uuid.v4()}
                contentEditable={<ContentEditable />}
                placeholder={<Placeholder />}
              />
            </div>
          </LexicalComposer>
        </Grid>
        <Grid item xs={12} className={classes.bottomRow}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container alignItems="center" justifyContent="flex-start">
                {usernameCutOff === true && layout === "3x3" ? null :
                  <Grid item>
                    <img src={Calendar} className={classes.calendar} />
                  </Grid>
                }
                <Grid item>
                  <label className={classes.daysAgo}>
                    {daysAgo === 0
                      ? "today"
                      : daysAgo !== 1
                        ? `${daysAgo} days ago`
                        : `${daysAgo} day ago`}
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item >
              <Grid container alignItems="center" >
                {usernameCutOff === true && layout === "3x3" ? null :
                <Grid item>
                  <img src={Replies} className={classes.calendar} />
                </Grid>
                }
                <Grid item>
                  <label className={classes.daysAgo}>
                    {`${replies} replies`}
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}

export default ThreeByThreeCard;
