import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { setIsUserAuthenticated, setToken } from "../../redux/reducers/user";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation } from "@reach/router";
import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  FormControl,
  Hidden,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "../container.js";
import VisibilityOn from "./icons/VisibilityOn.svg";
import VisibilityOff from "./icons/VisibilityOff.svg";
import ErrorIcon from "./icons/error.svg";
import { getUserObject } from "../../lib/helpers";
import fullSizeForgotPassword from "./icons/reset-password.png";
import ErrorInfo from "./icons/visible-error-info.svg";
import Check from "./icons/check.svg";
import InfoIcon from "./icons/visible-info.svg";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      // border: "2px solid #FFD574",
      backgroundColor: "#F7F5E7",
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,

      background: "#F7F5E7",
      "& input": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#4A4A6A",
      },
      "& fieldset": {
        border: "none",
      },
      "&:active fieldset": {
        // background: "#FFFFFF",
      },
      "&:hover fieldset": {
        // background: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        // background: "#FFFFFF",
        boxShadow: "none",
      },
      "&.Mui-error fieldset": {
        color: "#C94277",
      },
    },
  },
})(TextField);

const ResetPassword = ({ }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    clearErrors,
    setValue,
  } = useForm();

  const useStyles = makeStyles((theme) => ({
    formContainer: {
      paddingTop: "57px",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.up("md")]: {
        // maxWidth: "430px",
        // marginRight: "auto",
        paddingBottom: "200px",
      },
      "& p, span": {
        color: "#3C465F !important",
      },
      "& h2, h3, h4, h1, h5, h6": {
        color: "#3C465F !important",
      },
    },
    headerTextHolder: {
      flexDirection: "column",
      marginBottom: "28px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    headerTextHolderEmailSent: {
      flexDirection: "column",
      textAlign: 'center',
      marginBottom: "28px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    label: {
      color: "#3C465F",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    containerForPassword: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "column",
    },
    inputField: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "8px",
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: "4px",
      },
      '& input': {
        color: '#3C465F',
        WebkitBoxShadow: '0 0 0 30px #f7f5e7 inset !important',
        backgroundColor: '#F7F5E7',
        WebkitTextFillColor: '#3C465F'
      }
    },
    textSpan: {
      marginTop: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#3C465F",
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    loginButton: {
      height: "56px",
      background: "#FF0F5F",
      border: '2px solid #FF0F5F',
      width: "100%",
      color: "#ffffff",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      marginTop: "24px",
      textTransform: "uppercase",
      cursor: "pointer",
      fontFamily: 'Poppins'
    },
    formHolder: {
      width: "100%",
    },
    title: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      [theme.breakpoints.up("md")]: {
        fontSize: "32px",
        lineHeight: "40px",
      },
    },
    passwordRecovery: {
      fontFamily: "Poppins",
      textDecoration: "none",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "uppercase",
      color: "#3C465F",
      cursor: "pointer",
    },
    passwordRecoveryContainer: {
      marginTop: "16px",
    },
    errorMessage: {
      color: "#C94277",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      alignSelf: "flex-end",
    },
    bodyContainer: {
      maxWidth: "1440px",
      display: "flex",
      justifyContent: "space-between",
    },
    imageNoPaddingBackground: {
      //here
      [theme.breakpoints.up("md")]: {
        minHeight: "500px",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${fullSizeForgotPassword})`,
        backgroundSize: 'contain'
      },
    },
    subFormContainer: {
      maxWidth: "100%",
      paddingLeft: "calc(16.66666666666667% + 15px)",
      paddingRight: "8.8888888888889%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
      },
    },
    errorInputField: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 0,

        background: "#F7F5E7",
        "& input": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#3C465F",
        },
        "& fieldset": {
          border: "none",
        },
        "&:active fieldset": {
          // background: "#FFFFFF",
        },
        "&:hover fieldset": {
          // background: "#FFFFFF",
        },
        "&.Mui-focused fieldset": {
          // background: "#FFFFFF",
          border: "2px solid #FFD574",
          boxShadow: "none",
        },
        "&.Mui-error fieldset": {
          color: "#C94277",
        },
      },
    },
    errorLabel: {
      color: "#FF0F5F !important",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "12px",
      display: "inline-block",
      verticalAlign: "middle",
      marginLeft: ".5em",
      maxWidth: "90%",
    },
    errorImage: {
      display: "inline",
      verticalAlign: "middle",
    },
    errorContainer: {
      background: "#ffffff",
      padding: ".3em .3em .3em 0px",
    },
    checkImageContainer: {
      textAlign: 'center',
      marginBottom: '2em'
    },
    containerForPassword: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "column",
    },
    fieldContainer: {
      marginBottom: "1.5em",
    },
    infoLabel: {
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "13px",
      color: "#3C465F",
      display: "inline-block",
      verticalAlign: "middle",
      marginLeft: ".5em",
      maxWidth: "90%",
    },
    infoImage: {
      display: "inline-block",
      verticalAlign: "middle",
    },
    infoContainer: {},
    containerForPassword: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "column",
    },
  }));

  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordsNotMatchError, setShowPasswordsNotMatchError] = useState(false);
  const [passwordResetToken, setPasswordResetToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);


  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const location = useLocation();

  useEffect(() => {
    (async () => {
      var emailAddress = queryString.parse(location.search).email;
      var passwordResetToken = queryString.parse(location.search).token;

      if (emailAddress) {
        setEmail(decodeURIComponent(emailAddress));
      }

      if (passwordResetToken) {
        setPasswordResetToken(decodeURIComponent(passwordResetToken));
      }

    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (shouldRedirectToLogin) {
        window.location.href = '/visible/login?passwordResetSuccessfully=true&email=' + encodeURIComponent(email)
      }
    })();
  }, [shouldRedirectToLogin]);

  var onSubmit = async function (data) {

    var checkNumber = false;
    var matches = data.Password.match(/\d+/g);

    if (matches != null) {
      checkNumber = true;
    }

    var checkUpperLowerLetter = false;
    var matchesUpperLowerLetter = data.Password.match(
      "[a-z].*[A-Z]|[A-Z].*[a-z]"
    );

    if (matchesUpperLowerLetter != null) {
      checkUpperLowerLetter = true;
    }

    var checkSpecial = false;
    var matchesSpecial = data.Password.match(/[^A-Za-z0-9]/g);

    if (matchesSpecial != null) {
      checkSpecial = true;
    }

    if (!checkNumber || !checkUpperLowerLetter || !checkSpecial) {
      setShowPasswordError(true);

      return false;
    } else {
      setShowPasswordError(false);
    }

    if (data.Password !== data.ConfirmPassword) {
      setShowPasswordsNotMatchError(true);
    } else {
      setShowPasswordsNotMatchError(false);
    }

    if (checkNumber
      && checkUpperLowerLetter
      && checkSpecial
      && data.Password === data.ConfirmPassword
      && email
      && passwordResetToken) {
      var result = await resetPassword(data.Password, passwordResetToken, email);

      if (result) {
        setShouldRedirectToLogin(true);
      }
    }
  };

  var resetPassword = async function (password, code, email) {
    var model = {
      Password: password,
      Code: code,
      Email: email
    }

    var result = await fetch(
      process.env.GATSBY_QUESTIONAPI + "/ResetPassword/Reset",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(model),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      });

    if (result.Success && result.Result) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Grid container item alignItems={"center"} justify={"space-between"}>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={11} md={10} className={classes.bodyContainer}>
        <Grid container xs={12} md={6} className={classes.formContainer}>

          <Grid className={classes.subFormContainer}>

            <Grid container item className={classes.headerTextHolder}>
              <Typography variant="h4" className={classes.title}>
                {'Create new password'}
              </Typography>
            </Grid>
            <form className={classes.formHolder}>
              <div>
                <Grid
                  className={`${classes.containerForPassword} ${classes.fieldContainer}`}
                >
                  <label className={classes.label}>NEW PASSWORD</label>
                  <Controller
                    name="Password"
                    control={control}
                    rules={{
                      required: true,
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters long",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        autoFocus
                        {...field}
                        disableAutoFocus={true}
                        // error={failedAuthentication}
                        variant="outlined"
                        //error={failedAuthentication}
                        // onClick={handlePasswordFieldClick}
                        InputProps={{
                          type: values.showPassword ? "text" : "password",
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              <IconButton
                                aria-label={"toggle password visibility"}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {false ? (
                                  <img src={ErrorIcon} />
                                ) : values.showPassword ? (
                                  <img src={VisibilityOn} />
                                ) : (
                                      <img src={VisibilityOff} />
                                    )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        className={
                          errors.Password?.type === "required" ||
                            errors.Password?.type === "minLength" ||
                            showPasswordError
                            || showPasswordsNotMatchError
                            ? classes.errorInputField
                            : classes.inputField
                        }
                        onChange={(e) => {
                          setShowPasswordError(false);
                          setShowPasswordsNotMatchError(false);

                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors.Password?.type === "required" && (
                    <div className={classes.errorContainer}>
                      {" "}
                      <img src={ErrorInfo} className={classes.errorImage} />
                      <label className={classes.errorLabel}>
                        {"Password is required"}
                      </label>
                    </div>
                  )}
                  {errors.Password?.type === "minLength" && (
                    <div className={classes.errorContainer}>
                      {" "}
                      <img src={ErrorInfo} className={classes.errorImage} />
                      <label className={classes.errorLabel}>
                        {"Password must be at least 6 characters long"}
                      </label>
                    </div>
                  )}
                  {showPasswordError && (
                    <div className={classes.errorContainer}>
                      {" "}
                      <img src={ErrorInfo} className={classes.errorImage} />
                      <label className={classes.errorLabel}>
                        The password should contain a number, a lower case, an
                        uppercase and a special character.
                      </label>
                    </div>
                  )}
                  {showPasswordsNotMatchError && (
                    <div className={classes.errorContainer}>
                      {" "}
                      <img src={ErrorInfo} className={classes.errorImage} />
                      <label className={classes.errorLabel}>
                        The password and password confirmation do not match
                      </label>
                    </div>
                  )}
                  <div className={classes.infoContainer}>
                    <img src={InfoIcon} className={classes.infoImage} />
                    <label className={classes.infoLabel}>
                      Make it secure! You will need this to access your account.
                    </label>
                  </div>
                </Grid>
                <Grid
                  className={`${classes.containerForPassword} ${classes.fieldContainer}`}
                >
                  <label className={classes.label}>CONFIRM PASSWORD</label>
                  <Controller
                    name="ConfirmPassword"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        autoFocus
                        {...field}
                        disableAutoFocus={true}
                        // error={failedAuthentication}
                        variant="outlined"
                        //error={failedAuthentication}
                        // onClick={handlePasswordFieldClick}
                        InputProps={{
                          type: values.showPassword ? "text" : "password",
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              <IconButton
                                aria-label={"toggle password visibility"}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {false ? (
                                  <img src={ErrorIcon} />
                                ) : values.showPassword ? (
                                  <img src={VisibilityOn} />
                                ) : (
                                      <img src={VisibilityOff} />
                                    )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        className={
                            showPasswordsNotMatchError
                            ? classes.errorInputField
                            : classes.inputField
                        }
                        onChange={(e) => {
                          setShowPasswordsNotMatchError(false);

                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  {showPasswordsNotMatchError && (
                    <div className={classes.errorContainer}>
                      {" "}
                      <img src={ErrorInfo} className={classes.errorImage} />
                      <label className={classes.errorLabel}>
                        The password and password confirmation do not match
                      </label>
                    </div>
                  )}
                </Grid>
                {/* For future use: */}
                {/*<Grid className={classes.passwordRecoveryContainer}>*/}
                {/*  <a className={classes.passwordRecovery} src="#">Recover Password</a>*/}
                {/*</Grid>*/}
                <button
                  className={classes.loginButton}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  RESET PASSWORD
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid
            container
            item
            xs={0}
            sm={0}
            md={6}
            className={classes.imageNoPaddingBackground}
          ></Grid>
        </Hidden>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
    </Grid>
  );
};

export default ResetPassword;
