import * as React from "react";
import { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import Container from "../../container";
import {
  Typography,
  TextField,
  Hidden,
  Button,
  withStyles,
  Checkbox,
  Icon,
  Slide,
} from "@material-ui/core";
import { createClient } from "../../../lib/helpers";
// @ts-ignore
import emailIcon from "../img/SubmitIcon.svg";
// @ts-ignore
import errorIcon from "../../widgets/emailSignupWidget/errorIcon.svg";
// @ts-ignore
import Checked from "../../icon/checked.svg";
// @ts-ignore
import NonChecked from "../../icon/non-checked.svg";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import ReusableCheckbox from "../../landingPageComponents/blocks/reusableCheckbox";
import { emailCaptureSubmissionLogging } from "../../../lib/helpers";
import landingPageTint from "../img/landingPageTint.svg"
import landingPageTintMobile from "../img/landingPageTintMobile.svg"

const checkBoxStyles = (theme) => ({
  root: {
    display: "inline-block",
    width: "47px",
    padding: "0 !important",
    verticalAlign: "top",
    "&$checked": {
      color: "#3D70B2",
    },
    "& .MuiIconButton-label": {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },

  checked: {},
});

const iconStyles = (theme) => ({
  root: {
    height: "auto",
    width: "auto",
  },
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
const CustomIcon = withStyles(iconStyles)(Icon);

const useStyles = makeStyles((theme) => ({
  blueBackground: {
    background: "#3C465F",
    boxShadow:
      "0px -10px 14px rgba(64, 64, 64, 0.01), 0px -8px 16px rgba(23, 15, 73, 0.03)",
    transform: "translateX(0)",
    transition: "transform 0.5s ease-in-out",
  },
  blueBackgroundNoTint: {
    background: "#3C465F",
    boxShadow:
      "0px -10px 14px rgba(64, 64, 64, 0.01), 0px -8px 16px rgba(23, 15, 73, 0.03)",
  },
  whiteBackground: {
    background: "#FFFFFF",
    boxShadow:
      "0px -10px 14px rgba(64, 64, 64, 0.01), 0px -8px 16px rgba(23, 15, 73, 0.03)",
    transform: "translateX(0)",
    transition: "transform 0.5s ease-in-out",
  },
  stickyGate: {
    position: "sticky",
    bottom: 0,
    animation: "$slideInFromBottom 500ms",
  },

  "@keyframes slideInFromBottom": {
    "0%": {
      transform: "translateY(100%)",
    },
    "100%": {
      transform: "translateX(0)",
    },
  },
  stickyGateFadeOut: {
    transform: "translateY(100%)",
    position: "sticky",
    bottom: 0,
    // position: "sticky",
    // bottom: 0,
    // animation: "$slideAway 500ms",
    // opacity: 0,
    // transition: "opacity 1s",
  },

  "@keyframes slideAway": {
    "0%": {
      transform: "translateY(-100%)",
      opacity: 1,
    },
    "100%": {
      transform: "translateX(0)",
      opacity: 0,
      zIndex: "-9999",
    },
  },
  gateContainer: {
    paddingTop: "48px",
    paddingBottom: "48px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "24px",
      paddingBottom: "24px",
    },
  },
  titleAndParaHolder: {
    textAlign: "center",
    marginBottom: "1.5rem",
    flexDirection: "column",
    "& p, h1, h2, h3, h4, h5, label, span, li, ul": {
      color: "#FFF !important",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      textAlign: "start",
    },
  },
  titleAndParaHolderWhite: {
    textAlign: "center",
    marginBottom: "1.5rem",
    "& p, h1, h2, h3, h4, h5, label, span, li, ul": {
      color: "#2F2F4E !important",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      textAlign: "start",
    },
  },
  title: {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "47px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      lineHeight: "40px",
      letterSpacing: "-0.01em",
    },
  },

  titleWhite: {
    color: "#2F2F4E !important",
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "47px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      lineHeight: "40px",
      letterSpacing: "-0.01em",
    },
  },
  para: {
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  paraWhite: {
    color: "#2F2F4E !important",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  signUpImageContainer: {
    [theme.breakpoints.up("md")]: {
      padding: "29px 24px 29px 24px",
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  signUpImage: {
    height: "100%",
    width: "100%",
    borderRadius: "8px 8px 0 0",
    objectFit: "cover",
    [theme.breakpoints.up("md")]: {
      borderRadius: "8px",
    },
  },
  textButtonInputContainer: {
    padding: "1rem",
    color: "#ffffff",
    [theme.breakpoints.up("md")]: {
      padding: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "1.5rem 7px 1.5rem 1.5rem",
    },
  },
  inputField: {
    width: "100%",
    height: "52px",
    background: "#FFFFFF",
    boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
    borderRadius: "8px",
    "& > div": {
      fontSize: "14px",
      height: "100%",
      "&::before": {
        borderBottom: "none !important",
      },
    },
    "& input": {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  inputFieldWhite: {
    width: "100%",
    height: "52px",
    background: "#F5F7FA",
    borderRadius: "8px",
    border: "0.5px solid #DBE3EF",
    boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
    "& > div": {
      fontSize: "14px",
      height: "100%",
      "&::before": {
        borderBottom: "none !important",
      },
    },
    "& input": {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  buttonContainer: {
    marginTop: "1rem",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      paddingLeft: 8,
    },
  },
  buttonContainerError: {
    // marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      // marginTop: "1rem",
      paddingTop: "0",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      paddingLeft: 8,
    },
  },
  submitButton: {
    background: "#FF0F5F",
    color: "white",
    textTransform: "uppercase",
    width: "100%",
    height: "52px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
    "& .MuiButton-label": {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.04em",
    },
    "&:hover": {
      background: "#FF0F5F",
      color: "white",
    },
    "&:focus": {
      background: "#FF0F5F",
      color: "white",
    },
    "&:active": {
      background: "#FF0F5F",
      color: "white",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "1.5rem",
    },
  },
  submitIcon: {
    paddingLeft: "9px",
  },
  inputError: {
    boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
    border: "#FF0F5F 1px solid",
    borderRadius: "8px",
    color: "white",
  },
  subscribedContainer: {
    padding: "1rem",
  },
  paragraphText: {
    color: "white",
  },
  errorText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    display: "inline-flex",
    width: "100%",
    color: "white",
    alignItems: "center",
    paddingTop: "8px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8px",
    },
  },
  errorTextWhite: {
    bottom: "16px",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    paddingTop: "8px",
    width: "100%",
    color: "white",
    display: "inline-flex",
    color: "#3C465F",
    alignItems: "center",
    paddingTop: "8px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8px",
    },
  },
  errorIcon: {
    height: "16px",
    width: "16px",
    marginRight: "8px",
  },
  bodyText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "white",
  },
  holderForParaOnly: {
    paddingTop: "4px",
  },
  checkboxSpan: {
    display: "inline-block",
    width: "calc(100% - 47px)",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#FFFFFF",
  },
  checkboxSpanWhite: {
    display: "inline-block",
    width: "calc(100% - 47px)",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#3C455C",
  },
  marketingConsentContainer: {
    marginTop: "1em",
  },
  titleLabel: {
    color: "white !important",
  },
  checkboxContainer: {
    display: "flex",
  },
  // tintContainer:{
  //     height: "248px",
  //     bottom: 0,
  //     // backgroundImage: `url(${landingPageTint})`,
  //     width: "100%",
  //     backgroundRepeat: "no-repeat",
  //     backgroundSize: "cover",
  //     position: "absolute",
  // }
  tintImg:{
    position: "absolute",
    width: "100%",
  },
  imgContainer: {
    position: "relative",
  },
  alreadySignedInTextBlue: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    paddingTop: "24px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]:{
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  alreadySignedInTextWhite: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    paddingTop: "24px",
    [theme.breakpoints.down("md")]:{
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  linkTextBlueContainer: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textDecoration: "none",
    color: "#57C7FF",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "14px",
      lineHeight: "20px",
    }
  },
  linkTextWhiteContainer: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textDecoration: "none",
    color: "#0070EA",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "14px",
      lineHeight: "20px",
    }
  },
}))

// Function to check whether ref is in user's viewport and therefore gate should appear
export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [toggleFadeOut, setToggleFadeout] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      //console.log("entry", entry);
      setIntersecting(entry.isIntersecting);
    });
    const siblingObserver = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio < 0.3) {
        //console.log("sibling entry", entry);
        // setIntersecting(false)
        setToggleFadeout(entry.isIntersecting);
      }
    });
    observer.observe(ref.current);
    siblingObserver.observe(ref.current.previousElementSibling);
    //console.log("ref current", ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return [isIntersecting, toggleFadeOut];
}

const OriginalGatedLanding = ({
  title,
  paragraphText,
  brazeCustomAttributes,
  subscribedCallback,
  buttonText,
  placeholder,
  gateStyle,
  tintPage,
}) => {
  const [state, setState] = useState({
    username: "",
    errors: {},
    formIsValid: false,
    isSubmitted: false,
    isToggleOn: true,
    // campaignId: brazeSubscriptionGroupId,
    marketingConsent: false,
  });

  const holderEl = useRef(null);

  const gateRef = useRef();
  const [isIntersecting, toggleFadeout] = useOnScreen(gateRef);
  const [logGA, setLogGA] = useState(false);

  const [y, setY] = useState(typeof window !== "undefined" && window.scrollY);

  const [gateHeight, setGateHeight] = useState(0);
  const [scrollUp, setScrollUp] = useState(false);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const gateBlock = useRef(null)
  const classes = useStyles();

  const initialiseThankYou = async () => {
    const result = await getSignupThankYouText();
    setState((state) => ({ ...state, thankYouText: result[0].value }));
  };

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Get height of container and use it to set the offSetTop for the blur
  useLayoutEffect(() => {
    // Function to get and set the element's height
    const updateElementHeight = () => {
      if (gateBlock.current) {
        const height = gateBlock.current.getBoundingClientRect().height;
        setGateHeight(height);
      }
    };

    // Call the function once when the component mounts
    updateElementHeight();

    // Attach an event listener to window resize to update height on resize
    window.addEventListener('resize', updateElementHeight);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateElementHeight);
    };
  }, []);


  // Get height of container and use it to set the offSetTop for the blur when an error message appears
  useLayoutEffect(() => {
    // Function to get and set the element's height
    const updateElementHeight = () => {
      if (gateBlock.current) {
        const height = gateBlock.current.getBoundingClientRect().height;
        setGateHeight(height);
      }
    };

    // Call the function once when the component mounts
    updateElementHeight();

    // Attach an event listener to window resize to update height on resize
    window.addEventListener('resize', updateElementHeight);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateElementHeight);
    };
  }, [state.errors]);
  useEffect(() => {
    initialiseThankYou();
  }, []);

  useEffect(() => {
    (async () => {
      emailCaptureSubmissionLogging();
    })();
  }, [logGA]);

  const handleUsername = (event) => {
    let errors = {};
    let formIsValid = true;
    let field = event.target.value;

    setState((state) => ({
      ...state,
      username: event.target.value,
      errors: errors,
      formIsValid: formIsValid,
    }));
  };

  const submitted = (event) => {
    let formIsValid = state.formIsValid;
    let userName = state.username;
    let errors = {};

    const emailRegex = /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (typeof state.formIsValid == "undefined") {
      formIsValid = false;
      errors["empty"] = "Field is empty";
      setState((state) => ({ ...state, errors: errors }));
    }

    if (typeof state.formIsValid !== "undefined") {
      let lastAtPos = userName.lastIndexOf("@");
      let lastDotPos = userName.lastIndexOf(".");

      if (!userName.match(emailRegex)) {
        formIsValid = false;
        errors["email"] = userName.length <= 0 ? "Email is required" : "Please enter a valid email address";
        setState((state) => ({ ...state, errors: errors }));
      }
    }

    event.preventDefault();

    if (formIsValid) {
      //signupToBlog(state.username);

      //window.signup(
      //  state.username,
      //  state.campaignId,
      //  state.marketingConsent,
      //  brazeCustomAttributes
      //);

      //subscribedCallback();

      setLogGA(true);
      if (
        brazeCustomAttributes &&
        brazeCustomAttributes[0]?.customAttributeKey
      ) {
        localStorage.setItem(
          "custom_attr",
          btoa(
            brazeCustomAttributes[0].customAttributeKey +
              "|" +
              brazeCustomAttributes[0].customAttributeValue
          )
        );
      }
      const hash = window.location.hash;
      localStorage.setItem("hash", hash);
      localStorage.setItem("genMkt", state.marketingConsent);
      window.location.href =
        "/login?returnUrl=" +
        encodeURIComponent(`${window.location.pathname}${hash ? hash : ""}`) +
        "&usr=" +
        btoa(state.username);

      return null;
      //setState((state) => ({
      //  ...state,
      //  isToggleOn: !state.isToggleOn,
      //  isSubmitted: true,
      //}));
    } else {
      holderEl.current.className =
        holderEl.current.className + ` ${classes.inputError}`;
      //console.log("formIsValid", formIsValid);
    }
  };

  const getSignupThankYouText = async function () {
    var params = {};

    var query =
      "*[_type == 'dictionary'][key match ['signup_widget_thank_you_text']]";

    return createClient()
      .fetch(query, params)
      .then((response) => response)
      .then((responseJson) => {
        return responseJson;
      });
  };

  const handleChange = (event) => {
    setState({ ...state, marketingConsent: event.target.checked });
  };

  // callback to check if user is scrolling up and if so, set state to allow gate to slide away
  const handleNavigation = useCallback(
    (e) => {
      if (typeof window !== "undefined") {
        const window = e.currentTarget;
        if (y > window.scrollY) {
          setScrollUp(true);
        } else if (y < window.scrollY) {
          setScrollUp(false);
        }
        setY(window.scrollY);
      }
    },
    [y]
  );

  function getContainerStyle(gateStyle) {

    const ContainerStyle = {
      "Blue background  - Text center - Slide up from bottom of page": "blue",
      "White background  - Text center - Slide up from bottom of page": "white",
      "Blue background  - Text center - Faded": "blue",
      "White background  - Text center - Faded": "white",
      default: "faded"
    }

   return ContainerStyle[gateStyle] || ContainerStyle.default
  }

  const containerStyle = getContainerStyle(gateStyle)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setY(window.scrollY);
      window.addEventListener("scroll", handleNavigation);

      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }
  }, [handleNavigation]);
  return (
    <>
      <div ref={gateRef}></div>
      <div
        ref={gateBlock}
        className={`${
          containerStyle ===
          "blue"
            ? (tintPage ? classes.blueBackgroundNoTint : classes.blueBackground)
            : classes.whiteBackground
        } ${!tintPage ?
          isIntersecting || isKeyboardOpen
            ? classes.stickyGate
            : toggleFadeout && scrollUp && !isKeyboardOpen
              ? classes.stickyGateFadeOut
              : null
          : null}`}
      >
        <Container>
          <Grid container>
            <Grid md={2}></Grid>
            <Grid md={8} container className={classes.gateContainer}>
              <Grid
                justifyContent={"center"}
                item
                container
                className={
                  containerStyle ===
                  "blue"
                    ? classes.titleAndParaHolder
                    : classes.titleAndParaHolderWhite
                }
              >
                <Typography
                  className={
                    containerStyle ===
                    "blue"
                      ? classes.title
                      : classes.titleWhite
                  }
                  variant={"h2"}
                >
                  {title}
                </Typography>
                <Typography
                  className={
                    containerStyle === "blue"
                      ? classes.para
                      : classes.paraWhite
                  }
                  variant={"body1"}
                >
                  {paragraphText && paragraphText}
                </Typography>
              </Grid>

              <Grid container>
                <Grid md={1}></Grid>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                  {state.isToggleOn ? (
                    <TextField
                      ref={holderEl}
                      id="emailField"
                      className={
                        containerStyle === "blue"
                          ? classes.inputField
                          : classes.inputFieldWhite
                      }
                      placeholder={placeholder}
                      value={state.username}
                      onChange={handleUsername}
                    />
                  ) : null}
                </Grid>
                <Hidden smDown>
                  {state.isToggleOn && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      className={
                        state.errors && Object.keys(state.errors).length > 0
                          ? classes.buttonContainerError
                          : classes.buttonContainer
                      }
                    >
                      <Button
                        className={classes.submitButton}
                        onClick={submitted}
                      >
                        {buttonText}
                        <img src={emailIcon} className={classes.submitIcon}/>
                      </Button>
                    </Grid>
                  )}
                </Hidden>
                <Hidden mdUp>
                  {state.errors && Object.keys(state.errors).length > 0 ? (
                    <span
                      className={
                        containerStyle === "blue"
                          ? classes.errorText
                          : classes.errorTextWhite
                      }
                    >
                      <img
                        className={classes.errorIcon}
                        src={errorIcon}
                        alt="error"
                      />
                      {state.errors["email"]}
                    </span>
                  ) : null}
                </Hidden>
                <Hidden mdUp>
                  {state.isToggleOn && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={5}
                      className={
                        state.errors && Object.keys(state.errors).length > 0
                          ? classes.buttonContainerError
                          : classes.buttonContainer
                      }
                    >
                      <Button
                        className={classes.submitButton}
                        onClick={submitted}
                      >
                        {buttonText}
                        <img src={emailIcon} className={classes.submitIcon}/>
                      </Button>
                    </Grid>
                  )}
                </Hidden>
                <Grid md={1}></Grid>
              </Grid>
              <Grid container>
                <Grid md={1}></Grid>
                <Hidden smDown>
                  {state.errors && Object.keys(state.errors).length > 0 ? (
                    <>
                      <Grid md={10}>
                        <span
                          className={
                            containerStyle === "blue"
                              ? classes.errorText
                              : classes.errorTextWhite
                          }
                        >
                          <img
                            className={classes.errorIcon}
                            src={errorIcon}
                            alt="error"
                          />
                          {state.errors["email"]}
                        </span>
                      </Grid>
                    </>
                  ) : null}
                </Hidden>
                <Grid md={1}></Grid>
              </Grid>
              <Grid container>
                <Grid md={1}></Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  className={classes.marketingConsentContainer}
                >
                  {state.isToggleOn ? (
                    <label className={classes.checkboxContainer}>
                      <ReusableCheckbox
                        checked={state.marketingConsent}
                        handleChange={handleChange}
                      />
                      {/*<CustomCheckbox*/}
                      {/*  icon={*/}
                      {/*    <CustomIcon className={""}>*/}
                      {/*      <img src={NonChecked}  />*/}
                      {/*    </CustomIcon>*/}
                      {/*  }*/}
                      {/*  checkedIcon={*/}
                      {/*    <CustomIcon className={""}>*/}
                      {/*      <img src={Checked}  />*/}
                      {/*    </CustomIcon>*/}
                      {/*  }*/}
                      {/*  checked={state.marketingConsent}*/}
                      {/*  onChange={handleChange}*/}
                      {/*  name="marketingConsent"*/}
                      {/*/>*/}
                      <span
                        className={
                          containerStyle === "blue"
                            ? classes.checkboxSpan
                            : classes.checkboxSpanWhite
                        }
                      >
                        I would like to hear from you about products, events,
                        general marketing and other things from Boring Money –
                        including personalised content and ads - but please try
                        not to make them as boring as everything else in
                        finance!
                      </span>
                    </label>
                  ) : null}
                  <div>
                    <Typography variant={"body1"} className={containerStyle === "blue" ? classes.alreadySignedInTextBlue : classes.alreadySignedInTextWhite}>
                      Already have an account? <a className={containerStyle
                     === "blue" ? classes.linkTextBlueContainer : classes.linkTextWhiteContainer} href={"/login"}>Login</a>
                    </Typography>
                  </div>
                </Grid>
                <Grid md={1}></Grid>
              </Grid>
            </Grid>
            <Grid md={2}></Grid>
          </Grid>
        </Container>
      </div>
      {tintPage && <div className={classes.imgContainer}><img className={classes.tintImg} style={{bottom: gateHeight}} src={matchesMobile
        ? landingPageTintMobile : landingPageTint}/> </div>}
    </>
  );
};

export default OriginalGatedLanding;
