import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Check from "./icons/check.svg";
import {
  Grid,
  Hidden
} from "@material-ui/core";
import Container from "../container";
import queryString from "query-string";
import { useLocation } from "@reach/router";
//import fullSize from "./icons/verify-email.png";
import fullSize from "./icons/log-in.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#3C465F',
    paddingBottom: '.8em',
    display: 'block'
  },
  firstParagraph: {
    color: '#3C465F',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'block',
    paddingBottom: '.6em'
  },
  body: {
    color: '#3C465F',
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px'
  },
  loginButton: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#ffffff',
    backgroundColor: '#FF0F5F',
    border: '2px solid #FF0F5F',
    padding: '16px',
    display: 'block',
    textDecoration: 'none',
    width: '430px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2.2em',
    textAlign: 'center',
    [theme.breakpoints.down(1600)]: {
      width: "370px",
    },
    [theme.breakpoints.down(1450)]: {
      width: "370px",
    },
    [theme.breakpoints.down(500)]: {
      width: 'initial',
      maxWidth: "100%",
    },
  },
  verifyEmailContainer: {
    textAlign: 'center',
    maxWidth: "100%",
    paddingLeft: "calc(16.66666666666667% + 15px)",
    paddingRight: "8.8888888888889%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
    },
  },
  imageNoPaddingBackground: {
    //here
    [theme.breakpoints.up("md")]: {
      minHeight: "500px",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${fullSize})`,
      backgroundSize: 'contain'
    },
  },
  bodyContainer: {
    maxWidth: "1440px",
    display: "flex",
  },
  formContainer: {
    paddingTop: "2.5em",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      // maxWidth: "430px",
      // marginRight: "auto",
      paddingBottom: "200px",
    },
    "& p, span": {
      color: "#3C465F !important",
    },
    "& h2, h3, h4, h1, h5, h6": {
      color: "#3C465F !important",
    },
  },
  checkImage: {
    paddingBottom: '1.5em',
  },
  minFullWidth: {
    [theme.breakpoints.down(550)]: {
      minWidth: '100%'
    },   
  }
}));

function VerifyEmail(props) {
  const { } = props;

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      
    })();
  }, []);


  return (
    <Grid container item alignItems={"center"} justify={"space-between"}>
      <Grid item xs={0} md={1}></Grid>
      {true &&
        <Grid item xs={11} md={10} className={classes.bodyContainer}>
          <Grid container >

            <Grid item md={6} xs={12} className={classes.formContainer}>
              <Grid container alignItems="center" justify="center" className={classes.verifyEmailContainer}>
                <Grid item xs={12}>
                  <img src={Check} className={classes.checkImage} />
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.title}>Check your email</span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.firstParagraph}>Thank you for signing up to Visible!</span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.body}>We've sent you an email verification link. Simply click on the link and you'll be ready to go. If you don't see the email from us, remember to check your junk!</span>
                </Grid>
              <Grid item xs={12} className={classes.minFullWidth}>
                  <a href="/visible/login" className={classes.loginButton}>Login</a>
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid
                container
                item
                xs={0}
                md={6}
                className={classes.imageNoPaddingBackground}
              ></Grid>
            </Hidden>
          </Grid>
        </Grid>
      }
      <Grid item xs={0} md={1}></Grid>
    </Grid>
  );
}

export default VerifyEmail;
