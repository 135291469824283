import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  CheckBox,
  MenuItem,
  FormControlLabel,
  Button,
  Select,
  Checkbox,
  Collapse,
  InputAdornment,
  Input,
  useMediaQuery,
  ClickAwayListener,
  CircularProgress
} from "@material-ui/core";
import ChevronDown from "../../icon/chevron-down.svg";
import MobileInfo from "../../icon/mobileInfo.svg";
import ContainerForSVG from "../../containerForSVG";
import ArrowDown from "../../icon/arrowDown.svg";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { getSortOptionLabelById } from "../../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  totalRows: {
    color: "#3C455C",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: "#262641",
      paddingTop: "16px",
      paddingBottom: "4px",
      width: "100%",
    },
  },
  sortByDropdown: {
    color: "#2F2F4E",
    fontSize: "16px",
    lineHeight: "24px",
    width: "233px",
    border: "1px solid #DBE3EF",
    padding: "12px 16px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundImage: `url(${ChevronDown})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 16px) center",
    appearance: "none",
    cursor: "pointer",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  selectContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      order: -1,
    },
  },
  mobileInfoButton: {
    paddingRight: "8px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  advancedFiltersContainer: {
    height: "48px",
    width: "233px",
    padding: "12px 16px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundColor: "#ffffff",
    marginRight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
  advancedFiltersContainerLoading: {
    height: "48px",
    width: "233px",
    padding: "12px 16px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundColor: "#ffffff",
    marginRight: "16px",
    cursor: "pointer",
    fontWeight: "400",
    "& div": {
      [theme.breakpoints.up("md")]: {
        fontWeight: "400",
        paddingBottom: "12px"
      }
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px"
    },
    [theme.breakpoints.down("md")]: {
      fontWeight: "600",
      letterSpacing: "-0.4px",
      lineHeight: "28px",
      "& p": {
        margin: "0"
      }
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
  advancedFiltersContainerChecked: {
    height: "48px",
    width: "233px",
    padding: "12px 16px",
    borderRadius: "8px",
    borderTop: "1px solid #DBE3EF",
    borderLeft: "1px solid #DBE3EF",
    borderRight: "1px solid #DBE3EF",
    backgroundColor: "#ffffff",
    marginRight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  advancedFiltersContainerMobile: {
    color: "#2F2F4E",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "20px",
      paddingBottom: "16px"
    },
  },
  containerForSVGLoading: {
    minHeight: "24px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      minHeight: "28px"
    }
  },
  selectFiltersContainer: {
    width: "auto",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse"
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  blockContainer: {
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse"
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  arrowDownButton: {
    /*paddingLeft: "50px",*/
  },
  collapseContainer: {
    position: "Absolute",
    zIndex: "99",
    backgroundColor: "#ffffff",
    width: "600px",
    marginLeft: "-118px",
    marginTop: "16px",
    // boxShadow: " 0px 1px 2px 0px #0000000F",
    borderRadius: "8px",
    boxShadow: '0px 2px 2px rgba(0, 32, 51, 0.02), 0px 6px 4px rgba(0, 32, 51, 0.03), 0px 8px 8px rgba(0, 32, 51, 0.04), 0px 16px 16px rgba(0, 32, 51, 0.04), 0px 4px 24px rgba(0, 32, 51, 0.04), 0px 30px 50px rgba(0, 32, 51, 0.06)',

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      borderRadius: "0 0 8px 8px",
      position: "static",
    },
  },
  collapseInnerContainer: {
    padding: "16px",
  },
  collapseInnerContainer2: {
    padding: "16px",
    paddingLeft: "52px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px",

    },
  },
  collapseTitle: {
    fontWeight: 600,
  },
  checkbox: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkedbox: {
    backgroundColor: "transparent",
  },
  explanatoryText: {
    paddingLeft: "33px",
    fontSize: '14px',
    marginTop: '-0.65em',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "41px",
      marginTop: 'initial'
    }
  },
  collapseInnerBottom: {
    backgroundColor: "#F9FAFB",
    boxShadow: "0px 1px 0px 0px #E6E8F0 inset",
    borderRadius: "0 0 8px 8px",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
      boxShadow: "none"
    },
  },
  visitSiteButton: {
    backgroundColor: "#FF0F5F",
    color: "black",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    padding: "9px 17px",
    "&:hover": {
      background: "#FFF",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#FF0F5F",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonTextVisitSite: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "24px",
  },
  containerForSVG: { width: "100%" },
  advancedFilterstext: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
      letterSpacing: "-0.02em",
    },
  },
  leftPadding: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px"
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Poppins',
      fontWeight: '500',
      lineHeight: '18px',
      fontSize: '16px',
      letterSpacing: 0
    }
  },
  filtersContainer: {
  },
  loadingSpinner:{
    [theme.breakpoints.down("sm")]: {
      height: "16px !important",
      width: "16px !important"
    },
  }
}));

const defaultValues = {
  ROBO: false,
  DIY: false,
  DIGITAL: false,
  GREEN: false,
  BIGBRAND: false,
};

export default function BestBuySortAndProviders(props) {
  const classes = useStyles();
  const { totalRows, showAdvancedFilters, initialFilters, initialSortValue, defaultSortOption, sortOptions, loading } = props;

  //console.log('sort options: ', sortOptions);
  const location = useLocation();

  //   const [advancedFilters, setAdvancedFilters] = useState("sortBy");
  var sortByChange = function (event) {
    props.onSetSort(event.target.value);
  };
  //   var enableAdvancedFilters = function (event) {
  //     setAdvancedFilters("");
  //   };
  const [checked, setChecked] = React.useState(false);
  const [shouldSetInitialFilters, setShouldSetInitialFilters] = React.useState(true);
  const [parsedQueryString, setParsedQueryString] = React.useState(queryString.parse(location.search));
  const [initialSort, setInitialSort] = React.useState((defaultSortOption != null && defaultSortOption != undefined && defaultSortOption != '') ? defaultSortOption : 'OurRatingHL');
  const [initialRobo, setInitialRobo] = React.useState(false);
  const [initialDigital, setInitialDigital] = React.useState(false);
  const [initialDiy, setInitialDiy] = React.useState(false);
  const [initialGreen, setInitialGreen] = React.useState(false);
  const [initialBigBrand, setInitialBigBrand] = React.useState(false);
  const [sortOptionsKeyValuePairs, setSortOptionsKeyValuePairs] = React.useState([]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const { handleSubmit, reset, watch, control, register, setValue } = useForm({
    defaultValues: {
      ROBO: initialRobo,
      DIY: initialDiy,
      GREEN: initialGreen,
      DIGITAL: initialDigital,
      BIGBRAND: initialBigBrand
    },
  });
  useEffect(() => {
    (async () => {
      var sortByQueryStringValue = parsedQueryString.sortBy;
     // console.log('sb sortByQueryStringValue: ', sortByQueryStringValue);

      if (sortByQueryStringValue != null && sortByQueryStringValue != undefined && sortByQueryStringValue != '') {
        setInitialSort(sortByQueryStringValue);
      }
      var roboQueryStringValue = parsedQueryString.robo;
      //console.log('sb roboQueryStringValue: ', roboQueryStringValue);

      if (roboQueryStringValue != null && roboQueryStringValue != undefined && roboQueryStringValue != '') {
        setInitialRobo(roboQueryStringValue.toLowerCase() === 'true');
        setValue('ROBO', roboQueryStringValue.toLowerCase() === 'true');
      }

      var diyQueryStringValue = parsedQueryString.diy;
     // console.log('sb diyQueryStringValue: ', diyQueryStringValue);

      if (diyQueryStringValue != null && diyQueryStringValue != undefined && diyQueryStringValue != '') {
        setInitialDiy(diyQueryStringValue.toLowerCase() === 'true');
        setValue('DIY', diyQueryStringValue.toLowerCase() === 'true');
      }

      var digitalQueryStringValue = parsedQueryString.digital;
     // console.log('sb digitalQueryStringValue: ', digitalQueryStringValue);

      if (digitalQueryStringValue != null && digitalQueryStringValue != undefined && digitalQueryStringValue != '') {
        setInitialDigital(digitalQueryStringValue.toLowerCase() === 'true');
        setValue('DIGITAL', digitalQueryStringValue.toLowerCase() === 'true');

      }

      var greenQueryStringValue = parsedQueryString.green;
     // console.log('sb greenQueryStringValue: ', greenQueryStringValue);

      if (greenQueryStringValue != null && greenQueryStringValue != undefined && greenQueryStringValue != '') {
        setInitialGreen(greenQueryStringValue.toLowerCase() === 'true');
        setValue('GREEN', greenQueryStringValue.toLowerCase() === 'true');

      }

      var bigBrandQueryStringValue = parsedQueryString.bigBrand;
     // console.log('sb bigBrandQueryStringValue: ', bigBrandQueryStringValue);

      if (bigBrandQueryStringValue != null && bigBrandQueryStringValue != undefined && bigBrandQueryStringValue != '') {
        setInitialBigBrand(bigBrandQueryStringValue.toLowerCase() === 'true');
        setValue('BIGBRAND', bigBrandQueryStringValue.toLowerCase() === 'true');
      }
    })();
  }, [parsedQueryString]);
  function onSubmit(data) {
    props.onSetFilters(data);
    setChecked((prev) => !prev);
  }
  const handleClickAway = () => {
    setChecked(false);
  };
  const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    var sortOptionsKeyValuePairs = [];

    sortOptions.forEach(function (id) {
      var label = getSortOptionLabelById(id);
      var sortOptionKeyValuePair = {
        Id: id,
        Label: label
      };

      sortOptionsKeyValuePairs.push(sortOptionKeyValuePair);
    });

    setSortOptionsKeyValuePairs(sortOptionsKeyValuePairs);
  }, []);

  return (
    <Grid container justifyContent="space-between" alignItems="flex-start" className={classes.blockContainer}>
      <Grid>
        <Typography variant="h4" className={classes.totalRows}>
          <ContainerForSVG>{loading ? <><CircularProgress size={20} className={classes.loadingSpinner}/> &nbsp; </>  : `${totalRows}` } Providers</ContainerForSVG>
        </Typography></Grid>
      <Grid container className={classes.selectFiltersContainer} >
        {showAdvancedFilters == true &&
          <ClickAwayListener onClickAway={handleClickAway}>
            <Grid className={classes.advancedFiltersContainerMobile}>
              <Grid
                onClick={handleChange}
                className={loading ? classes.advancedFiltersContainerLoading : checked ? classes.advancedFiltersContainerChecked : classes.advancedFiltersContainer}
              >
                <Typography className={classes.advancedFilterstext}>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    className={loading ? classes.containerForSVGLoading : classes.containerForSVG}
                  >
                    Advanced Filters
                  <img
                      src={ArrowDown}
                      alt="ArrowDown"
                      className={classes.arrowDownButton}
                    />
                  </Grid>
                </Typography>
              </Grid>
              <Grid>
                <Collapse in={checked} className={classes.collapseContainer}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container className={classes.filtersContainer}>
                      <Grid xs={12} md={6} className={classes.collapseInnerContainer}>
                        <Typography className={classes.collapseTitle}>
                          How much help do you want?
                      </Typography>
                        <Controller
                          name="ROBO"
                          control={control}
                          defaultValue={initialRobo}

                          render={({ field }) => (
                            <FormControlLabel
                              className={classes.leftPadding}
                              control={
                                <Checkbox
                                  classes={{
                                    root: classes.checkbox,
                                    checked: classes.checkedbox,
                                  }}
                                  disableRipple
                                  onChange={(e) => { setInitialRobo(false); field.onChange(e.target.checked); }}
                                  checked={initialRobo || field.value}
                                  defaultChecked={initialRobo}
                                  id="roboChk"
                                />
                              }
                              label="Ready-made solution"

                            />
                          )}
                        />
                        <Typography className={classes.explanatoryText}>
                        Pick a portfolio made by experts
                      </Typography>
                        <Controller
                          name="DIY"
                          control={control}
                          defaultValue={initialDiy}

                          render={({ field }) => (
                            <FormControlLabel
                              className={classes.leftPadding}
                              control={
                                <Checkbox
                                  classes={{
                                    root: classes.checkbox,
                                    checked: classes.checkedbox,
                                  }}
                                  disableRipple
                                  onChange={(e) => { setInitialDiy(false); field.onChange(e.target.checked); }}
                                  checked={initialDiy || field.value}
                                  defaultChecked={initialDiy}
                                  id="diyChk"
                                />
                              }
                              label="Do It Yourself"

                            />
                          )}
                        />
                        <Typography className={classes.explanatoryText}>
                           Create your own portfolio from a wide range of investments
                      </Typography>
                      </Grid>

                      <Grid
                        xs={12} md={6}
                        container
                        className={classes.collapseInnerContainer2}
                      >
                        <Grid xs={12}>
                          <Typography className={classes.collapseTitle}>
                            Any special requests?
                        </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Controller
                            name="DIGITAL"
                            control={control}
                            defaultValue={initialDigital}

                            render={({ field }) => (
                              <FormControlLabel
                                className={classes.leftPadding}
                                control={
                                  <Checkbox
                                    classes={{
                                      root: classes.checkbox,
                                      checked: classes.checkedbox,
                                    }}
                                    disableRipple
                                    onChange={(e) => { setInitialDigital(false); field.onChange(e.target.checked); }
                                    }
                                    checked={initialDigital || field.value}
                                    defaultChecked={initialDigital}
                                    id="digitalChk"
                                  />
                                }
                                label="Best online experience"
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <Controller
                            name="GREEN"
                            control={control}
                            defaultValue={initialGreen}
                            render={({ field }) => (
                              <FormControlLabel
                                className={classes.leftPadding}
                                control={
                                  <Checkbox
                                    classes={{
                                      root: classes.checkbox,
                                      checked: classes.checkedbox,
                                    }}
                                    disableRipple
                                    onChange={(e) => { setInitialGreen(false); field.onChange(e.target.checked); }
                                    }
                                    checked={initialGreen || field.value}
                                    defaultChecked={initialGreen}
                                    id="greenChk"
                                  />
                                }
                                label="Green/Ethical"
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <Controller
                            name="BIGBRAND"
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel
                                className={classes.leftPadding}
                                control={
                                  <Checkbox
                                    classes={{
                                      root: classes.checkbox,
                                      checked: classes.checkedbox,
                                    }}
                                    disableRipple
                                    onChange={(e) => { setInitialGreen(false); field.onChange(e.target.checked); }
                                    }
                                    checked={initialBigBrand || field.value}
                                    defaultChecked={initialBigBrand}
                                    id="bigBrandChk"
                                  />
                                }
                                label="Big brand"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={classes.collapseInnerBottom}
                      justifyContent="flex-end"
                    >
                      <Button className={classes.visitSiteButton} type="submit" id="advSearch">
                        <Typography
                          className={classes.buttonTextVisitSite}
                          variant="button"
                        >
                          Search
                      </Typography>
                      </Button>
                    </Grid>
                  </form>
                </Collapse>
              </Grid>
            </Grid>
          </ClickAwayListener>
        }

        <Grid className={classes.selectContainer}>
          <select className={classes.sortByDropdown} onChange={sortByChange} defaultValue={initialSort} >
            <option value={"OurRatingHL"} selected={initialSort === 'OurRatingHL' || initialSort.toLowerCase() === 'ourratinghl'}>Sort By</option>
            {
              sortOptionsKeyValuePairs && sortOptionsKeyValuePairs.map((sortOption) => (
                < option value={sortOption.Id} selected={initialSort === sortOption.Id || initialSort.toLowerCase() === sortOption.Id.toLowerCase()}>{sortOption.Label}</option>
              ))
            }
          </select>
        </Grid>
      </Grid>
    </Grid >
  );
}
