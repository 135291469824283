import React, { useEffect, useState, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography, ClickAwayListener } from "@material-ui/core";
import InfoIcon from "../../icon/InfoIcon.svg";

const useStyles = makeStyles((theme) => ({
  ratingContainer: {
    width: "100%",
    lineHeight: "14px",
    background: "#DBE3EF",
    paddingRight: "16px",
    marginBottom: "16px",
  },
  ratingSectionTitle: {
    color: "#3C465F",
    fontWeight: "700",
    textTransform: "uppercase",
    fontSize: "14px",
    paddingBottom: "6px",
    lineHeight: "16px",
  },
  weSayResizer: {
    [theme.breakpoints.down("sm")]: {
      // paddingLeft: "8px",
      // paddingRight: "8px",
    },
    [theme.breakpoints.down(360)]: {
      // paddingLeft: "1px",
      // paddingRight: "1px",
    },
  },
  scale: {
    paddingLeft: "2px",
    paddingRight: "2px",
    // transform: "scale(0.75,0.75)",
    // width:"16px",
    // height: "16px"
  },
  ratingSectionTitlePaddedTop: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "16px",
    },
    paddingTop: "1.5em",
  },
  greyBubble: {
    background: "#ECF1F4",
    borderRadius: "8px",
    padding: "5px",
    textAlign: "center",
    color: "#3C465F",
    display: "inline-block",
    verticalAlign: "middle",
    border: "0.5px solid #DBE3EF",
    backgroundColor: "#FFFFFF",
  },
  greyBubbleInclusive: {
    background: "#ECF1F4",
    borderRadius: "8px",
    padding: "5px",
    textAlign: "center",
    color: "#3C465F",
    display: "inline-block",
    verticalAlign: "middle",
    border: "0.5px solid #DBE3EF",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    "& img": {
      paddingLeft: "6px",
      cursor: "pointer",
    },
    "@media (min-width: 1366px)": {
      margin: 0,
    },
  },
  greyBubbleXs: {
    background: "#ECF1F4",
    borderRadius: "8px",
    padding: "5px",
    textAlign: "center",
    color: "#3C465F",
    display: "inline-block",
    verticalAlign: "middle",
    border: "0.5px solid #DBE3EF",
    backgroundColor: "#FFFFFF",
    marginBlockStart: 0,
    marginBlockEnd: "1em",
    float: "right",
    display: "flex",
    alignItems: "center",
    "& img": {
      paddingLeft: "6px",
      cursor: "pointer",
    },
    "@media (min-width: 1366px)": {
      margin: 0,
    },
  },
  tooltipIcon: {
    paddingLeft: "8px",
  },
  costLabel: {
    color: "#3C465F",
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: "600",
    marginBlockStart: "0",
    marginBlockEnd: 0,
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
  },
  costLabelXs: {
    color: "#3C465F",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: "600",
    marginBlockStart: "0",
    marginBlockEnd: 0,
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    float: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  bubbleContainer: {
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    "@media (min-width: 1366px)": {
      paddingBottom: "6px",
    },
    "@media (min-width: 1440px)": {
      padding: "12px 0 12px 8px",
    },
  },
  bubbleContainerXs: {
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    marginTop: ".5em",
  },
  costContainer: {
    display: "inline-block",
    verticalAlign: "middle",
    paddingRight: "16px",
    "@media (min-width: 1366px)": {
      paddingRight: 0,
    },
  },
  costContainerXs: {
    verticalAlign: "middle",
  },
  costWrapper: {
    marginTop: "1em",
  },
  costLabelContainer: {
    display: "block",
    [theme.breakpoints.down(375)]: {
      display: "none",
    },
    paddingLeft: "16px",
  },
  costPlaceholder: {
    color: "#3C465F",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "700",
    letterSpacing: "-0.02em",
    display: "inline-block",
    verticalAlign: "middle",
    textTransform: "uppercase",
  },
  costLabelContainerXs: {
    display: "none",
    paddingLeft: "16px",
    [theme.breakpoints.down(375)]: {
      display: "block",
    },
  },
  costRightContainerXs: {
    display: "none",
    [theme.breakpoints.down(375)]: {
      display: "block",
    },
  },
  costRightContainer: {
    display: "block",
    [theme.breakpoints.down(375)]: {
      display: "none",
    },
  },
  noCostLabel: {
    float: "right",
    marginBlockStart: "0",
  },
  displayNone: {
    display: "none",
  },
  threeByThreeCostLabelContainerLgSm: {
    display: "block",
    [theme.breakpoints.down(1300)]: {
      display: "none",
    },
    [theme.breakpoints.down(960)]: {
      display: "block",
    },
    [theme.breakpoints.down(375)]: {
      display: "none",
    },
  },
  threeByThreeCostRightContainerLgSm: {
    display: "block",
    [theme.breakpoints.down(1300)]: {
      display: "none",
    },
    [theme.breakpoints.down(960)]: {
      display: "block",
    },
    [theme.breakpoints.down(375)]: {
      display: "none",
    },
  },
  threeByThreeCostLabelContainerMd: {
    display: "block",
    paddingLeft: "16px",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
    [theme.breakpoints.up(1300)]: {
      display: "none",
    },
  },
  threeByThreeCostRightContainerMd: {
    display: "block",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
    [theme.breakpoints.up(1300)]: {
      display: "none",
    },
  },
  tooltipHolder: {
    backgroundColor: "#FFFF !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginRight: "1rem",
    position: "absolute",
    borderRadius: "6px",
    marginLeft: "42px",
    top: "32%",
    padding: "12px",
    boxShadow:
      "0px 6px 10px rgba(101, 101, 101, 0.25), 0px 2px 4px rgba(64, 64, 64, 0.1)",
    "& p": {
      margin: 0,
    },
  "@media (min-width: 440px) and (max-width: 959px)": {
      top: "43%",
    },
    "@media (min-width: 1366px)": {
      top: "48%",
    },
    "@media (min-width: 1440px)": {
      top: "41%",
    },
  },
  tooltipHolderInvestment: {
    backgroundColor: "#FFFF !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginRight: "1rem",
    position: "absolute",
    borderRadius: "6px",
    marginLeft: "42px",
    top: "28%",
    padding: "12px",
    boxShadow:
      "0px 6px 10px rgba(101, 101, 101, 0.25), 0px 2px 4px rgba(64, 64, 64, 0.1)",
    "& p": {
      margin: 0,
    },
    "@media (min-width: 440px) and (max-width: 959px)": {
      top: "43%",
    },
    "@media (min-width: 1366px)": {
      top: "35%",
    },
    "@media (min-width: 1440px)": {
      top: "37%",
    },
  },
  tooltipHolderInactive: {
    display: "none",
  },

  costAndTooltipHolder: {
    "@media (min-width: 1366px)": {
      flexDirection: "column",
      alignContent: "end",
      alignItems: "end",
    },
    "@media (min-width: 1440px)": {
      alignItems: "center",
      flexDirection: "row",
    },
  },
}));

export default function MobileBestBuysCost(props) {
  const classes = useStyles();
  const apiURL = process.env.GATSBY_CALC_COST_API;
  const [costs, setCosts] = useState("n/a");
  const { dashboardId, calculatorAssumptionsModel, type, defaultLayout } =
    props;
  const [qsAmt, setQsAmt] = React.useState(1000);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipType, setTooltipType] = useState(null);
  const tooltipRef = useRef(null);

  // Tooltips for regular desktop and >375px mobile
  const inclusiveTooltipPress = () => {
    if (!tooltipOpen) {
      setTooltipOpen(true);
      setTooltipType("inclusive");
      tooltipRef.current.className = classes.tooltipHolder;
    } else {
      setTooltipOpen(false);
      tooltipRef.current.className = classes.tooltipHolderInactive;
    }
  };

  const handleInclusiveClickAway = () => {
    setTooltipOpen(false);
    tooltipRef.current.className = classes.tooltipHolderInactive;
  };
  const handleInvestmentClickAway = () => {
    setTooltipOpen(false);
    tooltipRef.current.className = classes.tooltipHolderInactive;
  };

  const investmentTooltipPress = () => {
    if (!tooltipOpen) {
      setTooltipOpen(true);
      setTooltipType("investment");
      tooltipRef.current.className = classes.tooltipHolderInvestment;
    } else {
      setTooltipOpen(false);
      tooltipRef.current.className = classes.tooltipHolderInactive;
    }
  };

  // Tooltips for tablets
  const [tooltipOpenXs, setTooltipOpenXs] = useState(false);
  const [tooltipXsType, setTooltipXsType] = useState(false);
  const tooltipRefXs = useRef(null);

  const inclusiveTooltipPressXs = () => {
    if (!tooltipOpenXs) {
      setTooltipOpenXs(true);
      setTooltipXsType("inclusive");
      tooltipRefXs.current.className = classes.tooltipHolder;
    } else {
      setTooltipOpenXs(false);
      tooltipRefXs.current.className = classes.tooltipHolderInactive;
    }
  };

  const handleInclusiveClickAwayXs = () => {
    setTooltipOpenXs(false);
    tooltipRefXs.current.className = classes.tooltipHolderInactive;
  };
  const handleInvestmentClickAwayXs = () => {
    setTooltipOpenXs(false);
    tooltipRefXs.current.className = classes.tooltipHolderInactive;
  };

  const investmentTooltipPressXs = () => {
    if (!tooltipOpenXs) {
      setTooltipOpenXs(true);
      setTooltipXsType("investment");
      tooltipRefXs.current.className = classes.tooltipHolderInvestment;
    } else {
      setTooltipOpenXs(false);
      tooltipRefXs.current.className = classes.tooltipHolderInactive;
    }
  };

  // Tooltips for extra small mobiles <375px
  const [tooltipOpenXsMob, setTooltipOpenXsMob] = useState(false);
  const [tooltipXsMobType, setTooltipXsMobType] = useState(false);
  const tooltipRefXsMob = useRef(null);

  const inclusiveTooltipPressXsMob = () => {
    if (!tooltipOpenXsMob) {
      setTooltipOpenXsMob(true);
      setTooltipXsMobType("inclusive");
      tooltipRefXsMob.current.className = classes.tooltipHolder;
    } else {
      setTooltipOpenXsMob(false);
      tooltipRefXsMob.current.className = classes.tooltipHolderInactive;
    }
  };

  const handleInclusiveClickAwayXsMob = () => {
    setTooltipOpenXsMob(false);
    tooltipRefXsMob.current.className = classes.tooltipHolderInactive;
  };
  const handleInvestmentClickAwayXsMob = () => {
    setTooltipOpenXsMob(false);
    tooltipRefXsMob.current.className = classes.tooltipHolderInactive;
  };

  const investmentTooltipPressXsMob = () => {
    if (!tooltipOpenXsMob) {
      setTooltipOpenXsMob(true);
      setTooltipXsMobType("investment");
      tooltipRefXsMob.current.className = classes.tooltipHolderInvestment;
    } else {
      setTooltipOpenXsMob(false);
      tooltipRefXsMob.current.className = classes.tooltipHolderInactive;
    }
  };
  var showRoboBubble = false;
  var showDIYBubble = false;
  if (props.platformType == "Robo-advisor") {
    showRoboBubble = true;
  }
  if (props.platformType == "Platform") {
    showDIYBubble = true;
  }

  try {
    if (costs.length == 0) {
      return null;
    } else {
      if (costs[0].platformType == "Robo-advisor") {
        showRoboBubble = true;
      }
      if (costs[0].platformType == "Platform") {
        showDIYBubble = true;
        //960 1300
      }
      return (
        <Grid container alignItems="center" className={classes.ratingContainer}>
          <Grid ref={tooltipRef} className={classes.tooltipHolderInactive}>
            {tooltipType === "inclusive" && (
              <p>
                This cost represents the total amount you will pay. It is based
                on a typical account and will be a very good guide, but may
                differ a little if you pick a less mainstream solution.
              </p>
            )}
            {tooltipType === "investment" && (
              <p>
                Cost shown is the administration fee only. There may be
                additional third-party investment or fund fees, which depend
                entirely on what you pick. Expect these to add about 0.25% to 1%
                a year to the total cost.
              </p>
            )}
          </Grid>
          <Grid ref={tooltipRefXs} className={classes.tooltipHolderInactive}>
            {tooltipXsType === "inclusive" && (
              <p>
                This cost represents the total amount you will pay. It is based
                on a typical account and will be a very good guide, but may
                differ a little if you pick a less mainstream solution.
              </p>
            )}
            {tooltipXsType === "investment" && (
              <p>
                Cost shown is the administration fee only. There may be
                additional third-party investment or fund fees, which depend
                entirely on what you pick. Expect these to add about 0.25% to 1%
                a year to the total cost.
              </p>
            )}
          </Grid>
          <Grid ref={tooltipRefXsMob} className={classes.tooltipHolderInactive}>
            {tooltipXsMobType === "inclusive" && (
              <p>
                This cost represents the total amount you will pay. It is based
                on a typical account and will be a very good guide, but may
                differ a little if you pick a less mainstream solution.
              </p>
            )}
            {tooltipXsMobType === "investment" && (
              <p>
                Cost shown is the administration fee only. There may be
                additional third-party investment or fund fees, which depend
                entirely on what you pick. Expect these to add about 0.25% to 1%
                a year to the total cost.
              </p>
            )}
          </Grid>
          <Grid
            item
            xs={2}
            className={`${classes.costLabelContainer} ${
              defaultLayout == null || defaultLayout == "1x1"
                ? ""
                : classes.threeByThreeCostLabelContainerLgSm
            }`}
          >
            <Typography variant="body1" className={classes.costPlaceholder}>
              Cost
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            className={`${classes.costRightContainer} ${
              defaultLayout == null || defaultLayout == "1x1"
                ? ""
                : classes.threeByThreeCostRightContainerLgSm
            }`}
          >
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              className={classes.costAndTooltipHolder}
            >
              <Grid item className={classes.costContainer}>
                {props.cost ? (
                  <p className={classes.costLabel}>£{props.cost}</p>
                ) : (
                  <p>n/a</p>
                )}
              </Grid>
              {showRoboBubble && (
                <Grid item className={classes.bubbleContainer}>
                  <p className={classes.greyBubbleInclusive}>
                    All Inclusive{" "}
                    <ClickAwayListener onClickAway={handleInclusiveClickAway}>
                      <img
                        src={InfoIcon}
                        className={classes.infoIcon}
                        onClick={inclusiveTooltipPress}
                      />
                    </ClickAwayListener>
                  </p>
                </Grid>
              )}
              {showDIYBubble && (
                <Grid item className={classes.bubbleContainer}>
                  <p className={classes.greyBubbleInclusive}>
                    + Investment Fees
                    <ClickAwayListener onClickAway={handleInvestmentClickAway}>
                      <img
                        src={InfoIcon}
                        className={classes.infoIcon}
                        onClick={investmentTooltipPress}
                      />
                    </ClickAwayListener>
                  </p>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.costLabelContainerXs}>
            <Typography variant="body1" className={classes.costPlaceholder}>
              Cost
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.costRightContainerXs}>
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              className={classes.costWrapper}
            >
              <Grid item className={classes.costContainerXs} xs={12}>
                {props.cost ? (
                  <p className={classes.costLabelXs}>£{props.cost}</p>
                ) : (
                  <p className={classes.noCostLabel}>n/a</p>
                )}
              </Grid>

              {showRoboBubble && (
                <Grid item className={classes.bubbleContainerXs} xs={12}>
                  <p className={classes.greyBubbleXs}>
                    All Inclusive
                    <ClickAwayListener
                      onClickAway={handleInclusiveClickAwayXsMob}
                    >
                      <img
                        src={InfoIcon}
                        className={classes.infoIcon}
                        onClick={inclusiveTooltipPressXsMob}
                      />
                    </ClickAwayListener>
                  </p>
                  {/* <img src={InfoIcon} /> */}
                </Grid>
              )}

              {showDIYBubble && (
                <Grid item className={classes.bubbleContainerXs} xs={12}>
                  <p className={classes.greyBubbleXs}>
                    + Investment Fees
                    <ClickAwayListener
                      onClickAway={handleInvestmentClickAwayXsMob}
                    >
                      <img
                        src={InfoIcon}
                        className={classes.infoIcon}
                        onClick={investmentTooltipPressXsMob}
                      />
                    </ClickAwayListener>
                  </p>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            className={`${
              defaultLayout == null || defaultLayout == "1x1"
                ? classes.displayNone
                : classes.threeByThreeCostLabelContainerMd
            }`}
          >
            <Typography variant="body1" className={classes.costPlaceholder}>
              Cost
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            className={`${
              defaultLayout == null || defaultLayout == "1x1"
                ? classes.displayNone
                : classes.threeByThreeCostRightContainerMd
            }`}
          >
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              className={classes.costWrapper}
            >
              <Grid item className={classes.costContainerXs} xs={12}>
                {props.cost ? (
                  <p className={classes.costLabelXs}>£{props.cost}</p>
                ) : (
                  <p className={classes.noCostLabel}>n/a</p>
                )}
              </Grid>
              {showRoboBubble && (
                <Grid item className={classes.bubbleContainerXs} xs={12}>
                  <p className={classes.greyBubbleXs}>
                    All Inclusive
                    <ClickAwayListener onClickAway={handleInclusiveClickAwayXs}>
                      <img
                        src={InfoIcon}
                        className={classes.infoIcon}
                        onClick={inclusiveTooltipPressXs}
                      />
                    </ClickAwayListener>
                  </p>
                </Grid>
              )}

              {showDIYBubble && (
                <Grid item className={classes.bubbleContainerXs} xs={12}>
                  <p className={classes.greyBubbleXs}>
                    + Investment Fees
                    <ClickAwayListener
                      onClickAway={handleInvestmentClickAwayXs}
                    >
                      <img
                        src={InfoIcon}
                        className={classes.infoIcon}
                        onClick={investmentTooltipPressXs}
                      />
                    </ClickAwayListener>
                  </p>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  } catch (e) {
    console.log("ERROR-BestBuysCosts", e);
    return <p>n/a</p>;
  }
}
