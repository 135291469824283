import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  useMediaQuery,
  ClickAwayListener,
  CircularProgress,
} from "@material-ui/core";
import ChevronDown from "../../icon/chevron-down.svg";
import MobileInfo from "../../icon/mobileInfo.svg";
import ContainerForSVG from "../../containerForSVG";
import ArrowDown from "../../icon/arrowDown.svg";
import { useForm, Controller } from "react-hook-form";
import queryString from "query-string";
import { getSortOptionLabelById } from "../../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  totalRows: {
    color: "#3C455C",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: "#262641",
      paddingTop: "16px",
      paddingBottom: "4px",
      width: "100%",
    },
  },
  sortByDropdown: {
    color: "#2F2F4E",
    fontSize: "16px",
    lineHeight: "24px",
    width: "233px",
    border: "1px solid #DBE3EF",
    padding: "12px 16px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundImage: `url(${ChevronDown})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 16px) center",
    appearance: "none",
    // cursor: "pointer",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  selectContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      order: -1,
    },
  },
  mobileInfoButton: {
    paddingRight: "8px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  advancedFiltersContainer: {
    height: "48px",
    width: "233px",
    padding: "12px 16px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundColor: "#ffffff",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // height: "auto",
    },
  },
  advancedFiltersContainerChecked: {
    height: "48px",
    width: "233px",
    padding: "12px 16px",
    borderRadius: "8px",
    borderTop: "1px solid #DBE3EF",
    borderLeft: "1px solid #DBE3EF",
    borderRight: "1px solid #DBE3EF",
    backgroundColor: "#ffffff",
    marginRight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  advancedFiltersContainerMobile: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "16px",
    },
  },
  selectFiltersContainer: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  arrowDownButton: {
    /*paddingLeft: "50px",*/
  },
  collapseContainer: {
    position: "Absolute",
    zIndex: "99",
    backgroundColor: "#ffffff",
    width: "600px",
    marginLeft: "-118px",
    marginTop: "16px",
    // boxShadow: " 0px 1px 2px 0px #0000000F",
    borderRadius: "8px",
    boxShadow:
      "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 6px 4px rgba(0, 32, 51, 0.03), 0px 8px 8px rgba(0, 32, 51, 0.04), 0px 16px 16px rgba(0, 32, 51, 0.04), 0px 4px 24px rgba(0, 32, 51, 0.04), 0px 30px 50px rgba(0, 32, 51, 0.06)",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      borderRadius: "0 0 8px 8px",
      position: "static",
    },
  },
  collapseInnerContainer: {
    padding: "16px",
  },
  collapseInnerContainer2: {
    padding: "16px",
    paddingLeft: "52px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
  collapseTitle: {
    fontWeight: 600,
  },
  checkbox: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkedbox: {
    backgroundColor: "transparent",
  },
  explanatoryText: {
    paddingLeft: "33px",
    fontSize: "14px",
    marginTop: "-0.65em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "41px",
      marginTop: "initial",
    },
  },
  collapseInnerBottom: {
    backgroundColor: "#F9FAFB",
    boxShadow: "0px 1px 0px 0px #E6E8F0 inset",
    borderRadius: "0 0 8px 8px",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
    },
  },
  visitSiteButton: {
    backgroundColor: "#FF0F5F",
    color: "black",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    padding: "9px 17px",
    "&:hover": {
      background: "#FFF",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#FF0F5F",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonTextVisitSite: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "24px",
  },
  containerForSVG: { width: "100%" },
  advancedFilterstext: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
      letterSpacing: "-0.02em",
    },
  },
  leftPadding: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px",
    },
    "& .MuiFormControlLabel-label": {
      fontFamily: "Poppins",
      fontWeight: "500",
      lineHeight: "18px",
      fontSize: "16px",
      letterSpacing: 0,
    },
  },
  filtersContainer: {},
}));

export default function BestBuySortAndProviders(props) {
  const classes = useStyles();

  const { showAdvancedFilters } = props;
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      direction={largeScreen ? "row" : "column-reverse"}
    >
      <Grid>
        <Typography variant="h4" className={classes.totalRows}>
          <ContainerForSVG>
            <CircularProgress size={20} />
            &nbsp;Providers
          </ContainerForSVG>
        </Typography>
      </Grid>
      <Grid
        container
        className={classes.selectFiltersContainer}
        direction={largeScreen ? "row" : "column-reverse"}
      >
        {showAdvancedFilters === true && (
          <Grid className={classes.advancedFiltersContainerMobile}>
            <Grid
              className={classes.advancedFiltersContainer}
              container
              alignItems="center"
              alignContent="center"
              justifyContent="flex-end"
              justify="flex-end"
            >
              <Typography className={classes.advancedFilterstext}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  alignContent="flex-end"
                  justify="space-between"
                  justifyContent="flex-end"
                  className={classes.containerForSVG}
                >
                  <img
                    src={ArrowDown}
                    alt="ArrowDown"
                    className={classes.arrowDownButton}
                  />
                </Grid>
              </Typography>
            </Grid>
            <Grid></Grid>
          </Grid>
        )}

        <Grid className={classes.selectContainer}>
          <select className={classes.sortByDropdown}></select>
        </Grid>
      </Grid>
    </Grid>
  );
}
