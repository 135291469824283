import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import StarIcon from "../../icon/Star.svg";
import EmptyStarIcon from "../../icon/StarUnfilled.svg";
import Rating from "@material-ui/lab/Rating";


const useStyles = makeStyles((theme) => ({
  ratingContainer: {
    width: "120px",
    lineHeight:"14px",
  },
  ratingSectionTitle: {
    color: "#3C465F",
    fontWeight: "700",
    textTransform: "uppercase",
    fontSize: "14px",
    paddingBottom: "6px",
    lineHeight:"16px",
    paddingLeft: "10px"
  },
  weSayResizer: {
    [theme.breakpoints.down("sm")]: {
      // paddingLeft: "8px",
      // paddingRight: "8px",
    },
    [theme.breakpoints.down(360)]: {
      // paddingLeft: "1px",
      // paddingRight: "1px",
    },
  },
  scale: {
    paddingLeft: "2px",
      paddingRight: "2px",
    // transform: "scale(0.75,0.75)",
    // width:"16px",
    // height: "16px"

  },
  ratingSectionTitlePaddedTop: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "16px",
    },
    paddingTop: "1.5em",
    paddingLeft: "5px"

  },
}));

export default function BestBuysRatings(props) {
  const classes = useStyles();
  const { materialUiScoreOur, smallDistanceStyle, materialUiScoreYour } = props;

  return (
    <Grid item className={classes.ratingContainer}>
      <Typography variant="body1" className={classes.ratingSectionTitle}>
        Our Rating
      </Typography>
      <Rating
        className={classes.weSayResizer}
        name="half-rating-read"
        defaultValue={materialUiScoreOur}
        itemStyle={smallDistanceStyle}
        precision={0.5}
        readOnly
        icon={<img src={StarIcon} alt="StarIcon" className={classes.scale} />}
        emptyIcon={
          <img
            src={EmptyStarIcon}
            alt="EmptyStarIcon"
            className={classes.scale}
          />
        }
      />
      <Typography
        variant="body1"
        className={`${classes.ratingSectionTitle} ${classes.ratingSectionTitlePaddedTop}`}
      >
        Your Rating
      </Typography>
      <Rating
        className={classes.weSayResizer}
        name="half-rating-read"
        defaultValue={materialUiScoreYour}
        itemStyle={smallDistanceStyle}
        precision={0.5}
        readOnly
        icon={<img src={StarIcon} alt="StarIcon" className={classes.scale} />}
        emptyIcon={
          <img
            src={EmptyStarIcon}
            alt="EmptyStarIcon"
            className={classes.scale}
          />
        }
      />
    </Grid>
  );
}
