import React from "react";
import * as styles from "../../typography.module.css";
import LinkHandler from "../../linkHandler/linkHandler";
import ContainerForLinks from "../../containerForLinks";
import { Typography, Link } from "@material-ui/core";
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube-embed'
import CtaWidget from "../../ctaWidget/ctaWidget";
import ProsConsWidget from "../../prosConsWidget/prosConsWidget";
import DropdownWidget from "../../dropdownWidget/dropdownWidget";
import TextboxWidget from "../../textboxWidget";
import CompanyReviewWidget from "../../companyReviewWidget";
import BlockQuote from "../../blockQuote/blockQuote";
import DisplayCompanyReviewsWidget from "../../displayCompanyReviewsWidget";
import CustomerReviewsWidgetDecider from "../../widgets/customerReviewsWidget/customerReviewsWidgetDecider";
import ProviderReviewsWidget from "../../widgets/providerReviewsWidget/providerReviewsWidget";
import ComparisonCriteriaWidget from "../../widgets/comparisonCriteriaWidget/comparisonCriteriaWidget";
import AdvertWidget from "../../widgets/advert/advertWidget";
import EmailSignupWidget from "../../widgets/emailSignupWidget/emailSignupWidget";
import Table from "../../tables/tables";
import Tips from "./tips";
import RecProviders from "./recProviders";
import AdviserRecommended from "../adviserRecommended";
import ProviderHeader from "../../providerHeader";
import YourRatingWidget from "../../yourRatingWidget";
import OurRatingWidget from "../../ourRatingWidget";
import { DashboardIDPasser } from "../../portableText";
import FrameWidget from "../../widgets/frameWidget/frameWidget";
import ReactSoundcloud from 'react-soundcloud-embed';
import { Figure } from "../../Figure";
import { AddTrailingSlashToUrl } from "../../../lib/helpers";
import DataCaptureForm from "./dataCaptureForm/dataCaptureForm";
import VideoLinkHandler from "../../linkHandler/videoHandler";
import BlockContent from "@sanity/block-content-to-react";


const CustomBlockRenderer = (props) => {
  const { style = "normal" } = props.node;

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, "");
    return React.createElement(
      `h${level}`,
      {
        className: `MuiTypography-h${level}`,
        style: { color: "#ffffff", paddingBottom: "15px", paddingTop: "30px" },
      },
      props.children
    );
  }

  if (style === "blockquote") {
    return <blockquote>- {props.children}</blockquote>;
  }
  if (style === "normal") {
    return React.createElement(
      "p",
      {
        className: `MuiTypography-body1`,
        style: { color: "#ffffff", paddingBottom: "15px", color: "#ffffff" },
      },
      props.children
    );
  }
  if (style === "small") {
    return React.createElement(
      "p",
      { className: styles.small, style: {color: "#ffffff", paddingBottom: "0px" } },
      props.children
    );
  }
  if (style === "extra-small") {
    return React.createElement(
      "p",
      { className: styles.extrasmall, style: {color: "#ffffff", paddingBottom: "0px" } },
      props.children
    );
  }
  else {
    return null;
  }
};
const titlesSerializers = {

  listItem(props) {

    try {

      if (props.node.listItem == "tableWidget") {
        return <p></p>;
      }

      if (props.node.listItem == "bullet") {
        return <li style={{ color: "#FFFFFF" }}><p className={`MuiTypography-body1`} style={{ paddingBottom: "12px", color: "#FFFFFF" }}>{props.children}</p></li>;
      }

      if (props.node.listItem == "number") {
        return <li style={{ color: "#FFFFFF" }}><p className={`MuiTypography-body1`} style={{ paddingBottom: "12px", color: "#FFFFFF" }}>{props.children}</p></li>;
      }

      const { style = "normal" } = props.node;
      if (style === 'normal') {
        return <li><p className="MuiTypography-body1" style={{ paddingBottom: "12px", color: "#FFFFFF" }}>{props.children}</p></li>
      }
      if (style === 'small') {
        return
        <li><p className={styles.small} style={{ paddingBottom: "0px", color:  "#FFFFFF"}}>{props.children}</p></li>
      }
      if (style === 'extra-small') {
        return <li><p className={styles.extrasmall} style={{ paddingBottom: "0px", color: "#FFFFFF" }}>{props.children}</p></li>
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props)
    } catch (e) {
      console.log("ERR-list:", e);
      return null;
    }

  },
  types: {
    block: CustomBlockRenderer,
    /* eslint-disable-next-line react/display-name */
    tipChooser: ({ node }) => {

      return <Tips tipElements={node.tipChoose.tipElements} title={node.tipChoose.title} description={node.tipChoose.description} fromPage="true" />
    },

    recProviders: ({ node }) => {

      return <RecProviders title={node.title} position={node.position} overrideCompanies={node.companies} />
    },

    recAdvisers: ({ node }) => {
      return <AdviserRecommended />
    },

    tableWidget: ({ node }) => {
      return null;
    },
    tableControl: ({ node }) => {

      return (<Table tableRows={node.rows} rowCount={node.rows.length} tableStyle={node.tableStyle} title={node.title} />)
    },
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    adviserReference: ({ node }) => <span>{node.adviser.name}</span>,
    externalLink: ({ node }) => <span>externalLink</span>,
    mainImage: Figure,
    mainVideo: ({ node }) => {
      const { url } = node


      if (url != null) {
        return (<VideoLinkHandler videoUrl={url} type={node._type} />)
      }
      else {
        return null;
      }

    },
    soundCloud: ({ node }) => {

      if ((node.url != "") && (node.url != null) && (typeof node.url != "undefined")) {

        return <ReactSoundcloud url={node.url} height="150px" visual="false" color="#FF0F5F" />
      }
      else {
        return null;
      }

    },
    mainAnchor: ({ node }) => {

      if (node.pageAnchor === undefined) {
        return null;
      }
      else {
        return (<a className={styles.mainAnchor} style={{color: "#57C7FF"}} id={node.pageAnchor?.AnchorName} />)
      }


    },
    ctaWidget: ({ node }) => {

      var ctaText = null;

      if ((node.LeftSideText === undefined) && (node.leftSideText === undefined)) {

        return null;
      }
      else {
        //console.log("cta", node)

        //AUTO insert via .Net library changes the field name, removing the leading CAP.

        if (node.LeftSideText !== undefined) {
          ctaText = node.LeftSideText;
        } else {
          ctaText = node.leftSideText;
        }

        var openInNewTab = false;

        if ((typeof node.openNewTab == "undefined") || (node.openNewTab == false) || (node.openNewTab == undefined)) {
          openInNewTab = false;
        } else {
          openInNewTab = true;
        }


        return (<CtaWidget leftSideText={ctaText} linkTitle={node.text} reference={node.reference} partner={node.partner} newTab={openInNewTab} downloadFile={node.downloadFile} downloadUrl={node.downloadUrl}  />)
      }
    },
    prosConsWidget: ({ node }) => {
      return (<ProsConsWidget prosTitle={node.prosTitle} pros={node.pros} consTitle={node.consTitle} cons={node.cons} />);
    },
    textboxWidget: ({ node }) => {
      return (<TextboxWidget title={node.title} text={node.text} image={node.image} imagePosition={node.imagePosition} />);
    },
    dropdownWidget: ({ node }) => {
      return (<DropdownWidget items={node.items} />);
    },
    companyReviewWidget: ({ node }) => {
      return (<CompanyReviewWidget leftSideText={node.LeftSideText} buttonText={node.buttonText} company={node.company} />);
    },
    blockQuote: ({ node }) => {
      return (<BlockQuote quote={node.quote} photo={node.photo} adviserName={node.adviserName} jobTitle={node.jobTitle} />);
    },
    displayCompanyReviewsWidget: ({ node }) => {
      return (<DisplayCompanyReviewsWidget companyReference={node.company} />);
    },
    providerHeaderWidget: ({ node }) => {

      let xyz = DashboardIDPasser();

      return (<ProviderHeader companyReference={node.company} node={node} />);
    },
    ourRatingWidget: ({ node }) => {
      return (<OurRatingWidget companyReference={node.company} />);
    },
    yourRatingWidget: ({ node }) => {
      return (<YourRatingWidget companyReference={node.company} />);
    },
    customerReviewsWidget: ({ node }) => {
      return (<CustomerReviewsWidgetDecider companyReference={node.company} />);
    },
    frameWidget: ({ node }) => {
      return (<FrameWidget href={node.href} />);
    },
    providerReviewsWidget: ({ node }) => {
      return (<ProviderReviewsWidget companyReference={node.company} companyReviewPageReference={node.companyReviewPageReference} />);
    },
    advertWidget: ({ node }) => {
      return (<AdvertWidget zoneId={node.adZone} mobileZoneId={node.mobileAdZone} />);
    },
    comparisonCriteriaWidget: ({ node }) => {
      return (<ComparisonCriteriaWidget />);
    },
    emailSignupWidget: ({ node }) => {


      return (<EmailSignupWidget signupRedirectLink={node.signupRedirectLink}  downloadUrl={node.downloadUrl}
                                 placeholderText={node.emailSignupWidgetTextboxPlaceholder}
                                  title={node.title} image={node.image} buttonText={node.emailSignupWidgetButtonText}
                                  paragraphText={node.paragraphText}
                                  thankYouText={node.thankYouText} brazeCustomAttributes={node.brazeCustomAttributes}  />);
    },
    dataCaptureForm: ({ node }) => {
      return (<DataCaptureForm brazeCustomAttributes={node.brazeCustomAttributes}  dataCaptureInputs={node.dataCaptureInputs} downloadUrl={node.downloadUrl} linkText={node.linkText} signupRedirectLink={node.signupRedirectLink} _rawThankYouText={node.thankYouText}/>)
    }
  },
  marks: {
    internalLinkPick: ({ mark, children }) => {


      if (mark.reference === undefined) {
        return null;
      }
      else {

        var type = mark.reference._type;

        var urlint = "/learn/articles";

        if (type == "questionPost") {
          urlint = "/question";
        }

        if (type == "fundManagerInfo") {
          urlint = "/fund-manager";
        }

        const { blank } = mark;
        const { url } = mark.reference;

        var mediaURL = process.env.GATSBY_MEDIABLOB;
        var linkURL = url;

        if ((type == "linkCategory") || (type == "fundFactsheetCategory")) {

          if (url.toLowerCase().includes("media")) {

            linkURL = linkURL.replace("https://www.boringmoney.co.uk/", mediaURL)

          } else {
            linkURL = url;
          }


          var linkTarget = "_self"

          if (blank) {
            linkTarget = "_blank"
          }

          linkURL = AddTrailingSlashToUrl(linkURL);

          return blank ?
            <Link style={{ color: "#57C7FF" }} className={styles.mainAnchor} href={linkURL} target={linkTarget}>{children}</Link>
            : <a style={{ color: "#57C7FF" }} className={styles.mainAnchor} href={linkURL} target={linkTarget}>{children}</a>
        }
        else if (type == "widgetCategory") {

          return <ContainerForLinks reference={mark.reference} _type={type}> {children}</ContainerForLinks>

        }
        else {
          if (mark.reference === undefined) {

            return null;
          }
          else {

            const { blank } = mark;
            const { slug = {} } = mark.reference

            var linkTarget = "_self"

            if (blank) {
              linkTarget = "_blank"
            }

            if ((type == "landingPage") || (type == "information") || (type == "course")) {
              urlint = "";

              //console.log("mark", mark.reference.subfolder._id)

              var idFolder = mark.reference.subfolder._ref;

              if (idFolder == null) {
                idFolder = mark.reference.subfolder._id;
              }

              return (
                <LinkHandler blueBg={true} type={type} subfolderRef={idFolder} slug={slug} children={children} url={url} linkTarget={linkTarget} />
              )


            }
            else {
              const href = `${urlint}/${slug.current}/`

              return blank ?
                <a style={{ color: "#57C7FF" }} className={styles.mainAnchor} href={href} target={linkTarget}>{children}</a>
                : <a style={{ color: "#57C7FF" }} className={styles.mainAnchor} href={href} target={linkTarget}>{children}</a>
            }



          }
        }
      }


    },

  }
};
export default titlesSerializers;
