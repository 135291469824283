import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import ConversationCard from "./layout/conversationCard";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import WhiteIconLeft from "./icons/WhiteIconLeft.svg";
import WhiteIconRight from "./icons/WhiteIconRight.svg";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";
import usePopularConversations from "../../../../hooks/usePopularConversations";
import * as uuid from 'uuid'

const useStyles = makeStyles((theme) => ({
  swiperSlide: {
    overflow: "auto",
    backgroundColor: "#ffffff",
    borderRadius: '24px',
    // padding: '16px',
  },
  cardList: {
    width: "100%",
  },
  cardsContainer: {
    height: "100%",
    marginTop: "0 !important",
    marginBottom: "0 !important",
    paddingTop: '24px'
  },
  navIconLeftContainer: {
    display: "flex",
    justifyContent: "end",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
    "@media (min-width: 1625px)": {
      marginRight: "40px",
    },
  },
  navIconRightContainer: {
    display: "flex",
    justifyContent: "start",
    marginRight: "auto",
    marginLeft: "auto",
    cursor: "pointer",

    "@media (min-width: 1625px)": {
      marginLeft: "40px",
    },
  },
  navIconLeft: {
    height: "48px",
    width: "48px",
  },
  navIconRight: {
    height: "48px",
    width: "48px",
  },
  bodyContainer: {
    "@media (min-width: 1423px)": {
      maxWidth: "1170px",
    },
  },
  mobileNavContainer: {
    cursor: "pointer",
    display: "flex",
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    justifyContent: "center",
  },
  mobileLeftIcon: {
    "@media (min-width: 695px)": {
      paddingRight: "6px",
    },
  },
  mobileRightIcon: {
    "@media (min-width: 695px)": {
      paddingLeft: "6px",
    },
  },
  swiperAnchorTag: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
    height: "100%",
  },
  beigeBackground: {
    backgroundColor: '#F7F5E8'
  },
  blueBackground: {
    backgroundColor: '#6464FF'
  }
}));

function PopularConversationsBlock(props) {
  const { layout, arrows, autoscroll, title, queryString, backgroundColor } = props;
  const [popularConversationsData, errors] = usePopularConversations(queryString);

  //console.log('layout: ', layout, 'arrows: ', arrows, '. autoscroll: ', autoscroll, '. title: ', title, '. queryString: ', queryString);
  const classes = useStyles();

  useEffect(() => {
    //console.log('popularConversations: ', popularConversationsData);
    var conversations = [];
    var conversationType = getConversationType();

    if (popularConversationsData && popularConversationsData.posts && popularConversationsData.posts.length > 0) {
      for (var i = 0; i < popularConversationsData.posts.length; i++) {
        var conversation = {
          bodyText: popularConversationsData.posts[i].content,
          username: popularConversationsData.posts[i].user.username,
          avatarText: popularConversationsData.posts[i].user['icon:text'],
          avatarBackgroundColor: popularConversationsData.posts[i].user['icon:bgColor'],
          timestampISO: popularConversationsData.posts[i].timestampISO,
          replies: popularConversationsData.posts[i].isMainPost ? (popularConversationsData.posts[i].topic.postcount - 1) :  popularConversationsData.posts[i].replies,
          slug: popularConversationsData.posts[i].topic.slug,
          pid: popularConversationsData.posts[i].pid
        }

        conversations.push(conversation);

      }

      setConversations(shuffle(conversations));
    }
  }, [popularConversationsData]);

  const [rightArrow, useRightArrow] = useState(null);
  const [leftArrow, useLeftArrow] = useState(null);

  const [mobileRightArrow, useMobileRightArrow] = useState(null);
  const [mobileLeftArrow, useMobileLeftArrow] = useState(null);

  const matchesDesktop = useMediaQuery("(min-width:960px)");
  const matchesMobile = useMediaQuery("(max-width: 950px)");

  const [conversations, setConversations] = useState([]);

  function getSlideAmount() {
    // if (layout == "2x2") {
    //   return 2;
    // }
    if (layout == "3x3") {
      return 3;
    }
    if (layout == "4x4") {
      return 4;
    }
    // if (layout == "5x5") {
    //   return 5;
    // }
    if (layout == "6x6") {
      return 6;
    }
  }

  let slideAmount = getSlideAmount();

  function getMobileSlideAmount() {

    if (layout == "3x3") {
      return 1;
    }
    if (layout == "4x4") {
      return 1;
    }
    // if (layout == "5x5") {
    //   return 2;
    // }
    // if (layout == "6x6") {
    //   return 2;
    // } else {
    //   return null;
    // }
  }

  let mobileSlideAmount = getMobileSlideAmount();

  function getTabletAmount() {
    if (layout === "3x3") {
      return 3;
    }
    if (layout === "4x4") {
      return 4;

    }
    // if (layout === "5x5") {
    //   return 4;
    // }
    // if (layout === "6x6") {
    //   return 4;
    // }
  }

  let tableSlideAmount = getTabletAmount();

  if (layout === "Left 1xbig, Right 2xsmall") {
    return null
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  var getConversationType = function () {
    var conversationType = 'posts';

    if (queryString) {
      var parameters = queryString.split('&');

      if (parameters && parameters.length > 0) {
        for (var i = 0; i < parameters.length; i++) {
          var parameterSplit = parameters[i].split('=');

          var parameterName = parameterSplit[0];
          var parameterValue = parameterSplit[1];

          if (parameterName === 'shows') {
            conversationType = parameterValue;
          }
        }
      }
    }

    return conversationType;
  }

  if (arrows === true || autoscroll === true) {
    return (
      <Grid className={`${classes.cardList} ${backgroundColor === 'blue' ? classes.blueBackground : ''} ${backgroundColor === 'beige' ? classes.beigeBackground : ''}`}>
        {/* Desktop Swiper */}
        {matchesDesktop && (
          <Grid container item alignItems="center" justify="space-between">
            <Grid item xs={0} md={1} className={classes.navIconLeftContainer}>
              {arrows && (
                <img
                  ref={useLeftArrow}
                  className={classes.navIconLeft}
                  src={WhiteIconLeft}
                ></img>
              )}
            </Grid>
            <Grid item xs={11} md={10} className={classes.bodyContainer}>
              <Grid container className={classes.cardsContainer}>
                <Swiper
                  modules={[Navigation, Pagination, Autoplay, A11y]}
                  slidesPerView={3}
                  // observer={true}
                  // resizeObserver={true}
                  // autoplay={autoscroll}
                  spaceBetween={24}
                  updateOnWindowResize={true}
                  navigation={{
                    nextEl: rightArrow,
                    prevEl: leftArrow,
                  }}
                  autoplay={
                    autoscroll
                      ? {
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false,
                        delay: 2000,
                      }
                      : false
                  }
                  loop={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    659: {
                      slidesPerView: tableSlideAmount,
                    },
                    959: {
                      slidesPerView: slideAmount,
                    }
                  }}
                >
                  {conversations.map(
                    ({ username, bodyText, avatarText, avatarBackgroundColor, timestampISO, replies, slug, pid }, index) => {

                      return (
                        <SwiperSlide className={classes.swiperSlide}>
                          {" "}
                          <a className={classes.swiperAnchorTag}  href={`/forum/posts/${slug}/#${pid}`}>
                          <ConversationCard
                            username={username}
                            bodyText={bodyText}
                            id={index}
                            avatarText={avatarText}
                            avatarBackgroundColor={avatarBackgroundColor}
                            timestampISO={timestampISO}
                            replies={replies}
                            layout={layout}
                          />

                          </a>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
                {matchesMobile && (
                  <div className={classes.mobileNavContainer}>
                    <img ref={useMobileLeftArrow} src={WhiteIconLeft}></img>
                    <img ref={useMobileRightArrow} src={WhiteIconRight}></img>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item xs={0} md={1} className={classes.navIconRightContainer}>
              {arrows && (
                <img
                  ref={useRightArrow}
                  className={classes.navIconRight}
                  src={WhiteIconRight}
                ></img>
              )}
            </Grid>
          </Grid>
        )}
        {/* Mobile swiper  */}
        {matchesMobile && (
          <Grid container item alignItems="center" justify="space-between">
            <Grid
              item
              xs={0}
              md={1}
              className={classes.navIconLeftContainer}
            ></Grid>
            <Grid item xs={11} md={10} className={classes.bodyContainer}>
              <Grid container spacing={0} className={classes.cardsContainer}>
                {matchesDesktop}
                <Swiper
                  modules={[Navigation, Pagination, Autoplay, A11y]}
                  slidesPerView={1}
                  observer={true}
                  resizeObserver={false}
                  updateOnWindowResize={true}
                  navigation={{
                    nextEl: mobileRightArrow,
                    prevEl: mobileLeftArrow,
                  }}
                  autoplay={
                    autoscroll
                      ? {
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false,
                      }
                      : false
                  }
                  loop={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 24,
                    },
                    659: {
                      slidesPerView: tableSlideAmount,
                      spaceBetween: 10,
                    },
                    959: {
                      slidesPerView: slideAmount,
                    }
                  }}
                >
                  {conversations.map(
                    ({ username, bodyText, avatarText, avatarBackgroundColor, timestampISO, replies, slug, pid }, index) => {
                      return (
                        <SwiperSlide className={classes.swiperSlide}>
                          {" "}
                          <a className={classes.swiperAnchorTag}  href={`/forum/posts/${slug}/#${pid}`}>
                          <ConversationCard
                            username={username}
                            bodyText={bodyText}
                            id={index}
                            avatarText={avatarText}
                            avatarBackgroundColor={avatarBackgroundColor}
                            timestampISO={timestampISO}
                            replies={replies}
                            layout={layout}
                          />
                          </a>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
                {arrows && (
                  <div className={classes.mobileNavContainer}>
                    <img
                      ref={useMobileLeftArrow}
                      src={WhiteIconLeft}
                      className={classes.mobileLeftIcon}
                    ></img>
                    <img
                      ref={useMobileRightArrow}
                      src={WhiteIconRight}
                      className={classes.mobileRightIcon}
                    ></img>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={0}
              md={1}
              className={classes.navIconRightContainer}
            ></Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default PopularConversationsBlock;
