import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Input, Typography } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  whiteContainer: {
    background: "#FFFFFF",
    boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
    borderRadius: "8px",
    padding: "10.5px 16px",
    paddingRight: '0',
    marginLeft: "2%",
    width: "30%",
    [theme.breakpoints.down("1100")]: {
      width: "35%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "16px",
      paddingRight: '16px'

    },
  },
  monthlyContainer: {
    display: "none",
  },
  textField: {
    width: "calc(100% - 100px)",
    height: "27px",
    textAlign: "right!important",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    letterSpacing: "0em",
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'none',
    paddingRight: '.5em',
    '&:focus': {
      outline: 'none'
    },
    '&::before': {
      content: '"£"',
      display: 'block',
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "27px",
      letterSpacing: "0em",
      color: "#3C465F",
    },
    "& > *": {
      textAlign: "right!important",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "27px",
      letterSpacing: "0em",
    },
  },
  form: {
    width: "100%",
  },
  submitButton: {
    backgroundColor: "#FF0F5F",
    borderRadius: "8px",
    padding: "16px, 24px, 16px, 24px",
  },
  title: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
      paddingLeft: "0",
      paddingBottom: "16px"

    },
  },
  buttonText: {
    color: "#FFFFFF",
  },
  formContainer: {
    background: "#3C465F",
    padding: "16px",
    borderRadius: "8px",
  },
  lumpSumText: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0em",
    color: "#3C465F",
  },
  poundSymbol: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    letterSpacing: "0em",
    color: "#3C465F",
  },
  amountsContainer: {
    width: "70%",
    [theme.breakpoints.down("1100")]: {
      width: "77%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titleContainer: {
    width: "28%",
    [theme.breakpoints.down("1100")]: {
      width: "22%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center"
    },
  },
  visitSiteButton: {
    backgroundColor: "#FF0F5F",
    color: "black",
    width: "23%",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    padding: "12px 0",
    marginLeft: "2%",
    "&:hover": {
      background: "#FFF",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#FF0F5F",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonTextVisitSite: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "24px",
  },
}));

export default function BestBuysFilters(props) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  function onSubmit(data) {
    // Here, we invoke the callback with the new value
    props.onChange(data);
  }
  const classes = useStyles();

  //var qs = queryString.parse(location.search).companies;

  const { type, amount } = props;

  //console.log(amount.amount)

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container className={classes.formContainer}>
        <Grid container alignItems="center" className={classes.titleContainer}>
          <Typography className={classes.title}>
            Calculate your charges
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          className={classes.amountsContainer}
        >
          <Grid
            className={classes.whiteContainer}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.lumpSumText}> My Amount</Typography>
            <Typography className={classes.poundSymbol}> £</Typography>
            <Controller
              name="amount"
              control={control}
              value={props.amount.amount}
              defaultValue={amount.amount}
              rules={{ required: true }}
              render={({ field }) => (

                <NumberFormat

                  className={classes.textField}
                  placeholder="0"
                  decimalScale = { 2}

                  prefix={''}

                  thousandSeparator={true}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            className={classes.monthlyContainer}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.lumpSumText}> Monthly</Typography>
            <Typography className={classes.poundSymbol}> £</Typography>
            <Controller
              name="amountMonthly"
              control={control}
              value={props.amount.amountMonthly}
              render={({ field }) => (
                 <NumberFormat

                  className={classes.textField}
                  placeholder="0"
                  decimalScale={2}

                  prefix={''}

                  thousandSeparator={true}
                  {...field}
                />
              )}
            />
          </Grid>
          <Button
            className={classes.visitSiteButton}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography
              className={classes.buttonTextVisitSite}
              variant="button"
            >
              Search
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
