import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  Hidden,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Container from "../container";
import { makeStyles } from "@material-ui/core/styles";
import close from "./close.svg";
import Image from "gatsby-plugin-sanity-image";
import ContainerForLinks from "../containerForLinks";
import { removeComparator, resetComparators } from "../../redux/reducers/comparators";

const ComparisonCard = ({ company, classes, companySelected, dispatch }) => {
  const [empty, setEmpty] = useState();

  const { companyDisplayName, companyLogo, dashboardId } =
    companySelected || {};

  useEffect(() => {
    if (!companySelected) {
      setEmpty(true);
    }
  }, [companySelected]);

  const handleClose = () => {
    return dispatch(removeComparator(companySelected));
  };

  return (
    <Grid
      container
      direction="column"
      className={
        empty && !companySelected
          ? classes.comparisonCardEmpty
          : classes.comparisonCardFilled
      }
    >
      {empty && !companySelected ? (
        <Grid item>
          <Typography className={classes.emptyCardText}>
            Choose another provider
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            xs={2}
            className={classes.filledLogoContainer}
          >
            {companyLogo && (
              <Image
                style={{
                  width: "48px",
                  height: "48px",
                  border: "0.5px solid #DBE3EF",
                  boxShadow: "0px 0.932743px 1.86549px rgba(64, 64, 64, 0.1)",
                  borderRadius: "3.73097px",
                }}
                {...companyLogo}
              />
            )}
          </Grid>
          <Grid
            container
            className={classes.filledCardTextContainer}
            direction="column"
            xs={8}
            alignContent="flex-start"
            justifyContent="center"
          >
            <Typography className={classes.filledCardText}>
              {companyDisplayName}
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            className={classes.filledCloseContainer}
            xs={2}
          >
            <img
              src={close}
              onClick={() => {
                handleClose();
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const ComparisonBottomBar = ({
  comparators,
  dispatch,
  type,
  comparisonPageReference,
}) => {
  const useStyles = makeStyles((theme) => ({
    comparisonContainer: {
      zIndex: "999",
      "-webkit-transform": "translateZ(0)",
      position: "sticky",
      // transform: "translateZ(0);-webkit-transform: translateZ(0)",
      bottom: "0",
      height: "109px",
      width: "100vw",
      background:
        "#3C465F",
      [theme.breakpoints.up("md")]: {
        height: "104px",
      },
    },
    detailsContainer: {
      padding: "0.5rem 0 1rem 0",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        padding: "1rem 0 1rem 0",
        maxWidth: "1770px",
      },
    },
    comparisonText: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      whiteSpace: "nowrap",
      [theme.breakpoints.up("md")]: {
        flex: "1 1 auto",
      },
      "@media (min-width: 960px) and (max-width: 1060px)": {
        wordBreak: "break-word",
        whiteSpace: "normal",
      },
    },
    comparisonAmount: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#FFFFFF",
      alignSelf: "center",
    },
    comparisonButton: {
      backgroundColor: "#FF0F5F",
      color: "black",
      boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
      padding: "12px",
      marginTop: "1rem",
      "& span": {
        height: "100%",
      },
      "&:hover": {
        background: "#FFF",
        "& *": {
          color: "#FF0F5F",
        },
      },
      "&:hover $buttonTextVisitComparison": {
        color: "#FF0F5F",
      },
      [theme.breakpoints.down("md")]: {
        borderRadius: "8px",
        marginBottom: "16px",
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: "169px",
        height: "48px",
        marginTop: "0",
      },
      "@media (min-width: 960px) and (max-width: 1295px)": {
        marginBottom: "0",
        // width: "auto",
      },
      "@media (min-width: 960px) and (max-width: 1060px)": {
        width: "auto",
      },
    },
    buttonTextVisitComparison: {
      color: "#fff",
      textTransform: "uppercase",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: "600",
      "&:hover": {
        color: "#FF0F5F",
      },
      "& a": {
        textDecoration: "none",
        color: "#fff",
        "&:hover": {
          color: "#FF0F5F",
        },
      },
    },

    compareTextAmountContainer: {
      [theme.breakpoints.up("md")]: {
        flexDirection: "column",
        alignContent: "flex-start",
        "& div": {
          flexBasis: "0",
        },
      },
      "@media (min-width: 960px) and (max-width: 1295px)": {
        width: "auto",
        flexShrink: "1",
      },
    },
    comparisonAmountContainer: {
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        flex: "1 1 auto",
      },
    },
    comparisonCardContainer: {
      justifyContent: "space-around",
      [theme.breakpoints.up("md")]: {
        flexGrow: "1",
      },
      "@media (min-width: 960px) and (max-width: 1295px)": {
        flexWrap: "nowrap",
        flexShrink: "0",
      },
    },
    comparisonCardEmpty: {
      border: "1.5px solid #FFFFFF",
      borderRadius: "8px",
      width: "270px",
      height: "72px",
      justifyContent: "center",
      alignItems: "center",
      "@media (min-width: 960px) and (max-width: 1295px)": {
        maxWidth: "270px",
      },
    },
    comparisonCardFilled: {
      border: "1.5px solid #FFFFFF",
      background: "#FFFFFF",
      borderRadius: "8px",
      width: "270px",
      height: "72px",
      justifyContent: "center",
      padding: "0.75rem",
      "@media (min-width: 960px) and (max-width: 1295px)": {
        maxWidth: "270px",
      },
    },
    emptyCardText: {
      fontWeight: "600",
      fontSize: "1rem",
      lineHeight: "24px",
      color: "#FFFFFF",
    },
    comparisonButtonLinkContainer: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        "& a": {
          width: "100%",
        },
      },
      "@media (min-width: 960px) and (max-width: 1295px)": {
        flexShrink: "1",
        width: "auto",
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
        alignContent: "center",
        boxSizing: "border-box",
        "& *": {
          boxSizing: "border-box",
        },
      },
    },
    filledCardText: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#2F2F4E",
    },
    filledLogoContainer: {
      paddingLeft: "0.75rem",
    },
    filledCardTextContainer: {
      paddingLeft: "0.75rem",
    },
    filledCloseContainer: {
      marginBottom: "auto",
      // paddingTop: "10px",
      alignSelf: "flex-end",
      width: "24px",
      "& img": {
        zIndex: "50",
        cursor: "pointer",
        height: "18.5px",
        width: "18.5px",
      },
    },
    disabled: {
      pointerEvents: "none",
      cursor: "default",
    },
    comparisonIcon: {
      "@media (min-width: 960px) and (max-width: 1295px)": {
        width: "auto !important",
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
      },
    },
    linkContainer: {
      [theme.breakpoints.up("md")]: {
        height: "45px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none",
      },
      [theme.breakpoints.down("md")]: {
        textDecoration: "none",
      },
    },
  }));

  const classes = useStyles();

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  let queryParams =
    comparators.length >= 2
      ? `/?company1=${comparators["0"].dashboardId}&company2=${comparators["1"].dashboardId}`
      : "";

  useEffect(() => {
    if (JSON.stringify(googleAnalyticsObject) === "{}") {
      return;
    }

    if (googleAnalyticsObject != {}) {
      if (window.dataLayer) {
        window.dataLayer.push({
          best_buy_compare_value: JSON.stringify(googleAnalyticsObject),
        });

        window.dataLayer.push({ event: "best_buy_compare" });
      }
    }
  }, [googleAnalyticsObject]);

  const handleCompareClick = function () {
    setGoogleAnalyticsObject({
      ProviderOne: comparators[0].companyDisplayName,
      ProviderTwo: comparators[1].companyDisplayName
    });
    dispatch(resetComparators());
  }

  return (
    <Grid container xs={12} className={classes.comparisonContainer}>
      <Container className={classes.bodyContainer}>
        <Grid
          xs={12}
          md={10}
          container
          className={classes.detailsContainer}
          direction="column"
        >
          <Grid
            md={3}
            container
            alignContent="center"
            className={classes.compareTextAmountContainer}
          >
            <Grid item xs={6}>
              <span className={classes.comparisonText}>Compare providers</span>
            </Grid>
            <Grid
              container
              xs={6}
              className={classes.comparisonAmountContainer}
              justify="flex-end"
            >
              <span className={classes.comparisonAmount}>
                {`${comparators.length.toString()}`} of 2 selected
              </span>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid
              container
              md={6}
              className={classes.comparisonCardContainer}
              justifyContent="space-around"
            >
              <Grid item className={classes.comparisonIcon}>
                <ComparisonCard
                  classes={classes}
                  companySelected={comparators["0"]}
                  dispatch={dispatch}
                />
              </Grid>
              <Grid item className={classes.comparisonIcon}>
                <ComparisonCard
                  classes={classes}
                  companySelected={comparators["1"]}
                  dispatch={dispatch}
                />
              </Grid>
            </Grid>
          </Hidden>
          <Grid
            container
            md={3}
            className={classes.comparisonButtonLinkContainer}
          >
            <Button
              className={classes.comparisonButton}
              disabled={comparators.length < 2 ? true : false}
              onClick={handleCompareClick}
            >
              <ContainerForLinks
                reference={comparisonPageReference}
                _type={comparisonPageReference._type}
                queryParams={queryParams}
                customClassName={classes.linkContainer}
              >
                <Typography className={classes.buttonTextVisitComparison}>
                  Compare
                </Typography>
              </ContainerForLinks>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default connect(
  (state) => ({
    comparators: state.comparatorReducer.comparators,
  }),
  null
)(ComparisonBottomBar);
