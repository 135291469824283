import React from "react";
import { Link } from "gatsby";
import { Hidden, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../../container";
import ContainerForLinks from "../../containerForLinks";

const useStyles = makeStyles((theme) => ({

  linkTextNotActive: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: "500"
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600"
  },
  linkTextCourse:{
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#3C465F",
    textTransform: "uppercase",
  },
  marginFromTitle: {
    marginTop: "32px",
    marginBottom: "0px",
  },
  dot: {
    color: "#B2C2C3",
    marginLeft: "7px",
    marginRight: "7px"
  },
  dotCourse:{
    color: "#3C465F",
    marginLeft: "7px",
    marginRight: "7px"
  },
  BreadcrumbPos:{
    [theme.breakpoints.up("md")]:{
      paddingTop: "14px",
      paddingBottom: "14px",
    },
  },

}));
export default function Breadcrumb(props) {

  const { bc, title, slug, subfolder, isCoursePage } = props;
  const classes = useStyles();

  const thisPage = subfolder.folder + slug.current + '/';

  if (bc.length > 0) {
    return (

      <Container>
        <div className={classes.BreadcrumbPos}>
          <Hidden smDown implementation="js">
            <Typography variant="subtitle2">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className={isCoursePage ? classes.linkTextCourse : classes.linkTextNotActive}
              >
                Home
                </Link>
              <span className={isCoursePage ? classes.dotCourse : classes.dot}>{" • "}</span>
              {bc && bc.map((items,counter) =>
              {
                if ((items.title!=null)&&(items.title.toLowerCase()!="home")) {
                  if ((bc.length === counter + 1) && (items.linkReference!=null)) {
                    return (
                      <>
                        <ContainerForLinks
                          reference={items.linkReference}
                          _type={items.linkReference._type}
                        >
                          <span tyle={{ textDecoration: "none" }} className={isCoursePage ? classes.linkTextCourse : classes.linkTextNotActive}>{items.title}</span>
                        </ContainerForLinks>

                      </>
                    )
                  }
                  else {
                    if ((items.linkReference != null)) {
                      return (
                        <>
                          <ContainerForLinks
                            reference={items.linkReference}
                            _type={items.linkReference._type}

                          >
                            <span tyle={{ textDecoration: "none" }} className={isCoursePage ? classes.linkTextCourse : classes.linkTextNotActive}>{items.title}</span>
                          </ContainerForLinks>



                          <span className={isCoursePage ? classes.dotCourse : classes.dot}>{" • "}</span>
                        </>
                      )
                    }
                    else {
                      return null;
                    }
                  }
                }

              }

              )}


            </Typography>
          </Hidden>
        </div>
      </Container>
    )
  }
  else {
    return (
    <Container>
      <div className={classes.BreadcrumbPos}>
        <Hidden smDown implementation="js">
          <Typography variant="subtitle2">
            <Link
              to="/"
              style={{ textDecoration: "none" }}
              className={isCoursePage ? classes.linkTextCourse : classes.linkTextNotActive}
            >
              Home
                </Link>
              <span className={isCoursePage ? classes.dotCourse : classes.dot}>{" • "}</span>
              <Link
                to={thisPage}
                style={{ textDecoration: "none" }}
                className={isCoursePage ? classes.linkTextCourse : classes.linkTextNotActive}
              >
                {title}
                </Link>
          </Typography>
        </Hidden>
      </div>
      </Container>
    )
  }




}
