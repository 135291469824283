import React, { useEffect, useState, useRef } from "react";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Hidden,
  Button,
  Checkbox,
  Switch,
  Slider,
  Typography,
} from "@material-ui/core";
import * as styles from "../landingPageComponents/adviserList.module.css";
import Container from "../../components/container";
import FundListCard from "./fundListCard";
import * as fundstyles from "./funds.module.css";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Crown from "../icon/crown-16x16.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Checked from "./img/checked.svg";
import NonChecked from "./img/non-checked.svg";
import { Icon } from "@material-ui/core";
import MobileFilterContainerExpandIcon from "./img/mobile-filter-container-expand-icon.svg";
import MobileFilterContainerCollapseIcon from "./img/mobile-filter-container-collapse-icon.svg";
import FundPopover from "./fundComponents/fundPopover";
import SearchImage from "./img/search.svg";

const CustomSlider = withStyles({
  root: {
    color: "#50586C",
    height: 3,
    padding: "13px 0",
    opacity: 1,
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    backgroundColor: "#DBE3EF",
    height: "8px",
    borderRadius: "5px",
    opacity: 1,
    boxShadow: "inset 0px 2px 2px rgba(74, 74, 104, 0.1)",
  },
  thumb: {
    backgroundColor: "#3C455C",
    border: "5px solid #FFFFFF",
    width: "28px",
    height: "28px",
    marginTop: "-10px",
    opacity: 1,
    boxShadow:
      "0px 5.5px 5px -3px rgba(14, 14, 44, 0.2), inset 0px -1px 0px rgba(14, 14, 44, 0.4)",
    marginLeft: "-11px",
  },
  valueLabel: {
    left: "calc(-50% + 3px)",
  },
  mark: {
    height: 0,
  },
})(Slider);

const CustomSliderWithGradientRail = withStyles({
  root: {
    color: "#50586C",
    opacity: 1,
    height: 3,
    padding: "13px 0",
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    background:
      "linear-gradient(90deg, #DBE3EF -0.45%, #6FCF97 21.52%, #FBE580 40.88%, #F2994A 60.24%, #FD3018 79.07%, #FF0F5F 100%)",
    height: "8px",
    borderRadius: "5px",
    opacity: 1,
    boxShadow: "inset 0px 2px 2px rgba(74, 74, 104, 0.1)",
  },
  thumb: {
    backgroundColor: "#3C455C",
    border: "5px solid #FFFFFF",
    width: "28px",
    height: "28px",
    marginTop: "-10px",
    marginLeft: "-11px",
    opacity: 1,
    boxShadow:
      "0px 5.5px 5px -3px rgba(14, 14, 44, 0.2), inset 0px -1px 0px rgba(14, 14, 44, 0.4)",
  },
  valueLabel: {
    left: "calc(-50% + 2px)",
  },
  mark: {
    height: 0,
  },
})(Slider);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--blue)",
    color: "white",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    height: "initial",
  },
  arrow: {
    color: "#f5f5f9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      background:
        "#3C465F;",
      color: "white",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      height: "3.5em",
      width: "3.5em",
      borderRadius: "1.75em",
      [theme.breakpoints.down("sm")]: {
        height: "3em",
        width: "3em",
        borderRadius: "1.5em",
        fontSize: "9px",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      paddingTop: ".8em",
    },
    "& .MuiPaginationItem-icon": {
      fontSize: "1.5rem",
    },
    "& .MuiPaginationItem-root": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "initial !important",
      },
    },
    "& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis": {
      backgroundColor: "white",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#2F2F4E",
      height: "3.5em",
      width: "3.5em",
      borderRadius: "1.75em",
      [theme.breakpoints.down("sm")]: {
        height: "3em",
        width: "3em",
        borderRadius: "1.5em",
        fontSize: "9px",
      },
    },
  },
  formControl: {
    width: "100%",
  },
  upperFiltersContainer: {
    border: "1px solid #DBE3EF",
    backgroundColor: "#FFFFFF",
  },
  slider: {
    padding: 0,
  },
  alignRight: {
    textAlign: "right",
  },
  sliderLabel: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#8C8CA2",
    paddingTop: "10px",
  },
  sliderUpperLabel: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F2F4E",
    marginBottom: "-9px",
  },
  paddedLeft: {
    paddingLeft: ".4em",
  },
  sortByContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    height: "60px",
  },
  activePassiveContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    height: "60px",
    paddingLeft: "24px",
    paddingRight: "24px",
    visibility: "hidden",
  },
  activePassiveText: {
    color: "#2F2F4E",
    opacity: "1"
  },
  activePassiveContainerinner: {
    // paddingLeft: "8px",
    // paddingRight: "8px",
  },
  activePassiveContainerinnerText: {
    paddingRight: "17px",
  },
  activePassiveWrapper: {
    height: "60px",
  },
  filtersHeader: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    color: "#3C455C",
  },
  rightFiltersContainer: {
    // marginTop: "-1em",
  },
  upperFiltersWrapper: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  headerWrapper: {
    marginBottom: "8px",
  },
  checkedLabel: {
    background: "#3C465F",
    borderRadius: "8px",
    color: "#FFFFFF",
    cursor: "pointer",
    textTransform: "uppercase",
    letterSpacing: "0.04em",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "600",
    height: "100%",
    display: "block",
    border: "1px solid #65689F",
  },
  nonCheckedLabel: {
    background: "#FFFFFF",
    borderRadius: "8px",
    color: "#3C455C",
    border: "1px solid #DBE3EF",
    cursor: "pointer",
    textTransform: "uppercase",
    letterSpacing: "0.04em",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "600",
    display: "block",
    height: "100%",
  },
  checkboxSpan: {
    paddingRight: ".8em",
  },
  fundType: {
    color: "#3C455C",
    letterSpacing: "-0.02em",
    fontSize: "14px",
    lineHeight: "28px",
    fontWeight: "600",
    marginRight: "2em",
  },
  totalRows: {
    color: "#3C455C",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    letterSpacing: "-0.02em",
  },
  lowerFilterContainer: {
    paddingTop: "2em",
    paddingBottom: "2.5em",
  },
  mobileFilterHeader: {
    float: "left",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    color: "#3C455C",
  },
  mobileExpandIcon: {
    float: "right",
    cursor: "pointer",
  },
  mobileUpperFiltersWrapper: {
    border: "1px solid #DBE3EF",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    padding: "1em",
  },
  mobileDetailsExpanded: {
    display: "block",
  },
  mobileDetailsCollapsed: {
    display: "none",
  },
  mobileExpandableHeader: {
    position: "relative",
    height: "3em",
    cursor: "pointer",
  },
  mobileCrownRatingContainer: {
    paddingTop: "2em",
  },
  mobileRiskContainer: {
    paddingTop: "2em",
    paddingBottom: "1.5em",
  },
  mobileHeader: {
    color: "#3C455C",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    marginTop: "1em",
    marginBottom: "1em",
    display: "block",
  },
  mobileFundType: {
    color: "#3C455C",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "28px",
    display: "block",
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  mobileFundTypesContainer: {
    paddingBottom: "1.5em",
  },
  tooltipText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#FAFCFE",
  },
  searchBarcontainer: {
    position: 'relative',
    [theme.breakpoints.down(1280)]: {
      marginBottom: '1em'
    },
  },
  searchImage: {
    position: 'absolute',
    right: '18px',
    height: '32px',
    width: '32px',
    cursor: 'pointer',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down(1280)]: {
      right: '10px',
    },
  },
  searchBar: {
    border: '1px solid #94A4D6',
    backgroundColor: '#FFFFFF',
    width: '100%',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#8C8CA2',
    paddingRight: '48px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '16px'
  },
  sortByDropdown: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    paddingLeft: '16px !important',
    lineHeight: '24px !important'
  },
  fundsUpdateDateLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '24px'
  },
  fundsUpdateDateContainer: {
    marginTop: '24px'
  }
}));
const defaultTheme = createMuiTheme();
const switchTheme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      root: {
        width: "46.8px",
        height: 26,
        padding: 0,
      },
      thumb: {
        width: "20.8px",
        height: "20.8px",
      },
      switchBase: {
        color: "#FFFFFF",
        transform: "translate(-6px,-7px)",
        "&$checked": {
          transform: "translate(14.4px,-7px)",
        },
        // [defaultTheme.breakpoints.down("md")]: {
        //   transform: "translate(-6px,-7px)",
        //   "&$checked": {
        //     transform: "translate(14.4px,-7px)",
        //   },
        // },
        // Controls default (unchecked) color for the thumb
        backgroundColor: "initial",
        "&:hover": {
          backgroundColor: "initial",
        },
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "initial",
          },
        },
      },
      track: {
        borderRadius: "20.8px",
        width: "46.8px",
        height: 26,
        padding: 0,
        opacity: 1,
        // Controls default (unchecked) color for the track
        backgroundColor: "#ECF1F4",
        boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
        "$checked$checked + &": {
          // Controls checked color for the track
          backgroundColor: "#009089",
          opacity: 1,
        },
      },
    },
  },
});

function FundsList(props) {
  const [fundsState, setFunds] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredFunds, setFilteredFunds] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [sortBy, setSortBy] = useState("sortBy");
  const [expanded, setExpanded] = useState(false);
  const [risk, setRisk] = useState([1, 5]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  const [frmstate, setFrmState] = useState({
    multiAsset: false,
    equity: false,
    fixedInterest: false,
    sustainable: false,
    passive: false,
    active: false,
    ratingValue: 1,
    riskLow: 1,
    riskHigh: 5,
    active: false,
  });

  const apiURL = process.env.GATSBY_FUNDS_WITH_SORT_API;

  const {
    multiAsset,
    equity,
    fixedInterest,
    sustainable,
    passive,
    active,
    ratingValue,
    riskLow,
    riskHigh
  } = frmstate;

  const [scrollTop, setScrollTop] = useState('');

  useEffect(() => {
    (() => {
      if (scrollTop != '') {
        window.location.href = "#filters";
      }
    })();
  }, [scrollTop]);

  const HandleChange = function (event) {
    var analyticsObject = {
      multiAsset: frmstate.multiAsset,
      equity: frmstate.equity,
      fixedInterest: frmstate.fixedInterest,
      sustainable: frmstate.sustainable,
      sortBy: sortBy
    };

    setGoogleAnalyticsObject({
      ...analyticsObject, [event.target.name]: event.target.checked
    });

    setFrmState({ ...frmstate, [event.target.name]: event.target.checked });
  };

  const handleChangeRating = (event, newValue) => {
    setFrmState({ ...frmstate, ratingValue: newValue });
  };
  const handleChangeRisk = (event, newValue) => {
    setRisk(newValue);
    setFrmState({ ...frmstate, riskLow: newValue[0], riskHigh: newValue[1] });
  };

  const handlePageChange = async (event, value) => {
    setScrollTop(scrollTop + '.');

    setPage(value);

    await searchUpdatePage(value);
  };

  useEffect(() => {
    (async () => {
      await searchPageLoad();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var model = {
        SortBy: sortBy,
      };

      var funds = await getFunds(model);

      updateList(funds, 0, itemsPerPage);
    })();
  }, [frmstate]);

  useEffect(() => {
    (async () => {
      var model = {
        SortBy: sortBy,
      };

      var funds = await getFunds(model);

      updateList(funds, 0, itemsPerPage);
    })();
  }, [sortBy]);

  var searchPageLoad = async function () {
    var model = {
      SortBy: "sortBy",
    };

    var funds = await getFunds(model);

    if (funds.length > 0) {
      setTotalRows(funds.length);
      setTotalPages(Math.ceil(funds.length / itemsPerPage));
      setFunds(funds.slice(0, itemsPerPage));
    }
  };

  var searchUpdatePage = async function (newPageNumber) {
    var lastPage = newPageNumber - 1;

    var startrecord = 0;

    if (lastPage != 0) {
      startrecord = (newPageNumber - 1) * parseInt(itemsPerPage);
    }

    var endrecord = startrecord + parseInt(itemsPerPage);
    var model = {
      SortBy: sortBy,
    };

    var funds = await getFunds(model);
    updateList(funds, startrecord, endrecord);
  };

  var getFunds = async function (model) {
    return fetch(apiURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      });
  };

  async function updateList(funds, startrecord, endrecord) {
    let filtered = [];
    var showIt = false;
    //console.log('funds: ', funds);
    //console.log("updateList", funds);

    for (var i = 0; i < funds.length; i++) {
      var boolMultiAssets =
        funds[i].Multi_Asset.toLowerCase() == "true" ? true : false;
      var boolEquity = funds[i].Equity.toLowerCase() == "true" ? true : false;
      var boolFixedInt =
        funds[i].Fixed_Interest.toLowerCase() == "true" ? true : false;
      var boolSustainable =
        funds[i].Sustainable_ESG.toLowerCase() == "true" ? true : false;
      var boolPassive =
        funds[i].ActivePassive.toLowerCase() == "true" ? false : true;

      var actpass = funds[i].ActivePassive.toLowerCase();

      var chilliRisk = funds[i].Chilli;
      var crownRating = funds[i].Crown;

      showIt = false;

      if (
        ((boolMultiAssets && frmstate.multiAsset) || !frmstate.multiAsset) &&
        ((boolEquity && frmstate.equity) || !frmstate.equity) &&
        ((boolFixedInt && frmstate.fixedInterest) || !frmstate.fixedInterest) &&
        ((boolSustainable && frmstate.sustainable) || !frmstate.sustainable) &&
        ((frmstate.passive && boolPassive && actpass != "") ||
          (frmstate.active && !boolPassive && actpass != "") ||
          (frmstate.active && frmstate.passive && actpass != "") ||
          (!frmstate.active && !frmstate.passive)) &&
        (((parseInt(chilliRisk) <= frmstate.riskHigh) && (parseInt(chilliRisk) >= frmstate.riskLow)) || (parseInt(chilliRisk) == 0)) &&
        ((parseInt(crownRating) >= frmstate.ratingValue) || ((parseInt(crownRating) == 0) && (parseInt(frmstate.ratingValue) == 1))) && (
          !searchPhrase || (searchPhrase && searchPhrase !== '' && funds[i].Fund_name.toLowerCase().includes(searchPhrase.toLowerCase()))
        )
      ) {
        showIt = true;
      }

      if (showIt) {
        filtered.push(funds[i]);
      }
      //console.log('risk show it: ', showIt);
    }

    funds = filtered;

    setTotalRows(funds.length);
    setTotalPages(Math.ceil(funds.length / itemsPerPage));
    setFunds(funds.slice(startrecord, endrecord));
  }

  function valuetext(value) {
    return `${value}`;
  }

  const searchButton = useRef(null);

  var handleKeyDown = async function (e) {
    if (e.key === 'Enter') {
      await HandleSearch();
    }
  }

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <HtmlTooltip
        open={open}
        style={{ height: "initial" }}
        enterTouchDelay={0}
        placement="top"
        title={value}
        PopperProps={{
          popperOptions: {
            adaptive: true,
            placement: "top",
            modifiers: {
              offset: {
                enabled: true,
                //offset: '40px, 40px',
              },
              rtansform: {},
            },
          },
        }}
      >
        {children}
      </HtmlTooltip>
    );
  }

  var sortByChange = function (event) {
    setGoogleAnalyticsObject({
      multiAsset: frmstate.multiAsset,
      equity: frmstate.equity,
      fixedInterest: frmstate.fixedInterest,
      sustainable: frmstate.sustainable,
      sortBy: event.target.value
    });

    setSortBy(event.target.value);
  };
  const classes = useStyles();

  var expandIconClick = function () {
    setExpanded(!expanded);
  };

  var HandleSearch = async function () {
    var model = {
      SortBy: sortBy,
    };
    var funds = await getFunds(model);

    updateList(funds, 0, itemsPerPage);
  }

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === "{}") {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({
            fund_filter_value_combined: JSON.stringify(googleAnalyticsObject),
          });

          window.dataLayer.push({ event: "fund_filter_change" });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  return (
    <Grid>
      <FormControl component="fieldset" className={classes.formControl}>
        <Hidden mdDown implementation="js">
          <div class={classes.upperFiltersContainer} id="filters">
            <Container>
              <Grid
                container
                spacing={2}
                className={classes.upperFiltersWrapper}
              >
                <Grid item xs={12} className={classes.headerWrapper}>
                  <Grid container >
                    <Typography variant="h4" className={classes.filtersHeader}>
                      These filters will shorten the list
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2} justify="space-between">
                    <Grid item xs={5}>
                      <Grid container>
                        <Grid item xs={12} className={classes.sliderUpperLabel}>
                          <FundPopover
                            title="Ratings"
                            isForSlider="true"
                            disclaimerLocation="fund_list_slider_text_rating"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomSlider
                            defaultValue={1}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={5}
                            onChange={handleChangeRating}
                            track={false}
                            valueLabelFormat={(value) => (
                              <div>
                                <Typography className={classes.tooltipText}>
                                  {value}
                                </Typography>

                                {/* <img src={Crown} /> */}
                              </div>
                            )}
                            className={classes.slider}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography className={classes.sliderLabel}>
                                {" "}
                                1
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                className={`${classes.alignRight} ${classes.sliderLabel}`}
                              >
                                5
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Grid item xs={12} className={classes.sliderUpperLabel}>
                          <FundPopover
                            title="Risk"
                            isForSlider="true"
                            disclaimerLocation="fund_list_slider_text_risk"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomSliderWithGradientRail
                            defaultValue={5}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-sliderRisk"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={5}
                            value={risk}
                            onChange={handleChangeRisk}
                            valueLabelFormat={(value) => (
                              <div>
                                <Typography className={classes.tooltipText}>
                                  {value}
                                </Typography>
                              </div>
                            )}
                            track={false}
                            className={classes.slider}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography className={classes.sliderLabel}>
                                Low
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                className={`${classes.alignRight} ${classes.sliderLabel}`}
                              >
                                High
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.rightFiltersContainer}>
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-end"
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <select
                        className={`${fundstyles.sortByDropdown} ${classes.sortByDropdown}`}
                        onChange={sortByChange}
                      >
                        <option value={"sortBy"}>Sort by</option>
                        <option value={"AlphabeticallyAZ"}>
                          Alphabetical (A-Z)
                        </option>
                        <option value={"AlphabeticallyZA"}>
                          Alphabetical (Z-A)
                        </option>
                        <option value={"CrownAscending"}>
                          Crown rating (Ascending)
                        </option>
                        <option value={"CrownDescending"}>
                          Crown rating (Descending)
                        </option>
                        <option value={"RiskAscending"}>
                          Risk (Ascending)
                        </option>
                        <option value={"RiskDescending"}>
                          Risk (Descending)
                        </option>
                        <option value={"CostAscending"}>
                          Cost (Ascending)
                        </option>
                        <option value={"CostDescending"}>
                          Cost (Descending)
                        </option>
                        <option value={"ReaderRatingAscending"}>
                          Value rating (Ascending)
                        </option>
                        <option value={"ReaderRatingDescending"}>
                          Value rating (Descending)
                        </option>
                        <option value={"ReturnOneYearAscending"}>
                          Return 1 year (Ascending)
                        </option>
                        <option value={"ReturnOneYearDescending"}>
                          Return 1 year (Descending)
                        </option>
                        <option value={"ReturnFiveYearAscending"}>
                          Return 5 year (Ascending)
                        </option>
                        <option value={"ReturnFiveYearDescending"}>
                          Return 5 year (Descending)
                        </option>
                      </select>
                    </Grid>
                    <Grid item className={`${classes.searchBarcontainer}`} xs={6}>
                      <input placeholder="Search funds" type="text" onChange={event => setSearchPhrase(event.target.value)} className={classes.searchBar} onKeyDown={handleKeyDown} />
                      <img src={SearchImage} className={classes.searchImage} onClick={async () => { await HandleSearch() }} ref={searchButton} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
          <Container>
            <Grid
              container
              alignItems="center"
              className={classes.lowerFilterContainer}
            >
              <Grid item xl={3} md={2}>
                <Grid container>
                  <span className={classes.totalRows}>{totalRows} Funds</span>
                </Grid>
              </Grid>
              <Grid item xl={9} md={10}>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <span className={classes.fundType}>Fund Type</span>
                  </Grid>
                  <Grid item>
                    <label
                      className={
                        multiAsset
                          ? classes.checkedLabel
                          : classes.nonCheckedLabel
                      }
                    >
                      <Checkbox
                        icon={
                          <Icon>
                            <img src={NonChecked} height={23} width={23} />
                          </Icon>
                        }
                        checkedIcon={
                          <Icon>
                            <img src={Checked} height={25} width={25} />
                          </Icon>
                        }
                        checked={multiAsset}
                        onChange={HandleChange}
                        name="multiAsset"
                      />
                      <span className={classes.checkboxSpan}>Multi asset</span>
                    </label>
                  </Grid>

                  <Grid item>
                    <label
                      className={
                        equity ? classes.checkedLabel : classes.nonCheckedLabel
                      }
                    >
                      <Checkbox
                        icon={
                          <Icon>
                            <img src={NonChecked} height={23} width={23} />
                          </Icon>
                        }
                        checkedIcon={
                          <Icon>
                            <img src={Checked} height={25} width={25} />
                          </Icon>
                        }
                        checked={equity}
                        onChange={HandleChange}
                        name="equity"
                      />
                      <span className={classes.checkboxSpan}>Equity</span>
                    </label>
                  </Grid>
                  <Grid item>
                    <label
                      className={
                        fixedInterest
                          ? classes.checkedLabel
                          : classes.nonCheckedLabel
                      }
                    >
                      <Checkbox
                        icon={
                          <Icon>
                            <img src={NonChecked} height={23} width={23} />
                          </Icon>
                        }
                        checkedIcon={
                          <Icon>
                            <img src={Checked} height={25} width={25} />
                          </Icon>
                        }
                        checked={fixedInterest}
                        onChange={HandleChange}
                        name="fixedInterest"
                      />
                      <span className={classes.checkboxSpan}>
                        Fixed Interest
                      </span>
                    </label>
                  </Grid>
                  <Grid item>
                    <label
                      className={
                        sustainable
                          ? classes.checkedLabel
                          : classes.nonCheckedLabel
                      }
                    >
                      <Checkbox
                        icon={
                          <Icon>
                            <img src={NonChecked} height={20} width={20} />
                          </Icon>
                        }
                        checkedIcon={
                          <Icon>
                            <img src={Checked} height={25} width={25} />
                          </Icon>
                        }
                        checked={sustainable}
                        onChange={HandleChange}
                        name="sustainable"
                      />
                      <span className={classes.checkboxSpan}>
                        Sustainable/ESG
                      </span>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Hidden>
        <Hidden lgUp implementation="js">
          <Container>
            <div>
              <span className={classes.mobileHeader}>
                These filters will shorten the list
              </span>
            </div>
            <Grid container>
              <Grid item className={`${classes.searchBarcontainer}`} xs={12}>
                <input placeholder="Search funds" type="text" onChange={event => setSearchPhrase(event.target.value)} className={classes.searchBar} onKeyDown={handleKeyDown} />
                <img src={SearchImage} className={classes.searchImage} onClick={async () => { await HandleSearch() }} ref={searchButton} />
              </Grid>
            </Grid>
            <div className={classes.mobileUpperFiltersWrapper}>
              <div
                className={classes.mobileExpandableHeader}
                onClick={expandIconClick}
              >
                <span className={classes.mobileFilterHeader}>Filter</span>
                <img
                  className={classes.mobileExpandIcon}
                  src={
                    expanded
                      ? MobileFilterContainerCollapseIcon
                      : MobileFilterContainerExpandIcon
                  }
                />
              </div>
              <div
                className={
                  expanded
                    ? classes.mobileDetailsExpanded
                    : classes.mobileDetailsCollapsed
                }
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    className={classes.mobileCrownRatingContainer}
                  >
                    <Grid container>
                      <Grid item xs={12} className={classes.sliderUpperLabel}>
                        <FundPopover
                          title="Ratings"
                          isForSlider="true"
                          disclaimerLocation="fund_list_slider_text_rating"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSlider
                          defaultValue={1}
                          getAriaValueText={valuetext}
                          aria-labelledby="discrete-slider"
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={1}
                          max={5}
                          onChange={handleChangeRating}
                          track={false}
                          valueLabelFormat={(value) => (
                            <div>
                              <Typography className={classes.tooltipText}>
                                {value}
                              </Typography>
                            </div>
                          )}
                          className={classes.slider}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.sliderLabel}>
                              {" "}
                              1
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              className={`${classes.alignRight} ${classes.sliderLabel}`}
                            >
                              5
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className={classes.sliderUpperLabel}>
                        <FundPopover
                          title="Risk"
                          isForSlider="true"
                          disclaimerLocation="fund_list_slider_text_risk"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSliderWithGradientRail
                          defaultValue={5}
                          getAriaValueText={valuetext}
                          aria-labelledby="discrete-sliderRisk"
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={1}
                          max={5}
                          value={risk}
                          onChange={handleChangeRisk}
                          valueLabelFormat={(value) => (
                            <div>
                              <Typography className={classes.tooltipText}>
                                {value}
                              </Typography>
                            </div>
                          )}
                          track={false}
                          className={classes.slider}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.sliderLabel}>
                              Low
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              className={`${classes.alignRight} ${classes.sliderLabel}`}
                            >
                              High
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <select
                      className={`${fundstyles.sortByDropdown} ${classes.sortByDropdown}`}
                      onChange={sortByChange}
                    >
                      <option value={"sortBy"}>Sort by</option>
                      <option value={"AlphabeticallyAZ"}>
                        Alphabetical (A-Z)
                        </option>
                      <option value={"AlphabeticallyZA"}>
                        Alphabetical (Z-A)
                        </option>
                      <option value={"CrownAscending"}>
                        Crown rating (Ascending)
                      </option>
                      <option value={"CrownDescending"}>
                        Crown rating (Descending)
                      </option>
                      <option value={"RiskAscending"}>Risk (Ascending)</option>
                      <option value={"RiskDescending"}>
                        Risk (Descending)
                      </option>
                      <option value={"CostAscending"}>Cost (Ascending)</option>
                      <option value={"CostDescending"}>
                        Cost (Descending)
                      </option>
                      <option value={"ReaderRatingAscending"}>
                        Value rating (Ascending)
                      </option>
                      <option value={"ReaderRatingDescending"}>
                        Value rating (Descending)
                      </option>
                      <option value={"ReturnOneYearAscending"}>
                        Return 1 year (Ascending)
                      </option>
                      <option value={"ReturnOneYearDescending"}>
                        Return 1 year (Descending)
                      </option>
                      <option value={"ReturnFiveYearAscending"}>
                        Return 5 year (Ascending)
                      </option>
                      <option value={"ReturnFiveYearDescending"}>
                        Return 5 year (Descending)
                      </option>
                    </select>
                  </Grid>

                </Grid>
              </div>
            </div>
            <div>
              <span className={classes.mobileFundType}>Fund type</span>
            </div>
            <Grid
              container
              className={classes.mobileFundTypesContainer}
              alignItems="center"
              justify="flex-start"
              spacing={2}
            >
              <Grid item>
                <label
                  className={
                    multiAsset ? classes.checkedLabel : classes.nonCheckedLabel
                  }
                >
                  <Checkbox
                    icon={
                      <Icon>
                        <img src={NonChecked} height={23} width={23} />
                      </Icon>
                    }
                    checkedIcon={
                      <Icon>
                        <img src={Checked} height={25} width={25} />
                      </Icon>
                    }
                    checked={multiAsset}
                    onChange={HandleChange}
                    name="multiAsset"
                  />
                  <span className={classes.checkboxSpan}>Multi asset</span>
                </label>
              </Grid>

              <Grid item>
                <label
                  className={
                    equity ? classes.checkedLabel : classes.nonCheckedLabel
                  }
                >
                  <Checkbox
                    icon={
                      <Icon>
                        <img src={NonChecked} height={23} width={23} />
                      </Icon>
                    }
                    checkedIcon={
                      <Icon>
                        <img src={Checked} height={25} width={25} />
                      </Icon>
                    }
                    checked={equity}
                    onChange={HandleChange}
                    name="equity"
                  />
                  <span className={classes.checkboxSpan}>Equity</span>
                </label>
              </Grid>
              <Grid item>
                <label
                  className={
                    fixedInterest
                      ? classes.checkedLabel
                      : classes.nonCheckedLabel
                  }
                >
                  <Checkbox
                    icon={
                      <Icon>
                        <img src={NonChecked} height={23} width={23} />
                      </Icon>
                    }
                    checkedIcon={
                      <Icon>
                        <img src={Checked} height={25} width={25} />
                      </Icon>
                    }
                    checked={fixedInterest}
                    onChange={HandleChange}
                    name="fixedInterest"
                  />
                  <span className={classes.checkboxSpan}>Fixed Interest</span>
                </label>
              </Grid>
              <Grid item>
                <label
                  className={
                    sustainable ? classes.checkedLabel : classes.nonCheckedLabel
                  }
                >
                  <Checkbox
                    icon={
                      <Icon>
                        <img src={NonChecked} height={20} width={20} />
                      </Icon>
                    }
                    checkedIcon={
                      <Icon>
                        <img src={Checked} height={25} width={25} />
                      </Icon>
                    }
                    checked={sustainable}
                    onChange={HandleChange}
                    name="sustainable"
                  />
                  <span className={classes.checkboxSpan}>Sustainable/ESG</span>
                </label>
              </Grid>
            </Grid>
          </Container>
        </Hidden>
      </FormControl>

      <Container>
        <div>
          <div>
            {fundsState &&
              fundsState.map((f) => {
                return <FundListCard fund={f} from="LIST" />;
              })}
          </div>
        </div>
      </Container>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={fundstyles.paginationContainer}
      >
        <Grid item>
          {fundsState.length > 0 && (
            <Pagination
              count={totalPages}
              page={page}
              className={classes.root}
              onChange={handlePageChange}
              rowsPerPage={itemsPerPage}
            />
          )}
        </Grid>
      </Grid>
      <Container>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-start"
          className={classes.fundsUpdateDateContainer}
        >
          <Grid item>
            <label className={classes.fundsUpdateDateLabel}>Page updated: {(new Date()).toLocaleDateString('en-GB')}</label>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default FundsList;
