import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../client-config";
import { Grid, Button, Typography } from "@material-ui/core";
import ContainerForLinks from "../../containerForLinks";
import Container from "../../container";
import Transparent from "../../icon/Transparent.svg";
import RoundCampusCTA from "./reuseableCampusComponents/roundCampusCTA";
import CampusCTA from "./reuseableCampusComponents/campusCTA";

import titlesSerializers from "./inheritableColorSerializers"
import titlesSerializersForBlueBackground from "./inheritableColorSerializersForBlueBackground"
import ReusableButton from "../../reusableButton";

import { replacePlaceholders } from "../../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
    // marginTop: "30px",
  },
  centerNoButton: {
    textAlign: "center",
    // marginTop: "30px",
  },
  grey: {
    justifyContent: "center",
    width: "100%"
  },
  left: {
    textAlign: "left",
    // marginTop: "30px",
  },
  buttonMargin: {
    paddingTop: "25px",
    paddingBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    padding: "16px 32px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 4px 0px #4040401A",
    transition: "background 0.3s",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      background: "#FF0F5F",
    },
    "&:hover $buttonText": {
      color: "#ffffff",
    },
  },
  redButton: {
    padding: "16px 32px",
    backgroundColor: "#FF0F5F",
    boxShadow: "0px 2px 4px 0px #4040401A",
    transition: "background 0.3s",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      background: "#ffffff",
    },
    "&:hover $buttonTextWhite": {
      color: "#FF0F5F",
    },
  },

  buttonContainerCenter: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainerCenterCourse: {
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainerLeft: {
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonText: {
    color: "#FF0F5F",
    transition: "color 0.3",
  },
  buttonTextWhite: {
    color: "#ffffff",
    transition: "color 0.3",
  },
  white: {
    backgroundColor: "#fff",
  },
  whiteCampus: {
    backgroundColor: "#fff",
    '& h1': {
      margin: 0,
      paddingTop: "27px !important",
      paddingBottom: "16px !important",
    },
  },
  blue: {
    background: '#3C465F',
    color: "#fff",
    '& h2, h3, h4, h1, h5, h6': {
      color: '#fff !important'
    }
  },

  blueCampus: {
    backgroundColor: "#6464FF",
    color: "#fff",
    '& h2, h3, h4, h1, h5, h6': {
      color: '#fff !important',
    },
    "& h1":{
      margin: 0,
      paddingTop: "48px !important",
      paddingBottom: "16px !important"
    },
  },
  greyCampus:{
    backgroundColor: "#F7F5E8",
    color: "#3C465F",
    '& h2, h3, h4, h1, h5, h6': {
      color: "#3C465F",
    },
    "& h1":{
      margin: 0,
      paddingTop: "48px !important",
      paddingBottom: "16px !important"
    },
  },
  paddingBottom: {
    // paddingBottom: '45px'
  },
  minHeight: {
    // minHeight: '320px'
  },
  whiteText: {
    "& p + ul":{
      marginTop: 0,
    },
    "& li":{
      paddingTop: "0.4rem",
      paddingBottom: "0.4rem",
      lineHeight: "1.5rem",
      fontSize: "1rem",
    },
    "& li:first-of-type":{
      paddingTop: 0,
    },
    "& li:last-of-type":{
      paddingBottom: 0,
    },
    "& ul":{
      paddingLeft: "1.2rem",
    },
    "& div": {
      "& h2": {
        paddingTop: "0 !important"
      },
      "& p:empty": {
        paddingBottom: "0 !important"
      }
    }
  },
  blueTextCourse: {
    color: "#FFFFFF",
    "& p + ul":{
      marginTop: 0,
    },
    "& li":{
      paddingTop: "0.4rem",
      paddingBottom: "0.4rem",
      lineHeight: "1.5rem",
      fontSize: "1rem",
    },
    "& li:first-of-type":{
      paddingTop: 0,
    },
    "& li:last-of-type":{
      paddingBottom: 0,
    },
    "& ul":{
      paddingLeft: "1.2rem",
    },
    "& div": {
      "& > *":{
        color: "#FFFFFF",
      },
      "& h2": {
        paddingTop: "0 !important"
      },
      "& p:empty": {
        paddingBottom: "0 !important"
      }
    }
  },
  whiteGreyCourseText: {
    color: "#3C465F",
    "& > *":{
      color: "#3C465F",
    },
    "& p + ul":{
      marginTop: 0,
    },
    "& li":{
      paddingTop: "0.4rem",
      paddingBottom: "0.4rem",
      lineHeight: "1.5rem",
      fontSize: "1rem",
    },
    "& li:first-of-type":{
      paddingTop: 0,
    },
    "& li:last-of-type":{
      paddingBottom: 0,
    },
    "& ul":{
      paddingLeft: "1.2rem",
    },
    "& div": {
      "& > *":{
        color: "#3C465F !important",
      },
      "& h2": {
        paddingTop: "0 !important"
      },
      "& p:empty": {
        paddingBottom: "0 !important"
      }
    }
  },
}));
export default function TitleBlock(props) {
  const { title, _rawIntroPara, alignment, linkText, reference, styles, isCoursePage, firstname } =
    props;
  const classes = useStyles();

  const blueStyles = isCoursePage ? classes.blueCampus : classes.blue;

  const courseText = styles === "White" ||  styles === "Grey-Transparent" ? classes.whiteGreyCourseText : classes.blueTextCourse;
  const centerContainer = isCoursePage ? classes.buttonContainerCenterCourse : classes.buttonContainerCenter;


  const replacedObject = replacePlaceholders(_rawIntroPara, process.env.REPLACETXT_FIRSTNAME, firstname);

  return (
    <Grid
      container
      className={ isCoursePage ?  ((styles == "White" && `${isCoursePage ? classes.whiteCampus : classes.white} ${classes.grey} `) ||
        (styles == "Blue" && `${blueStyles} ${classes.grey}  ${isCoursePage ? null : classes.minHeight}`) ||
        `${classes.greyCampus} ${isCoursePage ? null : classes.minHeight}` )  :
        ( (styles == "White" && `${classes.white} ${classes.grey} ${classes.paddingBottom} ${isCoursePage ? null : classes.minHeight}`) ||
        (styles == "Blue" && `${classes.blue} ${classes.grey} ${classes.paddingBottom} ${isCoursePage ? null : classes.minHeight}`) ||
        classes.grey )
      }
    >
      <Container>
        <Grid
          container
          item
          className={
            (alignment == "Text Center" && linkText && classes.center) ||
            (alignment == "Text Center" &&
              !linkText &&
              classes.centerNoButton) ||
            (alignment == "Text Left" && classes.left)
          }
        >
          <Grid
            item
            md={
              (alignment == "Text Center" && 12) ||
              (alignment == "Text Left" && 8)
            }
            xs={12}
            className={isCoursePage ? courseText : classes.whiteText}
          >
            {styles == "Blue"? <BasePortableText
              blocks={replacedObject}
              serializers={titlesSerializersForBlueBackground}
              {...clientConfig.sanity}
            />: <BasePortableText
                blocks={replacedObject}
            serializers={titlesSerializers}
            {...clientConfig.sanity}
          />}


          </Grid>
          {linkText && (
            <Grid
              className={
                (alignment == "Text Center" && centerContainer) ||
                (alignment == "Text Left" && classes.buttonContainerLeft)
              }
              container
              item
              md={
                (alignment == "Text Center" && 12) ||
                (alignment == "Text Left" && 4)
              }
              xs={12}
            >
              {
                isCoursePage ?
                // <RoundCampusCTA reference={reference} styles={styles} linkText={linkText} alignment={alignment}/> :
                  <CampusCTA reference={reference} styles={styles} linkText={linkText && linkText} alignment={alignment}  arrowIcon={false} buttonType={"circular"}/> :
              <Grid className={classes.buttonMargin}>
                <ReusableButton buttonColour={styles == "Blue" ? "white" : "red"} linkText={linkText && linkText} arrowIcon={false} reference={reference}/>
              </Grid>

              }
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  );
}
