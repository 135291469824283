import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Hidden, Checkbox, FormControlLabel } from "@material-ui/core";
import ContainerForLinks from "../../../containerForLinks";
import BestBuysInANutshell3x3WouldRecommend from "../../components/layout/3x3WouldRecommend/bestBuysInANutshell";
import BestBuysFirstRow3x3WouldRecommend from "../../components/layout/3x3WouldRecommend/bestBuysFirstRow";
import BestBuysFirstRow3x3WouldRecommendMedium from "../../components/layout/3x3WouldRecommend/bestBuysFirstRowMedium";
import BestBuysButtons from "../../components/layout/3x3WouldRecommend/bestBuysButtons";
import MobileBestBuysCost from "../../components/mobileBestBuyCost";
import BbHeartImage from "../../../icon/BbHeart.svg";
import Tick from "./tick.svg";
import SponsoredBadge from "./sponsored-badge.svg";
import { AddTrailingSlashToUrl } from "../../../../lib/helpers";

import comparators, { addComparator, removeComparator } from "../../../../redux/reducers/comparators";

const useStyles = makeStyles((theme) => ({
  providerHeaderContainer: {
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    padding: "8px 16px",
    position: "relative",
    borderBottom: "1px solid #E6E8F0",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 1rem 0.5rem 1rem"
  },
  providerHeaderContainerCompare:{
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    padding: "8px 16px",
    position: "relative",
    borderBottom: "1px solid #E6E8F0",
    paddingTop: "0",
    paddingBottom: "0",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 1rem 0.5rem 1rem"
  },
  sponsoredProviderHeaderContainerCompare: {
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    padding: "8px 16px",
    position: "relative",
    paddingTop: "0",
    paddingBottom: "0",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 1rem 0.5rem 1rem"
  },
  sponsoredProviderHeaderContainer: {
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    padding: "8px 16px",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 1rem 0.5rem 1rem"
  },
  companyDisplayName: {
    color: "#3C465F",
    fontWeight: "600",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",

    width: "auto",
  },
  providerContainer: {
    background: "white",
    borderRadius: "0px 0px 8px 8px",
    maxWidth: "100%",
    margin: "0",
    position: "relative",
    paddingTop: "16px",
  },
  logoAndScoreContainer: {
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cardContainer: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    marginBottom: "24px",
  },
  companyHighlight: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    color: "#2F2F4E",
    overflow: "hidden",
  },
  reviewAnchor: {
    textDecoration: "none",
    cursor: "pointer",
    alignSelf: "center",
  },
  ratingsPullRight: {
    marginRight: "-1em",
  },
  cardContainerNoMarginBottom: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
  },
  sponsoredProviderCard: {
    boxShadow: "0px 0px 0px 4px rgba(251, 117, 92, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
    border: "1px solid #FB755C",
    borderRadius: "8px",
  },
  wouldRecommendContainer: {
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingBottom: "8px",
    background:
      "linear-gradient(180deg, #F3F8FC 34.24%, #FFFFFF 75.38%), linear-gradient(180deg, #F3F8FC 34.24%, #FFFFFF 75.38%)",
    boxShadow: "inset 0px 1px 0px #E6E8F0",
    paddingTop: "8px",
  },
  wouldRecommendPercentageContainer: {
    [theme.breakpoints.down(1380)]: {
      maxWidth: "70% !important",
      flexBasis: "70% !important",
    },
    //[theme.breakpoints.down(959)]: {
    //    maxWidth: '75% !important',
    //    flexBasis: '75% !important',
    //},
    [theme.breakpoints.down(1185)]: {
      maxWidth: "100% !important",
      flexBasis: "100% !important",
    },
    [theme.breakpoints.down(959)]: {
      maxWidth: "70% !important",
      flexBasis: "70% !important",
    },
  },
  numberOfReviewsLabel: {
    float: "right",
    color: "#8C8CA2",
    fontSize: "14px",
    lineHeight: "12px",
    //[theme.breakpoints.down(1380)]: {
    //    float: 'left'
    //},

    //[theme.breakpoints.down(400)]: {
    //    float: 'left'
    //},
    [theme.breakpoints.down(1185)]: {
      float: "left",
    },
    [theme.breakpoints.down(959)]: {
      float: "right",
    },
    [theme.breakpoints.down(375)]: {
      fontWeight: "500",
      fontSize: "13px",
    },
    [theme.breakpoints.down(336)]: {
      fontWeight: "500",

      fontSize: "12px",
    },
  },
  inANutshellText: {
    fontSize: "14px",
    color: "#2F2F4E",
    fontWeight: "500",
    lineHeight: "18px",
    display: "block",
    verticalAlign: "middle",
  },
  wouldRecommendText: {
    fontSize: "14px",
    color: "#2F2F4E",
    fontWeight: "500",
    lineHeight: "18px",
    display: "block",
    verticalAlign: "middle",
    [theme.breakpoints.down(1240)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(1024)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(334)]: {
      fontSize: "11px",
    },
  },
  bbHeartImage: {
    display: "block",
    verticalAlign: "middle",
    paddingRight: "calc(.3em + 1.7px)",
    height: "18px",
  },
  wouldRecommendPercentageLabel: {
    color: "#3C465F",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "bold",
    marginBlockStart: "0",
    marginBlockEnd: 0,
    textAlign: "center",
    paddingRight: ".3em",
  },
  fullHeight: {
    height: "100%",
  },
  awardProviderPageLogo: {
    maxHeight: "67px",
  },
  reviewsLabelContainer: {
    [theme.breakpoints.down(1380)]: {
      maxWidth: "30% !important",
      flexBasis: "30% !important",
    },
    //[theme.breakpoints.down(959)]: {
    //    maxWidth: '25% !important',
    //    flexBasis: '25% !important',
    //    paddingTop: 'initial'
    //},
    [theme.breakpoints.down(1185)]: {
      maxWidth: "100% !important",
      flexBasis: "100% !important",
      paddingTop: "8px",
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: "30% !important",
      flexBasis: "30% !important",
      paddingTop: "initial",
    },
  },
  icon: {
    width: "1.125rem",
    height: "1.125rem",
    border: "1px solid #94A4D6",
    borderRadius: "5px",
  },
  checkedIcon: {
    background: "#94A4D6",
    width: "1.125rem",
    height: "1.125rem",
    borderRadius: "5px",
    border: "1px solid #94A4D6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      margin: "auto",
    }
  },
  checkboxIconContainer:{
    paddingRight: "0.4375rem",
  },
  checkboxContainer: {
    // paddingLeft: "auto",
    fontWeight: "500",
    margin: "0",
    padding: "0",
    "& span": {
    lineHeight: "18px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#2F2F4E",
    }
  },
  sponsoredProviderLabel: {
    position: 'absolute',
    top: '0',
    right: '-5px',
    height: '22px',
  }
}));

function BestBuysTableRow(props) {
  const {
    bestBuyLink,
    companyDisplayName,
    companyHighlight,
    inANutshell,
    isAffiliate,
    ourRating,
    yourRating,
    companyBBCats,
    reviewLink,
    dashboardId,
    companyLogo,
    bestBuyAward,
    defaultLayout,
    noOfReviews,
    wouldRecommendRating,
    awardURL,
    isSponsoredProvider
  } = props.company;

  const {comparators, dispatch, enableComparison} = props;

  const [checked, setChecked] = useState(false);

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});
  //console.log('best buy award: ', bestBuyAward);
  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === "{}") {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({
            best_buy_card_clicked_value: JSON.stringify(googleAnalyticsObject),
          });

          window.dataLayer.push({ event: "best_buy_card_clicked" });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  const classes = useStyles();
  var linkHref = "#";
  var linkHrefReview = "#";

  if (bestBuyLink != null) {
    linkHref = bestBuyLink.url;
  }
  if (reviewLink != null && reviewLink.length > 0) {
    linkHrefReview =
      reviewLink[0].subfolder[0]["folder"] + reviewLink[0]["slug"];
  }

  const materialUiScoreOur = ourRating / 2;
  const materialUiScoreYour = yourRating / 2;
  const smallDistanceStyle = {};

  var companyNameClicked = function () {
    setGoogleAnalyticsObject({
      ProviderName: companyDisplayName,
      Source: "provider name",
    });
  };

  const handleCheckbox = (e, args) => {
    if (e.target.checked) {
      return dispatch(addComparator(args[0]));
    }
    return dispatch(removeComparator(args[0]))
  };
  return (
    <Grid
      className={`${
        props.defaultLayout == null || props.defaultLayout == "1x1"
          ? classes.cardContainer
        : (props.isSponsoredProvider ? classes.sponsoredProviderCard : classes.cardContainerNoMarginBottom)
      }`}
    >
      <div className={enableComparison ? (props.isSponsoredProvider ? classes.sponsoredProviderHeaderContainerCompare : classes.providerHeaderContainerCompare) : (props.isSponsoredProvider ? classes.sponsoredProviderHeaderContainer : classes.providerHeaderContainer)}>
        <a
          href={AddTrailingSlashToUrl(linkHrefReview)}
          className={classes.reviewAnchor}
          onClick={() => {
            companyNameClicked();
          }}
        >
          {" "}
          <span variant="body1" className={classes.companyDisplayName}>
            {companyDisplayName}
          </span>
        </a>{" "}
        {enableComparison && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  handleCheckbox(e, [
                    {
                      companyDisplayName: companyDisplayName,
                      reviewLink: reviewLink,
                      companyLogo: companyLogo,
                      bestBuyLink: bestBuyLink,
                      linkHrefReview: linkHrefReview,
                      dashboardId: dashboardId,
                      type: props.type,
                    },
                  ]);
                }}
                disabled={
                  comparators.length >= 2 &&
                  comparators["0"].dashboardId !== dashboardId &&
                  comparators["1"].dashboardId !== dashboardId
                    ? true
                    : false
                }
                icon={<span className={classes.icon} />}
                checked={
                  comparators &&
                  comparators.some(function (el) {
                    return el.dashboardId === dashboardId;
                  })
                }
                checkedIcon={
                  <span className={classes.checkedIcon}>
                    <img src={Tick} />
                  </span>
                }
                className={classes.checkboxIconContainer}
                id={dashboardId}
              />
            }
            label="Compare"
            className={classes.checkboxContainer}
          />
        )}
      </div>
      <div className={classes.providerContainer}>
        {
          props.isSponsoredProvider && <img className={classes.sponsoredProviderLabel} src={SponsoredBadge} />
        }

        <Grid container className={classes.logoAndScoreContainer}>
          <Hidden mdDown implementation="js">
            <BestBuysFirstRow3x3WouldRecommend
              companyLogo={companyLogo}
              companyDisplayName={companyDisplayName}
              linkHrefReview={AddTrailingSlashToUrl(linkHrefReview)}
              materialUiScoreOur={materialUiScoreOur}
              smallDistanceStyle={smallDistanceStyle}
              bestBuyAward={bestBuyAward}
              awardURL={AddTrailingSlashToUrl(awardURL)}
            />
          </Hidden>
          <Hidden lgUp implementation="js">
            <BestBuysFirstRow3x3WouldRecommendMedium
              companyLogo={companyLogo}
              companyDisplayName={companyDisplayName}
              linkHrefReview={AddTrailingSlashToUrl(linkHrefReview)}
              materialUiScoreOur={materialUiScoreOur}
              smallDistanceStyle={smallDistanceStyle}
              bestBuyAward={bestBuyAward}
              awardURL={AddTrailingSlashToUrl(awardURL)}
            />
          </Hidden>
        </Grid>
        <Grid
          container
          className={classes.wouldRecommendContainer}
          alignItems="center"
          justify="flex-start"
        >
          <Grid
            item
            xs={9}
            className={classes.wouldRecommendPercentageContainer}
          >
            {props.company.wouldRecommendRating != null &&
              props.company.wouldRecommendRating != undefined && (
                <Grid container alignItems="center" justify="flex-start">
                  <Grid item>
                    <img src={BbHeartImage} className={classes.bbHeartImage} />
                  </Grid>

                  <Grid item>
                    <p className={classes.wouldRecommendPercentageLabel}>
                      {Math.round(props.company.wouldRecommendRating)}%
                    </p>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={classes.wouldRecommendText}
                    >
                      Would recommend
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid item xs={3} className={classes.reviewsLabelContainer}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item xs={12}>
                <a href={linkHrefReview}>
                  <Typography
                    variant="body1"
                    className={classes.numberOfReviewsLabel}
                  >
                    {props.company.noOfReviews == null ||
                    props.company.noOfReviews == undefined
                      ? 0
                      : props.company.noOfReviews}{" "}
                    reviews
                  </Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BestBuysInANutshell3x3WouldRecommend
          inANutshell={inANutshell}
          defaultLayout={props.defaultLayout}
        />
        {props.costInBBTable == true && (
          <MobileBestBuysCost
            key={props.company}
            platformType={props.company.platformType}
            type={props.type}
            cost={props.company.cost}
            defaultLayout={props.defaultLayout}
          />
        )}
        <BestBuysButtons
          linkHrefReview={AddTrailingSlashToUrl(linkHrefReview)}
          linkHref={AddTrailingSlashToUrl(linkHref)}
          isAffiliate={isAffiliate}
          companyDisplayName={companyDisplayName}
        />
      </div>
    </Grid>
  );
}

export default connect(state => ({
  comparators: state.comparatorReducer.comparators,
}), null)(BestBuysTableRow)
