import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../client-config";
import serializers from "../../serializers";
import ContainerForLinks from "../../containerForLinks";
import Transparent from "../../icon/Transparent.svg";
import Container from "../../container";
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";

export default function HeaderBlock(props) {
  const { alignment, linkText, desktopImage, tabletImage, mobileImage, reference, _rawIntroPara, mobileVideo, desktopVideo, tabletVideo } = props;
  const isVideoProvided = desktopVideo || undefined
  const [mobileImageUrl, setMobileImageUrl] = useState('url(' + (mobileImage && imageUrlFor(buildImageObj(mobileImage).asset)
    .url()) + ')');
  const [tabletImageUrl, setTabletImageUrl] = useState('url(' + (tabletImage && imageUrlFor(buildImageObj(tabletImage).asset)
    .url()) + ')');
  const [desktopImageUrl, setDesktopImageUrl] = useState('url(' + (desktopImage && imageUrlFor(buildImageObj(desktopImage).asset)
    .url()) + ')');

  const useStyles = makeStyles((theme) => ({
    textLeft: {
      flexDirection: "row-reverse",
    },
    textRight: {
      flexDirection: "row",
    },
    whiteHeroBlock: {
      background: "#ffffff",
      minHeight: "500px",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    blueHeroBlock: {
      background: '#3C465F',
      color: "#fff",
      minHeight: "500px",
      "& h2, h3, h4, h1, h5, h6": {
        color: "#fff !important",
      },
    },
    buttonMargin: {
      paddingTop: "25px",
      paddingBottom: "25px",
    },
    button: {
      padding: "16px 32px",
      borderRadius: "8px",
      backgroundColor: "#FF0F5F",
      boxShadow: "0px 2px 4px 0px #4040401A",
      transition: "background 0.3s",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&:hover": {
        background: "#ffffff",
      },
      "&:hover $buttonText": {
        color: "#FF0F5F",
      },
    },
    buttonText: {
      color: "#ffffff",
      transition: "color 0.3",
    },
    text: {
      flexDirection: "row",
      [theme.breakpoints.down("md")]: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
    imageHolderPaddingTextLeft: {
      padding: "15% 0% 15% 0",
      [theme.breakpoints.down("md")]: {
        padding: "15% 0 15% 0",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "4.16666666666667% 0 24px 0",
        // paddingBottom: "24px",
      },
    },

    imageHolderPaddingTextRight: {
      padding: "15% 0 15% 0%",
      [theme.breakpoints.down("md")]: {
        padding: "15% 0 15% 0",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "4.16666666666667% 0 24px 0",
        // paddingBottom: "24px",
      },
    },
    textContainer: {
      paddingRight: "16px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "0",
        maxWidth: "100%",
      },
    },
    textContainerLeftPadding: {
      paddingRight: "10.94017094017094%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0",
      },
    },
    textContainerRightPadding: {
      paddingLeft: "10.94017094017094%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0%",
      },
    },
    heroBodyContainerPadding: {
      maxWidth: "1170px",
    },
    textAlignCenter: {
      textAlign: 'center'
    },
    textAlignLeft: {
      textAlign: 'left'
    },
    textAlignRight: {
      textAlign: 'right'
    },
    headerBlockContainer: {
      color: '#fff',
      maxWidth: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      //backgroundColor: '#DBE3EF',
      backgroundSize: 'auto 103%',
      "& h2, h3, h4, h1, h5, h6, p, li": {
        color: "#fff !important",
      },
      [theme.breakpoints.down(4000)]: {
        backgroundImage: desktopImageUrl,
        height: '300px'
      },
      [theme.breakpoints.down(1280)]: {
        backgroundImage: tabletImageUrl,
        height: '300px'
      },
      [theme.breakpoints.down(600)]: {
        backgroundImage: mobileImageUrl,
        height: '250px'
      }
    },
    headerBlockVideoContainer: {
      color: '#fff',
      maxWidth: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'auto 103%',
      "& h2, h3, h4, h1, h5, h6, p, li": {
        color: "#fff !important",
      },
      [theme.breakpoints.down(4000)]: {
        height: '300px'
      },
      [theme.breakpoints.down(1280)]: {
        height: '300px'
      },
      [theme.breakpoints.down(600)]: {
        height: '250px'
      }
    },
    srcVidDesktop: {
      display: "block",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down(4000)]: {
        height: '300px',
        objectFit: "cover",
      },
      [theme.breakpoints.down(1280)]: {
        height: '100%',
        width: "100%",
        display: "none",
      },
      [theme.breakpoints.down(600)]: {
        height: '100%',
        width: "100%",
        display: "none",
      }
    },
    srcVidTablet: {
      display: "none",
      [theme.breakpoints.down(4000)]: {
        height: '300px',
        display: "none",
      },
      [theme.breakpoints.down(1280)]: {
        height: '100%',
        width: "100%",
        objectFit: "cover",
        display: "block",
      },
      [theme.breakpoints.down(600)]: {
        height: '100%',
        width: "100%",
        display: "none",
      }
    },
    srcVidMobile: {
      display: "none",
      [theme.breakpoints.down(4000)]: {
        height: '300px',
        display: "none",
      },
      [theme.breakpoints.down(1280)]: {
        height: '100%',
        width: "100%",
        display: "none",
      },
      [theme.breakpoints.down(600)]: {
        height: '100%',
        width: "100%",
        objectFit: "cover",
        display: "block",
      }
    },
    restrictedHeightText: {
      display: '-webkit-box',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '10',
    },
    restrictedHeight: {
      maxHeight: '177px',

      [theme.breakpoints.down(600)]: {
        maxHeight: '129px',
      }
    },
    restrictedHeightListItems: {
      maxHeight: '183px',
      [theme.breakpoints.down(780)]: {
        maxHeight: '177px',
      },
      [theme.breakpoints.down(600)]: {
        maxHeight: '127px',
      }
    },
    restrictedHeightNoButton: {
      maxHeight: '261px',
      [theme.breakpoints.down(600)]: {
        maxHeight: '220px',
      }
    }
  }));

  const classes = useStyles();

  return (

    isVideoProvided ?
      <Grid
        container
        item
        alignItems="center"
        justify="center"
        className={classes.headerBlockVideoContainer}
      >
        <video autoPlay muted loop playsinline className={classes.srcVidDesktop}>
          <source src={isVideoProvided} type="video/mp4" id="srcVid" />
        </video>

        <video autoPlay muted loop playsinline className={classes.srcVidTablet}>
          <source src={tabletVideo} type="video/mp4" id="srcVid1" />
        </video>

        <video autoPlay muted loop playsinline className={classes.srcVidMobile}>
          <source src={mobileVideo} type="video/mp4" id="srcVid2" />
        </video>
      </Grid>
      :

    <Grid
      container
      item
      alignItems="center"
      justify="center"
      className={classes.headerBlockContainer}
    >
      <Container>
        <Grid
          container
          item
          alignItems="center"
          justify={(alignment == "Text Right") ? 'flex-end' : ((alignment == "Text Left") ? 'flex-start' : 'center')}
          className={''}
        >
          <Grid item xs={12}>
            <Grid
              justify={(alignment == "Text Right") ? 'flex-end' : ((alignment == "Text Left") ? 'flex-start' : 'center')}
              container>
              <Grid item className={`${classes.restrictedHeightText} ${linkText ? ((_rawIntroPara && _rawIntroPara.length > 0 && _rawIntroPara.filter(e => e.listItem).length > 0) ? classes.restrictedHeightListItems : classes.restrictedHeight) : classes.restrictedHeightNoButton} ${(alignment == "Text Right") ? classes.textAlignRight : ((alignment == "Text Left") ? classes.textAlignLeft : classes.textAlignCenter)}`}>
                <BasePortableText
                  blocks={_rawIntroPara}
                  serializers={serializers}
                  {...clientConfig.sanity}
                />
              </Grid>
            </Grid>
          </Grid>
          {linkText && (
            <Grid item xs={12} className={classes.buttonMargin}>
              <Grid
                justify={(alignment == "Text Right") ? 'flex-end' : ((alignment == "Text Left") ? 'flex-start' : 'center')}
                container>
                <Grid item>
                  <ContainerForLinks
                    reference={reference}
                    _type={reference._type}
                  >
                    <Button className={classes.button}>
                      <Typography
                        variant="button"
                        className={classes.buttonText}
                      >
                        {linkText}
                      </Typography>
                    </Button>
                  </ContainerForLinks>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  );
}
