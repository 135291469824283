import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { setIsUserAuthenticated, setToken } from "../../redux/reducers/user";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation } from "@reach/router";
import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  FormControl,
  Hidden,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "../container.js";
import VisibilityOn from "./icons/VisibilityOn.svg";
import VisibilityOff from "./icons/VisibilityOff.svg";
import ErrorIcon from "./icons/error.svg";
import { getUserObject } from "../../lib/helpers";
import fullSizeForgotPassword from "./icons/reset-password.png";
import ErrorInfo from "./icons/visible-error-info.svg";
import Check from "./icons/check.svg";
import CampusCTA from "../landingPageComponents/blocks/reuseableCampusComponents/campusCTA";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      // border: "2px solid #FFD574",
      backgroundColor: "#F7F5E7",
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,

      background: "#F7F5E7",
      "& input": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#3C465F",
      },
      "& fieldset": {
        border: "none",
      },
      "&:active fieldset": {
        // background: "#FFFFFF",
      },
      "&:hover fieldset": {
        // background: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        // background: "#FFFFFF",
        boxShadow: "none",
      },
      "&.Mui-error fieldset": {
        color: "#3C465F",
      },
    },
  },
})(TextField);

const ForgotPassword = ({}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    clearErrors,
    setValue,
  } = useForm();

  const useStyles = makeStyles((theme) => ({
    formContainer: {
      paddingTop: "57px",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.up("md")]: {
        // maxWidth: "430px",
        // marginRight: "auto",
        paddingBottom: "200px",
      },
      "& p, span": {
        color: "#3C465F !important",
      },
      "& h2, h3, h4, h1, h5, h6": {
        color: "#3C465F !important",
      },
    },
    headerTextHolder: {
      flexDirection: "column",
      marginBottom: "28px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    headerTextHolderEmailSent: {
      flexDirection: "column",
      textAlign: "center",
      marginBottom: "28px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    label: {
      color: "#3C465F",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    containerForPassword: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "column",
    },
    inputField: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "8px",
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: "4px",
      },
      "& input": {
        color: "#3C465F",
        WebkitBoxShadow: "0 0 0 30px #f7f5e7 inset !important",
        backgroundColor: "#F7F5E7",
        WebkitTextFillColor: "#3C465F",
      },
    },
    textSpan: {
      marginTop: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#3C465F",
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    loginButton: {
      height: "56px",
      background: "#FF0F5F",
      border: "2px solid #FF0F5F",
      width: "100%",
      color: "#ffffff",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      marginTop: "24px",
      textTransform: "uppercase",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    formHolder: {
      width: "100%",
    },
    title: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      [theme.breakpoints.up("md")]: {
        fontSize: "32px",
        lineHeight: "40px",
      },
    },
    passwordRecovery: {
      fontFamily: "Poppins",
      textDecoration: "none",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "uppercase",
      color: "#3C465F",
      cursor: "pointer",
    },
    passwordRecoveryContainer: {
      marginTop: "16px",
    },
    errorMessage: {
      color: "#C94277",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      alignSelf: "flex-end",
    },
    bodyContainer: {
      maxWidth: "1440px",
      display: "flex",
      justifyContent: "space-between",
    },
    imageNoPaddingBackground: {
      //here
      [theme.breakpoints.up("md")]: {
        minHeight: "665px",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${fullSizeForgotPassword})`,
        backgroundSize: "contain",
      },
    },
    subFormContainer: {
      maxWidth: "100%",
      paddingLeft: "calc(16.66666666666667% + 15px)",
      paddingRight: "8.8888888888889%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
      },
    },
    errorInputField: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 0,

        background: "#F7F5E7",
        "& input": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#3C465F",
        },
        "& fieldset": {
          border: "none",
        },
        "&:active fieldset": {
          // background: "#FFFFFF",
        },
        "&:hover fieldset": {
          // background: "#FFFFFF",
        },
        "&.Mui-focused fieldset": {
          // background: "#FFFFFF",
          boxShadow: "none",
        },
        "&.Mui-error fieldset": {
          color: "#3C465F",
        },
      },
    },
    errorLabel: {
      color: "#FF0F5F !important",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "12px",
      display: "inline-block",
      verticalAlign: "middle",
      marginLeft: ".5em",
      maxWidth: "90%",
    },
    errorImage: {
      display: "inline",
      verticalAlign: "middle",
    },
    errorContainer: {
      background: "#ffffff",
      padding: ".3em .3em .3em 0px",
    },
    checkImageContainer: {
      textAlign: "center",
      marginBottom: "2em",
    },
    buttonCTAHolder: {
      marginTop: "24px",
    },
  }));

  const classes = useStyles();
  const [showEmailSent, setShowEmailSent] = useState(false);

  var onSubmit = async function (data) {
    var result = await sendForgotPasswordEmail(data.Email);

    if (result) {
      setShowEmailSent(true);
    }
  };

  var sendForgotPasswordEmail = async function (email) {
    var result = await fetch(
      process.env.GATSBY_QUESTIONAPI + "/ForgotPassword/Reset",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(email),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      });

    if (result.Success && result.Result) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid container item alignItems={"center"} justify={"space-between"}>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={11} md={10} className={classes.bodyContainer}>
        <Grid container xs={12} md={6} className={classes.formContainer}>
          {showEmailSent && (
            <Grid className={classes.subFormContainer}>
              <Grid container alignItems="center" justify="center">
                <Grid item className={classes.checkImageContainer}>
                  <img src={Check} className={classes.checkImage} />
                </Grid>
                <Grid item className={classes.headerTextHolderEmailSent}>
                  <Typography variant="h4" className={classes.title}>
                    {
                      "We’ve sent you an email with a link to reset your password."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!showEmailSent && (
            <Grid className={classes.subFormContainer}>
              <Grid container item className={classes.headerTextHolder}>
                <Typography variant="h4" className={classes.title}>
                  {"Forgotten your password?"}
                </Typography>
                <span className={classes.textSpan}>
                  Don’t worry, it happens to us all. We'll email you easy
                  instructions on how to reset it.
                </span>
              </Grid>
              <form className={classes.formHolder}>
                <div>
                  <Grid>
                    <label className={classes.label}>Email</label>
                    <Controller
                      name="Email"
                      control={control}
                      rules={{
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          autoFocus
                          {...field}
                          variant="outlined"
                          fullWidth
                          className={
                            errors.Email?.type === "required" ||
                            errors.Email?.type === "pattern"
                              ? classes.errorInputField
                              : classes.inputField
                          }
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    {(errors.Email?.type === "required" && (
                      <div className={classes.errorContainer}>
                        {" "}
                        <img src={ErrorInfo} className={classes.errorImage} />
                        <label className={classes.errorLabel}>
                          {"Email is required"}
                        </label>
                      </div>
                    )) ||
                      (errors.Email?.type === "pattern" && (
                        <div className={classes.errorContainer}>
                          <img src={ErrorInfo} className={classes.errorImage} />
                          <label className={classes.errorLabel}>
                            {"Please input a valid email address"}
                          </label>
                        </div>
                      ))}
                  </Grid>

                  {/* For future use: */}
                  {/*<Grid className={classes.passwordRecoveryContainer}>*/}
                  {/*  <a className={classes.passwordRecovery} src="#">Recover Password</a>*/}
                  {/*</Grid>*/}
                  <div className={classes.buttonCTAHolder}>
                    <CampusCTA
                      customClickEvent={handleSubmit(onSubmit)}
                      pageType={"available courses"}
                      linkText={"SEND EMAIL"}
                      buttonType={"rectangular"}
                      arrowIcon={false}
                      newTab={false}
                      autoHeight={false}
                    />
                  </div>
                </div>
              </form>
            </Grid>
          )}
        </Grid>
        <Hidden mdDown>
          <Grid
            container
            item
            xs={0}
            sm={0}
            md={6}
            className={classes.imageNoPaddingBackground}
          ></Grid>
        </Hidden>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
    </Grid>
  );
};

export default ForgotPassword;
