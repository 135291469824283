import React, { useRef, useState, useEffect } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { createClient } from "../../../lib/helpers";
import Container from "../../../components/container";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import * as styles from "../adviserList.module.css";

export default function Calculator(props) {

  const { href, isCoursePage } = props;
  const useStyles = makeStyles((theme) => ({
    calculatorContainer: {
      // marginTop: "15px",
      // paddingBottom: "30px",
      // marginTop: "35px",
      width: "100%",
      textAlign: "center",
    },

  }));

  const classes = useStyles();
  const [iframeHeight, setIframeHeight] = useState(0);
  const [habemusIframeMessage, setHabemusIframeMessage] = useState(false);

  useEffect(() => {
    (() => {

      if (!href.includes("calc")) {
        window.addEventListener('message', function (e) {
          var data;
          try {
            data = JSON.parse(e.data);
          } catch (e) {
            return false;
          }

          if (data.context !== 'iframe.resize') {
            return false;
          }

          var iframe = document.querySelector('iframe[src="' + href + '"]');

          if (!iframe) {
            return false;
          }

          setHabemusIframeMessage(true);

          setIframeHeight(data.height);

          iframe.height = data.height;

          return;
        });
      }
      
    })();
  });

  if (href.includes("calc")) {
    //console.log("calc")
    return (
      <>
        <Grid className={''}>
          <Container>
            <div className={classes.calculatorContainer}>
              <div className={styles.filtersContainerCalc}>
                <IframeResizer src={href} style={{ minWidth: '100%', border: '0px' }} />
              </div>
            </div>
          </Container>
        </Grid>
      </>
    )
  }
  else {
    //console.log("other")
    return (
      <>
        <Grid className={''}>
          <Container>
            <div className={classes.calculatorContainer}>
              <div className={isCoursePage ? styles.courseContainerCalc : styles.filtersContainerCalc}>
                <IframeResizer
                  heightCalculationMethod="max"
                  src={href}
                  style={{ minWidth: '100%', border: '0px', height: habemusIframeMessage ? iframeHeight : 'initial' }} />
              </div>
            </div>
          </Container>
        </Grid>
      </>
    )
  }


}

