import React from "react";
import { Typography, Button, Menu, MenuItem, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PageProps } from "gatsby";
import bmlogo2 from "./icons/bmlogo2.jpg";
import LiLogo from "./icons/LinkedIN.svg";

interface LinkedInProps {
  postContent: string;
  URLofPost: string;
}

const LinkedInCard = ({ postContent, URLofPost }: LinkedInProps) => {
  const useStyles = makeStyles((theme) => {
    return {
      card: {
        [theme.breakpoints.down("md")]: {
          padding: "8px",
        },
        [theme.breakpoints.up("md")]: {
          padding: "12px",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "15px",
        },
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      },
      postContainer: {
        backgroundColor: "#ffffff",
        fontFamily: `"Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif`,
        fontSize: "12px",
        height: "100%",
        width: "100%",
        lineHeight: "16px",
        borderColor: "#eee #ddd #bbb",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
        padding: "12px 16px 12px 16px",
        "&:hover": {
          boxShadow: "0px 10px 30px 1.6px #4646462b",
          filter: "none",
        },
        "& a": {
          color: "inherit",
          fontWeight: "normal",
          textDecoration: "none",
          outline: "0 none",
        },
        "& a:hover, a:focus": {
          textDecoration: "underline",
        },
      },
      linkedInIconContainer: {
        alignSelf: "flex-start",
        display: "flex",
        [theme.breakpoints.between("sm", "md")]:{
          justifyContent: "flex-end",
        },
      },
      linkedInIcon: {
        // height: "38.30835px",
        // width: "32.66665px",
        // height: "24px",
        // width: "24px",
        alignSelf: "flex-start",
      },
      bmLogo: {
        height: "48px",
        width: "48px",
        borderRadius: "20px",
      },
      accountName: {
        display: "contents",
        "& a": {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 700,
        },
      },
      postLink: {
        color: "rgb(29, 155, 240) !important",
        wordBreak: "break-all",
      },
      postContent: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        wordBreak: "break-word",
      },
      spacer: {
        height: "100%",
        fontSize: "15px",
        lineHeight: "20px",
        fontWeight: 400,
        visibility: "hidden",
      },
      accountDetailsHolder: {
        flexGrow: 1,
        maxWidth: "88.33%",
        width: "100%",
        "@media (max-width: 360px)":{
          paddingLeft: "10px",
        },
        [theme.breakpoints.between("sm", "md")]:{
          maxWidth: "inherit",
        },
      },
      detailsContainer: {
        flexWrap: "nowrap",
      },
      bmLogoHolder: {
        "@media (min-width: 0px) and (max-width: 360px)": {
          maxWidth: "inherit",
        },
      },
      accountFollowers: {
        display: "contents",
        "& a": {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 400,
        },
      },
    };
  });

  const classes = useStyles();

  const postLink = `https://www.linkedin.com/posts/${URLofPost}`;
  return (
    <Grid className={classes.card} xs={12} md={4}>
      <a href={postLink}>
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          className={classes.postContainer}
        >
          <Grid item xs={12}>
            <a href={postLink}>
              <Grid container alignItems="center">
                <Grid item xs={2} sm={1} md={3} lg={2}>
                  <img className={classes.bmLogo} src={bmlogo2} />
                </Grid>
                <Grid
                  item
                  container
                  className={classes.accountDetailsHolder}
                  xs={9}
                  sm={11}
                  md={9}
                  lg={10}
                  direction="row"
                >
                  <Grid
                    item
                    container
                    direction="column"
                    xs={10}
                    className={classes.detailsContainer}
                  >
                    <Grid xs={6} item className={classes.accountName}>
                      <a href="https://www.linkedin.com/company/boring-money">
                        Boring Money
                      </a>
                    </Grid>
                    <Grid xs={6} item className={classes.accountFollowers}>
                      <a href="https://www.linkedin.com/company/boring-money">2,193 followers</a>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} className={classes.linkedInIconContainer}>
                    <img
                      className={classes.linkedInIcon}
                      src={LiLogo}
                      alt="Linkedin logo"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </a>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.postContent}>{postContent}</p>
          </Grid>
        </Grid>
      </a>
    </Grid>
  );
};

export default LinkedInCard;
