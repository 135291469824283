import React, { useState, useEffect, useRef } from "react";
import PortableText from "../../../portableText";
import { makeStyles } from "@material-ui/core/styles";
import { buildImageObj } from "../../../../lib/helpers";
import { imageUrlFor } from "../../../../lib/image-url";
import {
  Hidden,
} from "@material-ui/core";

import serializers from "../../../serializers";
import { ResizeObserver } from "resize-observer";

const DesktopMainText = ({ text, image, imagePosition, fullSizeImage }) => {
  const [containerDivVisible, setContainerDiv] = useState();
  const [floatElementVisible, setFloatElement] = useState();
  const containerDiv = useRef(null);
  const floatElement = useRef(null);

  useEffect(() => {
    if (!containerDivVisible) {
      return;
    }

    if (!floatElementVisible) {
      return;
    }

    if (containerDiv.current != null && floatElement.current != null) {
      new ResizeObserver(setFloatElementHeight).observe(document.body);

      setFloatElementHeight();
    }

  }, [containerDivVisible, floatElementVisible]);

  const setFloatElementHeight = function () {
    if (!(imagePosition === 'Bottom left' || imagePosition === 'Bottom right')) {
      return;
    }

    floatElement.current.style["shape-outside"] = 'inset(calc(100% - ' + (Math.ceil(containerDiv.current.clientWidth / 3) + 30).toString() + 'px) 0 0)';
    floatElement.current.style['height'] = (containerDiv.current.clientHeight - 10).toString() + 'px';
  }


  var imagePadding = '1.5em';

  const useStyles = makeStyles((theme) => ({
    desktopImage: {
      borderRadius: '12px',
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    containerDiv: {
      cursor: 'initial',
    },
    wrapper: {
      height: '100%',
      overflowY: 'auto',
      paddingRight: '12px',
      '&::-webkit-scrollbar': {
        width: '6px',
        borderRadius: '30px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(148, 164, 214, .4)',
        opacity: '0.4',
        width: '4px',
        borderRadius: '30px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(148, 164, 214, 1)',
        boxShadow: '0px 3px 4px rgba(111, 116, 126, 0.16)',
        borderRadius: '30px',
        width: '6px'
      },
      scrollbarWidth: 'thin',
      scrollbarColor: 'rgba(148, 164, 214, 1) rgba(148, 164, 214, .4)'
    },
    outerWrapper: {
      background: '#FFFFFF',
      border: '1px solid #DBE3EF',
      boxShadow: '0px 2px 4px rgb(64 64 64 / 10%)',
      borderRadius: '12px',
      height: '100%',
      padding: '24px 12px 24px 24px'
    },
    outerWrapperFullSizeImg: {
      borderRadius: '12px',
      height: '100%',
      width: "100%",
      display: "flex",
    },
    floatRight: {
      float: 'right',
      marginLeft: "1.5em",
    },
    floatLeft: {
      float: 'left',
    },
    float: {
      display: 'flex',
      alignItems: 'flex-end',
      width: '33%',

    },
    borderRadius: {
      borderRadius: '9.55224px',
    },
    shapedOutside: {
    },
    marginBottomRight: {
      marginBottom: imagePadding,
      marginRight: imagePadding
    },
    marginBottomLeft: {
      marginLeft: imagePadding,
      marginBottom: imagePadding,
    },
    marginRight: {
      marginRight: imagePadding,
    },
    marginLeft: {
      marginLeft: imagePadding
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '36px',
      marginBottom: '1em',
      display: 'block',
      color: '#262641'
    },
    mobileCrop: {
      maxWidth: '100%',
      objectFit: 'crop'
    },
    marginBottom: {
      marginBottom: '1em'
    },
    textContainer: {
      '& p:last-child': {
        paddingBottom: '0 !important'
      }
    },
  fullSizeImg:{

    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]:{
      width: "100%",
      objectFit: "contain",
    },
  },
  }));

  var getImage = function (marginBottom) {
    return (<img className={`${classes.mobileCrop} ${classes.borderRadius} ${marginBottom ? classes.marginBottom : ''}`} src={imageUrlFor(buildImageObj(image))
      .width(600)
      .height(337)
      .fit("crop")
      .url()}
      alt="" />);
  }

  var img = null;
  if (typeof image !== 'undefined') {
    img = imageUrlFor(buildImageObj(image))
  }

  var fullSizeImg = null;

  if (typeof fullSizeImage !== 'undefined') {
    fullSizeImg = imageUrlFor(buildImageObj(fullSizeImage))
  }

  const classes = useStyles();

  return (
    <>
      {
        <div className={fullSizeImage && fullSizeImage.asset && fullSizeImage.asset ? classes.outerWrapperFullSizeImg : classes.outerWrapper} >
          {fullSizeImage && fullSizeImage.asset && fullSizeImage.asset ? <img className={classes.fullSizeImg} src={fullSizeImg.url()}/> :
          <div className={classes.wrapper}>
            <div>
              <Hidden only={['xs']} implementation="js">
                <div ref={el => {
                  floatElement.current = el;
                  setFloatElement(!!el);
                }}
                     className={`${classes.float} ${classes.borderRadius} ${imagePosition === 'Top left' || imagePosition === 'Bottom left' ? classes.floatLeft : classes.floatRight} ${imagePosition === 'Bottom left' || imagePosition === 'Bottom right' ? classes.shapedOutside : ''} ${imagePosition === 'Top left' ? classes.marginBottomRight : ''} ${imagePosition === 'Top right' ? classes.marginBottomLeft : ''} ${imagePosition === 'Bottom left' ? classes.marginRight : ''} ${imagePosition === 'Bottom right' ? classes.marginLeft : ''}`}>
                  {img && <img className={classes.desktopImage} src={img
                    .width(300)
                    .height(300)
                    .fit("crop")
                    .url()}
                               alt="" />
                  }
                </div>
                <div ref={el => {
                  containerDiv.current = el;
                  setContainerDiv(!!el);
                }} className={classes.textContainer}>
                  <PortableText serializers={serializers} blocks={text} />
                </div>
              </Hidden>
              <Hidden only={['sm', 'md', 'xl', 'lg']} implementation="js">
                {(imagePosition === 'Top left' || imagePosition === 'Top right') && getImage('marginBottom')}

                <PortableText serializers={serializers} blocks={text} />
                {(imagePosition === 'Bottom left' || imagePosition === 'Bottom right') && getImage()}
              </Hidden>
            </div>
          </div>
        }
        </div>

      }
    </>
  );
}


export default DesktopMainText;

