import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { imageUrlFor } from "../../../lib/image-url";
import { buildImageObj } from "../../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  logoImage: {
    verticalAlign: 'middle',
    marginRight: '.9em',
    marginLeft: '.5em',
    [theme.breakpoints.down("sm")]: {
      float: 'right',
      marginRight: '0'
    },
    width: '117px',
    height: '26px'
  },
  logoImageThreeXThree: {
    verticalAlign: 'middle',
    width: '117px',
    height: '26px',
    [theme.breakpoints.down("sm")]: {
      float: 'right',
    },
  },
}));

export default function BestBuysAwardImage(props) {
  const classes = useStyles();
  const { bestBuyAward, awardURL } = props;
  if (!bestBuyAward || bestBuyAward.length == 0 || !bestBuyAward[0].bestBuyCompareTableLogo) {
    return (<></>)
  }
  else {
    //console.log("bestBuyAward Image", awardURL)
    return (
      <a href={awardURL} target="_blank">
      <img
        src={imageUrlFor(buildImageObj(bestBuyAward[0].bestBuyCompareTableLogo))
          .width(310)
          .height(68)
          .fit("crop")
          .auto("format")
          .url()}
        alt={''}
        className={props.isForThreeXThree?classes.logoImageThreeXThree :classes.logoImage}
        />
      </a>
    );
  }
}
