import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import BestBuysImage from "./components/bestBuysImage";
import BestBuysInANutshell from "./components/bestBuysInANuthshell";
import BestBuysRatings from "./components/bestBuysRatings";
import BestBuysButtons from "./components/bestBuysButtons";
import MobileBestBuysCost from "./components/mobileBestBuyCost";
import BestBuysAwardImage from "./components/bestBuysAwardImage";
import { AddTrailingSlashToUrl } from "../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  providerHeaderContainer: {
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    padding: "8px 16px",
    position: "relative",
    borderBottom: "1px solid #E6E8F0",
  },
  companyDisplayName: {
    color: "#3C465F",
    fontWeight: "600",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",

    width: "auto",
  },
  providerContainer: {
    background: "white",
    borderRadius: "0px 0px 8px 8px",
    maxWidth: "100%",
    margin: "0",
    overflow: "hidden",
    position: "relative",
    paddingTop: '16px'
  },
  logoAndScoreContainer: {
    paddingBottom: "20px",
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  cardContainer: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    marginBottom: "24px",

  },
  companyHighlight: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    color: "#2F2F4E",
    overflow: "hidden",
  },
  reviewAnchor: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  ratingsPullRight: {
    marginRight: '-1em'
  },
  cardContainerNoMarginBottom: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
  }
}));

export default function BestBuysTableRowMobile(props) {
  const {
    bestBuyLink,
    companyDisplayName,
    companyHighlight,
    inANutshell,
    isAffiliate,
    ourRating,
    yourRating,
    companyBBCats,
    reviewLink,
    dashboardId,
    companyLogo,
    bestBuyAward,
    defaultLayout,
    awardURL
  } = props.company;

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'best_buy_card_clicked_value': JSON.stringify(googleAnalyticsObject) });

          window.dataLayer.push({ event: 'best_buy_card_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  const classes = useStyles();
  var linkHref = "#";
  var linkHrefReview = "#";

  if (bestBuyLink != null) {
    linkHref = bestBuyLink.url;
  }
  if (reviewLink != null && reviewLink.length > 0) {
    linkHrefReview =
      reviewLink[0].subfolder[0]["folder"] + reviewLink[0]["slug"];
  }

  const materialUiScoreOur = ourRating / 2;
  const materialUiScoreYour = yourRating / 2;
  const smallDistanceStyle = {};

  var companyNameClicked = function () {
    setGoogleAnalyticsObject({
      ProviderName: companyDisplayName,
      Source: 'provider name'
    });
  }

  return (
    <Grid className={`${(props.defaultLayout == null || props.defaultLayout == "1x1") ? classes.cardContainer : classes.cardContainerNoMarginBottom}`}>
      <div className={classes.providerHeaderContainer}>
        <a href={AddTrailingSlashToUrl(linkHrefReview)} className={classes.reviewAnchor} onClick={() => { companyNameClicked() }}> <span variant="body1" className={classes.companyDisplayName}>
          {companyDisplayName}
        </span></a>{" "}
        <BestBuysAwardImage isForThreeXThree={true} bestBuyAward={bestBuyAward} awardURL={AddTrailingSlashToUrl(awardURL)} />

        {/* <span variant="body1" className={classes.companyHighlight}>{companyHighlight}</span> */}
      </div>
      <div className={classes.providerContainer}>
        <Grid container className={classes.logoAndScoreContainer}>
          <Grid item xs={5}>
            <BestBuysImage
              bestBuyLogo={companyLogo}
              companyDisplayName={companyDisplayName}
              linkHrefReview={AddTrailingSlashToUrl(linkHrefReview)}
            />
          </Grid>
          <Grid item xs={7}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item className={classes.ratingsPullRight}>
                <BestBuysRatings
                  materialUiScoreOur={materialUiScoreOur}
                  smallDistanceStyle={smallDistanceStyle}
                  materialUiScoreYour={materialUiScoreYour}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BestBuysInANutshell inANutshell={inANutshell} defaultLayout={props.defaultLayout} />
        {props.costInBBTable == true && (
          <MobileBestBuysCost
            key={props.company}
            platformType={props.company.platformType}
            type={props.type}
            cost={props.company.cost}
            defaultLayout={props.defaultLayout}
          />
        )}
        <BestBuysButtons
          linkHrefReview={AddTrailingSlashToUrl(linkHrefReview)}
          linkHref={linkHref}
          isAffiliate={isAffiliate}
          isForThreeXThree={true}
          companyDisplayName={companyDisplayName}
        />
      </div>
    </Grid>
  );
}
