import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../../../client-config";
import serializers from "../../../../serializers";
import inheritableColorSerializersForBlueBackground from "../../inheritableColorSerializersForBlueBackground";
import { Grid, Typography } from "@material-ui/core";
import ContainerForLinks from "../../../../containerForLinks";

import { createClient, replacePlaceholders } from "../../../../../lib/helpers";

function PrimaryCard(props) {
  return (
    <Grid item sm={12} xs={12} md={6} className={props.classes.card}>
      <Grid className={props.classes.innerCard}>
        <ContainerForLinks
          customClassName={props.classes.primaryCardTitle}
          brazeRedirectUrl={
            props.blockType === "contentCardBigBlockGroup"
              ? props.brazeRedirectUrl
              : null
          }
          reference={props.reference}
          _type={props.reference && props.type}
        >
          <Grid className={props.classes.imageHolder}>
            {props.blockType === "contentCardBigBlockGroup" ?
              <img className={props.classes.brazeImg} src={props.brazeImgUrl} alt={""}  />
            :
              <BasePortableText
                blocks={props.blocks}
                serializers={serializers}
                {...clientConfig.sanity}
              ></BasePortableText>
            }
          </Grid>

          <Grid>
            <div className={props.classes.primaryTitleContainer}>
              <Typography
                variant="h4"
                className={`${props.classes.titleTextPrimary} ${props.clampedTitle}`}
              >
                {props.title}
              </Typography>
              <Grid
                item
                className={`${props.classes.blurbTextPrimary} ${props.clampedPara}`}
              >
                {props.blockType === "contentCardBigBlockGroup" ? (
                  <p className={props.classes.brazeDescPrimaryText}>{props.brazeDescription}</p>
                ) : (
                  <BasePortableText
                    blocks={props.blocks1}
                    serializers={serializers}
                    {...clientConfig.sanity}
                  />
                )}
              </Grid>
            </div>
          </Grid>
        </ContainerForLinks>
      </Grid>
    </Grid>
  );
}

function SecondaryCard(props) {
  return (
    <Grid
      container
      xs={12}
      md={12}
      className={props.classes.smallCard}
      spacing={2}
      direction="row"
    >
      <Grid item xs={6} className={props.classes.imgContainer}>
        <ContainerForLinks
          reference={props.reference}
          _type={props.reference && props.type}
          brazeRedirectUrl={props.blockType === "contentCardBigBlockGroup" ? props.brazeRedirectUrl : null}
        >
          <Grid className={props.classes.smallImageHolder}>
            {props.blockType === "contentCardBigBlockGroup" ? (
              <img className={props.classes.brazeImg} alt="" src={props.brazeImgUrl} />
            ) : (
              <BasePortableText
                blocks={props.blocks}
                serializers={serializers}
                {...clientConfig.sanity}
              ></BasePortableText>
            )}
          </Grid>
        </ContainerForLinks>
      </Grid>
      <Grid container item xs={6} className={props.classes.textContainer}>
        <Grid container direction="row">
          <Grid item className={props.classes.titleContainer}>
            <ContainerForLinks
              brazeRedirectUrl={
                props.blockType === "contentCardBigBlockGroup"
                  ? props.brazeRedirectUrl
                  : null
              }
              reference={props.reference}
              customClassName={props.classes.title}
              _type={props.reference && props.type}
            >
              <Typography
                variant={"h4"}
                className={`${
                  props.styles === "Blue"
                    ? props.classes.titleWhite
                    : props.classes.title
                } ${props.clampedTitle}`}
              >
                {props.title}
              </Typography>
            </ContainerForLinks>
          </Grid>
          <Grid
            item
            className={`${props.classes.blurbText} ${props.clampedPara}`}
          >
            {props.blockType === "contentCardBigBlockGroup" ?
              <p className={props.classes.brazeDescText}>{props.brazeDescription}</p>
            :
              <BasePortableText
                blocks={props.blocks1}
                serializers={
                  props.styles === "Blue"
                    ? inheritableColorSerializersForBlueBackground
                    : serializers
                }
                {...clientConfig.sanity}
              />
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const LeftOneBigByRightTwoSmall = ({
  primaryCard,
  title,
  linkText,
  rawIntroPara,
  reference,
  mainImageWithType,
  equaliseHeight,
  clampClasses,
  type,
  styles,
  firstname,
  brazeCards,
  brazeTitle,
  brazeImgUrl,
  brazeDescription,
  brazeRedirectUrl,
  ctaLink,
  hasError,
}) => {
  const useStyles = makeStyles((theme) => ({
    cardList: {
      backgroundColor: "#ffffff",
      width: "100%",
    },
    card: {
      position: "relative",
      padding: "0",
      "&:hover $title": {
        color: "#8C8CA1",
      },
      "&:hover $svg": {
        stroke: "#E60E56",
      },
    },
    innerCard: {
      height: "326px",
      borderRadius: "8px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      backgroundColor: "#ffffff",
      position: "relative",
      filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04))",
      boxShadow: "0px 1.15476px 2.30951px rgba(0, 0, 0, 0.04)",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    normalHeight: {
      height: "100%",
    },
    singleImageHeight: {
      height: "initial",
    },
    title: {
      fontWeight: "700",
      paddingBottom: "8px",
      color: "#262641",
      "& a": {
        color: "#262641",
      },
    },
    buttonTextWrapper: {
      color: "#262641",
      background: "#EAF0F6",
      border: "0.5px solid rgba(0, 29, 132, 0.18)",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
      borderRadius: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      display: "block",
      letterSpacing: 0,
    },
    buttonHolder: {
      alignItems: "center",
      position: "relative",
      bottom: "66px",
      left: "16px",
      right: 0,
      textAlign: "center",
      width: "calc(100% - 32px)",
    },
    imageHolder: {
      height: "100%",
      maxWidth: "100%",
      "& figure": {
        height: "inherit",
        "& img": {
          objectFit: "cover",
          height: "100%",
          borderRadius: "8px",
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
        },
      },
    },
    textHolder: {
      padding: "16px 16px 8px 16px",
      marginBottom: ".6em",
    },
    cardsContainer: {
      paddingTop: "33px",
      paddingBottom: "48px",
    },
    buttonText: {
      display: "inline-block",
      verticalAlign: "middle",
      textTransform: "uppercase",
      fontSize: "14px",
    },
    innerCardHover: {
      boxShadow: "0px 10px 30px 1.6px #4646462b",
      filter: "none",
    },
    bigCardLinkContainer: {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #FFFFFF",
      opacity: "0.95",
      backdropFilter: "blur(10px)",
    },
    titleText: {
      position: "absolute",
      bottom: "0",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #FFFFFF",
      opacity: "0.95",
      backdropFilter: "blur(10px)",
      width: "100%",
      fontSize: "18px",
      lineHeight: "27px",
      fontWeight: "bold",
      padding: "1rem",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "24px",
        fontSize: "16px",
      },
    },
    titleTextPrimary: {
      color: "#262641;",
      fontSize: "18px",
      lineHeight: "27px",
      fontWeight: "bold",
      marginBottom: "8px",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "24px",
        fontSize: "16px",
        background: "#FFFFFF",
      },
    },
    smallCard: {
      height: "fit-content",
      width: "100%",
      textDecoration: "none",
      flexShrink: "0",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
    primaryCardTitle: {
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "24px",
      textDecoration: "none",
      color: "#262641",
    },
    smallImageHolder: {
      maxWidth: "263px",
      "& figure": {
        "& img": {
          borderRadius: "8px",
        },
      },
      "@media (min-width:600px) and (max-width: 900px)": {
        paddingRight: "12px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: "auto",
        maxWidth: "none",
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#262641",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
      },
    },
    titleWhite: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
      },
    },
    blurbText: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "normal",
      height: "100%",
      "& p": {
        fontSize: "14px",
        lineHeight: "20px",
        [theme.breakpoints.down("lg")]: {
          padding: "0 !important",
        },
      },
    },
    blurbTextPrimary: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "normal",
      height: "100%",
      "& p": {
        fontSize: "14px",
        lineHeight: "20px",
        marginBottom: "0 !important",
        [theme.breakpoints.down("lg")]: {
          padding: "0 !important",
        },
      },
    },
    textContainer: {
      "@media (min-width: 960px) and (max-width: 1400px)": {
        paddingLeft: "16px",
      },
      "@media (min-width: 400px) and (max-width: 599px)": {
        flexGrow: "1 !important",
        paddingLeft: "16px",
        maxWidth: "100% !important",
      },
    },
    imgContainer: {
      "@media (min-width: 400px) and (max-width: 599px)": {
        flexBasis: "auto",
      },
    },
    primaryTitleContainer: {
      position: "absolute",
      bottom: "0",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #FFFFFF",
      opacity: "0.9",
      width: "100%",
      padding: "1rem",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      "@supports (-webkit-mask-box-image: none)": {
        backdropFilter: "blur(10px)",
      },
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: {
      height: "fit-content",
      flexShrink: 1,
      paddingBottom: "12px",
    },
    brazeImg:{
      width: "100%",
      height: "100%",
      borderRadius: "8px",
    },
    brazeDescPrimaryText: {
      margin: "0",
      color: "#2F2F4E",
    },
    brazeDescText: {
      color: styles === "Blue" ? "#FFFFFF" : "#2F2F4E",
      margin: "0",
    },
  }));

  const classes = useStyles();



  const replacedObject = replacePlaceholders(
    rawIntroPara,
    process.env.REPLACETXT_FIRSTNAME,
    firstname
  );

  return (
    <>
      {primaryCard ? (
        //   Primary card: Show Braze Content Card or CMS card if no brazeCards
        type === "contentCardBigBlockGroup" &&
        !hasError &&
        brazeCards.length !== undefined ? (
          brazeTitle || brazeTitle || brazeImgUrl || brazeDescription ? (
            <PrimaryCard
              key={title}
              classes={classes}
              blockType={type}
              blocks={mainImageWithType}
              clampedTitle={clampClasses.clampedTitle}
              title={brazeTitle}
              brazeImgUrl={brazeImgUrl}
              clampedPara={clampClasses.clampedPara}
              brazeDescription={brazeDescription}
              brazeRedirectUrl={brazeRedirectUrl}
            />
          ) : null
        ) : (
          <PrimaryCard
            key={title}
            classes={classes}
            reference={reference}
            type={reference?._type}
            blocks={mainImageWithType}
            clampedTitle={clampClasses.clampedTitle}
            title={title}
            clampedPara={clampClasses.clampedPara}
            blocks1={replacedObject}
          />
        )
      ) : //   Secondary cards: Show Braze Content Cards or CMS cards if no brazeCards
      type === "contentCardBigBlockGroup" &&
        !hasError &&
        brazeCards.length !== undefined ? (
        brazeTitle || brazeTitle || brazeImgUrl || brazeDescription ? (
          <SecondaryCard
            classes={classes}
            blocks={mainImageWithType}
            styles={styles}
            clampedTitle={clampClasses.clampedTitle}
            title={brazeTitle}
            clampedPara={clampClasses.clampedPara}
            blocks1={replacedObject}
            brazeImgUrl={brazeImgUrl}
            brazeDescription={brazeDescription}
            brazeRedirectUrl={brazeRedirectUrl}
            blockType={type}
          />
        ) : null
      ) : (
        <SecondaryCard
          classes={classes}
          reference={reference}
          type={reference?._type}
          blocks={mainImageWithType}
          styles={styles}
          clampedTitle={clampClasses.clampedTitle}
          title={title}
          clampedPara={clampClasses.clampedPara}
          blocks1={replacedObject}
        />
      )}
    </>
  );
};

export default LeftOneBigByRightTwoSmall;
