import React, { createContext, useContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

const ApiContext = createContext();


export function ApiProvider({ children }) {
  const [apiData, setApiData] = useState(null);

  var tok = null;
  var tokenData = null;
  var url = 'https://data.boringmoney.co.uk/api/firstname/';

  if (typeof window !== 'undefined') {

    tok = localStorage.getItem('id_token');
  
  }
  if (tok) {
    tokenData = jwtDecode(tok);

    url += tokenData.BrazeID;
  }

  // Function to fetch and store API data
  const fetchData = async () => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setApiData(data);


        sessionStorage.setItem('profile2nd', btoa(data));

      } else {
        console.error('Failed to fetch API data');
      }
    } catch (error) {
      console.error('Error fetching API data:', error);
    }
  };

  useEffect(() => {

    var nm = sessionStorage.getItem('profile2nd');

    if (nm!=null) {
      nm = atob(nm);
    }

    if ((nm==null)||(nm=="")||(typeof nm=="undefined")) {
      if (!apiData) {
        if (tokenData && tokenData.BrazeID) {
          fetchData();
        }
      }
    }
    else {
      setApiData(nm)
    }
        
  }, [apiData]);

  return (
    <ApiContext.Provider value={apiData}>
      {children}
    </ApiContext.Provider>
  );
}

export function useApiData() {
  return useContext(ApiContext);
}
