import * as React from "react";
import { Dispatch, SetStateAction} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
// @ts-ignore
import CheckmarkGrey from "../../../icon/CheckmarkGrey.svg";
// @ts-ignore
import TimerGrey from "../../../icon/TimerGrey.svg";
// @ts-ignore
import LessonGrey from "../../../icon/LessonGrey.svg"
import { useEffect, useState } from "react";
import lesson from "../../../coursePageComponents/lesson";

type StatusBarProps = {
  colour: string;
  completed: boolean;
  status: string;
  id: string;
  userId?: string;
  token?: string;
  userAuthenticated?: boolean;
  setCardCourses: Dispatch<SetStateAction<any>>;
};

type CourseDetailProps ={
  CourseId: string;
  LessonCount: number;
  Status?: string;
  TimeToComplete: string;
};

type CourseLessonsStatusProps ={
  LessonId: string;
  Status: string;
}[];

const CourseStatusBar = ({ colour, status, id, userId, token, userAuthenticated, setCardCourses }: StatusBarProps): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    parentBar: {
      width: "100%",
      height: "24px",
      backgroundColor: "#ffffff",
      position: "relative",
    },
    container: {
      padding: "16px 24px 16px 24px",
      [theme.breakpoints.down("md")]: {
        padding: "16px",
      },
    },
    progressTextPercentageHolder: {
      position: "absolute",
      top: 0,
      height: "24px",
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      overflow: "hidden"
    },
    checkmarkIcon: {
      height: "20px",
      width: "20px",
      marginRight: "6px",
    },
    lessonTimeContainer: {
      padding: "16px",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.up(1280)]: {
        padding: "16px 24px 16px 24px",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "normal",
      },
    },
    timerLessonSpan: {
      height: "100%",
      display: "inline-flex",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    timerLessonSpanPadding: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "1rem",
      },
    },
    iconPadding: {
      marginRight: "6px",
    },

    barStyle: {
      width: `${progress}%`,
      height: "100%",
      backgroundColor: "#3C465F",
      display: "flex",
    },
    progressText: {
      color: "rgba(100, 100, 255, 1)",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      paddingLeft: "8px",
    },
    percentageText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "rgba(100, 100, 255, 1)",
      paddingRight: "8px",
      marginLeft: "auto",
    },
    checkmarkText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: "#3C465F",
    },
  }));

  const useStylesRed = makeStyles((theme) => ({
    barStyle: {
      width: `${progress}%`,
      height: "100%",
      backgroundColor: "#FFB0CF",
      display: "flex",
    },
    progressText: {
      textTransform: "uppercase",
      color: "#C94277",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      paddingLeft: "8px",
    },
    percentageText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#C94277",
      paddingRight: "8px",
      marginLeft: "auto",
    },
    checkmarkText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: "#551A31",
    },
  }));

  const useStylesGreen = makeStyles((theme) => ({
    barStyle: {
      width: `${progress}%`,
      height: "100%",
      backgroundColor: "#3C465F",
      display: "flex",
    },
    progressText: {
      textTransform: "uppercase",
      color: "#A9DDD6",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      paddingLeft: "8px",
    },
    percentageText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#A9DDD6",
      paddingRight: "8px",
      marginLeft: "auto",
    },
    checkmarkText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: "#3C465F",
    },
  }));

  const useStylesOrange = makeStyles((theme) => ({
    barStyle: {
      width: `${progress}%`,
      height: "100%",
      backgroundColor: "#7B4023",
      display: "flex",
    },
    progressText: {
      textTransform: "uppercase",
      color: "#F4B393",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      paddingLeft: "8px",
    },
    percentageText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#F4B393",
      paddingRight: "8px",
      marginLeft: "auto",
    },
    checkmarkText: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: "#3C465F",
    },
  }));

  const [courseDetails, setCourseDetails] = useState<CourseDetailProps>();
  const [courseLessonsStatus, setCourseLessonsStatus] = useState<CourseLessonsStatusProps>();
  const [completedLessonCount, setCompletedLessonCount] = useState<number>(0)
  const [courseStatus, setCourseStatus] = useState<any | null>()
  const [percentage, setPercentage] = useState();

  var progress = courseDetails && completedLessonCount && Math.ceil(completedLessonCount / courseDetails.LessonCount * 100);

  progress = progress > 100 ? 100 : progress;

  var updateCourseId = id;
  if (id.includes("draft.")) {
    updateCourseId = id.replace("draft.", "");

    //console.log(id)
  }

  //console.log(updateCourseId)

  const getCourseDetails = async () => {

    await fetch(
      process.env.GATSBY_QUESTIONAPI +
      `/CourseDetails/CourseDetails?courseId=${updateCourseId}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // return responseJson;
        setCourseDetails(responseJson.Result)
      })
  }

  const getLessonsStatus = async () => {
    var model = {
      CourseId: updateCourseId,
      UserId: userId,
    }

    await fetch(process.env.GATSBY_QUESTIONAPI + '/LessonsStatus/LessonsStatus', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setCourseLessonsStatus(responseJson.Result);

      });
  }

  useEffect(() => {
    (async () => {
      getCourseDetails();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (courseDetails){
       getLessonsStatus();
      }
    })();
  }, [courseDetails])

  useEffect(() => {

    if (courseDetails) {
      calculatePercentage()
    }

  }, [courseLessonsStatus])


  const classes = useStyles(progress);

  let totalCount;

  // const [completedLessonCount, setCompletedLessonCount] = useState<number>(0)
  function calculatePercentage() {

    courseLessonsStatus.map((lesson) => {
      //console.log("lesson", lesson.LessonId)
      // console.log(completedLessonCount + 1)
      if (lesson.Status === "Complete"){
        setCompletedLessonCount(completedLessonCount => completedLessonCount + 1)
      }
    })


  };

  const getCourseStatus = async () => {
    var model = {
      CourseId: updateCourseId,
      UserId: userId,
    }

    await fetch(process.env.GATSBY_QUESTIONAPI + '/CourseStatus/CourseStatus', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setCourseStatus(responseJson.Result)
        // const buttonState = {
        //   courseStatus: responseJson.Result,
        //     id: courseDetails.CourseId}
        // };
        setCardCourses(prevState => [...prevState, {
          courseStatus: responseJson.Result,
          id: courseDetails.CourseId
        }])
      });

  }

  useEffect(() => {
    (async () => {
      if (courseDetails){
        getCourseStatus();
      }
    })();
  }, [courseDetails]);


  return (
    <>
      {courseStatus && courseStatus === "Not started" &&(
        <Grid item>
          <Grid className={classes.lessonTimeContainer}>
            <span className={classes.timerLessonSpan}>
              <img src={TimerGrey} className={classes.iconPadding}/> {courseDetails && courseDetails.TimeToComplete}
            </span>
            <span
              className={`${classes.timerLessonSpan} ${classes.timerLessonSpanPadding}`}
            >
              <img src={LessonGrey} className={classes.iconPadding}/> {courseDetails && courseDetails.LessonCount} lessons
            </span>
          </Grid>
        </Grid>
      )}
      {!courseStatus &&(
        <Grid item>
          <Grid className={classes.lessonTimeContainer}>
            <span className={classes.timerLessonSpan}>
              <img src={TimerGrey} className={classes.iconPadding}/> {courseDetails && courseDetails.TimeToComplete}
            </span>
            <span
              className={`${classes.timerLessonSpan} ${classes.timerLessonSpanPadding}`}
            >
              <img src={LessonGrey} className={classes.iconPadding}/> {courseDetails && courseDetails.LessonCount} lessons
            </span>
          </Grid>
        </Grid>
      )}

      {courseStatus && courseStatus === "Completed" && (
        <div className={classes.container}>
          <div>
            <span className={classes.checkmarkText}>
              <img className={classes.checkmarkIcon} src={CheckmarkGrey} />{" "}
              Completed
            </span>
          </div>
        </div>
      )}
      {courseStatus && courseStatus === "Started" && (
        <div className={classes.container}>
          <div className={classes.parentBar}>
            <div className={classes.barStyle}></div>

            <div className={classes.progressTextPercentageHolder}>
              <span className={classes.progressText}>
                In Progress
              </span>
              <span className={classes.percentageText}>
                {progress}%
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseStatusBar;
