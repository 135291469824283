import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { buildImageObj } from "../../../../lib/helpers";
import { imageUrlFor } from "../../../../lib/image-url";
import PortableText from "../../../portableText";
import serializers from "../../../serializers";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const useStyles = makeStyles((theme) => ({
  mobileSlide: {
    background: '#FFFFFF',
    borderRadius: '8px',
    height: '100%',
    position: 'relative'
  },
  titleContainer: {
    padding: '16px'
  },
  paragraphContainerNoImg: {
    padding: '16px',
    [theme.breakpoints.down("md")]:{
      padding: 0,
      margin: "16px",
      maxHeight: "430px",
      overflowY: "scroll",
      scrollbarColor: "rgba(148, 164, 214, 1) rgba(148, 164, 214, .4)",
      scrollbarWidth: "thin",
    }
  },
  paragraphContainer: {
    padding: '16px',
    [theme.breakpoints.down("md")]:{
      padding: 0,
      margin: "16px 16px 16px 16px",
      maxHeight: "120px",
      overflowY: "scroll",
      scrollbarColor: "rgba(148, 164, 214, 1) rgba(148, 164, 214, .4)",
      scrollbarWidth: "thin",
      // paddingRight: "9px",
      "& > div":{
        "& .MuiTypography-body1":{
          paddingBottom: "0 !important"
        }
      }
    },
  },
  mobileImage: {
    maxWidth: '100%',
    width: '100%',
    display: 'block'
  },
  mobileBottomImage: {
    width: '100%',
    display: 'block',
    position: 'absolute',
    maxWidth: 'calc(100% - 32px)',
    left: '16px',
    bottom: '16px'
  },
  title: {
    display: 'block',
    color: '#262641',
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold'
  },
  noPaddingBottom: {
    paddingBottom: '0 !important'
  }
}));

export default function MobileSlide(props) {
  const { tab } = props;

  const classes = useStyles();

  var img = null;
  if (typeof tab.image !== 'undefined') {
    img = imageUrlFor(buildImageObj(tab.image))
  }

  return (

    <div container className={classes.mobileSlide} direction="row" alignItems="flex-start" justify="flex-start">
      <div item xs={12} className={`${classes.titleContainer} ${(tab.imagePosition === 'Bottom left' || tab.imagePosition === 'Bottom right') ? classes.noPaddingBottom : ''}`}>
        <label className={classes.title}>{tab.title}</label>
      </div>
      {img && (tab.imagePosition === 'Top left' || tab.imagePosition === 'Top right') && <img className={classes.mobileImage} src={img
        .width(300)
        .height(300)
        .fit("crop")
        .url()}
        alt="" />
      }
      <div item xs={12} className={img.options.source ? classes.paragraphContainer : classes.paragraphContainerNoImg}>
        <PortableText serializers={serializers} blocks={tab._rawTextParagraph} />
      </div>
      {img && (tab.imagePosition === 'Bottom left' || tab.imagePosition === 'Bottom right') && <img className={classes.mobileBottomImage} src={img
        .width(300)
        .height(300)
        .fit("crop")
        .url()}
        alt="" />
      }
    </div>
  );
}
