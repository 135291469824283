import React from "react";
import { Grid } from "@material-ui/core";
import SocialFeed from "../../SocialFeed/socialFeed";

export default function SocialMediaPosts(props) {
  const { socialPost } = props;

  //console.log("socialPost", socialPost);

  if (socialPost.length > 0) {
    return (
      <>
          {socialPost && <SocialFeed socialPost={socialPost} />}
      </>
    );
  } else {
    return null;
  }
}
