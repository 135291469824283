import * as React from "react";
import LeftOneBigByRightTwoSmallCard from "./LeftOneBigByRightTwoSmallCard";
import BigBlockCard from "./BigBlockCard";
import { useEffect, useState, useMemo, useCallback, useLayoutEffect } from "react";

type contentCard = {
  Qc: string,
  Sc: boolean,
  aspectRatio: number,
  categories: [],
  clicked: false,
  created: Date,
  description: string
  dismissed: boolean
  dismissible: boolean
  expiresAt: Date,
  extras: any
  ha: null,
  id: string,
  imageUrl: string,
  linkText: string,
  pinned: boolean
  test: boolean
  title: string,
  updated: Date,
  url: string,
  viewed: boolean,
  wd: null
}

type contentCards = {
  cards: contentCard[],
  lastUpdated: Date
}

function filterOutDuplicates(arr) {
  const encounteredTitles = {};
  return arr?.cards?.filter(item => {
    if (!encounteredTitles[item.title]) {
      encounteredTitles[item.title] = true;
      return true;
    }
    return false;
  });
}

function sortByUpdatedDescending(arr: contentCard[]) {
  return arr.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
}

// Filter content cards by CMS supplied Key Value pair
function filterArrayByKeyAndValue(arr, key, value) {
  const filteredArray = arr.filter((item) => {
    if (item.extras.hasOwnProperty(key)) {
      return item.extras[key] === value;
    }
    return false;
  });
  return filteredArray;
}
export const RenderBigBlockCards = ({
  bigBlockItems,
  layout,
  isCoursePage,
  styles,
  bigCardRender,
  equaliseHeight,
  titleLineClamp,
  paragraphLineClamp,
  clampClasses,
  enableSwiper,
  type,
  contentCardKeyValuePair,
  firstname
}) => {
  const [brazeCards, setBrazeCards] = useState({})
  const [hasError, setHasError] = useState(false)
  const [content, setContent] = useState({})
  const [contentLoaded, setContentLoaded] = useState(false)
  const [componentLoaded, setComponentLoaded] = useState(false);



  useLayoutEffect(() => {
    setComponentLoaded(true)
    return () => setComponentLoaded(false)
  }, [])

  useLayoutEffect(() => {
    if (componentLoaded) {
      try {
        if (type === 'contentCardBigBlockGroup') {

          const brazeUser = window.getBrazeUser()
          console.log('brazeUser', brazeUser)

          const contentCards = window.brazeCardsTest()
          console.log('contentCards window', contentCards)

          // const subContentCards = window?.getContentCards()
          // setContent(subContentCards)

          // console.log('subContentCards', subContentCards);

          const contentCardsArr = contentCards?.cards
          console.log('contentCards array', contentCardsArr)

          window.logCardImpression(contentCards)

          if (Object.keys(contentCardsArr).length === 0) {
            setHasError(true)
            setContentLoaded(true)
          }

          if (Object.keys(contentCardsArr).length > 0) {
            console.log('if statement has been reached')
            setHasError(false)
            const uniqueCards = filterOutDuplicates(contentCards);
            const sortedAndUnique = sortByUpdatedDescending(uniqueCards)
            console.log("sorted unique", sortedAndUnique)
            if (contentCardKeyValuePair && sortedAndUnique?.length > 0) {
              const {contentCardKey, contentCardValue} = contentCardKeyValuePair
              const filteredData = filterArrayByKeyAndValue(sortedAndUnique, contentCardKey, contentCardValue)
              if (Object.keys(filteredData).length === 0) {
                setHasError(true)
              } else {
                setBrazeCards(filteredData)
                setContentLoaded(true)
              }
            } else {
              setBrazeCards(sortedAndUnique)
              setContentLoaded(true)
            }
          }
        }
      } catch (error) {
        console.log('useEffect Error', error)
      }
    }
  }, [componentLoaded])

  if (bigBlockItems) {
    return bigBlockItems?.map(
      (card: any, index: number, arr: string | any[]) => {
        const { title, linkText, _rawIntroPara, reference, mainImage } = card;
        let mainImageWithType = mainImage;

        if (mainImage != null) {
          mainImageWithType._type = "mainImage";
          if (title || linkText || _rawIntroPara) {
            mainImageWithType.isBigBlockGroupImage = true;
          } else {
            mainImageWithType.isBigBlockGroupSingleImage = true;
          }
        }

        let isLast;

        if (index + 1 === arr.length) {
          isLast = true;
        } else {
          isLast = false;
        }

        // const sortedCards = brazeCards || storedArray

        switch (layout) {
          case "Left 1xbig, Right 2xsmall":
            if (index > 0 && bigCardRender) {
              return null;
            }
            if (index === 0 && !bigCardRender) {
              return null;
            }
            return (
              <LeftOneBigByRightTwoSmallCard
                title={title}
                linkText={linkText}
                rawIntroPara={_rawIntroPara}
                reference={reference}
                mainImageWithType={mainImageWithType}
                primaryCard={index === 0}
                equaliseHeight={equaliseHeight}
                clampClasses={clampClasses}
                styles={styles}
                firstname={firstname}
                brazeCards={brazeCards}
                brazeTitle={brazeCards[index]?.title}
                brazeImgUrl={brazeCards[index]?.imageUrl}
                brazeDescription={brazeCards[index]?.description}
                brazeRedirectUrl={brazeCards[index]?.url}
                ctaLink={brazeCards[index]?.linkText}
                type={type}
                hasError={hasError}
              />
            );
          default:
            return (
              <BigBlockCard
                title={title}
                brazeTitle={brazeCards[index]?.title}
                brazeImgUrl={brazeCards[index]?.imageUrl}
                brazeDescription={brazeCards[index]?.description}
                brazeRedirectUrl={brazeCards[index]?.url}
                ctaLink={brazeCards[index]?.linkText}
                linkText={linkText}
                rawIntroPara={_rawIntroPara}
                reference={reference}
                mainImageWithType={mainImageWithType}
                isCoursePage={isCoursePage}
                styles={styles}
                equaliseHeight={equaliseHeight}
                clampClasses={clampClasses}
                paragraphLineClamp={paragraphLineClamp}
                layout={layout}
                isSwiperCard={enableSwiper}
                type={type}
                hasError={hasError}
                brazeCards={brazeCards}
                contentLoaded={contentLoaded}
                firstname={firstname}
              />
            );
        }
      }
    );
  }
};
